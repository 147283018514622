import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { HostStats } from 'src/app/models/stats';
@Component({
  selector: 'app-user-host-stats',
  templateUrl: './user-host-stats.component.html',
  styleUrls: ['./user-host-stats.component.scss']
})
export class UserHostStatsComponent implements OnInit {
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @Input() hostStats: HostStats[] = [];

  public displayedColumns = ['hostName', 'totalTime', 'totalCost'];
  public dataSource = new MatTableDataSource<HostStats>([]);

  constructor() {}

  ngOnInit(): void {
    this.dataSource.data = this.hostStats;
    this.dataSource.sort = this.sort;
  }
}
