import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TerminalComponent } from '../terminal/terminal.component';

@Component({
  selector: 'app-screen-dialog',
  templateUrl: './screen-dialog.component.html',
  styleUrls: ['./screen-dialog.component.scss']
})
export class ScreenDialogComponent {
  @ViewChild('terminal') terminal: TerminalComponent;

  public terminalId = new Date().getTime().toString();
  constructor(
    public dialogRef: MatDialogRef<ScreenDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onNoClick(): void {
    this.terminal.disconnect();
    this.dialogRef.close();
  }
}
