import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-omnibar',
  templateUrl: './omnibar.component.html',
  styleUrls: ['./omnibar.component.scss']
})
export class OmnibarComponent {
  @Input() disabled: boolean = false;
  @Input() value: string = '';
  @Output() keyup = new EventEmitter<KeyboardEvent>();
}
