import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime } from 'rxjs';
import { Meta } from 'src/app/models/meta';
import { StreamsService } from 'src/app/services/streams.service';
import { DataService } from 'src/app/services/data.service';
import { RawService } from 'src/app/services/raw.service';
import { DynamicFormComponent } from '../../dynamic-form/dynamic-form.component';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClient } from '@angular/common/http';
import { TeamsService } from 'src/app/services/teams.service';

@Component({
  selector: 'app-stream',
  templateUrl: './stream.component.html',
  styleUrls: ['./stream.component.scss']
})
export class StreamComponent implements OnInit {
  @ViewChild(DynamicFormComponent) parameterForm: DynamicFormComponent;

  public isLoading: boolean = true;
  public teamName: string = null;
  public streamName: string = null;
  public availableAdapters: any = [];
  public rawPathsAll: any = [];
  public rawPathsFiltered: any = [];
  public meta: Meta = null;
  public dynamicFormData: any = null;
  public isNew: boolean = false;

  private stream: any;
  private teamConfig: any;

  public saveStatus: 'COMPLETE' | 'ERROR' | 'SAVING' = 'COMPLETE';
  public errors: string[] = [];

  public adapterForm = new FormGroup({
    adapterName: new FormControl<any>('', [Validators.required, Validators.minLength(3)]),
    paths: new FormControl<any>('')
  });

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private streamsService: StreamsService,
    private teamsService: TeamsService,
    private dataService: DataService,
    private rawService: RawService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      const urlParts = this.router.url.split('/');

      if (urlParts[urlParts.length - 1] === 'add') {
        this.initNew(params.team);
      } else {
        this.load(params.team, params.streamName);
      }
    });
  }

  async initNew(teamName: string) {
    this.isNew = true;
    this.teamName = teamName;
    this.stream = {
      adapter: {
        name: null,
        paths: null
      },
      parameters: {}
    };

    this.loadAdapters();
    this.loadPaths();
    this.subcribeToFormChanges();
    this.isLoading = false;
  }

  async load(teamName: string, streamName: string) {
    this.teamName = teamName;
    this.streamName = streamName;

    await this.loadAdapters();
    await this.loadPaths();

    const sub = this.streamsService.get(this.teamName, this.streamName).subscribe((response: any) => {
      sub.unsubscribe();
      this.stream = response;

      this.meta = { params: [], returns: null };
      this.dynamicFormData = { id: Math.random().toString(36).substring(2, 9) };
      const adapter = this.availableAdapters.find((x) => x.name === this.stream.adapter.name);

      for (let param of adapter.params) {
        this.meta.params.push({
          name: param.name,
          description: null,
          type: param.type,
          required: true,
          options: []
        });
      }

      if (this.stream.hasOwnProperty('parameters')) {
        const keys = Object.keys(this.stream.parameters);
        for (let key of keys) {
          this.dynamicFormData[key] = this.stream.parameters[key];
        }
      }

      this.adapterForm.controls['adapterName'].disable();
      this.subcribeToFormChanges();
      this.isLoading = false;
    });
  }

  async loadAdapters() {
    this.availableAdapters = await this.dataService.loadAdaptersAsync(this.teamName);
  }

  async loadPaths() {
    const rawData: any = await this.rawService.getAsync(this.teamName);
    this.rawPathsAll = rawData.map((x) => x.fullPath);
    this.rawPathsFiltered = this.rawPathsAll;
  }

  public subcribeToFormChanges() {
    this.adapterForm.setValue({
      adapterName: this.stream?.adapter?.name,
      paths: this.stream?.adapter?.paths || null
    });

    this.adapterForm.valueChanges.pipe(debounceTime(2000)).subscribe((value: any) => {
      const raw = this.adapterForm.getRawValue();
      this.stream.adapter.name = raw.adapterName;
      this.stream.adapter.paths = raw.paths;
      this.stream.adapter['skip-cache'] = true;
    });
  }

  applyFilter(filterValue: string) {
    if (!filterValue || filterValue.length === 0) {
      this.rawPathsFiltered = this.rawPathsAll;
      return;
    }

    this.rawPathsFiltered = this.rawPathsAll.filter((s) => s.includes(filterValue));
  }

  async save() {
    this.saveStatus = 'SAVING';
    const adapter = this.adapterForm.getRawValue();
    const params = this.parameterForm.getFormData();

    this.stream.adapter.name = adapter.adapterName;
    this.stream.adapter.paths = adapter.paths;

    const keys = Object.keys(params);
    for (let key of keys) {
      if (key === 'id') continue;

      this.stream.parameters[key] = params[key];
    }

    if (!(await this.isValid())) return;

    if (!this.teamConfig) {
      this.teamConfig = await this.teamsService.getConfigAsync(this.teamName);
    }

    const path = `${this.teamConfig.path.streams}/${this.streamName.replace(' ', '_')}.json`;
    this.postFile({ path: path, type: 'json', data: this.stream });
  }

  async delete() {
    if (!confirm(`Are you sure you want to delete ${this.streamName}?`)) return;

    await this.streamsService.deleteAsync(this.teamName, this.streamName);
    this.router.navigate(['/', this.teamName, 'model', 'streams']);
  }

  postFile(data: any) {
    const sub = this.http.post(`${ConfigService.apiUrl}/file/${this.teamName}`, data).subscribe({
      error: (error) => {
        sub.unsubscribe();
        this.saveStatus = 'ERROR';
      },
      complete: () => {
        sub.unsubscribe();
        this.saveStatus = 'COMPLETE';
        if (this.isNew) {
          this.router.navigate(['/', this.teamName, 'model', 'stream', this.streamName]);
        }
      }
    });
  }

  onAdapterSelected(event) {
    const adapter = this.availableAdapters.find((x) => x.name === event.value);
    this.meta = { params: [], returns: null };
    this.dynamicFormData = { id: Math.random().toString(36).substring(2, 9) };
    this.stream.adapter.name = adapter.name;

    for (let param of adapter.params) {
      this.meta.params.push({
        name: param.name,
        description: null,
        type: param.type,
        required: true,
        options: []
      });

      this.stream.parameters[param.name] = null;
      this.dynamicFormData[param.name] = null;
    }
  }

  async isValid() {
    this.errors = [];

    if (!this.streamName || this.streamName.length < 1) this.errors.push('Stream name is required');
    else if (this.isNew) {
      const streams: any = await this.streamsService.loadAsync(this.teamName);
      const stream = streams.find((x) => x.name === this.streamName);
      if (stream) {
        this.errors.push('Stream with this name already exists');
      }
    }

    if (!this.adapterForm.valid) this.errors.push('Adapter is required');

    const keys = Object.keys(this.stream.parameters);
    for (let key of keys) {
      if (!this.stream.parameters[key]) {
        this.errors.push(`${key} is required`);
      }
    }

    const valid = this.errors.length === 0;
    this.saveStatus = valid ? 'SAVING' : 'ERROR';
    return valid;
  }
}
