import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { Meta } from '../models/meta';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MetaService {
  constructor(private http: HttpClient) {}

  public async getAsync(type: string, teamName: string, name: string) {
    const get$ = this.http.get<Meta>(`${ConfigService.apiUrl}/meta/${teamName}/${type}/${name}`);

    return lastValueFrom<Meta>(get$);
  }
}
