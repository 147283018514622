import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subscription, interval, switchMap } from 'rxjs';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class HeartbeatService {

  private heartbeatSubscription: Subscription | null = null;
  private heartbeatInterval = 3 * 60 * 1000; // 3 minutes in milliseconds
  private heartbeatUrl = `${ConfigService.apiUrl}/heartbeat`;

  constructor(private http: HttpClient) {}

  startHeartbeat(): void {
    if (this.heartbeatSubscription) {
      this.stopHeartbeat();
    }

    this.heartbeatSubscription = interval(this.heartbeatInterval)
      .pipe(
        switchMap(() => {
          return this.http.get(this.heartbeatUrl);
        })
      ).subscribe((response) => {
        // console.debug(response);
      })
  }

  stopHeartbeat(): void {
    if (this.heartbeatSubscription) {
      this.heartbeatSubscription.unsubscribe();
      this.heartbeatSubscription = null;
    }
  }
}
