// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  padding: 10px;
}

table {
  width: 100%;
}

.terminal-button > mat-icon {
  transform: scale(1.3333333333);
}

.trust-check {
  display: flex;
}

.compact {
  height: 38px !important;
}

.compact mat-cell,
.compact mat-cell-header {
  font-size: 10pt;
}

.divider {
  padding-top: 20px;
}

.save-btn {
  margin-right: 30px;
  margin-bottom: 15px;
}

.init {
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 10pt;
  font-family: "Roboto";
}`, "",{"version":3,"sources":["webpack://./src/app/components/devices/host/host.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,8BAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,uBAAA;AACF;;AAEA;;EAEE,eAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,kBAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,eAAA;EACA,qBAAA;AACF","sourcesContent":[".container {\n  padding: 10px;\n}\n\ntable {\n  width: 100%;\n}\n\n.terminal-button > mat-icon {\n  transform: scale(calc(32px / 24px));\n}\n\n.trust-check {\n  display: flex;\n}\n\n.compact {\n  height: 38px !important;\n}\n\n.compact mat-cell,\n.compact mat-cell-header {\n  font-size: 10pt;\n}\n\n.divider {\n  padding-top: 20px;\n}\n\n.save-btn {\n  margin-right: 30px;\n  margin-bottom: 15px;\n}\n\n.init {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n  font-size: 10pt;\n  font-family: 'Roboto';\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
