import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
import { debounce } from './debounce.decorator';

@Directive({
  selector: '[formControlName][appCurrencyMask]'
})
export class CurrencyMaskDirectiveDirective {
  private el: HTMLInputElement;
  private cursorPos: number;

  constructor(
    public ngControl: NgControl,
    private elementRef: ElementRef
  ) {
    this.el = this.elementRef.nativeElement;
  }

  @HostListener('ngModelChange', ['$event'])
  @debounce(500)
  onModelChange(event) {
    this.onInputChange(event);
  }

  ngOnInit() {
    this.onInputChange(this.ngControl.value);
  }

  onInputChange(event) {
    if (!event) return;

    let newVal = event.replace(/[^0-9.]/g, '');
    if (newVal.includes('.')) {
      const parts = newVal.split('.');
      let cents = parts[1].length <= 2 ? parts[1] : parts[1].slice(0, 2);
      newVal = `${parts[0]}.${cents}`;
    }

    newVal = newVal.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    this.ngControl.valueAccessor.writeValue(newVal);
  }
}
