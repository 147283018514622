import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SchemasService } from 'src/app/services/schemas.service';

export namespace SchemasComponent {
  export type SelectSchemaEvent = { rdsName: string; projectName: string };
}

@Component({
  selector: 'app-schemas',
  templateUrl: './schemas.component.html',
  styleUrls: ['./schemas.component.scss']
})
export class SchemasComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  @Input() displayedColumns: string[] = ['name', 'user', 'lastModified'];
  @Input() isWidget: boolean = false;

  @Output() onSelect = new EventEmitter<SchemasComponent.SelectSchemaEvent>();
  @Output() onNew = new EventEmitter();

  teamName: string;

  sortOrder: string[] = ['isRunning'];
  autoColumns: string[] = [];
  dataSource = new MatTableDataSource<any>([]);
  public availableColumns = ['host', 'name', 'project', 'path', 'lastModified', 'user'];
  manualColumns = ['isRunning'];
  pageSize = 10;
  isLoading = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    public router: Router,
    private schemasService: SchemasService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.teamName = params.team;
      this.load();
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) this.dataSource.paginator.firstPage();
  }

  public async load() {
    const response: any = await this.schemasService.loadAsync(this.teamName);
    if (response.length > 0) {

      const dateFields = ['created', 'lastModified'];
      response.forEach((row) => {
        dateFields.forEach((field) => {
          row[field] = new Date(row[field] * 1000);
        });
      });

      // sort columns by this.sortOrder
      var get_true_index = (list, item) => {
        var temp = list.indexOf(item);
        return temp < 0 ? list.length : temp;
      };
      this.displayedColumns.sort((a, b) => get_true_index(this.sortOrder, a) - get_true_index(this.sortOrder, b));

      // compute autoColumns (all columns except those rendered manually)
      this.autoColumns = this.displayedColumns.filter((x) => this.manualColumns.indexOf(x) == -1);
    }
    this.isLoading = false;
    this.dataSource.data = response;
  }

  openFile({ name, project }: { name: string; path: string; host: string; tags: string; project: string }) {
    if (this.isWidget) this.onSelect.emit({ rdsName: name, projectName: project });
    else this.router.navigate(['/', this.teamName, 'data', 'schema', name]);
  }

  add() {
    if (this.isWidget) this.onNew.emit();
    else this.router.navigate(['/', this.teamName, 'data', 'schema', 'add']);
  }
}
