import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Asset } from '../models/asset';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SampleService {
  constructor(private http: HttpClient) {}

  public get(teamName: string, type: string, assetName: string, fileName: string) {
    return this.http.get(
      `${ConfigService.apiUrl}/data/sample/${teamName}/${type}/${assetName}/${encodeURI(fileName.replace(/\//g, '\\'))}`
    );
  }

  public getSample(teamName: string, type: string, assetName: string, fileName: string, sample: number) {
    return this.http.get(
      `${ConfigService.apiUrl}/data/sample/${teamName}/${type}/${assetName}/${encodeURI(fileName.replace(/\//g, '\\'))}/${sample}`
    );
  }
}
