// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress {
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 15px;
}

.title {
  color: rgba(0, 0, 0, 0.4);
  font-size: 13.5px;
  margin: 3px;
  width: 150px;
}

#dynamic-form-container {
  height: 75px;
  padding-bottom: 5px;
}

.save {
  border-color: rgba(0, 0, 0, 0.1);
  margin-bottom: 3px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/model-selector/model-selector.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,mBAAA;EACA,eAAA;AACF;;AAEA;EACE,yBAAA;EACA,iBAAA;EACA,WAAA;EACA,YAAA;AACF;;AAEA;EACE,YAAA;EACA,mBAAA;AACF;;AAEA;EACE,gCAAA;EACA,kBAAA;AACF","sourcesContent":[".progress {\n  margin-top: 5px;\n  margin-bottom: 10px;\n  font-size: 15px;\n}\n\n.title {\n  color: rgba(0, 0, 0, 0.4);\n  font-size: 13.5px;\n  margin: 3px;\n  width: 150px;\n}\n\n#dynamic-form-container {\n  height: 75px;\n  padding-bottom: 5px;\n}\n\n.save {\n  border-color: rgba(0, 0, 0, 0.1);\n  margin-bottom: 3px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
