import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StreamsService } from 'src/app/services/streams.service';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit {
  public teamName: string;
  public streamName: string;
  public isLoading = true;
  public dataFeedUrl: string;
  public ticker: string;
  public timezone: string;
  public updateFrequency: number = 10;

  constructor(
    private activatedRoute: ActivatedRoute,
    private streamsService: StreamsService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.load(params.team, params.streamName);
    });
  }

  async load(teamName: string, streamName: string) {
    this.teamName = teamName;
    this.streamName = streamName;

    const retval: any = await this.streamsService.getTimezoneAsync(this.teamName);
    if (retval) {
      this.timezone = retval.timezone;
    }

    const sub = this.streamsService.get(this.teamName, this.streamName).subscribe((response: any) => {
      sub.unsubscribe();

      if (response.hasOwnProperty('parameters')) {
        const keys = Object.keys(response.parameters);
        if (keys.includes('ticker')) {
          this.ticker = response.parameters.ticker;
        }

        if (keys.includes('update_frequency_secs')) {
          this.updateFrequency = response.parameters.update_frequency_secs;
        }
      }

      this.dataFeedUrl = `${ConfigService.udfUrl}/${this.teamName}/${this.streamName}`;
      this.isLoading = false;
    });
  }
}
