import { Component, OnInit } from '@angular/core';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';

@Component({
  selector: 'app-gpus-widget',
  templateUrl: './gpus-widget.component.html',
  styleUrls: ['../base-widget/base-widget.component.scss', './gpus-widget.component.scss']
})
export class GpusWidgetComponent extends BaseWidgetComponent implements OnInit {
  constructor() {
    super();
  }

  ngOnInit(): void {}
}
