import { Injectable } from '@angular/core';
import { SerializedWorkflow, Workflow } from '../models/workflow';
import { Subject } from 'rxjs';
import { Connection } from '../models/workflow-connection';
import { v4 as uuidv4 } from 'uuid';
import { Node } from '../models/workflow-node';
import { Point } from '../models/point';

@Injectable({ providedIn: 'root' })
export class WorkflowService {
  private _workflow: Workflow;
  private _workflow$ = new Subject<Workflow>();
  public workflow$ = this._workflow$.asObservable();

  private _next = () => this._workflow$.next(this._workflow);
  public get workflow() {
    return this._workflow;
  }

  public getNewPosition(): Point {
    const minDistance = 85;
    let angle = 0;
    let radius = 100;
    let c = 0;

    const getNextPoint = () => {
      let x = Math.cos(angle) * radius;
      let y = Math.sin(angle) * radius;
      angle += (Math.PI * 2) / 12;
      if (angle > Math.PI * 2) {
        angle = 0;
        radius += 100;
      }
      return new Point(x, y);
    };

    let possible = new Point(0, 0);

    let tooClose: Node;
    do {
      tooClose = this._workflow.nodes.find((node) => node.position.distanceTo(possible) <= minDistance);

      if (tooClose) {
        possible = getNextPoint();
      }

      c++;
      if (c > 100) return possible;
    } while (tooClose);

    return possible;
  }

  constructor() {
    window.$workflow = this;
  }

  public load(json: SerializedWorkflow, options?: { scale?: number }): void {
    this._workflow = {
      id: json.id || uuidv4(),
      name: json.name || 'Workflow',
      description: json.description || 'Generic workflow',
      device: json.device,
      assets: json.assets,
      transfer: json.transfer,
      tags: json.tags ? json.tags.split(',') : [],
      nodes: [],
      connections: []
    };

    if (json) {
      this._workflow.nodes = json.nodes.map((n) => new Node({ ...n, scale: options?.scale ?? 1 }));
      this._workflow.connections = json.connections
        .map((c) => {
          const from = this._workflow.nodes.find((n) => n.id === c.from);
          if (!from) return null;
          const to = this._workflow.nodes.find((n) => n.id === c.to);
          if (!to) return null;
          const { id, animated } = c;
          return new Connection(from, to, id, animated);
        })
        .filter((c) => c !== null) as Connection[];
    }

    this._next();
  }

  public update(data: Partial<Workflow>): void {
    Object.assign(this._workflow, data);

    this._next();
  }

  public getNodeByID(id: string): Node | undefined {
    const filtered = this._workflow.nodes.filter((n) => n.id === id);
    return filtered.length === 1 ? filtered[0] : undefined;
  }

  public getConnectionByID(id: string): Connection | undefined {
    const filtered = this._workflow.connections.filter((n) => n.id === id);
    return filtered.length === 1 ? filtered[0] : undefined;
  }

  public insertNode(data: Partial<Node>): void {
    this._workflow.nodes.push(new Node({ ...data, position: this.getNewPosition() }));

    this._next();
  }

  public selectNode(id?: string): void {
    this._workflow.selectedNode = id && this._workflow.nodes.map((n) => n.id).includes(id) ? id : undefined;
    this._workflow.selectedConnection = undefined;

    this._next();
  }

  public updateNode(id: string, data: Partial<Node>): void {
    Object.assign(this.getNodeByID(id), data);
    this._workflow.selectedNode = undefined;

    this._next();
  }

  public removeNode(id: string): void {
    this.update({
      nodes: this._workflow.nodes.filter((n) => n.id !== id),
      connections: this._workflow.connections.filter((c) => c.from.id !== id && c.to.id !== id),
      selectedNode: undefined
    });

    this._next();
  }

  public createConnection(from: Node, to: Node) {
    const [refFrom, refTo] = [this.getNodeByID(from.id), this.getNodeByID(to.id)];
    let exists = false;
    for (const conn of this._workflow.connections) {
      if (conn.from === refFrom && conn.to === refTo) {
        exists = true;
        break;
      }
    }
    if (!exists) {
      this._workflow.connections.push(new Connection(refFrom, refTo));

      this._next();
    }
  }

  public selectConnection(id?: string): void {
    this._workflow.selectedConnection = id && this._workflow.connections.map((n) => n.id).includes(id) ? id : undefined;
    this._workflow.selectedNode = undefined;

    this._next();
  }

  public updateConnection(id: string, data: Partial<Connection>): void {
    Object.assign(this.getConnectionByID(id), data);
    this._workflow.selectedConnection = undefined;

    this._next();
  }

  public removeConnection(id: string): void {
    this.update({
      connections: this._workflow.connections.filter((c) => c.id !== id),
      selectedConnection: undefined
    });

    this._next();
  }

  public addTag(tag: string): void {
    this._workflow.tags.push(tag);

    this._next();
  }

  public removeTag(tag: string): void {
    let set = new Set(this._workflow.tags);
    set.delete(tag);
    this._workflow.tags = [...set];

    this._next();
  }

  public export = (): SerializedWorkflow => ({
    id: this._workflow.id,
    name: this._workflow.name,
    description: this._workflow.description,
    assets: this._workflow.assets,
    transfer: this._workflow.transfer,
    device: this._workflow.device,
    tags: this._workflow.tags.join(','),
    nodes: this._workflow.nodes.map(Node.serialize),
    connections: this._workflow.connections.map(Connection.serialize)
  });
}
