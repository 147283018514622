import { Component, Input, OnChanges, ViewChild, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { PerfDataEntry, PerfService } from 'src/app/services/perf.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { VaultService } from 'src/app/services/vault.service';
import {
  SciChartSurface,
  TSciChart,
  NumericAxis,
  NumberRange,
  XyDataSeries,
  FastLineRenderableSeries,
  SweepAnimation,
  LegendModifier,
  DataPointSelectionModifier,
  RolloverModifier,
  SeriesInfo,
  Point,
  DataPointSelectionChangedArgs,
  RolloverTooltipSvgAnnotation,
  MouseWheelZoomModifier,
  XAxisDragModifier,
  YAxisDragModifier,
  ZoomExtentsModifier,
  ZoomPanModifier,
  EExecuteOn,
  TAxisTitleStyle,
  SciChartOverview,
  IThemeProvider,
  FastMountainRenderableSeries,
  IRenderableSeries,
  CustomAnnotation,
  EHorizontalAnchorPoint,
  EAutoRange,
  EVerticalAnchorPoint
} from 'scichart';
import { Run } from 'src/app/models/run';
import { unzip } from 'lodash';
import { limits } from 'src/app/directives/number.util';
import { SubscriptionContainer } from 'src/app/models/subscription-container';
import { SettingsService } from 'src/app/services/settings.service';
import { appTheme } from '../chart/material-theme';
import { toTitleCase } from 'src/app/directives/string.util';
import { WidgetService } from 'src/app/services/widget.service';
import { BaseWidgetComponent } from '../../strategy-grid/base-widget/base-widget.component';

enum AutoFit {
  /** Once, when the data is initially populated */
  ONCE = 'Once',
  /** Always, when the data is populated */
  ALWAYS = 'Always',
  /** Locked, removes user zoom/panning */
  LOCKED = 'Locked'
}

class SmartFitAnnotation extends CustomAnnotation {
  public analysis: string;

  public constructor(x1: number, y1: number, analysis: string) {
    super({
      x1,
      y1,
      verticalAnchorPoint: EVerticalAnchorPoint.Top,
      horizontalAnchorPoint: EHorizontalAnchorPoint.Center
    });
    this.analysis = analysis;
  }

  public getSvgString(annotation: CustomAnnotation): string {
    if (this.analysis == 'overfit') {
      return `<svg id="Capa_1" xmlns="http://www.w3.org/2000/svg">
        <g transform="translate(-54.867218,-75.091687)">
            <path style="fill:${appTheme.VividGreen};fill-opacity:0.77;stroke:${appTheme.VividGreen};stroke-width:2px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
                d="m 55.47431,83.481251 c 7.158904,-7.408333 7.158904,-7.408333 7.158904,-7.408333 l 7.158906,7.408333 H 66.212668 V 94.593756 H 59.053761 V 83.481251 Z"
            "/>
        </g>
    </svg>`;
    } else {
      return `<svg id="Capa_1" xmlns="http://www.w3.org/2000/svg">
        <g transform="translate(-54.616083,-75.548914)">
            <path style="fill:${appTheme.VividRed};fill-opacity:0.77;stroke:${appTheme.VividRed};stroke-width:2px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
            d="m 55.47431,87.025547 c 7.158904,7.408333 7.158904,7.408333 7.158904,7.408333 L 69.79212,87.025547 H 66.212668 V 75.913042 h -7.158907 v 11.112505 z"
            />
        </g>
    </svg>`;
    }
  }
}

type PerfChartData = {
  runid: number;
  name: string;
  series: PerfChartSeriesData[];
};

type PerfChartSeriesData = {
  name: string;
  runid: number;
  epoch: number;
  value: number;
  saved: number;
  all: PerfDataEntry;
};

@Component({
  selector: 'app-perf-chart',
  templateUrl: './perf-chart.component.html',
  styleUrls: ['./perf-chart.component.scss'],
  providers: [PerfService]
})
export class PerfChartComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild(MatMenuTrigger, { static: false }) matMenuTrigger: MatMenuTrigger;
  @ViewChild('root', { static: true }) root: ElementRef<HTMLDivElement>;

  @Input() teamName: string;
  @Input() runs: Run[];
  @Input() displayedColumns: string[] = [];
  @Input() legend: boolean = false;

  private _subs = new SubscriptionContainer();
  private _xAxis: NumericAxis;
  private _yAxis: NumericAxis;

  public chart: SciChartSurface | undefined = undefined;
  public overview: SciChartOverview | undefined = undefined;
  public wasm: TSciChart | undefined = undefined;
  public modifiers = {
    legend: new LegendModifier({
      showCheckboxes: true,
      placementDivId: 'perf-chart-legend'
    }),
    rollover: new RolloverModifier({
      allowTooltipOverlapping: false,
      snapToDataPoint: true
    }),
    dataPointSelection: new DataPointSelectionModifier({
      allowClickSelect: true,
      allowDragSelect: false,
      onSelectionChanged: this.$selectPoint.bind(this),
      executeOn: EExecuteOn.MouseRightButton
    }),
    mouseWheelZoom: new MouseWheelZoomModifier(),
    zoomPan: new ZoomPanModifier(),
    zoomExtents: new ZoomExtentsModifier(),
    xAxisDrag: new XAxisDragModifier(),
    yAxisDrag: new YAxisDragModifier()
  };
  public overviewProxyLine: IRenderableSeries;
  public overviewProxySeries: XyDataSeries;
  public autofit: AutoFit = AutoFit.ALWAYS;

  public contextMenuPosition = { x: '0px', y: '0px' };

  private get _theme(): IThemeProvider {
    return this._settingsService.getChartThemeProvider();
  }

  private _openSnackBar(message: string, action: string): void {
    const durationInSeconds = 2.5;
    const horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    const verticalPosition: MatSnackBarVerticalPosition = 'top';

    this._snackbar.open(message, action, {
      horizontalPosition,
      verticalPosition,
      duration: durationInSeconds * 1000
    });
  }

  constructor(
    private readonly _perf: PerfService,
    private readonly _vault: VaultService,
    private readonly _snackbar: MatSnackBar,
    private readonly _element: ElementRef<HTMLElement>,
    private readonly _settingsService: SettingsService,
    private readonly _widget: WidgetService
  ) {
    (<any>window).perfChart = this;
  }

  public async ngOnInit(): Promise<void> {
    this.initChart();

    this._subs.add = this._settingsService.theme$.subscribe(this.$changeTheme.bind(this));
    this._subs.add = this._settingsService.chartTheme$.subscribe(this.$changeTheme.bind(this));
  }

  public async ngOnChanges(): Promise<void> {
    if (this._yAxis) this._yAxis.axisTitle = this.getYAxisTitle();
    await this.loadRuns();
  }

  public ngOnDestroy(): void {
    if (this.chart) this.chart.delete();
    if (this.overview) this.overview.delete();
    this._subs.dispose();
  }

  public async initChart(): Promise<void> {
    const { sciChartSurface: chart, wasmContext: wasm } = await SciChartSurface.create('perf-chart', {
      theme: this._theme,
      disableAspect: true
    });

    const growBy = new NumberRange(0.1, 0.1);
    const axisTitleStyle: TAxisTitleStyle = { fontSize: 20, fontFamily: 'Roboto' };
    this._xAxis = new NumericAxis(wasm, {
      axisTitle: 'epoch',
      axisTitleStyle,
      growBy,
      labelPrecision: 0,
      visibleRangeLimit: new NumberRange(0, limits('u32')),
      autoRange: this.autofit === AutoFit.LOCKED ? EAutoRange.Always : EAutoRange.Once,
      majorDelta: 1,
      autoTicks: false
    });
    this._yAxis = new NumericAxis(wasm, {
      axisTitle: this.getYAxisTitle(),
      axisTitleStyle,
      growBy,
      labelPrecision: 4,
      autoRange: this.autofit === AutoFit.LOCKED ? EAutoRange.Always : EAutoRange.Once
    });
    chart.xAxes.add(this._xAxis);
    chart.yAxes.add(this._yAxis);

    chart.chartModifiers.add(...Object.values(this.modifiers));

    this.overviewProxySeries = new XyDataSeries(wasm, {
      containsNaN: false,
      isSorted: true
    });
    chart.renderableSeries.add(new FastLineRenderableSeries(wasm, { dataSeries: this.overviewProxySeries }));

    const overview = await SciChartOverview.create(chart, 'perf-chart-overview', {
      theme: this._theme,
      transformRenderableSeries: ({ dataSeries }) =>
        new FastMountainRenderableSeries(wasm, {
          dataSeries
        })
    });

    Object.assign(this, { chart, wasm, overview });
  }

  public async loadRun(run: Run): Promise<PerfChartData[] | undefined> {
    try {
      const { data, run: runid } = await this._perf.loadAsync(this.teamName, run.runid);
      if (runid !== run.runid) return undefined;
      if (!this.runs.find((x) => x.host === run.host && x.runid === run.runid)) return undefined;

      return this.displayedColumns
        .filter((x) => x != 'epoch')
        .map((col) => ({
          runid,
          name: `${runid} ${col}`,
          series: data.map((epoch) => ({
            name: epoch.epoch.toString(),
            runid,
            epoch: epoch.epoch,
            value: epoch[col],
            saved: epoch.saved,
            all: epoch
          }))
        }));
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  public async loadRuns(): Promise<void> {
    const runs: PerfChartData[] = (await Promise.all(this.runs.map(this.loadRun.bind(this)))).flat().filter((x) => x !== undefined) as any;

    const renderableSeries = this.getRenderableRuns(runs);
    if (this.overviewProxySeries) {
      this.overviewProxySeries.clear();
    }

    if (runs.length !== 0) {
      const [xValues, yValues] = this.mapRunToXY(runs[0]);
      if (xValues?.length && yValues?.length) this.overviewProxySeries.appendRange(...this.mapRunToXY(runs[0]));
    }

    this.chart?.renderableSeries.clear();
    this.chart?.renderableSeries.add(...renderableSeries);
  }

  public mapRunToXY = (run: PerfChartData) => unzip(run.series.map(({ epoch, value }) => [epoch, value])) as [number[], number[]];

  public getRenderableRuns(runs: PerfChartData[]): IRenderableSeries[] {
    this.chart?.annotations?.clear();
    if (this.autofit === AutoFit.ALWAYS) this.chart?.zoomExtents(250);
    return runs.map((data) => {
      const { name: dataSeriesName } = data;
      const [xValues, yValues] = this.mapRunToXY(data);

      data.series.forEach((dataPt: PerfChartSeriesData) => {
        if (dataPt.all.analysis) this.addSmartFitAnnotation(dataPt);
      });

      const line = new FastLineRenderableSeries(this.wasm, {
        dataSeries: new XyDataSeries(this.wasm, {
          dataSeriesName,
          xValues,
          yValues,
          containsNaN: false,
          isSorted: true,
          metadata: {
            isSelected: false,
            data
          }
        }),
        animation: new SweepAnimation({ duration: 300, fadeEffect: true })
      });

      line.rolloverModifierProps.tooltipTemplate = (id: string, seriesInfo: SeriesInfo, rolloverTooltip: RolloverTooltipSvgAnnotation) => {
        const { tooltipTitle, tooltipColor, tooltipTextColor } = rolloverTooltip.tooltipProps;
        const { formattedYValue } = seriesInfo;
        const width = 225;
        const height = 24;
        const colorBlockSize = 16;
        const padding = 4;

        rolloverTooltip.updateSize(225, 24);
        return `
        <svg width="${width}" height="${height}">
          <rect width="${width}" height="${height}" rx="4" fill="#00000088" />
          <svg width="${width - 2 * padding}" x="${padding}" y="${padding}" >
            <rect width="${colorBlockSize}" height="${colorBlockSize}" rx="2" fill="${tooltipColor}" />
            <text x="${padding + colorBlockSize}" y="${height / 2}" font-size="13.3333px" font-family="Roboto" fill="${tooltipTextColor}">
              ${tooltipTitle} &centerdot; ${formattedYValue}
            </text>
          </svg>
        </svg>`;
      };
      return line;
    });
  }

  public addSmartFitAnnotation(xValue: PerfChartSeriesData) {
    const { epoch: x, value: y } = xValue;
    const annotation = new SmartFitAnnotation(x, y, xValue.all.analysis);
    this.chart.annotations.add(annotation);
  }

  public getYAxisTitle(): undefined | string | string[] {
    const realColumns = this.displayedColumns.filter((col) => col !== 'epoch');
    if (realColumns.length === 1) return realColumns[0];
    if (realColumns.length === 2) return realColumns.join(' / ');
    return '';
  }

  public $changeTheme(): void {
    if (this.chart) this.chart.applyTheme(this._theme);
    if (this.overview) this.overview.applyTheme(this._theme);
  }

  public $selectPoint({ source, selectedDataPoints }: DataPointSelectionChangedArgs): void {
    if (selectedDataPoints.length === 0) return; // no point clicked

    const { index: epoch, metadata } = selectedDataPoints[0];
    const perfData = (<any>metadata).data as PerfChartData;
    const item = perfData.series[epoch];

    const { x, y }: Point = (<any>source).mousePoint;
    const { offsetLeft } = <HTMLElement>this._element.nativeElement.offsetParent; // get the offset caused by the sidepane
    const offsetTop = 48; // the size of the top navbar

    this.contextMenuPosition = {
      x: `${x + offsetLeft}px`,
      y: `${y + offsetTop}px`
    };
    this.matMenuTrigger.menuData = { item };
    this.matMenuTrigger.menu.focusFirstItem('mouse');
    this.matMenuTrigger.openMenu();
  }

  public $inspect(item: PerfChartSeriesData): void {
    const widget = this._widget.createWidget(
      'ChatWidget',
      this.teamName,
      {
        inputs: {
          messages: [
            {
              isSelf: false,
              content: [`# SmartFit Action`, `### Model is ${toTitleCase(item.all.analysis)}ting`, `${item.all.msg.trim()}`].join('\n\n')
            }
          ],
          disabled: true
        }
      },
      {
        defaultSpace: {
          cols: 25,
          rows: 35,
          minItemCols: 20,
          minItemRows: 20
        },
        outputs: {
          onClose: ({ id }: BaseWidgetComponent.Event) => {
            // use setTimeout, otherwise gridster leaves gridster-preview behind
            setTimeout(() => this._widget.removeWidget(this.teamName, id));
          },
          onRestore: ({ id }: BaseWidgetComponent.Event) => {
            this._widget.restoreWidget(this.teamName, id);
          },
          onMaximize: ({ id }: BaseWidgetComponent.Event) => {
            this._widget.maximizeWidget(this.teamName, id);
          },
          onToggle: ({ id }: BaseWidgetComponent.Event) => {
            this._widget.toggleWidget(this.teamName, id);
          }
        }
      }
    );
    this._widget.addWidget(this.teamName, widget);
  }

  public $copy(item: PerfChartSeriesData): void {
    const data = [
      this.displayedColumns.join(','),
      this.displayedColumns.map((col) => (item.all.hasOwnProperty(col) ? item.all[col].toString() : '')).join(',')
    ].join('\n');

    navigator.clipboard
      .writeText(data + '\n')
      .then(() => this._openSnackBar('Data copied to clipboard successfully', 'OK'))
      .catch(() => {
        console.error('Unable to copy text');
      });
  }

  public $addToVault(model: PerfChartSeriesData): void {
    model['name'] = null;

    const sub = this._vault.add(this.teamName, model).subscribe((response) => {
      sub.unsubscribe();
      this._openSnackBar(`${response.message}`, 'OK');
    });
  }
}
