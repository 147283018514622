// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host text {
  pointer-events: none;
  font-size: 12px;
}

:host.arrow text {
  font-weight: bold;
  fill: #313131;
}

:host.node rect,
:host.node polygon {
  stroke-width: 1.5px;
  cursor: pointer;
  transition-property: fill, stroke;
  transition-duration: 150ms;
  transition-timing-function: linear;
}
:host.node text {
  text-anchor: middle;
}
:host.node.terminal text {
  fill: #ffffff;
  font-size: 24px;
}
:host.node:not(.terminal) rect,
:host.node:not(.terminal) polygon {
  fill: #ffffff;
  stroke: #000000;
}
:host.node .state-icon {
  translate: 49.5px 65px;
  scale: 1.125;
  transform-origin: 12px 12px;
  pointer-events: none;
}

:host.arrow path {
  stroke-width: 2.5px;
  fill: none;
}

:host.fail path {
  stroke: #b7b7b7;
  stroke-dasharray: 20px 5px;
}
:host.fail polygon {
  fill: #b7b7b7;
}`, "",{"version":3,"sources":["webpack://./src/app/components/team/project-board/project-board-internals.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,eAAA;AACF;;AAEA;EACE,iBAAA;EACA,aAAA;AACF;;AAGE;;EAEE,mBAAA;EACA,eAAA;EAEA,iCAAA;EACA,0BAAA;EACA,kCAAA;AADJ;AAIE;EACE,mBAAA;AAFJ;AAMI;EACE,aAAA;EACA,eAAA;AAJN;AASI;;EAEE,aAAA;EACA,eAAA;AAPN;AAWE;EACE,sBAAA;EACA,YAAA;EACA,2BAAA;EACA,oBAAA;AATJ;;AAaA;EACE,mBAAA;EACA,UAAA;AAVF;;AAcE;EACE,eAAA;EACA,0BAAA;AAXJ;AAcE;EACE,aAAA;AAZJ","sourcesContent":[":host text {\n  pointer-events: none;\n  font-size: 12px;\n}\n\n:host.arrow text {\n  font-weight: bold;\n  fill: #313131;\n}\n\n:host.node {\n  rect,\n  polygon {\n    stroke-width: 1.5px;\n    cursor: pointer;\n\n    transition-property: fill, stroke;\n    transition-duration: 150ms;\n    transition-timing-function: linear;\n  }\n\n  text {\n    text-anchor: middle;\n  }\n\n  &.terminal {\n    text {\n      fill: #ffffff;\n      font-size: 24px;\n    }\n  }\n\n  &:not(.terminal) {\n    rect,\n    polygon {\n      fill: #ffffff;\n      stroke: #000000;\n    }\n  }\n\n  .state-icon {\n    translate: 49.5px 65px;\n    scale: 1.125;\n    transform-origin: 12px 12px;\n    pointer-events: none;\n  }\n}\n\n:host.arrow path {\n  stroke-width: 2.5px;\n  fill: none;\n}\n\n:host.fail {\n  path {\n    stroke: #b7b7b7;\n    stroke-dasharray: 20px 5px;\n  }\n\n  polygon {\n    fill: #b7b7b7;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
