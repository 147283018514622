import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Project } from 'src/app/models/project';
import { IdentityService } from 'src/app/services/identity.service';
import { ProjectsService } from 'src/app/services/projects.service';

@Component({
  selector: 'app-project-selector',
  templateUrl: './project-selector.component.html',
  styleUrls: ['./project-selector.component.scss']
})
export class ProjectSelectorComponent implements OnInit {
  @Input() teamName: string;
  @Output() onProjectSelect = new EventEmitter<{ project: Project }>();

  public projects: Project[] = [];
  public selectedProjectName: string = '';

  constructor(
    public identity: IdentityService,
    private projectsService: ProjectsService
  ) {}

  ngOnInit(): void {
    const sub = this.projectsService.load(this.teamName).subscribe((response: Project[]) => {
      sub.unsubscribe();
      this.projects = response;
      this.projects.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      if (this.identity.me.selectedProjectName)
        this.identity.me.selectedProject = this.projects.find((x) => x.name === this.identity.me.selectedProjectName);
      this.selectedProjectName = this.identity.me.selectedProjectName;
    });
  }

  $select($event) {
    if (!$event.value) {
      this.onProjectSelect.emit({ project: null });
      return;
    }

    if ($event.value === '**NEWPROJECT**') {
      this.addProject();
      return;
    }

    const project = this.projects.find((x) => x.name === $event.value);
    if (!project) return;

    this.selectProject(project);
  }

  selectProject(project: Project) {
    this.identity.setProject(project);
    localStorage.setItem('lastProjectSelected', project?.name);
    this.selectedProjectName = project.name;
    this.onProjectSelect.emit({ project: project });
  }

  async addProject() {
    const projectName = prompt('Please enter a project name');
    if (!projectName || projectName.trim().length === 0) {
      this.selectedProjectName = '';
      this.onProjectSelect.emit({ project: null });
      return;
    }

    const exists = this.projects.find((x) => x.name == projectName);
    if (exists) {
      alert(`Project with name ${projectName} already exists`);
      this.selectedProjectName = '';
      this.onProjectSelect.emit({ project: null });
      return;
    }

    const project: any = await this.projectsService.add(this.teamName, projectName);
    this.projects.push(project);
    this.projects.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    this.selectProject(project);
  }
}
