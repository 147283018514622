// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  width: 50%;
  justify-content: center;
  align-items: center;
}

mat-card {
  width: 600px;
}

.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
}

.nwi-select {
  margin-left: 10px;
}

.nwi-btn {
  width: 100px;
  margin-left: 475px;
}

.progress-container {
  margin-bottom: 10px;
}

.progress {
  padding-left: 5px;
}

.complete {
  color: green;
}

.icon {
  padding: 12px 6px 6px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/init/init.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,YAAA;EACA,UAAA;EACA,uBAAA;EACA,mBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,YAAA;EACA,kBAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,qBAAA;AACF","sourcesContent":[".container {\n  margin-top: 20px;\n  margin-left: auto;\n  margin-right: auto;\n  height: 100%;\n  width: 50%;\n  justify-content: center;\n  align-items: center;\n}\n\nmat-card {\n  width: 600px;\n}\n\n.column {\n  display: flex;\n  flex-direction: column;\n}\n\n.row {\n  display: flex;\n  flex-direction: row;\n}\n\n.nwi-select {\n  margin-left: 10px;\n}\n\n.nwi-btn {\n  width: 100px;\n  margin-left: 475px;\n}\n\n.progress-container {\n  margin-bottom: 10px;\n}\n\n.progress {\n  padding-left: 5px;\n}\n\n.complete {\n  color: green;\n}\n\n.icon {\n  padding: 12px 6px 6px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
