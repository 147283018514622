// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  padding: var(--content-margin);
  display: block;
}

.loading-bar {
  position: absolute;
  top: 0;
}

.expansion-panel-content {
  margin: 0.5rem calc(1rem + 5px);
}
.expansion-panel-content form {
  display: grid;
  grid-template-columns: 3fr repeat(2, 1fr);
}

form {
  margin-bottom: 0;
}

.dynamic-form {
  display: flex;
  justify-content: center;
}

.dialog-spinner {
  margin: 0.5rem auto;
}

.mat-mdc-dialog-content {
  overflow: hidden;
}

.copy-name-field {
  width: 100%;
}

form {
  display: flex;
  gap: var(--content-gutter);
}

form > * {
  flex-grow: 1;
}

#shuffle-button {
  position: absolute;
  top: -7px;
  right: -11px;
}

.demo-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.demo-table {
  width: 100%;
}

input.hide-counter::-webkit-outer-spin-button,
input.hide-counter::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input.hide-counter[type=number] {
  -moz-appearance: textfield;
}`, "",{"version":3,"sources":["webpack://./src/app/components/data/new-feature/new-feature.component.scss"],"names":[],"mappings":"AAAA;EACE,8BAAA;EACA,cAAA;AACF;;AAEA;EACE,kBAAA;EACA,MAAA;AACF;;AAEA;EACE,+BAAA;AACF;AACE;EACE,aAAA;EACA,yCAAA;AACJ;;AAGA;EACE,gBAAA;AAAF;;AAGA;EACE,aAAA;EACA,uBAAA;AAAF;;AAGA;EACE,mBAAA;AAAF;;AAGA;EACE,gBAAA;AAAF;;AAGA;EACE,WAAA;AAAF;;AAGA;EACE,aAAA;EACA,0BAAA;AAAF;;AAGA;EACE,YAAA;AAAF;;AAGA;EACE,kBAAA;EACA,SAAA;EACA,YAAA;AAAF;;AAGA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AAAF;;AAGA;EACE,WAAA;AAAF;;AAGA;;EAEE,wBAAA;EACA,SAAA;AAAF;;AAGA;EACE,0BAAA;AAAF","sourcesContent":[":host {\n  padding: var(--content-margin);\n  display: block;\n}\n\n.loading-bar {\n  position: absolute;\n  top: 0;\n}\n\n.expansion-panel-content {\n  margin: 0.5rem calc(1rem + 5px);\n\n  form {\n    display: grid;\n    grid-template-columns: 3fr repeat(2, 1fr);\n  }\n}\n\nform {\n  margin-bottom: 0;\n}\n\n.dynamic-form {\n  display: flex;\n  justify-content: center;\n}\n\n.dialog-spinner {\n  margin: 0.5rem auto;\n}\n\n.mat-mdc-dialog-content {\n  overflow: hidden;\n}\n\n.copy-name-field {\n  width: 100%;\n}\n\nform {\n  display: flex;\n  gap: var(--content-gutter);\n}\n\nform > * {\n  flex-grow: 1;\n}\n\n#shuffle-button {\n  position: absolute;\n  top: -7px;\n  right: -11px;\n}\n\n.demo-message {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n\n.demo-table {\n  width: 100%;\n}\n\ninput.hide-counter::-webkit-outer-spin-button,\ninput.hide-counter::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n\ninput.hide-counter[type='number'] {\n  -moz-appearance: textfield;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
