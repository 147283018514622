import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class PaletteService {
  // generate palette here: http://tools.medialab.sciences-po.fr/iwanthue/
  public palettes: any = {
    'colorblind-32': [
      '#649de5',
      '#d5474f',
      '#dc80bb',
      '#6053bb',
      '#59bc77',
      '#c85736',
      '#577ceb',
      '#b47fc8',
      '#5a6bbc',
      '#b6b738',
      '#823014',
      '#d23e73',
      '#75b550',
      '#793076',
      '#452d7e',
      '#be6b27',
      '#4d6f24',
      '#932b3c',
      '#e88ae4',
      '#cf9029',
      '#c74996',
      '#9b9937',
      '#d3688e',
      '#a542a3',
      '#ab7adc',
      '#43c8ac',
      '#8a2b5b',
      '#db6b76',
      '#ce7058',
      '#b0b162',
      '#bd8449',
      '#d5a550'
    ],
    'dark-4': ['#0a1039', '#93c1f5', '#753b31', '#004e58'],
    'fancy-light-10': ['#8be0da', '#ecaaae', '#74aff3', '#d1ebb7', '#deb1e0', '#a9c38d', '#adbce9', '#e3c297', '#71cdeb', '#94ceb5']
  };

  private indexes: any = {};
  private _selected: string;
  get selected(): string {
    return this._selected;
  }
  set selected(value: string) {
    this._selected = value;
    this.indexes = {};
    localStorage.setItem('palette', value);
  }
  constructor() {
    let savedPalette: string = localStorage.getItem('palette');
    if (savedPalette && Object.keys(this.palettes).indexOf(savedPalette) != -1) {
      this.selected = savedPalette;
    } else {
      this.selected = Object.keys(this.palettes)[0];
    }
  }

  selectColor(rowIndex: number, custom: string = '') {
    if (Object.keys(this.indexes).indexOf(rowIndex.toString()) == -1) {
      this.indexes[rowIndex.toString()] = Object.keys(this.indexes).length;
    }
    let index = this.indexes[rowIndex.toString()];
    let palette: string[] = this.palettes[this.selected];
    let computed: number = index % palette.length;
    return palette[computed];
  }

  selectrgb(rowIndex: number, opacity: number) {
    let hex = this.selectColor(rowIndex);
    hex = hex.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    const result = `rgba(${r},${g},${b}, ${opacity / 100})`;
    return result;
  }
  get() {
    return Object.keys(this.palettes);
  }
}
