import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { HostStatsService } from 'src/app/services/host-stats.service';
@Component({
  selector: 'app-host-user-stats',
  templateUrl: './host-user-stats.component.html',
  styleUrls: ['./host-user-stats.component.scss']
})
export class HostUserStatsComponent implements OnInit {
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  @Input() teamName: string = '';
  @Input() hostValue: number = null;

  public displayedColumns = ['name', 'timeUsed', 'roi'];
  public dataSource = new MatTableDataSource<any>([]);

  constructor(private hostStatsService: HostStatsService) {}

  ngOnInit(): void {
    this.load();
  }

  load() {
    if (!this.teamName) {
      return;
    }

    const sub = this.hostStatsService.getUserStats(this.teamName).subscribe((response: any[]) => {
      sub.unsubscribe();
      this.dataSource.data = response;
      this.dataSource.sort = this.sort;
    });
  }
}
