import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-asset-logs',
  templateUrl: './asset-logs.component.html',
  styleUrls: ['./asset-logs.component.scss']
})
export class AssetLogsComponent implements OnInit {
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input() logs: any[];

  public displayedColumns = ['user', 'timestamp', 'escrowed'];
  public dataSource = new MatTableDataSource<any>([]);

  constructor() {}

  ngOnInit(): void {
    this.dataSource.data = this.logs;
    setTimeout(() => {
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }, 0);
  }
}
