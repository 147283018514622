import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  constructor(private http: HttpClient) {}

  public get(teamName: string, path: string) {
    return lastValueFrom(
      this.http.get(`${ConfigService.apiUrl}/file/${teamName}/${encodeURIComponent(path)}`, {
        responseType: 'text'
      })
    );
  }

  public post(teamName: string, path: string, type: string, data: any) {
    return this.http.post(`${ConfigService.apiUrl}/file/${teamName}`, { path, type, data });
  }

  public async postAsync(teamName: string, path: string, type: string, data: any) {
    return lastValueFrom(this.post(teamName, path, type, data));
  }
}
