import { Component, HostBinding, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChatService } from 'src/app/services/chat.service';

export type ChatDialogData = {
  maxWidth?: string;
  messages: ChatService.ChatMessage[];
};

@Component({
  selector: 'app-chat-dialog',
  templateUrl: './chat-dialog.component.html',
  styleUrls: ['./chat-dialog.component.scss']
})
export class ChatDialogComponent {
  @HostBinding('style.max-width') get maxWidth() {
    return this.data.maxWidth ?? 'unset';
  }

  constructor(
    public dialogRef: MatDialogRef<ChatDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ChatDialogData
  ) {
    (<any>window).chatDialog = this;
  }

  close() {
    this.dialogRef.close();
  }
}
