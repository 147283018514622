import { Node } from './workflow-node';
import { v4 as uuidv4 } from 'uuid';

export interface SerializedConnection {
  id: string;
  from: string;
  to: string;
  animated?: boolean;
}

export class Connection {
  public id: string = '0';
  public from: Node;
  public to: Node;
  public animated: boolean = false;

  constructor(from: Node, to: Node, id?: string, animated?: boolean) {
    this.from = from;
    this.to = to;
    this.id = id ?? uuidv4();
    this.animated = animated ?? false;
  }

  public clone(): Connection {
    return new Connection(this.from, this.to, this.id, this.animated);
  }

  public serialize = (): SerializedConnection => ({
    id: this.id,
    from: this.from.id,
    to: this.to.id,
    animated: this.animated
  });

  public static serialize = (conn: Connection) => conn.serialize();
}
