import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Point } from 'src/app/models/point';
import { Workflow } from 'src/app/models/workflow';
import SVGCanvasEventManager, { TapEvent } from 'src/app/models/svg-canvas-event-manager';
import { Connection } from 'src/app/models/workflow-connection';
import { Node } from 'src/app/models/workflow-node';
import { WorkflowService } from 'src/app/services/workflow.service';
import { Subscription } from 'rxjs';

const NODE_SIZE = 110;
const CURVE = 90;

export const makeSVGPath = (start: Point, end: Point) => {
  let c1 = new Point();
  let c2 = new Point();

  if (end.x - 5 < start.x) {
    const curveFactor = ((start.x - end.x) * CURVE) / 200;
    if (Math.abs(end.y - start.y) < NODE_SIZE / 2) {
      // Loopback
      c1.x = start.x + curveFactor;
      c1.y = start.y - curveFactor;
      c2.x = end.x - curveFactor;
      c2.y = end.y - curveFactor;
    } else {
      // Stick out some
      c1.x = start.x + curveFactor;
      c1.y = start.y + (end.y > start.y ? curveFactor : -curveFactor);
      c2.x = end.x - curveFactor;
      c2.y = end.y + (end.y > start.y ? -curveFactor : curveFactor);
    }
  } else {
    // Controls halfway between
    c1.x = start.x + (end.x - start.x) / 2;
    c1.y = start.y;
    c2.x = c1.x;
    c2.y = end.y;
  }

  const path = `M ${start.x} ${start.y} C ${c1.x} ${c1.y} ${c2.x} ${c2.y} ${end.x} ${end.y}`;

  return { path, c1, c2 };
};

export const findPointOnCurve = function (p: number, start: Point, c1: Point, c2: Point, end: Point): Point {
  // p is percentage from 0 to 1
  const op = 1 - p;
  // 3 green points between 4 points that define curve
  const g1x = start.x * p + c1.x * op;
  const g1y = start.y * p + c1.y * op;
  const g2x = c1.x * p + c2.x * op;
  const g2y = c1.y * p + c2.y * op;
  const g3x = c2.x * p + end.x * op;
  const g3y = c2.y * p + end.y * op;
  // 2 blue points between green points
  const b1x = g1x * p + g2x * op;
  const b1y = g1y * p + g2y * op;
  const b2x = g2x * p + g3x * op;
  const b2y = g2y * p + g3y * op;
  // Point on the curve between blue points
  const x = b1x * p + b2x * op;
  const y = b1y * p + b2y * op;

  return new Point(x, y);
};

@Component({
  selector: '[connection-preview]',
  template: `<svg:path [attr.d]="path" class="connection preview" />`,
  styleUrls: ['./workflow-canvas-connection.component.scss']
})
export class WorkflowCanvasConnectionPreviewComponent {
  @Input() startNode: Node;
  @Input() mouse: Point;

  public get path() {
    return makeSVGPath(this.startNode.outPortPosition, this.mouse).path;
  }
}

@Component({
  selector: '[wfc-connection]',
  template: `<svg:path [attr.d]="path" class="connection hitbox" />
    <svg:path [attr.d]="path" [class]="connectionClass" />
    <svg:g #svgClose [attr.display]="selected ? '' : 'none'">
      <svg:circle class="close-hitbox" [attr.cx]="center.x" [attr.cy]="center.y" r="12" />
      <svg:circle class="close-outline" [attr.cx]="center.x" [attr.cy]="center.y" r="12" />
      <svg:circle class="close" [attr.cx]="center.x" [attr.cy]="center.y" r="10" />
      <svg:svg viewBox="0 0 352 512" class="close-x" width="14" height="14" [attr.x]="center.x - 7" [attr.y]="center.y - 7">
        <svg:path
          d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
        />
      </svg:svg>
    </svg:g>`,
  styleUrls: ['./workflow-canvas-connection.component.scss', './workflow-canvas.vars.scss']
})
export class WorkflowCanvasConnectionComponent implements OnInit, OnDestroy, AfterViewInit {
  public connection: Connection;
  private _connEm: SVGCanvasEventManager;
  private _closeEm: SVGCanvasEventManager;
  private _subscriptions: Subscription[] = [];
  public selected: boolean;
  public unselected: boolean;
  public path: string;
  public center: Point;
  public connectionClass: string;

  @Input() id: string;

  @ViewChild('svgClose') svgClose: ElementRef<SVGElement>;

  constructor(
    private _host: ElementRef,
    private _workflowService: WorkflowService
  ) {}

  public ngOnInit(): void {
    this.$nextWorkflow(this._workflowService.workflow);

    this._subscriptions.push(this._workflowService.workflow$.subscribe(this.$nextWorkflow.bind(this)));
    this._connEm = new SVGCanvasEventManager(this._host.nativeElement);
    this._connEm.$tap(this.$tap);
  }

  public ngAfterViewInit(): void {
    this._closeEm = new SVGCanvasEventManager(this.svgClose.nativeElement);
    this._closeEm.$tap(this.$tapClose);
  }

  public ngOnDestroy(): void {
    this._subscriptions.forEach((sub) => sub.unsubscribe());
    this._connEm.setdown();
    this._closeEm.setdown();
  }

  public $nextWorkflow = (wf: Workflow) => {
    const conn = this._workflowService.getConnectionByID(this.id);
    if (conn) {
      const { selectedConnection } = wf;
      this.connection = conn;
      this.selected = selectedConnection == this.id;
      this.unselected = selectedConnection && !this.selected;

      const { path, c1, c2 } = makeSVGPath(this.connection.from.outPortPosition, this.connection.to.inPortPosition);
      this.path = path;
      this.center = findPointOnCurve(0.5, this.connection.from.outPortPosition, c1, c2, this.connection.to.inPortPosition);
      this.connectionClass =
        'connection' + (this.selected ? ' selected' : this.unselected ? ' unselected' : '') + (this.connection.animated ? ' animated' : '');
    }
  };

  public $tap = ($ev: TapEvent): void => {
    $ev.stopPropagation();
    this._workflowService.selectConnection(this.id);
  };

  public $tapClose = ($ev: TapEvent): void => {
    $ev.stopPropagation();
    this._workflowService.removeConnection(this.id);
  };
}
