// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `mat-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0.33;
}`, "",{"version":3,"sources":["webpack://./src/app/components/spinner/spinner.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,aAAA;AACF","sourcesContent":["mat-spinner {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  opacity: 0.33;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
