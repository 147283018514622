import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class VaultService {
  constructor(private http: HttpClient) {}

  public load(teamName: string) {
    return this.http.get<any[]>(`${ConfigService.apiUrl}/vault/${teamName}`);
  }

  public loadAsync(teamName: string) {
    return lastValueFrom(this.load(teamName));
  }

  public get(teamName: string, vaultName: string) {
    return this.http.get<any>(`${ConfigService.apiUrl}/vault/${teamName}/${vaultName}`);
  }

  public add(teamName: string, item: any) {
    return this.http.post<any>(`${ConfigService.apiUrl}/vault/add/${teamName}`, item);
  }

  public update(teamName: string, item: any) {
    return this.http.put<any>(`${ConfigService.apiUrl}/vault/name/${teamName}`, { data: item });
  }

  public deploy(teamName: string, deployment: any) {
    return this.http.put<any>(`${ConfigService.apiUrl}/vault/deploy/${teamName}`, { data: deployment });
  }

  public remove(teamName: string, vaultName: string) {
    return this.http.delete<any>(`${ConfigService.apiUrl}/vault/delete/${teamName}/${vaultName}`);
  }
}
