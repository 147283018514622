import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ColumnService {
  constructor() {}

  public getColumns(data: any[]) {
    const keys = [];
    data.forEach((x) => keys.push(Object.keys(x)));

    const stack = [...keys];
    const res = [];
    while (stack.length) {
      const next = stack.pop();
      if (Array.isArray(next)) {
        stack.push(...next);
      } else {
        res.push(next);
      }
    }

    return [...new Set(res.reverse())];
  }
}
