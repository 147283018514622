// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.monaco-container {
  position: fixed;
  height: 100%;
  width: 100%;
  display: block;
}

#query-builder-container {
  padding: 10px;
}

.smol {
  height: 24px;
  line-height: 22px;
  font-size: 12px;
}

ngx-monaco-editor {
  display: unset;
  height: 100%;
}

.monaco-container {
  position: fixed;
  height: 100%;
  width: 100%;
  display: block;
}

#strategy-toolbar {
  padding: 5px;
}

#strat-button {
  float: right;
  margin-left: 7px;
  margin-top: 7px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/strategy-grid/strategy-widget/strategy-widget.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,YAAA;EACA,WAAA;EACA,cAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,YAAA;EACA,iBAAA;EACA,eAAA;AACF;;AAEA;EACE,cAAA;EACA,YAAA;AACF;;AAEA;EACE,eAAA;EACA,YAAA;EACA,WAAA;EACA,cAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,YAAA;EACA,gBAAA;EACA,eAAA;AACF","sourcesContent":[".monaco-container {\n  position: fixed;\n  height: 100%;\n  width: 100%;\n  display: block;\n}\n\n#query-builder-container {\n  padding: 10px;\n}\n\n.smol {\n  height: 24px;\n  line-height: 22px;\n  font-size: 12px;\n}\n\nngx-monaco-editor {\n  display: unset;\n  height: 100%;\n}\n\n.monaco-container {\n  position: fixed;\n  height: 100%;\n  width: 100%;\n  display: block;\n}\n\n#strategy-toolbar {\n  padding: 5px;\n}\n\n#strat-button {\n  float: right;\n  margin-left: 7px;\n  margin-top: 7px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
