import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { LoggingService } from './services/logging.service';
import { NotificationService } from './services/notification.service';
import { ConfigService } from './services/config.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error: Error | HttpErrorResponse | any) {
    const logger = this.injector.get(LoggingService);
    const notifier = this.injector.get(NotificationService);

    if (!ConfigService.suppressErrors) {
      notifier.showError('An error has occurred. See log.');
    }
    logger.logError(error);
  }
}
