// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  max-height: 80dvh;
  display: block;
  overflow: auto;
  padding: 1rem;
}

.button-wrapper {
  float: right;
  position: relative;
  top: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/components/markdown-dialog/markdown-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,cAAA;EACA,cAAA;EACA,aAAA;AACF;;AAEA;EACE,YAAA;EACA,kBAAA;EACA,MAAA;AACF","sourcesContent":[":host {\n  max-height: 80dvh;\n  display: block;\n  overflow: auto;\n  padding: 1rem;\n}\n\n.button-wrapper {\n  float: right;\n  position: relative;\n  top: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
