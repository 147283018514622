// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.status-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.deploy-title {
  overflow: clip;
  text-overflow: ellipsis;
  white-space: pre;
}

.stats,
.two-stats {
  display: flex;
  flex-wrap: wrap;
  gap: var(--content-gutter);
}

.stat {
  display: flex;
  gap: 0.25rem;
  align-items: center;
}
.stat mat-icon {
  transform: scale(0.6666666667);
}

.models-panel {
  width: 100%;
}

.no-click {
  pointer-events: none;
}

.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: none;
}

.chip {
  font-size: 11px !important;
  padding: 4px 9px !important;
}

.launch-button {
  position: absolute;
  top: 0px;
  right: 0px;
  transform: scale(0.75);
}

.action-button {
  bottom: 0px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/models/deploy-card/deploy-card.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;;AAEA;EACE,cAAA;EACA,uBAAA;EACA,gBAAA;AACF;;AAEA;;EAEE,aAAA;EACA,eAAA;EACA,0BAAA;AACF;;AAEA;EACE,aAAA;EACA,YAAA;EACA,mBAAA;AACF;AACE;EACE,8BAAA;AACJ;;AAGA;EACE,WAAA;AAAF;;AAGA;EACE,oBAAA;AAAF;;AAGA;EACE,gBAAA;AAAF;;AAGA;EACE,0BAAA;EACA,2BAAA;AAAF;;AAGA;EACE,kBAAA;EACA,QAAA;EACA,UAAA;EACA,sBAAA;AAAF;;AAGA;EACE,WAAA;AAAF","sourcesContent":[".status-avatar {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.deploy-title {\n  overflow: clip;\n  text-overflow: ellipsis;\n  white-space: pre;\n}\n\n.stats,\n.two-stats {\n  display: flex;\n  flex-wrap: wrap;\n  gap: var(--content-gutter);\n}\n\n.stat {\n  display: flex;\n  gap: 0.25rem;\n  align-items: center;\n\n  mat-icon {\n    transform: scale(calc(16px / 24px));\n  }\n}\n\n.models-panel {\n  width: 100%;\n}\n\n.no-click {\n  pointer-events: none;\n}\n\n.mat-expansion-panel:not([class*='mat-elevation-z']) {\n  box-shadow: none;\n}\n\n.chip {\n  font-size: 11px !important;\n  padding: 4px 9px !important;\n}\n\n.launch-button {\n  position: absolute;\n  top: 0px;\n  right: 0px;\n  transform: scale(0.75);\n}\n\n.action-button {\n  bottom: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
