import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss']
})
export class DateTimePickerComponent implements OnInit, OnChanges {
  @Input() inputDate = null;
  @Input() disabled: boolean = false;
  @Output() dateChange = new EventEmitter();
  public dateControl = new FormControl(null);

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.inputDate.currentValue) {
      this.dateControl.setValue(new Date(changes.inputDate.currentValue), { emitEvent: false });
    } else {
      this.dateControl.setValue(null, { emitEvent: false });
    }
  }

  ngOnInit(): void {
    this.dateControl.valueChanges.subscribe((value: Date) => {
      this.dateChange.emit(Math.floor(value.getTime() / 1000));
    });
  }
}
