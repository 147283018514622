import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { FileService } from 'src/app/services/file.service';

@Component({
  selector: 'app-monaco-widget',
  templateUrl: './monaco-widget.component.html',
  styleUrls: ['../base-widget/base-widget.component.scss', './monaco-widget.component.scss']
})
export class MonacoWidgetComponent extends BaseWidgetComponent implements OnInit, OnDestroy {
  @Input() path: any = null;
  @Input() title: string = null;

  content: string = null;

  public editorOptions = {
    theme: 'vs-dark',
    language: 'python',
    automaticLayout: true,
    scrollBeyondLastLine: false,
    minimap: {
      enabled: false
    }
  };

  constructor(private fileService: FileService) {
    super();
  }

  ngOnInit(): void {
    this.fileService.get(this.teamName, this.path).then((content) => {
      this.content = content;
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
