// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.expanded {
  height: 30px;
}

.selected {
  background-color: #eee;
  font-weight: bold;
}

.no-ds {
  margin-left: 15px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 11pt;
}

mat-list-item {
  border-radius: 30px;
}

mat-list-item:hover {
  background-color: #F5F5F5;
  border-radius: 30px;
}

.dataset-list {
  padding: 0;
}

.list-item {
  cursor: pointer;
}

.dataset-item {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-size: 11pt;
}

.list-item .dataset-item .delete-button {
  visibility: hidden;
  cursor: pointer;
  margin-right: 0;
}

.list-item:hover .dataset-item .delete-button {
  visibility: visible;
}`, "",{"version":3,"sources":["webpack://./src/app/components/strategy-grid/raw-data-widget/raw-data-widget.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE,sBAAA;EACA,iBAAA;AACF;;AAEA;EACE,iBAAA;EACA,iDAAA;EACA,eAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,yBAAA;EACA,mBAAA;AACF;;AAEA;EACE,UAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,aAAA;EACA,WAAA;EACA,8BAAA;EACA,mBAAA;EACA,eAAA;AACF;;AAEA;EACE,kBAAA;EACA,eAAA;EACA,eAAA;AACF;;AAEA;EACE,mBAAA;AACF","sourcesContent":[".expanded {\n  height: 30px;\n}\n\n.selected {\n  background-color: #eee;\n  font-weight: bold;\n}\n\n.no-ds {\n  margin-left: 15px;\n  font-family: Roboto, \"Helvetica Neue\", sans-serif;\n  font-size: 11pt;\n}\n\nmat-list-item {\n  border-radius: 30px;\n}\n\nmat-list-item:hover {\n  background-color: #F5F5F5;\n  border-radius: 30px;\n}\n\n.dataset-list {\n  padding: 0;\n}\n\n.list-item {\n  cursor: pointer\n}\n\n.dataset-item {\n  display: flex;\n  width: 100%;\n  justify-content: space-between;\n  align-items: center;\n  font-size: 11pt;\n}\n\n.list-item .dataset-item .delete-button {\n  visibility: hidden;\n  cursor: pointer;\n  margin-right: 0;\n}\n\n.list-item:hover .dataset-item .delete-button{\n  visibility: visible;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
