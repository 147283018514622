import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class CacheFileService {
  constructor(private http: HttpClient) {}

  public createCacheFileAsync(teamName: string, path: string) {
    return lastValueFrom(this.http.post(`${ConfigService.apiUrl}/cache-file/${teamName}`, { path }));
  }

  public loadLog(teamName: string, path: string, pageSize: number, pageNum: number) {
    return this.http.get(`${ConfigService.apiUrl}/cache-file/log/${teamName}/${path}/${pageSize}/${pageNum}`);
  }
}
