import { Component, EventEmitter, HostListener, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { WidgetService } from 'src/app/services/widget.service';
import { PerfTableComponent } from '../../models/perf-table/perf-table.component';


@Component({
  selector: 'app-perf-table-widget',
  templateUrl: './perf-table-widget.component.html',
  styleUrls: ['../base-widget/base-widget.component.scss', './perf-table-widget.component.scss']
})
export class PerfTableWidgetComponent extends BaseWidgetComponent implements OnInit {
  @ViewChild(PerfTableComponent) perfTableComponent!: PerfTableComponent;

  @Input() run: any;
  @Input() displayedColumns: string[] = [];
  @Input() dynamicColumns: any[] = [];
  @Input() live: boolean = false;

  private removeResizeListener: () => void;
  public title: string = null;

  constructor(private _widgetService: WidgetService, private renderer: Renderer2) {
    super();
  }

  onResize() {
    setTimeout(() => {
    this.perfTableComponent.optimizePageSize();
    }, 500);
  }

  ngOnInit(): void {
    this.removeResizeListener = this.renderer.listen(window, `resize:${this.id}`, this.onResize.bind(this));
  }

  ngOnDestroy(): void {
    if (this.removeResizeListener) this.removeResizeListener();
  }
}
