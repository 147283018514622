// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
  padding: 20px;
  height: calc(100% - 40px);
}

#top-padding {
  height: 10px;
}

as-split-area {
  overflow: hidden !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/models/analysis-container/analysis-container.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,yBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,2BAAA;AACF","sourcesContent":[".content {\n  padding: 20px;\n  height: calc(100% - 40px);\n}\n\n#top-padding {\n  height: 10px;\n}\n\nas-split-area {\n  overflow: hidden !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
