import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Project } from 'src/app/models/project';
import { VaultItem } from 'src/app/models/vault-item';
import { PredictEngineService } from 'src/app/services/predict-engine.service';
import { VaultService } from 'src/app/services/vault.service';
import { WorkersService } from 'src/app/services/workers.service';

@Component({
  selector: 'app-deploy-selector',
  templateUrl: './deploy-selector.component.html',
  styleUrls: ['./deploy-selector.component.scss']
})
export class DeploySelectorComponent implements OnInit, OnChanges {
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input() teamName: string;
  @Input() project: Project;
  @Output() onOpenVault = new EventEmitter<{ projectName: string }>();

  public dataSource = new MatTableDataSource<VaultItem>([]);

  public vaultItems: VaultItem[] = [];
  // public selectedVaultItem: VaultItem = null;
  // public changeVaultItem: boolean = false;
  // public newVaultItemWorker: string = null;
  public workers: string[] = [];
  public isLoading = true;

  public displayedColumns = ['name', 'project', 'runid', 'epoch', 'dateAdded', 'deploy'];

  constructor(
    private _vaultService: VaultService,
    private _predictEngineService: PredictEngineService,
    private _workersService: WorkersService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  ngOnChanges(_changes: SimpleChanges): Promise<void> {
    if (_changes.project.firstChange || _changes.project.currentValue?.name === _changes.project.previousValue?.name) return;

    this.init();
  }

  init() {
    this.isLoading = true;
    const sub = this._vaultService.load(this.teamName).subscribe((response) => {
      sub.unsubscribe();
      if (!response || response.length == 0) {
        this.isLoading = false;
        return;
      }

      for (let item of response) {
        item['deploy'] = item.workers.length > 0 ? item.workers[0] : null;
      }

      this.isLoading = false;
      this.dataSource.data = response;

      setTimeout(() => {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }, 0);
    });

    const predictEnginesub = this._predictEngineService.load(this.teamName).subscribe((response) => {
      predictEnginesub.unsubscribe();
      this.workers = response.filter((x) => x.name.toLowerCase() !== 'lit-udf').map((x) => x.name);
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) this.dataSource.paginator.firstPage();
  }

  $clickOpenVault() {
    this.onOpenVault.emit({ projectName: this.project.name });
  }

  async $onDeploySelected($event, row) {
    if (row.workers.length > 0) {
      if (row.workers[0] === $event.value) {
        return;
      }

      await this._workersService.removeModelAsync(this.teamName, row.workers[0], row.name);
    }

    if ($event.value === null) {
      row.workers = row.workers.filter((x) => x !== row.workers[0]);
      row.deploy = null;
      return;
    }

    await this._workersService.addModelAsync(this.teamName, $event.value, row.name);
  }
}
