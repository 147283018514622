import { Component, Input, OnInit } from '@angular/core';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { WidgetService } from 'src/app/services/widget.service';

@Component({
  selector: 'app-vault-widget',
  templateUrl: './vault-widget.component.html',
  styleUrls: ['../base-widget/base-widget.component.scss', './vault-widget.component.scss']
})
export class VaultWidgetComponent extends BaseWidgetComponent implements OnInit {
  @Input() vaultName: string = null;

  constructor(private _widgetService: WidgetService) {
    super();
  }

  ngOnInit(): void {
    console.log('vault-name', this.vaultName);
    if(this.vaultName && this.vaultName.length > 0) {
      this.widgetView = 'DETAIL';
    }

    this._subs.add = this._widgetService.resize$.subscribe(() => {
      if ((<any>window).vault) (<any>window).vault.onResize()
    })
  }

  vaultDetailClick($event) {
    console.log('vaultDetailClick', $event);
    this.vaultName = $event.vaultName;
    this.widgetView = 'DETAIL';
  }

  closeDetail() {
    this.widgetView = 'LIST';
    this.vaultName = null;
  }

  close() {
    if (this.widgetView === 'DETAIL') {
      this.widgetView = 'LIST';
      this.vaultName = null;
    } else {
      super.close();
    }
  }
}
