// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  max-height: 80dvh;
  display: block;
  overflow: auto;
  --padding: 1rem;
  padding: var(--padding);
}

.button-wrapper {
  position: absolute;
  top: var(--padding);
  right: var(--padding);
}`, "",{"version":3,"sources":["webpack://./src/app/components/chat-dialog/chat-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,cAAA;EACA,cAAA;EAEA,eAAA;EACA,uBAAA;AAAF;;AAGA;EACE,kBAAA;EACA,mBAAA;EACA,qBAAA;AAAF","sourcesContent":[":host {\n  max-height: 80dvh;\n  display: block;\n  overflow: auto;\n\n  --padding: 1rem;\n  padding: var(--padding);\n}\n\n.button-wrapper {\n  position: absolute;\n  top: var(--padding);\n  right: var(--padding);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
