import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Point } from 'src/app/models/point';
import { Workflow } from 'src/app/models/workflow';
import { MoveEvent } from 'src/app/models/svg-canvas-event-manager';
import { Node } from 'src/app/models/workflow-node';
import { WorkflowService } from 'src/app/services/workflow.service';
import { WorkflowDrawerMode } from '../definition.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { NgxMatColorPickerInputEvent } from '@angular-material-components/color-picker';
import { Connection } from 'src/app/models/workflow-connection';
import { SVGCanvasComponent } from 'src/app/components/svg-canvas/svg-canvas.component';

export interface WorkflowDrawerEvent {
  mode?: WorkflowDrawerMode;
  component?: Node;
  id: string;
}

export type WorkflowCanvasGridBehaviour = 'hidden' | 'show' | 'snap';

@Component({
  selector: 'app-workflow-canvas',
  templateUrl: './workflow-canvas.component.html',
  styleUrls: ['./workflow-canvas.component.scss', './workflow-canvas.vars.scss']
})
export class WorkflowCanvasComponent implements OnInit {
  public workflow: Workflow = {
    id: '',
    name: '',
    description: '',
    assets: [],
    transfer: null,
    device: 'cpu',
    tags: [],
    nodes: [],
    connections: []
  };
  public nodeIDs: string[] = [];
  public connIDs: string[] = [];
  public grid: WorkflowCanvasGridBehaviour = 'snap';
  private _workflow$: Subscription;

  @Input() formDirty: boolean = false;

  @Output() save = new EventEmitter<Workflow>();
  @Output() drawer = new EventEmitter<WorkflowDrawerEvent>();
  @Output() delete = new EventEmitter<string>();
  @Output() back = new EventEmitter<void>();

  @ViewChild('svgCanvas', { static: true }) svgCanvas: SVGCanvasComponent;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  public connectionInProgress?: { from: Node; to: Point } = undefined;
  public closestNode?: Node = undefined;

  public contextMenuPosition = { x: '0px', y: '0px' };

  public get view() {
    return this.svgCanvas?.view;
  }

  public get showGrid() {
    return this.grid !== 'hidden';
  }

  public get snapToGrid() {
    return this.grid === 'snap';
  }

  /* #region Lifecycle */
  constructor(private _workflowService: WorkflowService) {
    (<any>window).wfc = this;
  }

  public ngOnInit(): void {
    this.$nextWorkflow(this._workflowService.workflow);

    this._workflow$ = this._workflowService.workflow$.subscribe((wf) => {
      this.$nextWorkflow(wf);
    });
  }

  public ngOnDestroy() {
    this._workflow$.unsubscribe();
  }
  /* #endregion */

  /* #region Events */
  public $nextWorkflow = (wf?: Workflow): void => {
    if (wf?.selectedNode) {
      this.drawer.emit({
        id: this.workflow.id,
        mode: 'EDITCOMPONENT',
        component: this._workflowService.getNodeByID(wf.selectedNode)
      });
    }
    this.workflow = wf;
    this.nodeIDs = wf?.nodes.map((n) => n.id) ?? [];
    this.connIDs = wf?.connections.map((c) => c.id) ?? [];
  };

  public $contextmenuBase = ($ev: PointerEvent) => {
    $ev.preventDefault();
    $ev.stopPropagation();

    this.openContextMenu($ev, {
      type: 'grid',
      item: this
    });
  };

  public $contextmenuNode = ($ev: PointerEvent, nodeID: string) => {
    $ev.preventDefault();
    $ev.stopPropagation();

    this.openContextMenu($ev, {
      type: 'node',
      item: this._workflowService.getNodeByID(nodeID)
    });
  };

  public $contextmenuConnection = ($ev: PointerEvent, connID: string) => {
    $ev.preventDefault();
    $ev.stopPropagation();

    this.openContextMenu($ev, {
      type: 'connection',
      item: this._workflowService.getConnectionByID(connID)
    });
  };

  public $tap = (): void => {
    this._workflowService.selectNode();
    this.drawer.emit({ id: this.workflow.id });
    if (this.trigger.menuOpen) this.closeContextMenu();
  };

  public $move = (): void => {
    if (this.trigger.menuOpen) this.closeContextMenu();
  };

  public $moveEnd = (): void => {
    if (this.trigger.menuOpen) this.closeContextMenu();
  };

  public $pinch = (): void => {
    if (this.trigger.menuOpen) this.closeContextMenu();
  };

  public $clickAdd = (): void => {
    if (this.trigger.menuOpen) this.closeContextMenu();
    this.drawer.emit({ id: this.workflow.id, mode: 'ADDCOMPONENT' });
  };

  public $clickSave = (): void => {
    if (this.trigger.menuOpen) this.closeContextMenu();
    this.save.emit(this.workflow);
  };

  public $clickSettings = (): void => {
    if (this.trigger.menuOpen) this.closeContextMenu();
    this.drawer.emit({ id: this.workflow.id, mode: 'SETTINGS' });
  };

  public $clickDelete = (): void => {
    if (this.trigger.menuOpen) this.closeContextMenu();
    this.delete.emit(this.workflow.id);
  };

  public $clickBack = (): void => {
    this.back.emit();
  };

  public $dragConnection = ($ev: { node: Node; e: MoveEvent }): void => {
    if (this.trigger.menuOpen) this.closeContextMenu();
    const mousePosition = this.coordsToSVG($ev.e.detail.x - this.view.offsetLeft!, $ev.e.detail.y - this.view.offsetTop!);
    this.closestNode = this.getClosestInPortNode(mousePosition);
    this.connectionInProgress = {
      from: $ev.node,
      to: this.closestNode?.inPortPosition ?? mousePosition
    };
  };

  public $dragConnectionEnd = ($ev: { node: Node; e: MoveEvent }): void => {
    if (this.trigger.menuOpen) this.closeContextMenu();
    if (this.closestNode) {
      this._workflowService.createConnection($ev.node, this.closestNode);
    }
    Object.assign(this, {
      connectionInProgress: undefined,
      closestNode: undefined
    });
  };

  public $nodeColorChange = ($ev: NgxMatColorPickerInputEvent, node: Node): void => {
    node.color = $ev.value;
  };

  public $nodeColorPickerClose = (node: Node): void => {
    this._workflowService.updateNode(node.id, { color: node.color });
  };

  public $animateConnection = (connection: Connection): void => {
    this._workflowService.updateConnection(connection.id, {
      animated: !connection.animated
    });
  };
  /* #endregion */

  public getClosestInPortNode(loc: Point): Node | undefined {
    const { nodes } = this.workflow;

    const minDist: number = 60;
    let closestNode: Node | undefined;
    let closestDist: number;

    for (const node of nodes) {
      const distToMouse = node.inPortPosition.distanceTo(loc);

      if (distToMouse <= minDist) {
        if (!closestNode) {
          closestNode = node.clone();
          closestDist = distToMouse;
        } else {
          if (distToMouse < closestDist!) {
            closestNode = node.clone();
            closestDist = distToMouse;
          }
        }
      }
    }

    return closestNode;
  }

  public coordsToSVG = (x: number, y: number): Point => new Point((x - this.view.x) / this.view.scale, (y - this.view.y) / this.view.scale);

  private _openContextMenu($ev: PointerEvent, data: { type: string; item: unknown }): void {
    this.contextMenuPosition.x = `${$ev.pageX}px`;
    this.contextMenuPosition.y = `${$ev.pageY}px`;
    this.trigger.menuData = data;
    this.trigger.openMenu();
  }

  private _refreshContextMenu($ev: PointerEvent, data: { type: string; item: unknown }): void {
    const sub = this.trigger.menuClosed.subscribe(() => {
      sub.unsubscribe();
      this._openContextMenu($ev, data);
    });
    this.trigger.closeMenu();
  }

  public openContextMenu($ev: PointerEvent, data: { type: string; item: unknown }): void {
    // Deselect node and connection, close drawer if open
    if (this.workflow.selectedNode || this.workflow.selectedConnection) {
      this._workflowService.selectNode();
      this._workflowService.selectConnection();
    }
    this.drawer.emit({ id: this.workflow.id });

    // Set up context menu
    if (this.trigger.menuOpen) this._refreshContextMenu($ev, data);
    else this._openContextMenu($ev, data);
  }

  public closeContextMenu(): void {
    this.trigger.closeMenu();
    this.contextMenuPosition = { x: '0px', y: '0px' };
    this.trigger.menuData = {};
  }
}
