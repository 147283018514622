// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  width: 100%;
}

td,
th {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 200px;
}

.container {
  width: 100%;
  height: calc(100% - 56px);
  overflow-x: auto;
  overflow-y: visible;
}

.paginator {
  height: 56px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/data/csv-viewer/csv-viewer.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAEA;;EAEE,mBAAA;EACA,gBAAA;EACA,uBAAA;EACA,gBAAA;AACF;;AAEA;EACE,WAAA;EACA,yBAAA;EACA,gBAAA;EACA,mBAAA;AACF;;AAEA;EACE,YAAA;AACF","sourcesContent":["table {\n  width: 100%;\n}\n\ntd,\nth {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  min-width: 200px;\n}\n\n.container {\n  width: 100%;\n  height: calc(100% - 56px);\n  overflow-x: auto;\n  overflow-y: visible;\n}\n\n.paginator {\n  height: 56px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
