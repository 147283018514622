import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Host } from 'src/app/models/host';
import { StreamsService } from 'src/app/services/streams.service';
import { HostsService } from 'src/app/services/hosts.service';
import { IdentityService } from 'src/app/services/identity.service';
import { TrustService } from 'src/app/services/trust.service';

@Component({
  selector: 'app-streams',
  templateUrl: './streams.component.html',
  styleUrls: ['./streams.component.scss']
})
export class StreamsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  public displayedColumns: string[] = ['name', 'host', 'path', 'adapter', 'settings'];
  public pageSize = 10;
  public teamName: string;
  public isLoading = true;
  public dataSource = new MatTableDataSource<any>([]);

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private streamsService: StreamsService,
    private hostsService: HostsService,
    private trustService: TrustService,
    private identity: IdentityService
  ) {}

  ngOnInit(): void {
    if (window.innerHeight > 890) {
      this.pageSize = 20;
    }

    this.activatedRoute.params.subscribe((params) => {
      this.load(params.team);
    });
  }

  load(teamName: string) {
    this.teamName = teamName;
    const sub = this.streamsService.load(this.teamName).subscribe((response: any) => {
      sub.unsubscribe();
      this.dataSource.data = response;

      setTimeout(() => {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }, 0);

      this.isLoading = false;
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) this.dataSource.paginator.firstPage();
  }

  rowClick(row) {
    this.router.navigate([this.teamName, 'model', 'chart', row.name]);
  }

  settings(row) {
    this.router.navigate([this.teamName, 'model', 'stream', row.name]);
  }

  add() {
    this.router.navigate([this.teamName, 'model', 'stream', 'add']);
  }

  async delete(row) {
    if (!confirm(`Are you sure you want to delete ${row.name}?`)) return;

    await this.streamsService.deleteAsync(this.teamName, row.name);
    this.dataSource.data = this.dataSource.data.filter((x) => x.name !== row.name);
  }
}
