import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MetaParam } from 'src/app/models/meta';

@Component({
  selector: 'app-control',
  templateUrl: './control.component.html',
  styleUrls: ['./control.component.scss']
})
export class ControlComponent {
  @Input() control!: MetaParam;
  @Input() form!: FormGroup;
  @Input() chunkDescription: boolean = false;
  @Output() change = new EventEmitter<void>();
}
