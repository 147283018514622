// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  position: relative;
  top: -1px;
  height: calc(100% + 1px);
  display: block;
}

.loading-spinner {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  display: flex;
  height: 100%;
  flex-direction: column;
}
.content .extra-controls {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  flex-grow: 1;
}
.content .bottom-pane {
  padding: 0 1rem;
  border-top-width: 1px;
  border-top-style: solid;
  display: flex;
  height: 24px;
  align-items: center;
  width: 100%;
}
.content .bottom-pane .pipe {
  width: 1px;
  height: 100%;
  margin: 0 1rem;
}`, "",{"version":3,"sources":["webpack://./src/app/components/data/wav-viewer/wav-viewer.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,SAAA;EACA,wBAAA;EACA,cAAA;AACF;;AAEA;EACE,kBAAA;EACA,YAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;;AAEA;EACE,aAAA;EACA,YAAA;EACA,sBAAA;AACF;AACE;EACE,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;EACA,YAAA;AACJ;AAEE;EACE,eAAA;EACA,qBAAA;EACA,uBAAA;EACA,aAAA;EACA,YAAA;EACA,mBAAA;EACA,WAAA;AAAJ;AAEI;EACE,UAAA;EACA,YAAA;EACA,cAAA;AAAN","sourcesContent":[":host {\n  position: relative;\n  top: -1px;\n  height: calc(100% + 1px);\n  display: block;\n}\n\n.loading-spinner {\n  position: absolute;\n  height: 100%;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.content {\n  display: flex;\n  height: 100%;\n  flex-direction: column;\n\n  .extra-controls {\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    gap: 2rem;\n    flex-grow: 1;\n  }\n\n  .bottom-pane {\n    padding: 0 1rem;\n    border-top-width: 1px;\n    border-top-style: solid;\n    display: flex;\n    height: 24px;\n    align-items: center;\n    width: 100%;\n\n    .pipe {\n      width: 1px;\n      height: 100%;\n      margin: 0 1rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
