import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/services/config.service';
import WaveSurfer from 'wavesurfer.js';
import TimelinePlugin from 'wavesurfer.js/dist/plugins/timeline';
import SpectrogramPlugin from 'wavesurfer.js/dist/plugins/spectrogram';
import colormap from 'colormap';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-wav-viewer',
  templateUrl: './wav-viewer.component.html',
  styleUrls: ['./wav-viewer.component.scss']
})
export class WavViewerComponent implements OnInit {
  @Input() teamName: string;
  @Input() filePath: string;

  @HostListener('document:keydown', ['$event'])
  public keydownEvent($ev: KeyboardEvent) {
    if ($ev.code === 'Space') this.wavesurfer?.playPause();
    else if ($ev.code === 'ArrowRight') this.wavesurfer?.skip(5);
    else if ($ev.code === 'ArrowLeft') this.wavesurfer?.skip(-5);
  }

  public wavesurfer?: WaveSurfer;
  public loading: boolean = true;
  public meta: AudioBuffer | undefined;

  public static SPECTRO_HEIGHT = 512 as const;
  public static WAVE_HEIGHT = 128 as const;

  public get channelDisplayString() {
    if (this.meta.numberOfChannels === 1) return 'Mono';
    else if (this.meta.numberOfChannels === 2) return 'Stereo';
    return `${this.meta.numberOfChannels} Channels`;
  }

  public get sampleRateDisplayString() {
    return `${this.meta.sampleRate} Hz`;
  }

  public get durationDisplayString() {
    let totalDuration = Math.round(this.meta.duration * 1000);
    const hours = Math.floor(totalDuration / (60 * 60 * 1000));
    const minutes = Math.floor(totalDuration / (60 * 1000));
    const seconds = (totalDuration - minutes * (60 * 1000)) / 1000;

    return `${hours.toString().padStart(2, '0')} h ${minutes.toString().padStart(2, '0')} m ${seconds.toFixed(3)} s`;
  }

  public get samplesDisplayString() {
    return Math.round(this.meta.duration * this.meta.sampleRate).toLocaleString();
  }

  private _getWavBlob = async () =>
    new Promise<[Blob, string]>((resolve, _reject) => {
      const encoded = encodeURI(this.filePath.replace(/\//g, '\\'));
      const sub = this.http
        .get(`${ConfigService.apiUrl}/audio/${this.teamName}/${encoded}`, { responseType: 'blob' })
        .subscribe((response: Blob) => {
          sub.unsubscribe();
          resolve([response, URL.createObjectURL(new Blob([response], { type: response.type }))]);
        });
    });

  constructor(private http: HttpClient) {
    (<any>window).wavViewer = this;
  }

  ngOnInit(): void {
    const colors = colormap({
      // colormap: "greys",
      colormap: 'bone',
      nshades: 256,
      format: 'float',
      alpha: 1
    });

    const progressColor = document.createElement('canvas').getContext('2d').createLinearGradient(0, 0, 0, WavViewerComponent.WAVE_HEIGHT);
    progressColor.addColorStop(0, '#0277bd');
    progressColor.addColorStop(0.5, '#1976d2');
    progressColor.addColorStop(1, '#1565C0');

    this._getWavBlob().then(async ([blob, url]) => {
      this.meta = await new AudioContext().decodeAudioData(await blob.arrayBuffer());

      this.wavesurfer = WaveSurfer.create({
        container: '#waveform',
        waveColor: 'gray',
        progressColor,
        barWidth: 2,
        height: WavViewerComponent.WAVE_HEIGHT,
        mediaControls: false,
        dragToSeek: true,
        interact: true,
        normalize: true,
        sampleRate: this.meta.sampleRate,
        plugins: [
          TimelinePlugin.create({
            container: '#wave-timeline'
          }),

          SpectrogramPlugin.create({
            container: '#wave-spectrogram',
            labels: true,
            colorMap: colors,
            fftSamples: WavViewerComponent.SPECTRO_HEIGHT * 2,
            windowFunc: 'cosine'
          })
        ],
        url
      });
      this.wavesurfer.once('ready', () => {
        this.loading = false;
      });
    });
  }
}
