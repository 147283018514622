import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { Subject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ColumnService } from './column.service';
import { Run } from '../models/run';

@Injectable({ providedIn: 'root' })
export class ExperimentsService {
  private _collection$: Subject<any> = new Subject();
  private _meta$: Subject<any[]> = new Subject();
  private _stop$: Subject<any> = new Subject();
  private _start$: Subject<any> = new Subject();

  public popin$: Subject<any> = new Subject();

  get collection$() {
    return this._collection$.asObservable();
  }
  get meta$() {
    return this._meta$.asObservable();
  }
  get stop$() {
    return this._stop$.asObservable();
  }
  get start$() {
    return this._start$.asObservable();
  }

  constructor(
    private http: HttpClient,
    private columnService: ColumnService
  ) {
    window.$experiments = this;
  }

  public load(teamName: string = ''): Observable<any> {
    this.http.get(`${ConfigService.apiUrl}/experiments/${teamName}`).subscribe((response: any) => {
      const runs = response;
      const meta = this.getMeta(runs);
      this._meta$.next(meta);

      runs.forEach((element) => {
        Object.keys(element).forEach((key) => {
          if (element[key] && typeof element[key] == 'string' && element[key][0] == '[' && element[key][element[key].length - 1] == ']') {
            try {
              var elementData = eval(element[key]);
              if (Array.isArray(elementData) && elementData.length == 1) {
                element[key] = elementData[0];
              }
            } catch (exc) {
              console.error(exc);
            }
          }
        });
      });

      this._collection$.next({ team: teamName, meta: meta, runs: runs });
    });

    return this._collection$.asObservable();
  }

  public archive(teamName: string, experiments: Run[]): Observable<any> {
    const data = {
      teamName: teamName,
      experiments: experiments.map((x) => x.runid),
      value: true
    };
    return this.http.post(`${ConfigService.apiUrl}/experiments/archive`, data);
  }

  public unarchive(teamName: string, experiments: Run[]): Observable<any> {
    const data = {
      teamName: teamName,
      experiments: experiments.map((x) => x.runid),
      value: false
    };
    return this.http.post(`${ConfigService.apiUrl}/experiments/unarchive`, data);
  }

  public start(teamName: string, config: string, device: string, smartfit?: boolean): Observable<any> {
    // /experiments/start/:teamName/:host/:config/:device
    this.http
      .put(`${ConfigService.apiUrl}/experiments/start/${teamName}/${config}/${device}/${smartfit === true ? 'true' : 'false'}`, {})
      .subscribe((response: any) => {
        this._start$.next(response);
      });

    return this._start$.asObservable();
  }

  public continue(teamName: string, runid: number, device: string, smartfit?: boolean): Observable<any> {
    // /experiments/continue/:teamName/:runid/:device
    this.http
      .put(
        `${ConfigService.apiUrl}/experiments/continue/${teamName}/${runid}/${device}/${smartfit === true ? 'true' : 'false'}`,
        {}
      )
      .subscribe((response: any) => {
        this._start$.next(response);
      });

    return this._start$.asObservable();
  }

  public compile(teamName: string, config: string, format?: 'text' | 'json'): Observable<any> {
    // /experiments/start/:teamName/:host/:config/:device
    this.http
      .put(`${ConfigService.apiUrl}/experiments/compile/${teamName}/${config}${format === 'json' ? '?json' : ''}`, {})
      .subscribe((response: any) => {
        this._start$.next(response);
      });

    return this._start$.asObservable();
  }

  public stop(teamName: string, runid: number): Observable<any> {
    const url = `${ConfigService.apiUrl}/experiments/stop/${teamName}/${runid}`;
    this.http.put(url, {}).subscribe((response: any) => {
      this._stop$.next(response);
    });
    return this._stop$.asObservable();
  }

  public static known_numbers = [
    'elapsed',
    'max_u5',
    'max_u1',
    'min_o30',
    'loss',
    'val_loss',
    'acc',
    'val_acc',
    'accuracy',
    'val_accuracy',
    'binary_accuracy',
    'val_binary_accuracy',
    'P',
    'R',
    'val_P',
    'val_R',
    'max_val_P',
    'min_val_P',
    'max_val_R',
    'min_val_R',
    'min_loss',
    'max_loss'
  ];
  public static known_text = ['created', 'modified', 'last', 'pid', 'host', 'path', 'tags', 'is_archived', 'device'];

  public getMeta(runs) {
    // get meta data from runs
    // var keys = []; runs.forEach(x => keys.push(Object.keys(x)));
    // const columns = this.flatten(keys, true);
    const columns = this.columnService.getColumns(runs);

    const meta = columns.map((columnName) => {
      let type = !isNaN(runs[runs.length - 1][columnName] || runs[0][columnName]) ? 'number' : 'text'; // best guess
      if (columnName == 'vault') {
        type = 'vault';
      } else if (columnName == 'title') {
        type = 'markdown';
      } else if (ExperimentsService.known_text.indexOf(columnName) > -1) {
        type = 'text';
      } else if (ExperimentsService.known_numbers.indexOf(columnName) > -1) {
        type = 'number';
      }
      return {
        columnDef: columnName,
        headName: columnName.replace(/_/g, ' '),
        type: type
      };
    });

    return meta;
  }
}
