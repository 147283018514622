import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { catchError, of } from 'rxjs';
import { PredictAnalysisService } from 'src/app/services/predict-analysis.service';
import { VaultService } from 'src/app/services/vault.service';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import {
  SciChartSurface,
  NumericAxis,
  FastColumnRenderableSeries,
  XyDataSeries,
  GradientParams,
  Point,
  CategoryAxis,
  TextLabelProvider,
  StackedColumnCollection,
  StackedColumnRenderableSeries,
  ZoomExtentsModifier,
  ZoomPanModifier,
  MouseWheelZoomModifier,
  LegendModifier,
} from 'scichart';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-model-inputs-widget',
  templateUrl: './model-inputs-widget.component.html',
  styleUrls: ['../base-widget/base-widget.component.scss', './model-inputs-widget.component.scss']
})
export class ModelInputsWidgetComponent extends BaseWidgetComponent implements OnInit, OnDestroy {
  @ViewChild('featureSelector') featureSelector: MatSelect;
  @ViewChild('engineSelector') engineSelector: MatSelect;
  @ViewChild('inputsPaginator') inputsPaginator: MatPaginator;

  @Input() timestamp: string;
  @Input() modelName: string;
  @Input() modelValues: any;

  public changes = [];
  public showInputs = false;
  public inputsLoading = false;
  public inputData: any = null;
  public features: string[] = null;
  public impact: any = null;
  public impactLoading: boolean = false;
  public impactContent : string  = "Loading ..."
  public chart: SciChartSurface | undefined = undefined;
  public impactChart: SciChartSurface | undefined = undefined;

  public editorOptions = {
    theme: 'vs-dark',
    language: 'json',
    automaticLayout: true,
    scrollBeyondLastLine: false,
    minimap: {
      enabled: false
    }
  };

  public demoTableColumns: string[] | undefined;
  public demoTableData: any | undefined;
  public view: string = 'TABLE';
  public demoTableDS = new MatTableDataSource<any>([]);
  public meta = null;
  public sample_timestamp: string = null;

  public selectedFeature = null;
  public inputcsXValues = [];
  public inputopenValues = [];
  public inputhighValues = [];
  public inputlowValues = [];
  public inputcloseValues = [];
  public inputvolumeValues = [];

  public selectedPrediction = null;
  public selectedValue: any = null;
  public selectedElement = null;
  public selectedColumn = null;
  public predictionLoading = false;
  public selectedEngine: string = null;

  public deploys: string[] = [];
  public pageSize = 5;

  public get featurechanges(): any[] {
    return this.changes.filter((x) => x.feature == this.selectedFeature);
  }

  private oldPrediction: string = null;

  constructor(
    private predictAnalysis: PredictAnalysisService,
    private vaultService: VaultService,
    private _settingsService: SettingsService
  ) {
    super();
    if ((<any>window).insights) {
      (<any>window).insights.push(this);
    } else {
      (<any>window).insights = [this];
    }
  }

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  async init() {
    this.changes = [];
    this.showInputs = true;
    this.inputsLoading = true;
    this.selectedPrediction = [this.timestamp, this.modelValues];
    if(this.modelName && this.modelName.indexOf(":")) {
      this.selectedEngine = this.modelName.split(":")[0];
    }

    let modelName = this.modelName;
    if(modelName.indexOf(":")) {
      modelName = modelName.split(":")[1];
    }
    const vaultSub = this.vaultService.get(this.teamName, modelName).subscribe(async (response: any) => {
      vaultSub.unsubscribe();

      if (!response.vaultItem || response.vaultItem.workers.length === 0) return;

      this.deploys = response.vaultItem.workers;
      this.onEngineSelect(this.deploys[0]);
      setTimeout(() => {
        this.getImpactAnalysis();
      }, 100);
    });

  }

  onEngineSelect(engineName: string) {
    this.selectedEngine = engineName;
    if (this.engineSelector) this.engineSelector.value = this.selectedEngine;
    var func = this.predictAnalysis.getPredictionInputs(this.teamName, this.selectedEngine, this.timestamp);
    const sub = func.pipe(catchError((err) => of([]))).subscribe(async (response: any) => {
      sub.unsubscribe();
      this.inputData = response;
      this.features = this.inputData && this.inputData.features ? Object.keys(this.inputData.features) : [];
      this.inputsLoading = false;
      if (this.features?.length > 0) {
        this.selectFeature(this.features[0]);
      }
    });
  }

  public selectFeature(feature) {
    this.selectedFeature = feature;
    this.sample_timestamp = new Date(this.inputData.transactionTime).toLocaleString();
    this.meta = this.inputData.meta[feature];
    let demoResults = this.inputData.features[feature];

    if (demoResults === undefined) {
      // Clear any previous demo results
      Object.assign(this, { demoResults: undefined, demoTableColumns: undefined, demoTableData: undefined });
    } else if (demoResults.length === 0) {
      // Mark the results as empty, which displays a message
      Object.assign(this, { demoResults: 'empty', demoTableColumns: undefined, demoTableData: undefined });
    } else {
      const rowToObject = (row, rowNum = 0) => ({
        '#': rowNum + 1,
        ...Object.fromEntries(row.map((e, i) => [this.demoTableColumns[i + 1], e]))
      });

      // Resolve 2D data [[...],] vs 1D data [...]
      const is2D = demoResults[0] instanceof Array;
      const numberResultColumns = is2D ? demoResults[0].length : demoResults.length;

      if (numberResultColumns !== this.meta?.returns.length) {
        // Automatically number the columns in case the meta's returns field isn't applicable
        this.demoTableColumns = ['#', ...new Array(numberResultColumns).fill(0).map((_e, i) => (i + 1).toString())];
      } else {
        this.demoTableColumns = ['#', ...this.meta.returns];
      }

      // 2D provides multiple rows, whereas 1D data is a single row.
      if (is2D) {
        this.demoTableData = demoResults.map(rowToObject);
      } else {
        this.demoTableData = [rowToObject(demoResults)];
      }

      if (!['open', 'high', 'low', 'close'].every((x) => this.meta?.returns?.includes(x))) {
        this.view = 'TABLE';
      }

      this.updateData();

      this.demoTableDS.paginator = this.inputsPaginator;
      this.featureSelector.value = feature;
    }
  }

  public updateData() {
    const toPreso = (x) => x;
    this.inputcsXValues = this.demoTableData.map((item) => toPreso(item['#']));
    this.inputopenValues = this.demoTableData.map((item) => toPreso(item['open']));
    this.inputhighValues = this.demoTableData.map((item) => toPreso(item['high']));
    this.inputlowValues = this.demoTableData.map((item) => toPreso(item['low']));
    this.inputcloseValues = this.demoTableData.map((item) => toPreso(item['close']));
    this.inputvolumeValues = this.demoTableData.map((item) => toPreso(item['volume']));

    this.demoTableDS.data = this.demoTableData;
  }

  public selectInput(element, column) {
    this.selectedElement = element;
    this.selectedColumn = column;
    this.selectedValue = element[column];
  }

  public changeView(newView: string) {
    this.view = newView;
  }

  public getChangesPlaceholder() {
    var retval = null;

    if (this.changes.length == 0) {
      retval = 'No Changes';
    } else if (this.changes.length == 1) {
      retval = `${this.changes.length} change`;
    } else {
      retval = `${this.changes.length} changes`;
    }

    return retval;
  }

  public selectedValueChanged(event) {
    const value = parseFloat(event.srcElement.value);

    const row = this.selectedElement['#'] - 1;

    const oldValue = this.demoTableData[row][this.selectedColumn];
    this.demoTableData[row][this.selectedColumn] = value;
    const columnIndex = this.demoTableColumns.indexOf(this.selectedColumn) - 1; // to compensate for the '#' column we add
    this.inputData.features[this.selectedFeature][this.selectedElement['#'] - 1][columnIndex] = value;

    this.updateData();
    const change = {
      feature: this.selectedFeature,
      row: row,
      col: columnIndex,
      colName: this.selectedColumn,
      from: oldValue,
      value: value
    };
    this.changes.push(change);
    this.makePrediction();
  }

  public makePrediction() {
    const timestamp = this.selectedPrediction[0];
    this.predictionLoading = true;
    if (!this.oldPrediction) {
      this.oldPrediction = `Original prediction: ${this.selectedPrediction[1]}`;
    }
    let model_name = this.modelName;
    if (model_name.startsWith('model_')) {
      model_name = this.modelName.substring('model_'.length);
    }
    const sub = this.predictAnalysis
      .makePrediction(this.teamName, this.selectedEngine, model_name, timestamp, this.changes)
      .subscribe(async (response: any) => {
        sub.unsubscribe();
        this.selectedPrediction[1] = response;
        this.predictionLoading = false;
      });
  }

  public getImpactAnalysis() {
    const timestamp = this.selectedPrediction[0];
    let model_name = this.modelName;
    if (model_name.startsWith('model_')) {
      model_name = this.modelName.substring('model_'.length);
    }
    this.impactLoading = true;
    const sub = this.predictAnalysis
      .getImpactAnalysis(this.teamName, this.selectedEngine, model_name, timestamp)
      .subscribe(async (response: any) => {
        sub.unsubscribe();
        this.impact = response;
        this.impactContent = JSON.stringify(this.impact, null, '\t');
        this.impactLoading = false;

        const theme = this._settingsService.getChartThemeProvider();
        const { sciChartSurface: impactChart, wasmContext: wasmContext } = await SciChartSurface.create(this.id+"_impact", {
          theme,
          disableAspect: true
        });
        Object.assign(this, { impactChart });

        const labelProvider = new TextLabelProvider( {
          labels: this.impact.independence.map(x => (x[0] + " " + x[1]).replace("no_drop", "nodrop").replaceAll("_", " ")),
          maxLength: 7
        });

        impactChart.xAxes.add(new NumericAxis(wasmContext, {
          labelProvider: labelProvider
        } ));
        impactChart.yAxes.add(new NumericAxis(wasmContext));

        // Create some data
        const xValues = [...new Array(this.impact.independence.length).keys()];
        const yValues1 = this.impact.independence.map(x => Math.abs(x[2]));
        const yValues2 = this.impact.saliency.map(x => Math.abs(x[2]));


        const stackedCollection = new StackedColumnCollection(wasmContext);

        // Using a different stackedGroupId causes grouping (side-by-side)
        stackedCollection.add(new StackedColumnRenderableSeries(wasmContext, {
          dataSeries: new XyDataSeries(wasmContext, { xValues, yValues: yValues1, dataSeriesName: "Independence" }),
          fill: "#8BC34A",
          stroke:"#E4F5FC",
          strokeThickness: 1,
          opacity: 0.8,
          stackedGroupId: "StackedGroupId-First"
        }));

        stackedCollection.add(new StackedColumnRenderableSeries(wasmContext, {
          dataSeries: new XyDataSeries(wasmContext, { xValues, yValues: yValues2, dataSeriesName: "Saliency" }),
          fill: "#FFAB40",
          stroke: "#E4F5FC",
          strokeThickness: 1,
          opacity: 0.8,
          stackedGroupId: "StackedGroupId-Second"
        }));

        impactChart.chartModifiers.add(
          new ZoomExtentsModifier(),
          new ZoomPanModifier(),
          new MouseWheelZoomModifier(),
          new LegendModifier({
            showCheckboxes: false,
            showSeriesMarkers: true,
            showLegend: true,
          })
        );


        impactChart.renderableSeries.add(stackedCollection);


      });

  }

  tabInitialized = false;
  changeTab(event) {
    if(event == 1 && !this.tabInitialized) {
      // angular mat-tab is dumb, preserveContent is not good enough
      setTimeout(() => {
        this.tabInitialized = true;
        const impactChart = document.getElementById(this.id + "_impact");
        impactChart.classList.remove("hidden");
        impactChart.classList.add("chartContainer")
        document.getElementById(this.id+"_impactTab").appendChild(impactChart)
      }, 100);
    }
  }
}
