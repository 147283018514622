import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { HostPasswordDialogComponent } from '../components/host-password-dialog/host-password-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TrustService {
  constructor(
    private http: HttpClient,
    public dialog: MatDialog
  ) {}

  public add(teamName: string, hostName: string, password: string) {
    return this.http.post(`${ConfigService.apiUrl}/trust/${teamName}/${hostName}`, { password });
  }

  public delete(teamName: string, hostName: string) {
    return this.http.delete(`${ConfigService.apiUrl}/trust/${teamName}/${hostName}`);
  }

  async promptUserForCreds(teamName: string, hostName: string, createTrust: boolean = false) {
    const dialogRef = this.dialog.open(HostPasswordDialogComponent, {
      width: '450px',
      data: { host: hostName, createTrust: createTrust }
    });

    const results = await lastValueFrom(dialogRef.afterClosed());

    if (!results || !results.password) return { password: null, isTrusted: false, canceled: true };

    if (!results.createTrust) {
      return { password: results.password, isTrusted: false, canceled: false };
    }

    await this.addTrust(teamName, hostName, results.password);
    return { password: results.password, isTrusted: true, canceled: false };
  }

  async addTrust(teamName: string, hostName: string, password: string) {
    await lastValueFrom(this.add(teamName, hostName, password));
  }
}
