// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-field {
  padding-top: 10px;
}

.iframe-container {
  padding-left: 10px;
}

.iframe {
  width: 90%;
  height: 90vh;
  border: none;
}`, "",{"version":3,"sources":["webpack://./src/app/components/data/discovery/discovery.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,UAAA;EACA,YAAA;EACA,YAAA;AACF","sourcesContent":[".form-field {\n  padding-top: 10px;\n}\n\n.iframe-container {\n  padding-left: 10px;\n}\n\n.iframe {\n  width: 90%;\n  height: 90vh;\n  border: none;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
