// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0 1rem 1.25rem;
}
:host .omnibar {
  flex: 1 1 0;
}`, "",{"version":3,"sources":["webpack://./src/app/components/omnibar/omnibar.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,uBAAA;AACF;AACE;EACE,WAAA;AACJ","sourcesContent":[":host {\n  display: flex;\n  align-items: center;\n  gap: 1rem;\n  padding: 0 1rem 1.25rem;\n\n  .omnibar {\n    flex: 1 1 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
