import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import SVGCanvasEventManager, { MoveEvent } from 'src/app/models/svg-canvas-event-manager';
import { Node } from 'src/app/models/workflow-node';

@Component({
  selector: '[outport]',
  template: `<svg:circle class="port-hitbox" [attr.cx]="node.outPortOffset.x" [attr.cy]="node.outPortOffset.y" [attr.r]="20 * node.scale" />
    <svg:circle [class]="portClass" [attr.cx]="node.outPortOffset.x" [attr.cy]="node.outPortOffset.y" [attr.r]="4 * node.scale" />`,
  styleUrls: ['./workflow-canvas-node.component.scss', './workflow-canvas.vars.scss']
})
export class WorkflowCanvasOutPortComponent implements OnInit, OnDestroy {
  private _em: SVGCanvasEventManager;

  @Input() node: Node;
  @Input() unselected: boolean;
  @Output() drag = new EventEmitter<{ node: Node; e: MoveEvent }>();
  @Output() end = new EventEmitter<{ node: Node; e: MoveEvent }>();

  public get portClass(): string {
    return 'port' + (this.unselected ? ' unselected' : '');
  }

  constructor(private _host: ElementRef) {}

  public ngOnInit(): void {
    this._em = new SVGCanvasEventManager(this._host.nativeElement);
    this._em.$move((e: MoveEvent) => {
      e.stopPropagation();
      this.drag.emit({ node: this.node, e });
    });
    this._em.$moveEnd((e: MoveEvent) => {
      e.stopPropagation();
      this.end.emit({ node: this.node, e });
    });
  }

  public ngOnDestroy(): void {
    this._em.setdown();
  }
}
