// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
  position: relative;
  margin: 20px 30px;
}

.tab-group {
  height: 100%;
}

.button-row {
  display: flex;
}

.feature-hero {
  display: grid;
  grid-template-areas: "title icon" "subtitle icon";
  grid-template-rows: repeat(2, auto);
  grid-template-columns: repeat(2, auto);
  row-gap: 0.25rem;
}
.feature-hero mat-icon {
  grid-area: icon;
  align-self: center;
  justify-self: end;
  width: 28px;
  height: 28px;
  font-size: 28px;
  line-height: 28px;
}
.feature-hero h1 {
  grid-area: title;
  margin: 0;
}
.feature-hero h3 {
  grid-area: subtitle;
  margin-top: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/components/data/feature/feature.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,iBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,aAAA;EACA,iDACE;EAEF,mCAAA;EACA,sCAAA;EACA,gBAAA;AADF;AAGE;EACE,eAAA;EACA,kBAAA;EACA,iBAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;EACA,iBAAA;AADJ;AAIE;EACE,gBAAA;EACA,SAAA;AAFJ;AAKE;EACE,mBAAA;EACA,aAAA;AAHJ","sourcesContent":[".content {\n  position: relative;\n  margin: 20px 30px;\n}\n\n.tab-group {\n  height: 100%;\n}\n\n.button-row {\n  display: flex;\n}\n\n.feature-hero {\n  display: grid;\n  grid-template-areas:\n    'title icon'\n    'subtitle icon';\n  grid-template-rows: repeat(2, auto);\n  grid-template-columns: repeat(2, auto);\n  row-gap: 0.25rem;\n\n  mat-icon {\n    grid-area: icon;\n    align-self: center;\n    justify-self: end;\n    width: 28px;\n    height: 28px;\n    font-size: 28px;\n    line-height: 28px;\n  }\n\n  h1 {\n    grid-area: title;\n    margin: 0;\n  }\n\n  h3 {\n    grid-area: subtitle;\n    margin-top: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
