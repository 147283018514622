import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { DataBuild } from '../models/data-build';
import { Pid } from '../models/pid';
import { lastValueFrom } from 'rxjs';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DataBuildsService {
  private _dataBuildCollection$: Subject<DataBuild[]> = new Subject();
  private _build$: Subject<any> = new Subject();
  private _start$: Subject<any> = new Subject();

  constructor(private http: HttpClient) {}

  public load(teamName: string): Observable<DataBuild[]> {
    this.http.get(`${ConfigService.apiUrl}/data/builds/${teamName}`).subscribe((response: DataBuild[]) => {
      this._dataBuildCollection$.next(response);
    });

    return this._dataBuildCollection$.asObservable();
  }

  public start(teamName: string, buildName: string, preCacheOnly: boolean, strict: boolean): Observable<any> {
    this.http
      .put(`${ConfigService.apiUrl}/data/builds/start/${teamName}/${buildName}`, { preCacheOnly, strict })
      .subscribe((response: any) => {
        this._start$.next(response);
      });

    return this._start$.asObservable();
  }

  public stop(teamName: string, buildName: string, runid: string): Observable<any> {
    this.http.put(`${ConfigService.apiUrl}/data/builds/stop/${teamName}/${buildName}`, { runid }).subscribe((response: any) => {
      this._start$.next(response);
    });

    return this._start$.asObservable();
  }

  public clean(teamName: string, buildName: string): Observable<any> {
    this.http.put(`${ConfigService.apiUrl}/data/builds/clean/${teamName}/${buildName}`, {}).subscribe((response: any) => {
      this._start$.next(response);
    });

    return this._start$.asObservable();
  }

  public async loadOneAsync(teamName: string, runid: number) {
    return lastValueFrom(this.http.get<DataBuild>(`${ConfigService.apiUrl}/data/build/${teamName}/${runid}`));
  }

  public loadPids(teamName: string, buildName: string, runid: number, isArchived: boolean): Observable<Pid[]> {
    return this.http.get<Pid[]>(`${ConfigService.apiUrl}/data/workflow/pids/${teamName}/${buildName}/${runid}/${isArchived}`);
  }

  public loadLog(teamName: string, runid: number, isArchived: boolean, pageSize: number, pageNum: number): Observable<any> {
    return this.http.get(`${ConfigService.apiUrl}/data/workflow/log/${teamName}/${runid}/${isArchived}/${pageSize}/${pageNum}`);
  }

  public async updateTagsAsync(teamName: string, runid: number, isArchived: boolean, tags: string) {
    return lastValueFrom(this.http.put(`${ConfigService.apiUrl}/data/builds/tags/${teamName}`, { runid, isArchived, tags }));
  }

  public archive(teamName: string, runid: number): Observable<any> {
    return this.http.post(`${ConfigService.apiUrl}/data/builds/archive/`, { teamName, runid });
  }

  public restore(teamName: string, runid: number): Observable<any> {
    return this.http.post(`${ConfigService.apiUrl}/data/builds/restore/`, { teamName, runid });
  }
}
