// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grid-container {
  margin: 20px;
}

.dashboard-card {
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  cursor: pointer;
}

.more-button {
  position: absolute;
  top: 5px;
  right: 10px;
}

.dashboard-card-content {
  text-align: left;
}

mat-grid-list {
  height: calc(100vh - 50px) !important;
  margin: 20px;
}

.mat-h1 {
  padding: 20px 0 0 0;
}

.mat-mdc-card-title span {
  font-size: 19px;
  margin-top: 6px;
}

.big-number {
  font-size: 24px;
}

.label {
  font-size: 0.8em;
}

.metric {
  padding: 10px;
}

.dashboard-card-content a {
  text-decoration: none;
  font-size: 0.9em;
  line-height: 25px;
}

.mat-mdc-card {
  min-width: 350px;
  min-height: 150px;
  height: calc(100% - 35px);
}`, "",{"version":3,"sources":["webpack://./src/app/components/home/home.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE,kBAAA;EACA,SAAA;EACA,UAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;AACF;;AAEA;EACE,kBAAA;EACA,QAAA;EACA,WAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,qCAAA;EACA,YAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,eAAA;EACA,eAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,qBAAA;EACA,gBAAA;EACA,iBAAA;AACF;;AAEA;EACE,gBAAA;EACA,iBAAA;EACA,yBAAA;AACF","sourcesContent":[".grid-container {\n  margin: 20px;\n}\n\n.dashboard-card {\n  position: absolute;\n  top: 15px;\n  left: 15px;\n  right: 15px;\n  bottom: 15px;\n  cursor: pointer;\n}\n\n.more-button {\n  position: absolute;\n  top: 5px;\n  right: 10px;\n}\n\n.dashboard-card-content {\n  text-align: left;\n}\n\nmat-grid-list {\n  height: calc(100vh - 50px) !important;\n  margin: 20px;\n}\n\n.mat-h1 {\n  padding: 20px 0 0 0;\n}\n\n.mat-mdc-card-title span {\n  font-size: 19px;\n  margin-top: 6px;\n}\n\n.big-number {\n  font-size: 24px;\n}\n\n.label {\n  font-size: 0.8em;\n}\n\n.metric {\n  padding: 10px;\n}\n\n.dashboard-card-content a {\n  text-decoration: none;\n  font-size: 0.9em;\n  line-height: 25px;\n}\n\n.mat-mdc-card {\n  min-width: 350px;\n  min-height: 150px;\n  height: calc(100% - 35px);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
