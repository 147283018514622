import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfigService } from 'src/app/services/config.service';
import { ModelIndicatorsService } from 'src/app/services/model-indicators-service';
import {
  widget,
  IChartingLibraryWidget,
  ChartingLibraryWidgetOptions,
  LanguageCode,
  ResolutionString,
  Timezone
} from '../../../assets/charting_library';
import { VaultService } from 'src/app/services/vault.service';
import { ChartSaveLoadService } from 'src/app/services/chart-save-load.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-tv-chart-container',
  templateUrl: './tv-chart-container.component.html',
  styleUrls: ['./tv-chart-container.component.scss']
})
export class TvChartContainerComponent implements OnInit, OnDestroy {
  private _symbol: ChartingLibraryWidgetOptions['symbol'] = 'AAPL';
  private _interval: ChartingLibraryWidgetOptions['interval'] = '1' as ResolutionString;
  // BEWARE: no trailing slash is expected in feed URL
  private _datafeedUrl = ConfigService.udfUrl;
  private _libraryPath: ChartingLibraryWidgetOptions['library_path'] = '/assets/charting_library/';
  private _chartsStorageUrl: ChartingLibraryWidgetOptions['charts_storage_url'] = 'https://saveload.tradingview.com';
  private _chartsStorageApiVersion: ChartingLibraryWidgetOptions['charts_storage_api_version'] = '1.1';
  private _clientId: ChartingLibraryWidgetOptions['client_id'] = 'tradingview.com';
  private _userId: ChartingLibraryWidgetOptions['user_id'] = 'bend1a17';
  private _fullscreen: ChartingLibraryWidgetOptions['fullscreen'] = false;
  private _autosize: ChartingLibraryWidgetOptions['autosize'] = true;
  private _containerId: ChartingLibraryWidgetOptions['container'] = 'tv_chart_container';
  private _tvWidget: IChartingLibraryWidget | null = null;
  private _updateFrequency: number = 10;
  private _timezone: 'exchange' | Timezone = 'exchange';
  private _options: ChartingLibraryWidgetOptions;
  private pollInterval: any = null;

  @Input()
  set symbol(symbol: ChartingLibraryWidgetOptions['symbol']) {
    this._symbol = symbol || this._symbol;
  }

  @Input()
  set interval(interval: ChartingLibraryWidgetOptions['interval']) {
    this._interval = interval || this._interval;
  }

  @Input()
  set datafeedUrl(datafeedUrl: string) {
    this._datafeedUrl = datafeedUrl || this._datafeedUrl;
  }

  @Input()
  set libraryPath(libraryPath: ChartingLibraryWidgetOptions['library_path']) {
    this._libraryPath = libraryPath || this._libraryPath;
  }

  @Input()
  set chartsStorageUrl(chartsStorageUrl: ChartingLibraryWidgetOptions['charts_storage_url']) {
    this._chartsStorageUrl = chartsStorageUrl || this._chartsStorageUrl;
  }

  @Input()
  set chartsStorageApiVersion(chartsStorageApiVersion: ChartingLibraryWidgetOptions['charts_storage_api_version']) {
    this._chartsStorageApiVersion = chartsStorageApiVersion || this._chartsStorageApiVersion;
  }

  @Input()
  set clientId(clientId: ChartingLibraryWidgetOptions['client_id']) {
    this._clientId = clientId || this._clientId;
  }

  @Input()
  set userId(userId: ChartingLibraryWidgetOptions['user_id']) {
    this._userId = userId || this._userId;
  }

  @Input()
  set fullscreen(fullscreen: ChartingLibraryWidgetOptions['fullscreen']) {
    this._fullscreen = fullscreen || this._fullscreen;
  }

  @Input()
  set autosize(autosize: ChartingLibraryWidgetOptions['autosize']) {
    this._autosize = autosize || this._autosize;
  }

  @Input()
  set containerId(containerId: ChartingLibraryWidgetOptions['container_id']) {
    this._containerId = containerId || this._containerId;
  }

  @Input()
  set updateFrequency(updateFrequency: number) {
    this._updateFrequency = updateFrequency || this._updateFrequency;
  }

  @Input()
  set timezone(timezone: 'exchange' | Timezone) {
    this._timezone = timezone || this._timezone;
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private modelIndicatorsService: ModelIndicatorsService,
    private vaultService: VaultService,
    private dataService: DataService
  ) {}

  teamName: string;

  ngOnInit() {
    (<any>window).chart = this;
  }

  ngAfterViewInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.teamName = params.team;
      this.load();
    });
  }

  load() {
    const sub = this.modelIndicatorsService.load(this.teamName).subscribe(async (response) => {
      sub.unsubscribe();
      const model_indicators = response;
      let vault: any = await this.vaultService.loadAsync(this.teamName);
      vault = vault.filter((x) => model_indicators.includes(x.name));

      function getLanguageFromURL(): LanguageCode | null {
        const regex = new RegExp('[\\?&]lang=([^&#]*)');
        const results = regex.exec(location.search);

        return results === null ? null : (decodeURIComponent(results[1].replace(/\+/g, ' ')) as LanguageCode);
      }

      this._options = {
        symbol: this._symbol,
        datafeed: new (window as any).Datafeeds.UDFCompatibleDatafeed(this._datafeedUrl, this._updateFrequency * 1000),
        interval: this._interval,
        container: this._containerId,
        library_path: this._libraryPath,
        locale: getLanguageFromURL() || 'en',
        disabled_features: ['use_localstorage_for_settings'],
        enabled_features: ['study_templates'],
        charts_storage_url: this._chartsStorageUrl,
        charts_storage_api_version: this._chartsStorageApiVersion,
        client_id: this._clientId,
        user_id: this._userId,
        fullscreen: this._fullscreen,
        timezone: this._timezone,
        autosize: this._autosize,
        compare_symbols: vault.map((x) => ({
          symbol: `model_${x.name}`,
          title: `model_${x.name}`,
          full_name: `model_${x.name}`,
          description: `model_${x.name}`,
          exchange: 'LIT',
          type: 'expression'
        })),
        debug: false,
        save_load_adapter: new ChartSaveLoadService()
      };

      const widgetOptions: ChartingLibraryWidgetOptions = this._options as ChartingLibraryWidgetOptions;

      const tvWidget = new widget(widgetOptions);
      this._tvWidget = tvWidget;

      tvWidget.onChartReady(() => {
        tvWidget.headerReady().then(async () => {
          const button = tvWidget.createButton();

          const iframes = document.getElementsByTagName('iframe');
          if (iframes && iframes.length > 0) {
            const iframeObj = document.getElementsByTagName('iframe')[0];

            if (iframeObj) {
              const compareButton = iframeObj.contentWindow.document.getElementById('header-toolbar-compare');

              if (compareButton) {
                const caption = compareButton.querySelector('.js-button-text');
                if (compareButton) {
                  caption.innerHTML = 'Models';
                }
              }
            }
          }

          button.setAttribute('title', 'Click to capture upto last 10 minutes of data');
          button.classList.add('apply-common-tooltip');
          button.classList.add('button');
          button.innerHTML = 'Capture';
          button.addEventListener('click', () => {
            const tv: any = this._tvWidget;
            const models = tv._options.compare_symbols.map((x) => x.symbol.replace('model_', ''));
            if (models.length === 0) {
              alert('No models available.');
              return;
            }

            button.style.setProperty('pointer-events', 'none');
            button.style.setProperty('color', 'gray');

            alert('Capture initiated.');

            const sub = this.dataService.capture(this.teamName, models).subscribe((x) => {
              sub.unsubscribe();
              this.poll(button);
            });
          });

          const results: any = await this.dataService.pollAsync(this.teamName);
          if (results.captureInprogress) {
            button.style.setProperty('pointer-events', 'none');
            button.style.setProperty('color', 'gray');
            this.poll(button, true);
          }
        });
      });
    });
  }

  poll(button, suppressMessage = false) {
    this.pollInterval = setInterval(async () => {
      const results: any = await this.dataService.pollAsync(this.teamName);
      if (!results.captureInprogress) {
        button.style.removeProperty('pointer-events');
        button.style.removeProperty('color');
        clearInterval(this.pollInterval);

        if (!suppressMessage) {
          alert('Capture complete.');
        }
      }
    }, 10000);
  }
  ngOnDestroy() {
    if (this._tvWidget !== null) {
      this._tvWidget.remove();
      this._tvWidget = null;
    }

    if (this.pollInterval) {
      clearInterval(this.pollInterval);
    }
  }
}
