import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';

@Component({
  selector: 'app-hosts-widget',
  templateUrl: './hosts-widget.component.html',
  styleUrls: ['../base-widget/base-widget.component.scss', './hosts-widget.component.scss']
})
export class HostsWidgetComponent extends BaseWidgetComponent implements OnInit {
  public hostName: string;

  @Output() onTerminal = new EventEmitter<{ parentId: string }>();

  constructor() {
    super();
  }

  ngOnInit(): void {}

  onHostSelect($event: any) {
    this.hostName = $event.hostName;
    this.widgetView = 'DETAIL';
  }

  onCloseHost() {
    this.hostName = null;
    this.widgetView = 'LIST';
  }

  onTerminalSelect() {
    this.onTerminal.emit({ parentId: this.id });
  }
}
