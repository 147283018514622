// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.connection {
  stroke: var(--wfc-lines);
  fill: none;
  cursor: pointer;
}

.connection.preview {
  stroke-dasharray: 10;
  stroke-width: 2px;
  cursor: default;
}

.connection.selected {
  stroke: var(--wfc-red);
}

.connection.unselected {
  stroke: #d7d7da;
}

.connection.animated {
  stroke-dasharray: 10;
  animation: connection-stroke-march 500ms linear infinite;
}

@keyframes connection-stroke-march {
  from {
    stroke-dashoffset: 20;
  }
  to {
    stroke-dashoffset: 0;
  }
}
.connection.hitbox {
  stroke: rgba(255, 255, 255, 0);
  stroke-width: 24px;
}

.close {
  fill: var(--wfc-red);
}

.close-outline {
  fill: #ffffff;
  stroke-width: 1;
  stroke: var(--wfc-red);
}

.close-hitbox {
  fill: rgba(255, 0, 255, 0);
  stroke-width: 24;
  stroke: rgba(255, 0, 255, 0);
  cursor: pointer;
}

.close-x {
  fill: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/app/components/models/definition/workflow-canvas/workflow-canvas-connection.component.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;EACA,UAAA;EACA,eAAA;AACF;;AAEA;EACE,oBAAA;EACA,iBAAA;EACA,eAAA;AACF;;AAEA;EACE,sBAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,oBAAA;EACA,wDAAA;AACF;;AAEA;EACE;IACE,qBAAA;EACF;EACA;IACE,oBAAA;EACF;AACF;AAEA;EACE,8BAAA;EACA,kBAAA;AAAF;;AAGA;EACE,oBAAA;AAAF;;AAGA;EACE,aAAA;EACA,eAAA;EACA,sBAAA;AAAF;;AAGA;EACE,0BAAA;EACA,gBAAA;EACA,4BAAA;EACA,eAAA;AAAF;;AAGA;EACE,aAAA;AAAF","sourcesContent":[".connection {\n  stroke: var(--wfc-lines);\n  fill: none;\n  cursor: pointer;\n}\n\n.connection.preview {\n  stroke-dasharray: 10;\n  stroke-width: 2px;\n  cursor: default;\n}\n\n.connection.selected {\n  stroke: var(--wfc-red);\n}\n\n.connection.unselected {\n  stroke: #d7d7da;\n}\n\n.connection.animated {\n  stroke-dasharray: 10;\n  animation: connection-stroke-march 500ms linear infinite;\n}\n\n@keyframes connection-stroke-march {\n  from {\n    stroke-dashoffset: 20;\n  }\n  to {\n    stroke-dashoffset: 0;\n  }\n}\n\n.connection.hitbox {\n  stroke: #ffffff00;\n  stroke-width: 24px;\n}\n\n.close {\n  fill: var(--wfc-red);\n}\n\n.close-outline {\n  fill: #ffffff;\n  stroke-width: 1;\n  stroke: var(--wfc-red);\n}\n\n.close-hitbox {\n  fill: #ff00ff00;\n  stroke-width: 24;\n  stroke: #ff00ff00;\n  cursor: pointer;\n}\n\n.close-x {\n  fill: #ffffff;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
