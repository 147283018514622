// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  width: 100%;
  height: 100%;
}

svg {
  touch-action: manipulation;
  box-sizing: border-box;
  background: #e7ebee;
  pointer-events: painted;
}

.button-stack {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  bottom: 0.5rem;
  left: calc(3px + 0.5rem);
}`, "",{"version":3,"sources":["webpack://./src/app/components/models/definition/workflow-canvas/workflow-canvas.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;AACF;;AAEA;EACE,0BAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AACF;;AAEA;EACE,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,WAAA;EACA,cAAA;EACA,wBAAA;AACF","sourcesContent":[":host {\n  width: 100%;\n  height: 100%;\n}\n\nsvg {\n  touch-action: manipulation;\n  box-sizing: border-box;\n  background: #e7ebee;\n  pointer-events: painted;\n}\n\n.button-stack {\n  position: absolute;\n  display: flex;\n  flex-direction: column;\n  gap: 0.5rem;\n  bottom: 0.5rem;\n  left: calc(3px + 0.5rem);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
