import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { DataBuild } from 'src/app/models/data-build';
import { WidgetService } from 'src/app/services/widget.service';
import { SchemaComponent } from '../../data/schema/schema.component';
import { ContentObserver } from '@angular/cdk/observers';
import { DataBuildsService } from 'src/app/services/data-builds.service';

@Component({
  selector: 'app-builds-widget',
  templateUrl: './builds-widget.component.html',
  styleUrls: ['../base-widget/base-widget.component.scss', './builds-widget.component.scss']
})
export class BuildsWidgetComponent extends BaseWidgetComponent implements OnInit {
  public runid: number;
  public rdsName: string;
  public user: string;
  public isArchived: boolean;
  public clone: any = null;

  @Input() build: DataBuild = null;
  @Output() onAsset = new EventEmitter<{ parentId: string; assetName: string }>();


  constructor(private _widgetService: WidgetService, private _dataBuildsService: DataBuildsService) {
    super();
    (<any>window).buildsWidget = this;
  }

  ngOnInit(): void {
    if (this.build) {
      this.rdsName = this.build.rdsName;
      this.runid = this.build.runid;
      this.user = this.build.user;
      this.isArchived = this.build.isArchived;
      this.widgetView = 'DETAIL';
    }

    this._subs.add = this._widgetService.resize$.subscribe(() => {
      if ((<any>window).builds) (<any>window).builds.onResize();
      if ((<any>window).build) (<any>window).build.onResize();
    });
  }

  onBuildClick($event) {
    this.rdsName = $event.rdsName;
    this.runid = $event.runid;
    this.user = $event.user;
    this.isArchived = $event.isArchived;

    this.widgetView = 'DETAIL';
  }

  async onCloneClick($event) {
    const build: any = await this._dataBuildsService.loadOneAsync(this.teamName, $event.runid);
    if (!build) return;

    this.rdsName = `build_${Date.now().toString()}`;
    build.schema.name = this.rdsName;
    this.clone = build.schema;
    this.widgetView = 'NEW';
  }

  onOpenAsset($event) {
    const input = { parentId: this.id, ...$event };
    this.onAsset.emit(input);
  }

  onNewBuild() {
    this.widgetView = 'NEW';
  }


  closeBuild() {
    this.rdsName = null;
    this.runid = null;
    this.user = null;
    this.isArchived = null;
    this.clone = null;
    this.widgetView = 'LIST';
  }

  close() {
    if (this.widgetView === 'DETAIL' || this.widgetView === 'NEW') {
      this.closeBuild();
    } else {
      super.close();
    }
  }

  onStartBuild($event: SchemaComponent.BuildEvent) {
      console.log('onbuild')
  }

  onDeleteBuild(_$event: SchemaComponent.DeleteEvent) {
    // Object.assign(this, {
    //   projectName: undefined,
    //   rdsName: undefined,
    //   isNew: true,
    //   widgetView: 'LIST'
    // });
    console.log('ondelete');
  }


  onSaveBuild(_$event: SchemaComponent.SaveEvent) {
    console.log('onsave');
    // Object.assign(this, {
    //   projectName: undefined,
    //   rdsName: undefined,
    //   isNew: true,
    //   widgetView: 'LIST'
    // });
  }
}
