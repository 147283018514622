import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfigService } from 'src/app/services/config.service';
import { CacheService } from 'src/app/services/cache.service';
import { shareReplay, catchError, EMPTY } from 'rxjs';
@Component({
  selector: 'app-model-view',
  templateUrl: './model-view.component.html',
  styleUrls: ['./model-view.component.scss']
})
export class ModelViewComponent implements OnInit {
  public editorOptions = {
    theme: 'vs-dark',
    language: 'python',
    automaticLayout: true,
    scrollBeyondLastLine: false,
    minimap: {
      enabled: false
    }
  };

  @Input() teamName: string;
  @Input() path: string;
  @Input() empty: boolean = false;

  public code: string = null;
  public apiUrl: string = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      if (this.empty || !this.teamName || !this.path) {
        this.code = 'Nothing selected.';
      } else {
        if (this.teamName && this.path) this.load(this.teamName, encodeURI(this.path.replace(/\//g, '\\')));
        else {
          this.load(params.team, params.path);
        }
      }
    });
  }

  load(teamName: string, path: string) {
    this.code = 'Loading...';
    if (!CacheService.cache[path]) {
      CacheService.cache[path] = this.http.get(`${ConfigService.apiUrl}/file/${teamName}/${path}`, { responseType: 'text' }).pipe(
        shareReplay(1),
        catchError((err) => {
          console.error(err);
          CacheService.delete(path);
          return EMPTY;
        })
      );
    }

    CacheService.cache[path].subscribe((data: any) => {
      this.code = data.toString().trim();
      this.changeDetector.detectChanges();
    });
  }

  deleteCacheReload() {
    const encoded = encodeURI(this.path.replace(/\//g, '\\'));
    CacheService.delete(encoded);
    this.load(this.teamName, encoded);
  }
}
