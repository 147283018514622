// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#container {
  height: 100%;
}

ngx-monaco-diff-editor {
  object-fit: fill;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/components/models/model-diff/model-diff.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE,gBAAA;EACA,YAAA;AACF","sourcesContent":["#container {\n  height: 100%;\n}\n\nngx-monaco-diff-editor {\n  object-fit: fill;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
