// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .editor-container {
  height: 100% !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/strategy-grid/packet-widget/packet-widget.component.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;AACF","sourcesContent":["::ng-deep .editor-container {\n  height: 100% !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
