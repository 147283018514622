import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { GpusService } from 'src/app/services/gpus.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IdentityService } from 'src/app/services/identity.service';
import { MatButton } from '@angular/material/button';
import { UsersService } from 'src/app/services/users.service';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TeamPolicy } from 'src/app/models/policy';

@Component({
  selector: 'app-gpus',
  templateUrl: './gpus.component.html',
  styleUrls: ['./gpus.component.scss']
})
export class GpusComponent implements OnInit, AfterViewInit {
  public isLoading: boolean = false;
  public users: any[] = [{ username: 'unprovision' }];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatButton) refresh: MatButton;

  private teamName: string;
  public dataSource = new MatTableDataSource<any>([]);
  public displayedColumns = [
    'host',
    'name',
    'pci_bus_id',
    'vbios_version',
    'pstate',
    'memory_total',
    'temperature_gpu',
    'utilization_gpu',
    'utilization_memory',
    'pid',
    'user'
  ];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private gpuService: GpusService,
    public identity: IdentityService,
    private usersService: UsersService,
    public snackbar: MatSnackBar
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.reloadData();
    }, 100);
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.teamName = params.team;
      this.init(params.team);
    });
  }

  init(teamName: string) {
    this.teamName = teamName;
    this.loadUsers();
  }

  loadUsers() {
    const sub = this.usersService.load(this.teamName).subscribe((results) => {
      sub.unsubscribe();
      this.users.push(...results);
    });
  }

  reloadData() {
    this.isLoading = true;
    const sub = this.gpuService.load(this.teamName).subscribe((results) => {
      this.dataSource = new MatTableDataSource<any>(results);
      this.isLoading = false;
      sub.unsubscribe();
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim().toLowerCase();
    this.dataSource.filter = filterValue;
  }

  openSnackBar(message: string, action: string) {
    const durationInSeconds = 3;
    const horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    const verticalPosition: MatSnackBarVerticalPosition = 'top';

    this.snackbar.open(message, action, {
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition,
      duration: durationInSeconds * 1000
    });
  }
}
