export class VaultItem {
  host: string = null;
  project: string = null;
  runid: string = null;
  epoch: string = null;
  name: string = null;
  new_name: string = null;
  value: string = null;
  notes: string = '';
  workers: string[] = [];
  deployment: string[] = null;
  color: string = null;
}
