import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { StreamsService } from 'src/app/services/streams.service';

@Component({
  selector: 'app-stream-api-stats',
  templateUrl: './stream-api-stats.component.html',
  styleUrls: ['./stream-api-stats.component.scss']
})
export class StreamApiStatsComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input() teamName: string = null;
  @Input() streamName: string = null;

  public dataSource = new MatTableDataSource<any>([]);
  public displayedColumns = ['endpoint', 'last_access', 'requests', 'errors', 'average_response_time'];
  public isLoading = true;

  constructor(private _streamsService: StreamsService) {}

  ngOnInit(): void {
    const sub = this._streamsService.getApiStats(this.teamName, this.streamName).subscribe((results: any) => {
      sub.unsubscribe();
      if (!results) return;

      this.dataSource.data = results;
      this.isLoading = false;
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
}
