import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Project } from 'src/app/models/project';
import { IdentityService } from 'src/app/services/identity.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { OmnibarComponent } from '../../omnibar/omnibar.component';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(OmnibarComponent) omnibar: OmnibarComponent;

  public isLoading: boolean = true;
  public allProjects: Project[] = [];
  public dataSource = new MatTableDataSource<Project>([]);
  public selection = new SelectionModel<Project>(true, []);
  public displayedColumns = [
    'select',
    'projectName',
    'totalRuns',
    'totalEpochs',
    'totalElapsed',
    'totalCost',
    'totalValue',
    'totalUsers',
    'lastModified'
  ];
  public teamUsers = [];
  public filters = {
    archived: false
  };

  public get allSelectedCanBeArchived(): boolean {
    return this.selection.selected.every((x) => !x.isArchived);
  }

  public get allSelectedCanBeRestored(): boolean {
    return this.selection.selected.every((x) => x.isArchived);
  }

  private get _teamName(): string {
    return this._identity.me.selectedTeamName;
  }

  constructor(
    private router: Router,
    private projectsService: ProjectsService,
    private _identity: IdentityService,
    private _notifier: NotificationService
  ) {}

  public ngOnInit(): void {
    this.load();
  }

  public async load(): Promise<void> {
    this.allProjects = await this.projectsService.loadAsync(this._teamName);
    this.applyCustomFilters();
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.isLoading = false;
  }

  public rowClick(row: Project): void {
    if (row.isArchived ?? false) {
      this._notifier.showError('Restore the archived project to inspect it');
    } else this.router.navigate(['/', this._teamName, 'project', row.name]);
  }

  public selectAll(): void {
    this.dataSource.filteredData.forEach(this.selection.select.bind(this.selection));
  }

  public refresh(): void {
    this.isLoading = true;
    this.dataSource.data = [];
    this.load();
  }

  public applyFilter(searchTerm?: string): void {
    searchTerm = searchTerm?.trim().toLowerCase() ?? '';
    this.dataSource.filter = searchTerm;
  }

  public applyCustomFilters(): void {
    const { archived } = this.filters;
    this.dataSource.data = this.allProjects.filter((proj) => {
      if (archived !== (proj.isArchived ?? false)) return false;
      return true;
    });
    this.selection.clear();
  }

  public $clickArchivedFilter($ev: MouseEvent): void {
    $ev.preventDefault();

    this.filters.archived = !this.filters.archived;
    this.applyCustomFilters();
  }

  public archive(): void {
    this.setArchive(true);
  }

  public restore(): void {
    this.setArchive(false);
  }

  public setArchive(archive: boolean): void {
    const archivist = (archive ? this.projectsService.archive : this.projectsService.restore).bind(this.projectsService);
    const { selected } = this.selection;

    selected.forEach((project) => {
      const sub = archivist(this._teamName, project.name).subscribe(() => {
        sub.unsubscribe();
        project.isArchived = !project.isArchived;
        this.selection.toggle(project);
        this.applyFilter(this.omnibar.value);
      });
    });
  }
}
