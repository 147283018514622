import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import {
  Entity,
  Field,
  EntityContext,
  FieldContext,
  InputContext,
  OperatorContext,
  Option,
  QueryBuilderClassNames,
  QueryBuilderConfig,
  RemoveButtonContext,
  Rule,
  RuleSet
} from './query-builder.interfaces';
import {
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef,
  HostBinding
} from '@angular/core';

export const CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => QueryBuilderComponent),
  multi: true
};

export const VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => QueryBuilderComponent),
  multi: true
};

@Component({
  selector: 'app-query-builder',
  templateUrl: './query-builder.component.html',
  styleUrls: ['./query-builder.component.scss'],
  providers: [CONTROL_VALUE_ACCESSOR, VALIDATOR]
})
export class QueryBuilderComponent implements OnChanges, ControlValueAccessor, Validator {
  @Input() disabled: boolean;
  @Input() data: RuleSet = { condition: 'and', rules: [] };
  @Input() allowRuleset: boolean = true;
  @Input() @HostBinding('class.collapsible') allowCollapse: boolean = false;
  @Input() emptyMessage: string = 'A ruleset cannot be empty. Please add a rule or remove it all together.';
  @Input() classNames: QueryBuilderClassNames;
  @Input() operatorMap: { [key: string]: string[] };
  @Input() parentValue: RuleSet;
  @Input() config: QueryBuilderConfig = { fields: {} };
  @Input() parentChangeCallback: () => void;
  @Input() parentTouchedCallback: () => void;
  @Input() persistValueOnFieldChange: boolean = false;
  @Input()
  get value(): RuleSet {
    return this.data;
  }
  set value(value: RuleSet) {
    this.data = value || { condition: 'and', rules: [] };
    this._handleDataChange();
  }

  @ViewChild('treeContainer', { static: true }) treeContainer: ElementRef;

  private _defaultPersistValueTypes: string[] = ['string', 'number', 'time', 'date', 'boolean'];
  private _defaultEmptyList: any[] = [];
  private _operatorsCache: { [key: string]: string[] };
  private _inputContextCache = new Map<Rule, InputContext>();
  private _operatorContextCache = new Map<Rule, OperatorContext>();
  private _fieldContextCache = new Map<Rule, FieldContext>();
  private _entityContextCache = new Map<Rule, EntityContext>();
  private _removeButtonContextCache = new Map<Rule, RemoveButtonContext>();

  public onChangeCallback: () => void;
  public onTouchedCallback: () => any;

  public fields: Field[];
  public filterFields: Field[];
  public entities: Entity[];
  public defaultOperatorMap: { [key: string]: string[] } = {
    string: ['=', '!=', 'contains', 'like'],
    number: ['=', '!=', '>', '>=', '<', '<='],
    time: ['=', '!=', '>', '>=', '<', '<='],
    date: ['=', '!=', '>', '>=', '<', '<='],
    category: ['=', '!=', 'in', 'not in'],
    boolean: ['=']
  };

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    (<any>window).queryBuilder = this;
  }

  ngOnChanges(_changes: SimpleChanges) {
    const config = this.config;
    const type = typeof config;

    if (type === 'object') {
      this.fields = Object.keys(config.fields).map((value) => {
        const field = config.fields[value];
        field.value = field.value || value;
        return field;
      });
      if (config.entities) {
        this.entities = Object.keys(config.entities).map((value) => {
          const entity = config.entities[value];
          entity.value = entity.value || value;
          return entity;
        });
      } else {
        this.entities = null;
      }
      this._operatorsCache = {};
    } else {
      throw new Error(`Expected 'config' must be a valid object, got ${type} instead.`);
    }
  }

  validate(_control: AbstractControl): ValidationErrors | null {
    const errors: { [key: string]: any } = {};
    const ruleErrorStore = [];
    let hasErrors = false;

    if (!this.config.allowEmptyRulesets && this._emptyRuleInRuleset(this.data)) {
      errors.empty = 'Empty rulesets are not allowed.';
      hasErrors = true;
    }

    this._validateRulesInRuleset(this.data, ruleErrorStore);

    if (ruleErrorStore.length) {
      errors.rules = ruleErrorStore;
      hasErrors = true;
    }
    return hasErrors ? errors : null;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = () => fn(this.data);
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = () => fn(this.data);
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.changeDetectorRef.detectChanges();
  }

  getDisabledState = (): boolean => this.disabled;

  getOperators(field: string): string[] {
    if (this._operatorsCache[field]) return this._operatorsCache[field];

    let operators = this._defaultEmptyList;
    const fieldObject = this.config.fields[field];

    if (this.config.getOperators) return this.config.getOperators(field, fieldObject);

    const type = fieldObject.type;

    if (fieldObject && fieldObject.operators) {
      operators = fieldObject.operators;
    } else if (type) {
      operators = (this.operatorMap && this.operatorMap[type]) || this.defaultOperatorMap[type] || this._defaultEmptyList;
      if (operators.length === 0) {
        console.warn(
          `No operators found for field '${field}' with type ${fieldObject.type}. ` +
            `Please define an 'operators' property on the field or use the 'operatorMap' binding to fix this.`
        );
      }
      if (fieldObject.nullable) operators = operators.concat(['is null', 'is not null']);
    } else {
      console.warn(`No 'type' property found on field: '${field}'`);
    }

    this._operatorsCache[field] = operators;
    return operators;
  }

  getFields = (entity: string): Field[] =>
    this.entities && entity ? this.fields.filter((field) => field && field.entity === entity) : this.fields;

  getInputType(field: string, operator: string): string {
    if (this.config.getInputType) return this.config.getInputType(field, operator);

    if (!this.config.fields[field])
      throw new Error(`No configuration for field '${field}' could be found! Please add it to config.fields.`);

    const type = this.config.fields[field].type;
    switch (operator) {
      case 'is null':
      case 'is not null':
        return null;
      case 'in':
      case 'not in':
        return type === 'category' || type === 'boolean' ? 'multiselect' : type;
      default:
        return type;
    }
  }

  getOptions = (field: string): Option[] =>
    this.config.getOptions ? this.config.getOptions(field) : this.config.fields[field].options || this._defaultEmptyList;

  getDefaultField(entity: Entity): Field {
    if (!entity) {
      return null;
    } else if (entity.defaultField !== undefined) {
      return this.getDefaultValue(entity.defaultField);
    } else {
      const entityFields = this.fields.filter((field) => field && field.entity === entity.value);
      if (entityFields && entityFields.length) {
        return entityFields[0];
      } else {
        console.warn(
          `No fields found for entity '${entity.name}'. ` +
            `A 'defaultOperator' is also not specified on the field config. Operator value will default to null.`
        );
        return null;
      }
    }
  }

  getDefaultOperator(field: Field): string {
    if (field && field.defaultOperator !== undefined) {
      return this.getDefaultValue(field.defaultOperator);
    } else {
      const operators = this.getOperators(field.value);
      if (operators && operators.length) {
        return operators[0];
      } else {
        console.warn(
          `No operators found for field '${field.value}'. ` +
            `A 'defaultOperator' is also not specified on the field config. Operator value will default to null.`
        );
        return null;
      }
    }
  }

  addRule(parent?: RuleSet): void {
    if (this.disabled) return;

    parent = parent || this.data;
    if (this.config.addRule) {
      this.config.addRule(parent);
    } else {
      const field = this.fields[0];
      parent.rules = parent.rules.concat([
        {
          field: field.value,
          operator: this.getDefaultOperator(field),
          value: this.getDefaultValue(field.defaultValue),
          entity: field.entity
        }
      ]);
    }

    this._handleTouched();
    this._handleDataChange();
  }

  removeRule(rule: Rule, parent?: RuleSet): void {
    if (this.disabled) return;

    parent = parent || this.data;
    if (this.config.removeRule) this.config.removeRule(rule, parent);
    else parent.rules = parent.rules.filter((r) => r !== rule);

    this._inputContextCache.delete(rule);
    this._operatorContextCache.delete(rule);
    this._fieldContextCache.delete(rule);
    this._entityContextCache.delete(rule);
    this._removeButtonContextCache.delete(rule);

    this._handleTouched();
    this._handleDataChange();
  }

  addRuleSet(parent?: RuleSet): void {
    if (this.disabled) return;

    parent = parent || this.data;
    if (this.config.addRuleSet) this.config.addRuleSet(parent);
    else parent.rules = parent.rules.concat([{ condition: 'and', rules: [] }]);

    this._handleTouched();
    this._handleDataChange();
  }

  removeRuleSet(ruleset?: RuleSet, parent?: RuleSet): void {
    if (this.disabled) return;

    ruleset = ruleset || this.data;
    parent = parent || this.parentValue;
    if (this.config.removeRuleSet) this.config.removeRuleSet(ruleset, parent);
    else parent.rules = parent.rules.filter((r) => r !== ruleset);

    this._handleTouched();
    this._handleDataChange();
  }

  transitionEnd(): void {
    this.treeContainer.nativeElement.style.maxHeight = null;
  }

  toggleCollapse(): void {
    this.computedTreeContainerHeight();
    setTimeout(() => {
      this.data.collapsed = !this.data.collapsed;
    }, 100);
  }

  computedTreeContainerHeight(): void {
    const nativeElement: HTMLElement = this.treeContainer.nativeElement;
    if (nativeElement && nativeElement.firstElementChild) {
      nativeElement.style.maxHeight = nativeElement.firstElementChild.clientHeight + 8 + 'px';
    }
  }

  changeCondition(value: string): void {
    if (this.disabled) return;

    this.data.condition = value;
    this._handleTouched();
    this._handleDataChange();
  }

  changeOperator(rule: Rule): void {
    if (this.disabled) return;

    if (this.config.coerceValueForOperator) rule.value = this.config.coerceValueForOperator(rule.operator, rule.value, rule);
    else rule.value = this.coerceValueForOperator(rule.operator, rule.value, rule);

    this._handleTouched();
    this._handleDataChange();
  }

  coerceValueForOperator(operator: string, value: any, rule: Rule): any {
    const inputType: string = this.getInputType(rule.field, operator);
    if (inputType === 'multiselect' && !Array.isArray(value)) return [value];

    return value;
  }

  changeInput(): void {
    if (this.disabled) return;

    this._handleTouched();
    this._handleDataChange();
  }

  changeField(fieldValue: string, rule: Rule): void {
    if (this.disabled) return;

    const inputContext = this.getInputContext(rule);
    const currentField = inputContext && inputContext.field;

    const nextField: Field = this.config.fields[fieldValue];

    const nextValue = this._calculateFieldChangeValue(currentField, nextField, rule.value);

    if (nextValue !== undefined) rule.value = nextValue;
    else delete rule.value;

    rule.operator = this.getDefaultOperator(nextField);

    this._inputContextCache.delete(rule);
    this._operatorContextCache.delete(rule);
    this._fieldContextCache.delete(rule);
    this._entityContextCache.delete(rule);
    this.getInputContext(rule);
    this.getFieldContext(rule);
    this.getOperatorContext(rule);
    this.getEntityContext(rule);

    this._handleTouched();
    this._handleDataChange();
  }

  changeEntity(entityValue: string, rule: Rule, index: number, data: RuleSet): void {
    if (this.disabled) return;

    let i = index;
    let rs = data;
    const entity: Entity = this.entities.find((e) => e.value === entityValue);
    const defaultField: Field = this.getDefaultField(entity);
    if (!rs) {
      rs = this.data;
      i = rs.rules.findIndex((x) => x === rule);
    }
    rule.field = defaultField.value;
    rs.rules[i] = rule;
    if (defaultField) {
      this.changeField(defaultField.value, rule);
    } else {
      this._handleTouched();
      this._handleDataChange();
    }
  }

  getDefaultValue = <T>(defaultValue: T | (() => T)) => (typeof defaultValue === 'function' ? (defaultValue as () => T)() : defaultValue);

  getFieldContext(rule: Rule): FieldContext {
    if (!this._fieldContextCache.has(rule)) {
      this._fieldContextCache.set(rule, {
        onChange: this.changeField.bind(this),
        getFields: this.getFields.bind(this),
        getDisabledState: this.getDisabledState,
        fields: this.fields,
        $implicit: rule
      });
    }
    return this._fieldContextCache.get(rule);
  }

  getEntityContext(rule: Rule): EntityContext {
    if (!this._entityContextCache.has(rule)) {
      this._entityContextCache.set(rule, {
        onChange: this.changeEntity.bind(this),
        getDisabledState: this.getDisabledState,
        entities: this.entities,
        $implicit: rule
      });
    }
    return this._entityContextCache.get(rule);
  }

  getOperatorContext(rule: Rule): OperatorContext {
    if (!this._operatorContextCache.has(rule)) {
      this._operatorContextCache.set(rule, {
        onChange: this.changeOperator.bind(this),
        getDisabledState: this.getDisabledState,
        operators: this.getOperators(rule.field),
        $implicit: rule
      });
    }
    return this._operatorContextCache.get(rule);
  }

  getInputContext(rule: Rule): InputContext {
    if (!this._inputContextCache.has(rule)) {
      this._inputContextCache.set(rule, {
        onChange: this.changeInput.bind(this),
        getDisabledState: this.getDisabledState,
        options: this.getOptions(rule.field),
        field: this.config.fields[rule.field],
        $implicit: rule
      });
    }
    return this._inputContextCache.get(rule);
  }

  private _calculateFieldChangeValue(currentField: Field, nextField: Field, currentValue: any): any {
    if (this.config.calculateFieldChangeValue !== null && this.config.calculateFieldChangeValue !== undefined)
      return this.config.calculateFieldChangeValue(currentField, nextField, currentValue);

    const canKeepValue = () => {
      if (currentField === null || nextField === null || currentField === undefined || nextField === undefined) return false;
      return currentField.type === nextField.type && this._defaultPersistValueTypes.indexOf(currentField.type) !== -1;
    };

    if (this.persistValueOnFieldChange && canKeepValue()) return currentValue;
    if (nextField && nextField.defaultValue !== undefined) return this.getDefaultValue(nextField.defaultValue);

    return undefined;
  }

  private _emptyRuleInRuleset = (ruleset: RuleSet) =>
    !ruleset ||
    !ruleset.rules ||
    ruleset.rules.length === 0 ||
    ruleset.rules.some((item: RuleSet) => (item.rules ? this._emptyRuleInRuleset(item) : false));

  private _validateRulesInRuleset(ruleset: RuleSet, errorStore: any[]) {
    if (ruleset && ruleset.rules && ruleset.rules.length > 0) {
      ruleset.rules.forEach((item) => {
        if ((item as RuleSet).rules) {
          return this._validateRulesInRuleset(item as RuleSet, errorStore);
        } else if ((item as Rule).field) {
          const field = this.config.fields[(item as Rule).field];
          if (field && field.validator && field.validator.apply) {
            const error = field.validator(item as Rule, ruleset);
            if (error !== null) errorStore.push(error);
          }
        }
      });
    }
  }

  private _handleDataChange(): void {
    this.changeDetectorRef.markForCheck();
    if (this.onChangeCallback) this.onChangeCallback();
    if (this.parentChangeCallback) this.parentChangeCallback();
  }

  private _handleTouched(): void {
    if (this.onTouchedCallback) this.onTouchedCallback();
    if (this.parentTouchedCallback) this.parentTouchedCallback();
  }
}
