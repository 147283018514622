// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  width: 100%;
}

tr {
  cursor: pointer;
}

.copy-button mat-icon {
  font-size: 20px;
  line-height: 24px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/models/definitions/definitions.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,eAAA;EACA,iBAAA;AACF","sourcesContent":["table {\n  width: 100%;\n}\n\ntr {\n  cursor: pointer;\n}\n\n.copy-button mat-icon {\n  font-size: 20px;\n  line-height: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
