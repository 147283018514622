import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { TRACK_POINTS } from './utils';
import { IdentityService } from 'src/app/services/identity.service';
import { Project, ProjectEvent } from 'src/app/models/project';
import { MatMenu } from '@angular/material/menu';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DeploySelectorComponent } from '../../deploy-selector/deploy-selector.component';

@Component({
  selector: 'app-project-board',
  templateUrl: './project-board.component.html',
  styleUrls: ['./project-board.component.scss']
})
export class ProjectBoardComponent implements OnInit, OnDestroy {
  public readonly TRACK_POINTS = TRACK_POINTS;

  @ViewChild('projectsMenu') projectsMenu: MatMenu;
  @ViewChild(DeploySelectorComponent) DeploySelectorComponent;

  @Input()
  parentId: string;
  @Input() public hud: boolean = false;
  @Input() public widget: boolean = false;
  @Input() public eventHost: HTMLElement = undefined;
  @Input() public sizeHost: HTMLElement = undefined;
  @Output() viewExpermentsClick = new EventEmitter<{ parentId: string; projectName: string }>();
  @Output() viewDefinitionClick = new EventEmitter<{ parentId: string; projectName: string; definitionName: string }>();
  @Output() viewBuildClick = new EventEmitter<{ parentId: string; projectName: string; runid: number }>();
  @Output() viewFeatureExplorerClick = new EventEmitter<{ parentId: string; projectName: string }>();
  @Output() viewVaultClick = new EventEmitter<{ parentId: string; projectName: string }>();
  @Output() viewAssetClick = new EventEmitter<{ parentId: string; projectName: string; assetName: string }>();
  @Output() viewUploadClick = new EventEmitter<{ parentId: string }>();

  private _subs: Subscription[] = [];

  public selectedDevice: any = null;
  public cachingFiles = 0;

  public firstFormGroup: FormGroup = this._formBuilder.group({ firstCtrl: [''] });
  public secondFormGroup: FormGroup = this._formBuilder.group({ secondCtrl: [''] });
  public thirdFormGroup: FormGroup = this._formBuilder.group({ thirdCtrl: [''] });
  public fourthFormGroup: FormGroup = this._formBuilder.group({ fourthCtrl: [''] });
  public fifthFormGroup: FormGroup = this._formBuilder.group({ fifthCtrl: [''] });
  public sixthFormGroup: FormGroup = this._formBuilder.group({ sixthCtrl: [''] });

  public projects: Project[] = [];

  public get teamName(): string {
    return this._identity.me.selectedTeamName;
  }

  public get project(): Project | undefined {
    return this._identity.me.selectedProject ?? undefined;
  }

  public get events(): ProjectEvent[] {
    return this._identity.me.selectedProject?.events ?? [];
  }

  constructor(
    private _identity: IdentityService,
    private _formBuilder: FormBuilder
  ) {
    (<any>window).projectBoard = this;
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this._subs.forEach((sub) => sub.unsubscribe);
  }

  onOpenExperiments(input: any) {
    this.viewExpermentsClick.emit({ parentId: this.parentId, projectName: input.projectName });
  }

  onAddToVault() {
    if (this.DeploySelectorComponent) {
      this.DeploySelectorComponent.init();
    }
  }

  openFeatureExplorer() {
    this.viewFeatureExplorerClick.emit({ parentId: this.parentId, projectName: this.project.name });
  }

  onProjectSelect(input: any) {
    this._identity.setProject(input.project);
  }

  onCachingFiles(input: any) {
    this.cachingFiles = input.cachingCount;
  }

  async $clickUpload() {
    this.viewUploadClick.emit({ parentId: this.parentId });
  }

  onDefinitionClick(input: any) {
    this.viewDefinitionClick.emit({ parentId: this.parentId, projectName: input.projectName, definitionName: input.definitionName });
  }

  onBuildDetails(input: any) {
    this.viewBuildClick.emit({ parentId: this.parentId, projectName: input.projectName, runid: input.runid });
  }

  onOpenVault(input: any) {
    this.viewVaultClick.emit({ parentId: this.parentId, projectName: input.projectName });
  }

  onOpenAsset(input: any) {
    this.viewAssetClick.emit({ parentId: this.parentId, projectName: input.projectName, assetName: input.assetName });
  }
}
