// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 210px;
  padding: 3px;
}

.swatches {
  display: flex;
  flex-wrap: wrap;
}

.swatch {
  background-color: currentColor;
  border: 1px solid;
  border-radius: 2px;
  flex: 0 0 15px;
  height: 15px;
  margin: 3px;
  position: relative;
  width: 15px;
  cursor: pointer;
}
.swatch:after {
  border: 2px solid;
  border-color: transparent;
  border-radius: 6px;
  box-sizing: initial;
  content: "";
  height: 100%;
  padding: 3px;
  position: absolute;
  left: -5px;
  top: -5px;
  transition: border-color 150ms ease-in-out;
  width: 100%;
}

.separator {
  height: 1px;
}

input[type=color] {
  -webkit-appearance: none;
          appearance: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 100%;
  padding: 0px 1px;
  margin: -1px 0px;
}
input[type=color]::-webkit-color-swatch {
  border-radius: 2px;
  border: none;
  -webkit-transition: background-color 150ms ease-in-out;
  transition: background-color 150ms ease-in-out;
}
input[type=color]::-moz-color-swatch {
  border-radius: 2px;
  border: none;
  -moz-transition: background-color 150ms ease-in-out;
  transition: background-color 150ms ease-in-out;
}`, "",{"version":3,"sources":["webpack://./src/app/components/palette-input/palette-input.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;EACA,YAAA;EACA,YAAA;AACF;;AAEA;EACE,aAAA;EACA,eAAA;AACF;;AAEA;EACE,8BAAA;EACA,iBAAA;EACA,kBAAA;EACA,cAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,WAAA;EACA,eAAA;AACF;AACE;EACE,iBAAA;EACA,yBAAA;EACA,kBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;EACA,UAAA;EACA,SAAA;EACA,0CAAA;EACA,WAAA;AACJ;;AAGA;EACE,WAAA;AAAF;;AAGA;EACE,wBAAA;UAAA,gBAAA;EACA,6BAAA;EACA,YAAA;EACA,eAAA;EACA,WAAA;EACA,gBAAA;EACA,gBAAA;AAAF;AAEE;EACE,kBAAA;EACA,YAAA;EACA,sDAAA;EAAA,8CAAA;AAAJ;AAGE;EACE,kBAAA;EACA,YAAA;EACA,mDAAA;EAAA,8CAAA;AADJ","sourcesContent":[":host {\n  display: flex;\n  flex-direction: column;\n  gap: 6px;\n  width: 210px;\n  padding: 3px;\n}\n\n.swatches {\n  display: flex;\n  flex-wrap: wrap;\n}\n\n.swatch {\n  background-color: currentColor;\n  border: 1px solid;\n  border-radius: 2px;\n  flex: 0 0 15px;\n  height: 15px;\n  margin: 3px;\n  position: relative;\n  width: 15px;\n  cursor: pointer;\n\n  &:after {\n    border: 2px solid;\n    border-color: transparent;\n    border-radius: 6px;\n    box-sizing: initial;\n    content: '';\n    height: 100%;\n    padding: 3px;\n    position: absolute;\n    left: -5px;\n    top: -5px;\n    transition: border-color 150ms ease-in-out;\n    width: 100%;\n  }\n}\n\n.separator {\n  height: 1px;\n}\n\ninput[type='color'] {\n  appearance: none;\n  background-color: transparent;\n  border: none;\n  cursor: pointer;\n  width: 100%;\n  padding: 0px 1px;\n  margin: -1px 0px;\n\n  &::-webkit-color-swatch {\n    border-radius: 2px;\n    border: none;\n    transition: background-color 150ms ease-in-out;\n  }\n\n  &::-moz-color-swatch {\n    border-radius: 2px;\n    border: none;\n    transition: background-color 150ms ease-in-out;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
