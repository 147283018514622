import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { ConfigService } from 'src/app/services/config.service';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { AuthGuard } from 'src/app/services/auth.guard';
import { HostsService } from 'src/app/services/hosts.service';
import { IdentityService } from 'src/app/services/identity.service';
import { SocketService } from 'src/app/services/socket.service';
import { TrustService } from 'src/app/services/trust.service';
import { Host } from 'src/app/models/host';
import { DirectoryComponent } from '../data/directory/directory.component';
import { CacheFileService } from 'src/app/services/cache-file.service';
import { DatasetsService } from 'src/app/services/datasets.service';

@Component({
  selector: 'app-file-upload-dialog',
  templateUrl: './file-upload-dialog.component.html',
  styleUrls: ['./file-upload-dialog.component.scss']
})
export class FileUploadDialogComponent implements OnInit {
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.onCancel();
  }

  @ViewChild('stepper') private stepper: MatStepper;
  @ViewChild(DirectoryComponent) directoryComponent: DirectoryComponent;

  constructor(
    private http: HttpClient,
    public dialogRef: MatDialogRef<FileUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private socketService: SocketService,
    private identity: IdentityService,
    private hostsService: HostsService,
    private authGuard: AuthGuard,
    private datasetsService: DatasetsService,
    private cacheFileService: CacheFileService
  ) {}

  public selectedFiles = [];
  public destination = null;
  public uploadProgress: any[] = [];
  public uploadComplete: boolean = false;
  public uploadInProgress: boolean = false;
  public dialogState = 'MAX';

  ngOnInit(): void {}

  resize() {
    this.dialogState = this.dialogState === 'MAX' ? 'MIN' : 'MAX';
    if (this.dialogState === 'MIN') this.dialogRef.updateSize(null, '150px');
    else this.dialogRef.updateSize(null, 'auto');
  }

  onSelectFiles(event) {
    this.selectedFiles = event.target.files;
    if (this.selectedFiles.length > 0) this.stepper.next();
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSelectDirectory(event) {
    this.destination = event;
  }

  public async dropped(files: NgxFileDropEntry[]) {
    for (const droppedFile of files) {
      if (!droppedFile.fileEntry.isFile) continue;

      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      const file = await this.getFile(fileEntry);
      this.selectedFiles.push(file);
    }

    if (this.selectedFiles.length > 0) this.stepper.next();
  }

  public fileOver(event) {}

  public fileLeave(event) {}

  async getFile(fileEntry) {
    try {
      return new Promise((resolve, reject) => fileEntry.file(resolve, reject));
    } catch (err) {
      console.log(err);
    }
  }

  onUpload() {
    this.uploadInProgress = true;
    this.stepper.next();

    const hostDest = this.destination.split(':');
    const host = this.directoryComponent.getSelectedHost();
    for (let i = 0; i < this.selectedFiles.length; i++) {
      this.upload(i, this.selectedFiles[i], hostDest[1], hostDest[0], host.isLocal);
    }
  }

  async upload(idx: number, file: File, destination: string, hostName: string, isLocal: boolean) {
    const host: Host = await this.hostsService.getAsync(this.data.teamName, hostName);
    this.uploadProgress[idx] = {
      value: 0,
      fileName: file.name,
      destination: `${destination}/${file.name}`,
      msg: `Uploading ${file.name}`,
      sub: null,
      status: null,
      socketSub: null
    };

    const formData = new FormData();
    formData.append('data', file);

    this.uploadProgress[idx].sub = this.http
      .post(`${ConfigService.apiUrl}/upload/${this.data.teamName}/${isLocal}/${encodeURI(destination.replace(/\//g, '\\'))}`, formData, {
        reportProgress: true,
        observe: 'events'
      })
      .subscribe({
        next: (event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
            this.uploadProgress[idx].value = Math.round((100 * event.loaded) / event.total);
            this.uploadProgress[idx].msg = `${this.uploadProgress[idx].fileName} uploading...`;
          } else if (event instanceof HttpResponse) {
            this.uploadProgress[idx].sub.unsubscribe();
            if (!isLocal) {
              this.uploadProgress[idx].status = 'SYNCING';
              this.uploadProgress[idx].msg = `${this.uploadProgress[idx].fileName} moving file to ${hostName}...`;
              this.uploadProgress[idx].value = 0;
              this.sync(host, event.body.uploadPath, destination, idx);
            } else {
              this.uploadProgress[idx].status = 'COMPLETE';
              this.uploadProgress[idx].msg = `${this.uploadProgress[idx].fileName}`;
              if (this.data.dataset) this.addPathToDataset(this.uploadProgress[idx].destination);
              this.cacheFileService.createCacheFileAsync(this.data.teamName, this.uploadProgress[idx].destination);

              if (
                this.uploadProgress.length === this.uploadProgress.filter((x) => x.status === 'COMPLETE' || x.status === 'ERROR').length
              ) {
                this.destination = null;
                this.uploadComplete = true;
              }
            }
          }
        },
        error: (err: any) => {
          this.uploadProgress[idx].sub.unsubscribe();
          this.uploadProgress[idx].value = 0;
          this.uploadProgress[idx].msg = `Could not upload the file: ${this.uploadProgress[idx].fileName}`;
          this.uploadProgress[idx].status = 'ERROR';

          if (this.uploadProgress.length === this.uploadProgress.filter((x) => x.status === 'COMPLETE' || x.status === 'ERROR').length) {
            this.destination = null;
            this.uploadComplete = true;
          }
        }
      });
  }

  async sync(host: Host, source: string, destination: string, idx: number) {
    const token = localStorage.getItem('id_token');
    if (!token) {
      this.authGuard.logout();
      return;
    }

    const socketConfig = { username: this.identity.me.username, teamName: this.data.teamName, token, idx, source, destination };
    this.subscribeToSocketEvents(socketConfig, host);
  }

  subscribeToSocketEvents(config: any, host: Host) {
    this.socketService.scp(config);
    this.uploadProgress[config.idx].socketSub = this.socketService.scpEvents.subscribe((msg: any) => {
      this.uploadProgress[msg.idx].status = msg.status;
      this.uploadProgress[msg.idx].value = msg.percent;

      if (this.uploadProgress[msg.idx].status === 'COMPLETE' || this.uploadProgress[config.idx].status === 'ERROR') {
        this.uploadProgress[msg.idx].socketSub.unsubscribe();
        this.uploadProgress[msg.idx].msg = `${this.uploadProgress[config.idx].fileName}`;

        if (this.uploadProgress[msg.idx].status === 'COMPLETE') {
          if (this.data.dataset) this.addPathToDataset(this.uploadProgress[config.idx].destination);
          this.cacheFileService.createCacheFileAsync(this.data.teamName, this.uploadProgress[config.idx].destination);
        }
      }

      if (this.uploadProgress.length === this.uploadProgress.filter((x) => x.status === 'COMPLETE' || x.status === 'ERROR').length) {
        this.destination = null;
        this.uploadComplete = true;
      }
    });
  }

  public async addPathToDataset(path: string) {
    await this.datasetsService.update(this.data.teamName, this.data.dataset, path, true);
    this.datasetsService.pathAdded$.next({dataset: this.data.dataset, path});
  }
}
