import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable, map, startWith } from 'rxjs';
import { Project } from 'src/app/models/project';
import { Feature, FeatureService } from 'src/app/services/feature.service';
import { ProjectsService } from 'src/app/services/projects.service';

@Component({
  selector: 'app-feature-selector',
  templateUrl: './feature-selector.component.html',
  styleUrls: ['./feature-selector.component.scss']
})
export class FeatureSelectorComponent implements OnInit, OnChanges {
  @ViewChild('featureInput') featureInput: ElementRef<HTMLInputElement>;

  @Input() teamName: string;
  @Input() project: Project;
  @Output() onProjectUpdate = new EventEmitter<{ project: Project }>();

  public selectedFeatures: string[] = [];
  public features: string[] = [];
  public filteredFeatures: Observable<string[]>;

  public formGroup = new FormGroup({});
  public featureCtrl = new FormControl('');

  constructor(
    private _featureService: FeatureService,
    private _projectsService: ProjectsService
  ) {}

  async ngOnInit(): Promise<void> {
    this.init();
  }

  async ngOnChanges(_changes: SimpleChanges): Promise<void> {
    if (_changes.project.firstChange || _changes.project.currentValue?.name === _changes.project.previousValue?.name) return;

    await this.init();
  }

  async init() {
    this.features = (await this._featureService.getAllAsync(this.teamName)).map((x) => x.name);
    this.selectedFeatures = [...this.project?.feature];

    this.filteredFeatures = this.featureCtrl.valueChanges.pipe(
      startWith(null),
      map((feature: string | null) => {
        const features = this.features.filter((x) => !this.selectedFeatures.includes(x));

        if (!feature || feature.length === 0) return features;

        return this._filter(feature, features);
      })
    );
  }

  private _filter(value: string, items: string[]): string[] {
    if (!value) return null;

    const filterValue = value.toLowerCase();
    return items.filter((x) => x.toLowerCase().includes(filterValue));
  }

  async addFeature(event: MatAutocompleteSelectedEvent): Promise<void> {
    let featureName: string = null;
    featureName = event.option.viewValue;

    if (featureName) {
      this.selectedFeatures.push(featureName);
      this.update();
    }

    this.featureInput.nativeElement.value = '';
    this.featureCtrl.setValue(null);
  }

  drop(event: CdkDragDrop<Feature[]>) {
    moveItemInArray(this.selectedFeatures, event.previousIndex, event.currentIndex);
    this.update();
  }

  deleteFeature(feature): void {
    this.selectedFeatures = this.selectedFeatures.filter((x) => x !== feature);
    this.update();
    this.featureCtrl.setValue(null);
  }

  update() {
    this.project.feature = [...this.selectedFeatures];
    this.onProjectUpdate.emit({ project: this.project });
  }
}
