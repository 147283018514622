// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
  height: calc(100% - 2 * var(--content-margin));
}

table {
  width: 100%;
}

.compact {
  height: 38px !important;
}

.compact mat-cell,
.compact mat-cell-header {
  font-size: 10pt;
}

.form-field {
  width: 360px;
}

.notes-form-field {
  width: 100%;
}

.divider {
  padding-top: 20px;
}

ngx-monaco-editor {
  object-fit: fill;
  height: 100% !important;
  margin: 0 var(--content-margin);
}

.code-wrapper {
  height: calc(100vh - 450px);
}

.error {
  background-color: white;
  color: red;
}

#button-row {
  padding: 0 20px;
}

form {
  margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/models/vault-detail/vault-detail.component.scss"],"names":[],"mappings":"AAAA;EACE,8CAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,uBAAA;AACF;;AAEA;;EAEE,eAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,gBAAA;EACA,uBAAA;EACA,+BAAA;AACF;;AAEA;EACE,2BAAA;AACF;;AAEA;EACE,uBAAA;EACA,UAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,gBAAA;AACF","sourcesContent":[".content {\n  height: calc(100% - 2 * var(--content-margin));\n}\n\ntable {\n  width: 100%;\n}\n\n.compact {\n  height: 38px !important;\n}\n\n.compact mat-cell,\n.compact mat-cell-header {\n  font-size: 10pt;\n}\n\n.form-field {\n  width: 360px;\n}\n\n.notes-form-field {\n  width: 100%;\n}\n\n.divider {\n  padding-top: 20px;\n}\n\nngx-monaco-editor {\n  object-fit: fill;\n  height: 100% !important;\n  margin: 0 var(--content-margin);\n}\n\n.code-wrapper {\n  height: calc(100vh - 450px);\n}\n\n.error {\n  background-color: white;\n  color: red;\n}\n\n#button-row {\n  padding: 0 20px;\n}\n\nform {\n  margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
