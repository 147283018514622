import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PredictAnalysisService } from 'src/app/services/predict-analysis.service';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { RawService } from 'src/app/services/raw.service';
import { VaultService } from 'src/app/services/vault.service';
import { VaultItem } from 'src/app/models/vault-item';
import { MatSelect } from '@angular/material/select';
import { InsightsLineChartComponent } from 'src/app/components/insights-line-chart/insights-line-chart.component';
import { Subject, catchError, debounceTime, distinctUntilChanged, of } from 'rxjs';

@Component({
  selector: 'app-analysis',
  templateUrl: './analysis.component.html',
  styleUrls: ['./analysis.component.scss']
})
export class AnalysisComponent implements OnInit, OnDestroy {
  @Input() id: string;

  @ViewChild('featureSelector') featureSelector: MatSelect;
  @ViewChild('picker') picker: MatDatepicker<Date>;
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild('inputsPaginator') inputsPaginator: MatPaginator;
  @ViewChild('rawPaginator') rawPaginator: MatPaginator;
  @ViewChild('insightLineChart') lineChart: InsightsLineChartComponent;

  public inputUpdate = new Subject<void>();

  selectedValue: any = null;
  selectedElement = null;
  selectedColumn = null;

  selectedType: string = 'engine';
  selectedEngine: any = null;
  selectedModel: string = null;
  availableEngines: any[] = [];
  selectedDate: Date = null;
  selectedAdhocModel: string = null;
  selectedFilename: string = null;
  teamName: string;
  availableFiles: File[] = [];
  allModels: VaultItem[] = [];
  adhocDates: string[] = [];
  rawData: any[] = null;

  demoTableColumns: string[] | undefined;
  rawTableColumns: string[] | undefined;
  demoTableData: any | undefined;
  demoTableDS = new MatTableDataSource<any>([]);
  rawTableDS = new MatTableDataSource<any>([]);

  pageSize = 5;
  view: string = 'TABLE';
  xValues = [];
  yValues = [];

  csXValues = [];
  openValues = [];
  highValues = [];
  lowValues = [];
  closeValues = [];
  volumeValues = [];

  predictions: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private predictAnalysis: PredictAnalysisService,
    private rawService: RawService,
    private vaultService: VaultService
  ) {
    this.inputUpdate.subscribe(async () => {
      if (!(this.selectedEngine || this.selectedAdhocModel) || !this.selectedDate || !(this.selectedModel || this.selectedFilename)) {
        return;
      }

      this.retrieve();
    });
  }

  myFilter = (d: Date | null): boolean => {
    if (this.selectedType === 'engine') {
      if (!this.selectedEngine || !this.selectedEngine.dates) return true;
      return this.selectedEngine.dates.find((x) => x == d.toISOString().split('T')[0]);
    } else if (this.selectedType === 'adhoc') {
      if (!this.adhocDates) return true;
      return this.adhocDates.find((x) => x == d.toISOString().split('T')[0]) != null;
    } else {
      return true;
    }
  };

  ngOnInit(): void {
    (<any>window).analysis = this;
    this.activatedRoute.params.subscribe((params) => {
      this.load(params.team);
    });
  }

  ngOnDestroy(): void {
    this.inputUpdate.unsubscribe();
  }

  selectInput(element, column) {
    this.selectedElement = element;
    this.selectedColumn = column;
    this.selectedValue = element[column];
  }

  changes = [];
  selectedValueChanged(event) {
    const value = parseFloat(event.srcElement.value);

    const row = this.selectedElement['#'] - 1;

    this.demoTableData[row][this.selectedColumn] = value;
    const columnIndex = this.demoTableColumns.indexOf(this.selectedColumn) - 1; // to compensate for the '#' column we add
    this.inputData.features[this.selectedFeature][this.selectedElement['#'] - 1][columnIndex] = value;

    this.updateData();
    const change = {
      feature: this.selectedFeature,
      row: row,
      col: columnIndex,
      value: value
    };
    this.changes.push(change);
    this.makePrediction();
  }

  makePrediction() {
    const timestamp = this.selectedPrediction[0];
    this.predictionLoading = true;
    const sub = this.predictAnalysis
      .makePrediction(this.teamName, this.selectedEngine.name, this.selectedModel, timestamp, this.changes)
      .subscribe(async (response: any) => {
        sub.unsubscribe();
        this.selectedPrediction[1] = response;
        this.predictionLoading = false;
      });
  }

  changeAdhocFile(path) {
    const sub = this.predictAnalysis.getAdhocDays(this.teamName, path).subscribe(async (response: any) => {
      sub.unsubscribe();
      this.adhocDates = response;
    });
  }

  selectDate(date) {
    this.selectedDate = date.value;
    this.inputUpdate.next();
  }

  async load(teamName: string) {
    this.teamName = teamName;
    const sub = this.predictAnalysis.load(teamName).subscribe(async (response: any) => {
      sub.unsubscribe();
      this.availableEngines = response;
    });

    const sub2 = this.rawService.get(teamName).subscribe((response: any[]) => {
      sub2.unsubscribe();
      this.availableFiles = response.filter((x) => x.folder.endsWith('adhoc'));
    });

    const sub3 = this.vaultService.load(this.teamName).subscribe((response) => {
      sub3.unsubscribe();
      this.allModels = response;
    });
  }

  inputsLoading = false;
  predictionLoading = false;
  selectedPrediction = null;
  inputData: any = null;
  features: string[] = null;

  rawLoading = false;
  requestedTimestamp: string = null;

  loadPrediction(index) {
    const prediction = this.predictions[index];

    this.changes = [];
    this.features = null;
    this.demoTableDS = new MatTableDataSource<any>([]);

    // this.lineChart.setIndexAnnotation(new Date(prediction[0]).getTime() / 1000);

    this.selectedPrediction = prediction;
    this.requestedTimestamp = new Date(prediction[0]).toLocaleString();

    var func = null;
    if (this.selectedType == 'engine') {
      func = this.predictAnalysis.getPredictionInputs(this.teamName, this.selectedEngine.name, prediction[0]);
    } else if (this.selectedType == 'adhoc') {
      func = this.predictAnalysis.getAdhocPredictionInputs(this.teamName, this.selectedAdhocModel, prediction[0], this.selectedFilename);
    } else {
      return;
    }

    this.inputsLoading = true;
    const sub = func.pipe(catchError((err) => of([]))).subscribe(async (response: any) => {
      sub.unsubscribe();
      this.inputData = response;
      this.features = this.inputData && this.inputData.features ? Object.keys(this.inputData.features) : [];
      this.inputsLoading = false;
      if (this.features?.length > 0) {
        this.selectFeature(this.features[0]);
      }
    });
  }

  meta = null;
  sample_timestamp: string = null;
  selectedFeature = null;
  selectFeature(feature) {
    this.selectedFeature = feature;
    this.sample_timestamp = new Date(this.inputData.transactionTime).toLocaleString();
    this.meta = this.inputData.meta[feature];
    let demoResults = this.inputData.features[feature];

    if (demoResults === undefined) {
      // Clear any previous demo results
      Object.assign(this, { demoResults: undefined, demoTableColumns: undefined, demoTableData: undefined });
    } else if (demoResults.length === 0) {
      // Mark the results as empty, which displays a message
      Object.assign(this, { demoResults: 'empty', demoTableColumns: undefined, demoTableData: undefined });
    } else {
      const rowToObject = (row, rowNum = 0) => ({
        '#': rowNum + 1,
        ...Object.fromEntries(row.map((e, i) => [this.demoTableColumns[i + 1], e]))
      });

      // Resolve 2D data [[...],] vs 1D data [...]
      const is2D = demoResults[0] instanceof Array;
      const numberResultColumns = is2D ? demoResults[0].length : demoResults.length;

      if (numberResultColumns !== this.meta?.returns.length) {
        // Automatically number the columns in case the meta's returns field isn't applicable
        this.demoTableColumns = ['#', ...new Array(numberResultColumns).fill(0).map((_e, i) => (i + 1).toString())];
      } else {
        this.demoTableColumns = ['#', ...this.meta.returns];
      }

      // 2D provides multiple rows, whereas 1D data is a single row.
      if (is2D) {
        this.demoTableData = demoResults.map(rowToObject);
      } else {
        this.demoTableData = [rowToObject(demoResults)];
      }

      if (!['open', 'high', 'low', 'close'].every((x) => this.meta?.returns?.includes(x))) {
        this.view = 'TABLE';
      }

      this.updateData();

      this.demoTableDS.paginator = this.inputsPaginator;
      this.featureSelector.value = feature;
    }
  }

  updateData() {
    const toPreso = (x) => x;
    this.csXValues = this.demoTableData.map((item) => toPreso(item['#']));
    this.openValues = this.demoTableData.map((item) => toPreso(item['open']));
    this.highValues = this.demoTableData.map((item) => toPreso(item['high']));
    this.lowValues = this.demoTableData.map((item) => toPreso(item['low']));
    this.closeValues = this.demoTableData.map((item) => toPreso(item['close']));
    this.volumeValues = this.demoTableData.map((item) => toPreso(item['volume']));

    this.demoTableDS.data = this.demoTableData;
  }

  async retrieve() {
    const _this = this;
    const date = this.selectedDate.toISOString().split('T')[0];

    var getPredictionsFunction = null;
    if (this.selectedType == 'engine') {
      getPredictionsFunction = this.predictAnalysis.getPredictions(this.teamName, this.selectedModel, date);
    } else if (this.selectedType == 'adhoc') {
      getPredictionsFunction = this.predictAnalysis.getAdhocPredictions(
        this.teamName,
        this.selectedAdhocModel,
        date,
        this.selectedFilename
      );
    } else {
      console.error('Error: Unrecognized Type', this.selectedType);
      return;
    }

    const sub = getPredictionsFunction.subscribe(async (response: any) => {
      sub.unsubscribe();

      response.raw.forEach((element) => {
        element.t = new Date(element.t);
      });

      this.rawData = response.raw.map((x) => {
        return {
          t: new Date(x[0]),
          o: x[1],
          h: x[2],
          l: x[3],
          c: x[4],
          v: x[5]
        };
      });

      if (this.rawData.length > 0) {
        this.rawTableColumns = Object.keys(this.rawData[0]);
        this.rawTableDS.data = this.rawData;
        this.rawTableDS.paginator = this.rawPaginator;
      }

      this.predictions = response.predictions;
      this.xValues = this.predictions.map((x) => new Date(x[0]).getTime() / 1000);
      this.yValues = this.predictions.map((x) => x[1]);
    });
  }

  changeView(newView: string) {
    this.view = newView;
  }
}
