import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators, AbstractControl, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AvailableFeature, Feature, FeatureService } from 'src/app/services/feature.service';
import { DynamicFormComponent } from '../../dynamic-form/dynamic-form.component';
import { Meta } from 'src/app/models/meta';
import { MatSelectChange } from '@angular/material/select';
import { MatExpansionPanel } from '@angular/material/expansion';
import { RawService } from 'src/app/services/raw.service';

@Component({
  selector: 'app-new-feature',
  templateUrl: './new-feature.component.html',
  styleUrls: ['./new-feature.component.scss']
})
export class NewFeatureComponent implements OnInit {
  @ViewChild('dynamicForm') dynamicForm?: DynamicFormComponent;
  @ViewChild('metaPanel') metaPanel: MatExpansionPanel;
  @ViewChild('parameterPanel') parameterPanel: MatExpansionPanel;
  @ViewChild('demoPanel') demoPanel: MatExpansionPanel;

  private _loading = {
    features: false,
    meta: false,
    indices: false,
    demo: false
  };

  public get loading() {
    return Object.values(this._loading).reduce((anyLoading, isLoading) => anyLoading || isLoading, false);
  }

  public get demoPanelDisabled() {
    return this.availableRDS === undefined || this.meta === undefined || (this.dynamicForm?.form?.invalid ?? true);
  }

  feature: Feature = { name: '', path: '' };
  features: Feature[] = [];
  teamName: string;
  meta: Meta;
  hasMeta: boolean = false;

  availableFeatures: AvailableFeature[] | undefined;
  availableRDS: any[] | undefined;
  selectedRDS: any | undefined;
  demoResults: number[] | number[][] | 'empty' | 'error' | undefined;
  demoTableColumns: string[] | undefined;
  demoTableData: any | undefined;

  metaForm = this.formBuilder.group({
    feature: new FormControl('', Validators.required),
    name: new FormControl('', [
      Validators.required,
      ({ value }: AbstractControl<string>) =>
        this.features.map((x) => x.name.toLowerCase()).includes(value.toLowerCase()) ? { usedName: { value } } : null
    ])
  });
  demoForm = this.formBuilder.group({
    index: new FormControl(0, Validators.required),
    rds: new FormControl('', Validators.required)
  });

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private featureService: FeatureService,
    private rawService: RawService
  ) {
    (<any>window).newFeature = this;
  }

  ngOnInit() {
    this.teamName = this.activatedRoute.snapshot.params.team;
    this.loadFeatures()
      .then(() => this.metaPanel.open())
      .then(this.loadClone.bind(this));
    this.loadRDS();
  }

  async loadClone(): Promise<void> {
    // Check feature
    const { featureName } = this.activatedRoute.snapshot.queryParams;
    if (!featureName) return;
    const copySource = this.features.find(({ name }) => name === featureName);
    if (!copySource) return;

    // Apply feature
    this.metaForm.setValue({
      feature: copySource.path,
      name: featureName
    });
    this.metaForm.markAllAsTouched();
    Object.assign(this.feature, copySource);
    await this.loadMeta(true);
  }

  async loadFeatures(): Promise<void> {
    this._loading.features = true;

    await Promise.all([
      (async () => (this.availableFeatures = await this.featureService.getAvailableAsync(this.teamName)))(),
      (async () => (this.features = await this.featureService.getAllAsync(this.teamName)))()
    ]);

    this._loading.features = false;
  }

  async loadMeta(cloningFeature: boolean = false): Promise<void> {
    this._loading.meta = true;
    this.hasMeta = false;
    this.meta = undefined;

    const meta = await this.featureService.reflectAsync(this.teamName, this.feature.path);
    if (!cloningFeature) {
      // Reassign the entire object to remove previously loaded feature's meta.
      this.feature = {
        name: this.metaForm.controls.name.value,
        path: this.metaForm.controls.feature.value,
        ...Object.fromEntries(meta.params.map(({ name }) => [name, undefined]))
      };
    }

    this.meta = meta;
    this.hasMeta = true;
    this._loading.meta = false;
  }

  async loadRDS(): Promise<void> {
    this.availableRDS = (await this.rawService.getAsync(this.teamName))
      .filter((rds) => rds.hasCacheFile)
      .sort((a, b) => (a.fullPath < b.fullPath ? 1 : -1));
  }

  async loadIndices(asset: string, fileName: string): Promise<void> {
    this._loading.indices = true;
    // this.demoForm.disable();

    const indices = await this.rawService.count(this.teamName, asset, fileName);
    this.selectedRDS.indices = indices ?? 0;

    // this.demoForm.enable();
    this._loading.indices = false;
  }

  async runDemo(params: { [key: string]: any }): Promise<void> {
    this._loading.demo = true;
    this.setTableData(undefined);

    try {
      const data = await this.featureService.demoAsync(
        this.teamName,
        this.feature.path,
        this.selectedRDS.fullPath,
        this.demoForm.controls.index.value,
        params
      );
      this.setTableData(data);
    } catch (error) {
      this.demoResults = 'error';
    }

    this._loading.demo = false;
  }

  setRandomIndex() {
    this.demoForm.controls.index.setValue(Math.floor(Math.random() * this.selectedRDS?.indices ?? 0));
  }

  setTableData(demoResults?: any[] | any[][]): void {
    if (demoResults === undefined) {
      // Clear any previous demo results
      Object.assign(this, { demoResults: undefined, demoTableColumns: undefined, demoTableData: undefined });
    } else if (demoResults.length === 0) {
      // Mark the results as empty, which displays a message
      Object.assign(this, { demoResults: 'empty', demoTableColumns: undefined, demoTableData: undefined });
    } else {
      const rowToObject = (row, rowNum = 0) => ({
        '#': rowNum + 1,
        ...Object.fromEntries(row.map((e, i) => [this.demoTableColumns[i + 1], e]))
      });

      this.demoResults = demoResults;

      // Resolve 2D data [[...],] vs 1D data [...]
      const is2D = demoResults[0] instanceof Array;
      const numberResultColumns = is2D ? demoResults[0].length : demoResults.length;

      if (numberResultColumns !== this.meta.returns.length) {
        // Automatically number the columns in case the meta's returns field isn't applicable
        this.demoTableColumns = ['#', ...new Array(numberResultColumns).fill(0).map((_e, i) => (i + 1).toString())];
      } else {
        this.demoTableColumns = ['#', ...this.meta.returns];
      }

      // 2D provides multiple rows, whereas 1D data is a single row.
      if (is2D) {
        this.demoTableData = demoResults.map(rowToObject);
      } else {
        this.demoTableData = [rowToObject(demoResults)];
      }
    }
  }

  async closePanel(panel: MatExpansionPanel): Promise<void> {
    return new Promise<void>((resolve) => {
      if (!panel.expanded) resolve();
      const sub = panel.afterCollapse.subscribe(() => {
        sub.unsubscribe();
        resolve();
      });
      panel.close();
    });
  }

  $changePath($ev: MatSelectChange): void {
    this.feature.path = $ev.value;

    this.closePanel(this.demoPanel);
    this.closePanel(this.parameterPanel).then(this.loadMeta.bind(this));
  }

  $changeName($ev: Event): void {
    this.feature.name = ($ev.target as HTMLInputElement).value;
  }

  $parameterFormLoaded(): void {
    this.parameterPanel.open();
  }

  $changeRDS($ev: MatSelectChange): void {
    this.selectedRDS = $ev.value;
    const { folder, fileName } = $ev.value;
    const asset = folder.split('/').pop();

    this.loadIndices(asset, fileName);
  }

  $clickDemo() {
    const params = Object.fromEntries(this.meta.params.map(({ name }) => [name, this.feature[name]]));
    this.runDemo(params);
  }

  $clickSave() {
    this.featureService
      .addAsync(this.teamName, this.feature)
      .then(() => {
        this.router.navigate([this.teamName, 'data', 'features']);
      })
      .catch((e) => {
        console.warn('Failed to save!', { e });
      });
  }
}
