// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file-input {
  display: none;
}

.btn {
  padding-top: 15px;
}

.drop-icon-container {
  margin-top: 300px;
}
.drop-icon-container > * {
  transform: scale(5);
}

.drop-icon-hint {
  margin-top: 100px;
}
.drop-icon-hint span {
  font-weight: bold;
}

.progress-container {
  margin-bottom: 10px;
}

.progress {
  padding-left: 5px;
}

.complete {
  color: green;
}

.error {
  color: red;
}

.icon {
  padding: 12px 6px 6px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/file-upload-dialog/file-upload-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,iBAAA;AACF;AACE;EACE,mBAAA;AACJ;;AAGA;EACE,iBAAA;AAAF;AAEE;EACE,iBAAA;AAAJ;;AAIA;EACE,mBAAA;AADF;;AAIA;EACE,iBAAA;AADF;;AAIA;EACE,YAAA;AADF;;AAIA;EACE,UAAA;AADF;;AAIA;EACE,qBAAA;AADF","sourcesContent":[".file-input {\n  display: none;\n}\n\n.btn {\n  padding-top: 15px;\n}\n\n.drop-icon-container {\n  margin-top: 300px;\n\n  & > * {\n    transform: scale(5);\n  }\n}\n\n.drop-icon-hint {\n  margin-top: 100px;\n\n  span {\n    font-weight: bold;\n  }\n}\n\n.progress-container {\n  margin-bottom: 10px;\n}\n\n.progress {\n  padding-left: 5px;\n}\n\n.complete {\n  color: green;\n}\n\n.error {\n  color: red;\n}\n\n.icon {\n  padding: 12px 6px 6px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
