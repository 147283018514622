import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ConfigService } from 'src/app/services/config.service';
import { DataBuildsService } from 'src/app/services/data-builds.service';
import { debounce } from 'lodash';
import { CacheFileService } from 'src/app/services/cache-file.service';

@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.scss']
})
export class LogComponent implements OnInit, OnChanges {
  private paginator: MatPaginator;
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.loadLog(this.paginator.pageIndex + 1);
  }

  @Input() teamName: string = '';
  @Input() runId: number;
  @Input() isArchived: boolean;
  @Input() type: 'WORKFLOW' | 'CACHE';
  @Input() path: string = '';

  public log: string = '';

  public editorOptions = {
    theme: 'vs-dark',
    language: 'python',
    automaticLayout: true,
    scrollBeyondLastLine: false,
    minimap: {
      enabled: false
    },
    wordBasedSuggestions: false,
    lineNumbers: (x) => {
      return x + this.linesPerPage * (this.stats.currentPage - 1);
    }
  };

  public stats = {
    totalPages: 0,
    currentPage: 0,
    hasNextPage: false,
    hasPrevPage: false,
    total: 0
  };

  public fetching: boolean = true;
  public linesPerPage = 100;

  constructor(
    private dataBuildsService: DataBuildsService,
    private cacheFileService: CacheFileService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.loadLog(1);
  }

  ngOnInit(): void {
    this.onEditorResize = debounce(this.onEditorResize, 1000);
    this.optimizePageSize();
  }

  optimizePageSize() {
    const x = window.innerHeight;
    const blocks = Math.round(0.009872 * x - 2);
    this.linesPerPage = blocks * 5;

    if (this.paginator) this.paginator._changePageSize(this.linesPerPage);
  }

  onEditorResize() {
    this.optimizePageSize();
    this.loadLog(this.stats.currentPage);
  }

  loadLog(pageNum: number) {
    if (this.type === 'WORKFLOW') this.loadWorkflowLog(pageNum);

    if (this.type === 'CACHE') {
      this.loadCacheLog(pageNum);
    }
  }

  loadCacheLog(pageNum: number) {
    const encodedPath = encodeURI(this.path.replace(/\//g, '\\'));
    const sub = this.cacheFileService.loadLog(this.teamName, encodedPath, this.linesPerPage, pageNum).subscribe({
      error: (error) => {
        sub.unsubscribe();
        console.error(error);
      },
      next: (results: any) => {
        sub.unsubscribe();
        const data = results.data.split(ConfigService.magicNum);
        this.stats = JSON.parse(data[0]);

        if (this.stats.total > 0) {
          this.log = data[1];
        } else this.log = 'No data';

        this.fetching = false;
      }
    });
  }

  loadWorkflowLog(pageNum: number) {
    this.fetching = true;
    const sub = this.dataBuildsService.loadLog(this.teamName, this.runId, this.isArchived, this.linesPerPage, pageNum).subscribe({
      error: (error) => {
        sub.unsubscribe();
        console.error(error);
      },
      next: (results) => {
        sub.unsubscribe();
        const data = results.data.split(ConfigService.magicNum);
        this.stats = JSON.parse(data[0]);

        if (this.stats.total > 0) {
          this.log = data[1];
        } else this.log = 'No data';

        this.fetching = false;
      }
    });
  }

  public page(event: PageEvent) {
    this.linesPerPage = event.pageSize;
    this.loadLog(event.pageIndex + 1);
  }
}
