import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { WidgetService } from 'src/app/services/widget.service';

@Component({
  selector: 'app-experiments-widget',
  templateUrl: './experiments-widget.component.html',
  styleUrls: ['../base-widget/base-widget.component.scss', './experiments-widget.component.scss']
})
export class ExperimentsWidgetComponent extends BaseWidgetComponent implements OnInit {
  @Input() definitionName: string;
  @Output() onProcessView = new EventEmitter<{command: string, title: string}>();
  @Output() onVaultItem = new EventEmitter<{vaultName: string}>();
  @Output() onPerfTableView = new EventEmitter<{run: any, displayedColumns: string[], dynamicColumns: any[], live: boolean}>();

  constructor(private _widgetService: WidgetService) {
    super();
  }

  ngOnInit(): void {
    this._subs.add = this._widgetService.resize$.subscribe((response) => {
      (<any>window).experiments.onResize();
    });
  }

  processView($event) {
    this.onProcessView.emit($event);
  }

  vaultItem($event) {
    this.onVaultItem.emit($event);
  }

  perfTableView($event) {
    this.onPerfTableView.emit($event);
  }
}
