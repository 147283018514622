// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  width: 100%;
}

tr {
  cursor: pointer;
}

.won {
  background: #b7e9c1;
}

.lost {
  background: rgba(249, 139, 133, 0.5);
}

.muted {
  background: rgba(131, 161, 205, 0.2);
}`, "",{"version":3,"sources":["webpack://./src/app/components/strategy-grid/breakout-widget/breakout-widget.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,oCAAA;AACF;;AAEA;EACE,oCAAA;AACF","sourcesContent":["table {\n  width: 100%;\n}\n\ntr {\n  cursor: pointer;\n}\n\n.won {\n  background: #b7e9c1;\n}\n\n.lost {\n  background: rgba(249, 139, 133, 0.5);\n}\n\n.muted {\n  background: rgba(131, 161, 205, 0.2);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
