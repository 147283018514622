import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { SchemasComponent } from '../../data/schemas/schemas.component';
import { SchemaComponent } from '../../data/schema/schema.component';

export namespace SchemasWidgetComponent {
  export type BuildEvent = BaseWidgetComponent.Event<SchemaComponent.BuildEvent>;
  export type DeleteEvent = BaseWidgetComponent.Event<SchemaComponent.DeleteEvent>;
  export type NewEvent = BaseWidgetComponent.Event;
  export type SaveEvent = BaseWidgetComponent.Event<SchemaComponent.SaveEvent>;
  export type SelectEvent = BaseWidgetComponent.Event<SchemasComponent.SelectSchemaEvent>;
}

@Component({
  selector: 'app-schemas-widget',
  templateUrl: './schemas-widget.component.html',
  styleUrls: ['../base-widget/base-widget.component.scss', './schemas-widget.component.scss']
})
export class SchemasWidgetComponent extends BaseWidgetComponent {
  @Input() teamName: string;
  @Output() onBuild = new EventEmitter<SchemaComponent.BuildEvent>();

  rdsName: string = undefined;
  projectName: string = undefined;
  isNew: boolean = false;

  constructor() {
    super();
  }

  $build($event: SchemaComponent.BuildEvent) {
    this.onBuild.emit($event);
  }

  $delete(_$event: SchemaComponent.DeleteEvent) {
    Object.assign(this, {
      projectName: undefined,
      rdsName: undefined,
      isNew: true,
      widgetView: 'LIST'
    });
  }

  $new() {
    Object.assign(this, {
      projectName: undefined,
      rdsName: undefined,
      isNew: true,
      widgetView: 'DETAIL'
    });
  }

  $save(_$event: SchemaComponent.SaveEvent) {
    Object.assign(this, {
      projectName: undefined,
      rdsName: undefined,
      isNew: true,
      widgetView: 'LIST'
    });
  }

  $select({ projectName, rdsName }: SchemasComponent.SelectSchemaEvent) {
    Object.assign(this, {
      projectName,
      rdsName,
      isNew: false,
      widgetView: 'DETAIL'
    });
  }

  $close() {
    if (this.widgetView === 'DETAIL') {
      Object.assign(this, {
        projectName: undefined,
        rdsName: undefined,
        isNew: false,
        widgetView: 'LIST'
      });
    } else this.close();
  }
}
