// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button {
  margin-top: 20px;
}

:host[flexible] form {
  flex-grow: 1;
}

:host[flexible] .no-params {
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/components/dynamic-form/dynamic-form.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,kBAAA;AACF","sourcesContent":["button {\n  margin-top: 20px;\n}\n\n:host[flexible] form {\n  flex-grow: 1;\n}\n\n:host[flexible] .no-params {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
