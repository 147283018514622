import { Component, ViewChild, OnDestroy, OnInit, Input, ElementRef, Output, EventEmitter } from '@angular/core';
import { map, Observable, startWith } from 'rxjs';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-chip-list',
  templateUrl: './chip-list.component.html',
  styleUrls: ['./chip-list.component.scss']
})
export class ChipListComponent implements OnInit, OnDestroy {
  @Output() chipClick = new EventEmitter();
  @Output() chipAdded = new EventEmitter<{name: string}>();
  @Output() chipRemoved = new EventEmitter<{name: string}>();
  @Input() public label: string = '';
  @Input() public value = [];
  @Input() public all = [];

  public get available() : string[] {
    return this.all.filter(x => !this.value.includes(x));
  }

  public formCtrl = new FormControl('');
  @ViewChild('input') input: ElementRef<HTMLInputElement>;

  constructor() { }

  ngOnInit(): void { }

  onClick(event) {
    this.chipClick.emit(event);
  }
  ngOnDestroy(): void {}

  async add(event: MatAutocompleteSelectedEvent) {
    this.value.push(event.option.viewValue);
    this.input.nativeElement.value = '';
    this.formCtrl.setValue(null);
    this.chipAdded.emit({name: event.option.viewValue});
  }

  delete(item) {
    this.value = this.value.filter((x) => x !== item);
    this.formCtrl.setValue(null);
    this.chipRemoved.emit({ name: item });
  }
}
