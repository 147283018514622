// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hint {
  font-size: 0.8em;
  margin-bottom: 15px;
  white-space: pre-wrap;
}
.hint .btn {
  color: #2196f3;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/components/dynamic-form/pretty-description/pretty-description.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,mBAAA;EACA,qBAAA;AACF;AACE;EACE,cAAA;EACA,eAAA;AACJ","sourcesContent":[".hint {\n  font-size: 0.8em;\n  margin-bottom: 15px;\n  white-space: pre-wrap;\n\n  .btn {\n    color: #2196f3;\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
