import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Node } from 'src/app/models/workflow-node';

@Component({
  selector: '[shape]',
  template: `<svg:g [attr.transform]="transform">
      <svg:polygon
        [class]="nodeClass"
        points="40 5 70.3108891 22.25 70.3108891 56.75 40 74 9.68911087 56.75 9.68911087 22.25"
        [attr.fill]="node.color"
      />
    </svg:g>

    <svg:text text-anchor="middle" [attr.y]="iconOffset" [attr.font-size]="fontSize" [class]="iconClass">
      {{ node.icon }}
    </svg:text>

    <svg:g transform="translate(0,0)">
      <svg:g *ngIf="!isOutput" [outport] [node]="node" [unselected]="unselected" (drag)="$drag($event)" (end)="$end($event)" />
      <svg:g *ngIf="!isInput" [inport] [node]="node" [highlight]="connectionCandidate" [unselected]="unselected" />
    </svg:g>`,
  styleUrls: ['./workflow-canvas-node.component.scss', './workflow-canvas.vars.scss']
})
export class WorkflowCanvasShapeComponent {
  @Input() node: Node;
  @Input() selected: boolean;
  @Input() unselected: boolean;
  @Input() dragging: boolean;
  @Input() connectionCandidate: boolean;

  @Output() drag = new EventEmitter<{ node: Node; e: Event }>();
  @Output() end = new EventEmitter<{ node: Node; e: Event }>();

  public get isInput(): boolean {
    return this.node.name === 'input';
  }

  public get isOutput(): boolean {
    return this.node.name === 'output';
  }

  public get isSpecial(): boolean {
    return this.isInput || this.isOutput;
  }

  public get transform(): string {
    return `translate(-${(this.node.width * this.node.scale) / 2}, -${(this.node.height * this.node.scale) / 2}) scale(${this.node.scale})`;
  }

  public get nodeClass(): string {
    return 'node' + (this.selected ? ' selected' : this.unselected ? ' unselected' : '') + (this.dragging ? ' dragging' : '');
  }

  public get strokeClass(): string {
    return 'stroke' + (this.selected ? ' selected' : this.unselected ? ' unselected' : '');
  }

  public get fontSize(): number {
    return 28 * this.node.scale;
  }

  public get iconClass(): string {
    return (
      'icon' +
      (this.selected ? ' selected' : this.unselected ? ' unselected' : '') +
      (this.dragging ? ' dragging' : '') +
      (this.isSpecial ? ' special' : '')
    );
  }

  public get iconOffset(): number {
    return this.fontSize / 2;
  }

  public $drag = ($ev) => this.drag.emit($ev);
  public $end = ($ev) => this.end.emit($ev);
}
