import { Component, OnInit } from '@angular/core';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { LabService } from 'src/app/services/lab.service';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-lab-widget',
  templateUrl: './lab-widget.component.html',
  styleUrls: ['../base-widget/base-widget.component.scss', './lab-widget.component.scss']
})
export class LabWidgetComponent extends BaseWidgetComponent implements OnInit {

  labUrl: string = null;
  status: string = "Establishing secure connection to notebook server ...";

  constructor(private _labService: LabService) {
    super();
  }

  async ngOnInit(): Promise<void> {
      const response = await this._labService.getTokenAsync(this.teamName);
      if(response && response.token) {
        this.labUrl = `${ConfigService.labUrl}?token=${response.token}`;
      }
      else {
        this.status = "Could not retrieve token from server."
      }
  }
}
