export const divmod = (x: number, d: number) => [Math.floor(x / d), x % d];

export const structurizeDuration = (duration: number) => {
  let years: number;
  let months: number;
  let days: number;
  let hours: number;
  let minutes: number;
  let seconds: number;
  [years, duration] = divmod(duration, 1000 * 60 * 60 * 24 * 365.24245);
  [months, duration] = divmod(duration, (1000 * 60 * 60 * 24 * 365.24245) / 12);
  [days, duration] = divmod(duration, 1000 * 60 * 60 * 24);
  [hours, duration] = divmod(duration, 1000 * 60 * 60);
  [minutes, duration] = divmod(duration, 1000 * 60);
  [seconds, duration] = divmod(duration, 1000);

  return {
    years,
    months,
    days,
    hours,
    minutes,
    seconds
  };
};

export const humanizeDuration = (duration: number) => {
  const { years, months, days, hours, minutes, seconds } = structurizeDuration(duration);
  let bigger = undefined;
  let smaller = undefined;

  if (years !== 0) {
    bigger = `${years}y`;
    if (months !== 0) smaller = `${months}M`;
  } else if (months !== 0) {
    bigger = `${months}M`;
    if (days !== 0) smaller = `${months}d`;
  } else if (days !== 0) {
    bigger = `${days}d`;
    if (hours !== 0) smaller = `${hours}h`;
  } else if (hours !== 0) {
    bigger = `${hours}h`;
    if (minutes !== 0) smaller = `${minutes}m`;
  } else if (minutes !== 0) {
    bigger = `${minutes}m`;
    if (seconds !== 0) smaller = `${seconds}s`;
  } else {
    bigger = `${seconds}s`;
  }

  return smaller !== undefined ? `${bigger}, ${smaller} ago` : `${bigger} ago`;
};
