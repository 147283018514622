// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  width: 100%;
}

.compact {
  height: 38px !important;
}

.compact mat-cell,
.compact mat-cell-header {
  font-size: 10pt;
}`, "",{"version":3,"sources":["webpack://./src/app/components/team/user-project-stats/user-project-stats.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAEA;EACE,uBAAA;AACF;;AAEA;;EAEE,eAAA;AACF","sourcesContent":["table {\n  width: 100%;\n}\n\n.compact {\n  height: 38px !important;\n}\n\n.compact mat-cell,\n.compact mat-cell-header {\n  font-size: 10pt;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
