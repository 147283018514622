import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { VaultService } from 'src/app/services/vault.service';

import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { consolidateStatus } from '../../service-status-icon/service-status-icon.component';
import { SubscriptionContainer } from 'src/app/models/subscription-container';
import { SocketService } from 'src/app/services/socket.service';

@Component({
  selector: 'app-vault',
  templateUrl: './vault.component.html',
  styleUrls: ['./vault.component.scss']
})
export class VaultComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input() isWidget: boolean = false;
  @Input() teamName: string;
  @Input() parentId: string;
  @Output() vaultDetailClick = new EventEmitter<{ parentId: string; vaultName: string }>();

  public dataSource = new MatTableDataSource<any>([]);
  public pageSize = 10;
  public isLoading = true;
  public displayedColumns = ['name', 'services', 'runid', 'epoch', 'dateAdded'];
  public availableColumns = ['host', 'name', 'services', 'runid', 'epoch', 'value', 'val_accuracy', 'dateAdded'];
  public serviceCounts = { active: 0, warn: 0, error: 0, unknown: 0, all: 0, known: 0 };
  public accuracyCounts = { pass: 0, warn: 0, fail: 0, all: 0 };
  public uniqueProjects = new Set();
  private _subs = new SubscriptionContainer();

  constructor(
    private _host: ElementRef,
    private activatedRoute: ActivatedRoute,
    private vaultService: VaultService,
    private router: Router,
    private _socketService: SocketService
  ) {}

  ngOnInit(): void {
    if (this.isWidget) {
      this.load(this.teamName);
    } else {
      this.activatedRoute.params.subscribe((params) => {
        this.load(params.team);
      });
    }

    this._socketService.joinRoom('vault');
    this._subs.add = this._socketService.subscribeToRoomEvents('vault', () => {
      this.load(this.teamName);
    });
    (<any>window).vault = this;
  }

  ngOnDestroy(): void {
    this._socketService.leaveRoom('vault');
    this._subs.dispose();
  }

  async load(teamName: string): Promise<void> {
    this.teamName = teamName;

    const sub = this.vaultService.load(this.teamName).subscribe((response) => {
      this.serviceCounts = { active: 0, warn: 0, error: 0, unknown: 0, all: 0, known: 0 };
      this.accuracyCounts = { pass: 0, warn: 0, fail: 0, all: 0 };
      this.uniqueProjects = new Set();

      sub.unsubscribe();
      this.isLoading = false;

      if (!response || response.length == 0) return;

      const timestampColumns = ['dateAdded'];
      timestampColumns.forEach((col) => {
        response.forEach((row) => {
          if (row[col]) row[col] = new Date(row[col]);
        });
      });
      response.forEach(this.processModelStats.bind(this));
      this.dataSource.data = response;

      setTimeout(() => {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.optimizePageSize();
      }, 0);
    });
  }

  processModelStats(row) {
    const statusClass = consolidateStatus(row.services);
    row.rendering = { statusClass };
    this.uniqueProjects.add(row.project);

    this.serviceCounts[statusClass] += 1;
    this.serviceCounts.all += 1;
    if (statusClass !== 'unknown') this.serviceCounts.known += 1;

    if (row.perf?.val_accuracy < 0.7) this.accuracyCounts.fail += 1;
    else if (row.perf?.val_accuracy < 0.85) this.accuracyCounts.warn += 1;
    else this.accuracyCounts.pass += 1;
    this.accuracyCounts.all += 1;
  }

  onResize() {
    this.optimizePageSize();
  }

  optimizePageSize() {
    if (this.isWidget) {
      const innerHeight = this._host.nativeElement.parentElement.clientHeight;
      const cardsHeight = 150 + 16;
      const searchHeight = 64 + 31;
      const headSize = 38;
      const rowSize = 57;
      const footSize = 58;
      this.pageSize = Math.floor((innerHeight - cardsHeight - searchHeight - headSize - footSize) / rowSize);
    } else {
      var x = window.innerHeight;
      var blocks = Math.round(0.00493601 * x - 2);
      this.pageSize = blocks * 5;
    }
    if (this.paginator) this.paginator._changePageSize(this.pageSize);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) this.dataSource.paginator.firstPage();
  }

  rowClick(row: any) {
    if (this.isWidget) {
      this.vaultDetailClick.emit({ parentId: this.parentId, vaultName: row.name });
    } else {
      this.router.navigate([this.teamName, 'vault-detail', row.name]);
    }
  }
}
