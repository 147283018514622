// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-field-row {
  padding-top: var(--content-margin);
}

table {
  width: 100%;
}

.mat-mdc-footer-cell {
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/app/components/team/project/project.component.scss"],"names":[],"mappings":"AAAA;EACE,kCAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,iBAAA;AACF","sourcesContent":[".form-field-row {\n  padding-top: var(--content-margin);\n}\n\ntable {\n  width: 100%;\n}\n\n.mat-mdc-footer-cell {\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
