import { Component, Input } from '@angular/core';
import { Node } from 'src/app/models/workflow-node';

@Component({
  selector: '[inport]',
  template: `<svg:circle
    [class]="portClass"
    [attr.cx]="node.inPortOffset.x"
    [attr.cy]="node.inPortOffset.y"
    [attr.r]="highlight ? 6 * node.scale : 4 * node.scale"
  />`,
  styleUrls: ['./workflow-canvas-node.component.scss', './workflow-canvas.vars.scss']
})
export class WorkflowCanvasInPortComponent {
  @Input() node: Node;
  @Input() unselected: boolean;
  @Input() highlight: boolean;

  public get portClass(): string {
    return 'port' + (this.highlight ? ' highlight' : this.unselected ? ' unselected' : '');
  }
}
