import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { StratsService } from 'src/app/services/strats';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { WidgetService } from 'src/app/services/widget.service';

@Component({
  selector: 'app-breakout-widget',
  templateUrl: './breakout-widget.component.html',
  styleUrls: ['../base-widget/base-widget.component.scss', './breakout-widget.component.scss']
})
export class BreakoutWidgetComponent extends BaseWidgetComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) breakoutPaginator: MatPaginator;

  @Input() ticker: string;
  @Input() modelName: string;
  @Input() strategyName: string;
  @Input() days: number;

  breakout: any;
  showBreakout = false;
  breakoutDS = new MatTableDataSource<any>([]);
  breakoutPageSize = 5;
  breakoutColumns = [];
  isLoading = true;

  constructor(
    private _stratsService: StratsService,
    private _widgetService: WidgetService
  ) {
    super();
  }

  ngOnInit(): void {
    this._subs.add = this._widgetService.tickerUpdate$.subscribe((response: any) => {
      if (response.parentId !== this.parentId) return;
      this.ticker = response.ticker;
      this.init();
    });

    this.init();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  init() {
    const sub = this._stratsService
      .loadBreakout(this.teamName, this.ticker, this.modelName, this.strategyName, this.days)
      .subscribe((response) => {
        sub.unsubscribe();
        this.showBreakout = true;
        this.breakout = <any[]>response;
        this.breakout.forEach((x) => {
          x.timestamps = new Date(x.timestamps).toLocaleString();
          x.exit_time = new Date(x.exit_time).toLocaleString();
        });
        this.breakoutColumns = Object.keys(response[0]).filter((x) => !x.includes('Unnamed'));
        this.breakoutDS = new MatTableDataSource<any>(this.breakout);
        setTimeout(() => {
          this.breakoutDS.paginator = this.breakoutPaginator;
        }, 100);
        this.isLoading = false;
      });

    this._subs.add = this._widgetService.resize$.subscribe((response) => {
      if (response.item.id == this.id) {
        setTimeout(() => {
          const rows = response.itemComponent.$item.rows;
          this.breakoutPageSize = Math.trunc((rows * 2) / 5) - 2;
          this.breakoutPaginator._changePageSize(this.breakoutPageSize);
        }, 100);
      }
    });
  }
}
