import { Injectable } from '@angular/core';
import { Host } from '../models/host';
import { ConfigService } from './config.service';
import { lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HostsService {
  public locations = ['on premise', 'google', 'aws', 'azure'];

  constructor(private http: HttpClient) {}

  public async loadAsync(teamName: string) {
    return lastValueFrom<Host[]>(this.http.get<Host[]>(`${ConfigService.apiUrl}/hosts/${teamName}`));
  }

  public async getAsync(teamName: string, hostName: string) {
    return lastValueFrom<Host>(this.http.get<Host>(`${ConfigService.apiUrl}/hosts/${teamName}/${hostName}`));
  }

  public async addAsync(teamName: string, host: Host) {
    return lastValueFrom<Host>(this.http.post<Host>(`${ConfigService.apiUrl}/hosts/${teamName}`, host));
  }

  public async updateAsync(teamName: string, host: Host) {
    return lastValueFrom<Host>(this.http.put<Host>(`${ConfigService.apiUrl}/hosts/${teamName}/${host.id}`, host));
  }
}
