import { SerializedPoint } from './point';

type EventHandler = ($ev: Event) => void;

type Handlers = {
  tap: EventHandler[];
  move: EventHandler[];
  moveEnd: EventHandler[];
  pinch: EventHandler[];
};

type TinyTouch = {
  identifier: number;
  pageX: number;
  pageY: number;
};

/** https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/button */
export enum MouseButton {
  Left = 0,
  Wheel = 1,
  Right = 2,
  BrowserBack = 3,
  BrowserForward = 4
}

export type TapEvent = CustomEvent<SerializedPoint>;

export type PinchEvent = CustomEvent<SerializedPoint & { delta: SerializedPoint; scale: number }>;

export type MoveEvent = CustomEvent<SerializedPoint & { delta: SerializedPoint }>;

export default class SVGCanvasEventManager {
  private _el: Element;
  public name: string;
  private _handlers: Handlers = {
    tap: [],
    move: [],
    moveEnd: [],
    pinch: []
  };
  private _currentTouches: TinyTouch[] = [];
  private _startDistance: number;
  private _scale: number = 1;
  private _previousScale: number = 1;
  private _click: boolean = false;
  private _dragging: boolean = false;
  private _mouseDown: boolean = false;
  private _previousCenter: SerializedPoint | null = null;
  private _touchIdentifier: number;
  private _previousLocation: SerializedPoint | null;
  private _delta: SerializedPoint;
  private _previousDelta: SerializedPoint;

  constructor(element: Element) {
    this._el = element;
    this.name = element.id || element.tagName;

    this.setup();
  }

  private setup = (): void => {
    if ('ontouchstart' in window) this._el.addEventListener('touchstart', this._mousedown.bind(this));
    else this._el.addEventListener('mousedown', this._mousedown.bind(this));
  };

  public setdown = (): void => {
    if ('ontouchstart' in window) {
      this._el.removeEventListener('touchstart', this._mousedown.bind(this));
      this._el.removeEventListener('touchstart', this._pinchStart.bind(this));
      this._el.removeEventListener('touchend', this._pinchEnd.bind(this));
      this._el.removeEventListener('touchcancel', this._pinchCancel.bind(this));
      this._el.removeEventListener('touchmove', this._pinchMove.bind(this));
    } else {
      this._el.removeEventListener('mousedown', this._mousedown.bind(this));
    }
  };

  // Distance is used to calc the scale. This saves the starting distance
  private _setStartDistance = (): void => {
    if (this._currentTouches.length >= 2)
      this._startDistance = this._distance(...(this._currentTouches.slice(0, 2) as [TinyTouch, TinyTouch]));
  };

  // When a finger touches the screen:
  // - make a small version copy of the event
  // - save it
  // - recalc the starting distance just in case (is this needed?)
  private _pinchStart = ($ev: TouchEvent) => {
    $ev.preventDefault();
    const touches = $ev.changedTouches;
    for (const touch of Array.from(touches)) {
      const { identifier, pageX, pageY } = touch;
      this._currentTouches.push({ identifier, pageX, pageY });
    }
    this._setStartDistance();
  };

  // When a finger is lifted:
  // - remove the event from currenTouches
  // - save the scale if we're done (less than 2 touches)
  // - clear the saved center point (used for 2 finger panning)
  private _pinchEnd = ($ev: TouchEvent): void => {
    $ev.preventDefault();

    for (const finishedTouch of Array.from($ev.changedTouches)) {
      const idx = this._currentTouches.findIndex((t: Touch) => t.identifier === finishedTouch.identifier);

      if (idx == -1) return;
      this._currentTouches.splice(idx, 1);
    }
    this._setStartDistance();
    if (this._currentTouches.length < 2) this._previousScale = this._scale;

    this._previousCenter = null;
  };

  _pinchCancel = ($ev): void => {
    $ev.preventDefault();
    // TODO
  };

  // The touches from the event are not order guaranteed
  // so we need to get the first two touches based on the order
  // saved in currentTouches array
  // - this.scale tracks the scale-in-progress, while this.previousScale is where it's
  //   saved once you let go
  // - center is calculated so we can also pan the canvas while scaling
  // - previousCenter is tracked so we can create the delta object needed for panning
  private _pinchMove = ($ev: TouchEvent): void => {
    $ev.preventDefault();
    if ($ev.changedTouches.length < 2) return;

    const touches: Touch[] = Array.from($ev.touches);
    const first = touches.find((t: Touch) => t.identifier === this._currentTouches[0].identifier);
    const second = touches.find((t: Touch) => t.identifier === this._currentTouches[1].identifier);

    if (!first || !second) return;

    const newDistance = this._distance(first, second);

    this._scale = (this._previousScale * newDistance) / this._startDistance;

    const center: SerializedPoint = {
      x: (first.pageX + second.pageX) / 2,
      y: (first.pageY + second.pageY) / 2
    };

    if (!this._previousCenter) this._previousCenter = center;

    const customEvent = new CustomEvent('pinch', {
      detail: {
        x: center.x,
        y: center.y,
        scale: this._scale,
        delta: {
          x: center.x - this._previousCenter.x,
          y: center.y - this._previousCenter.y
        }
      }
    });
    this.callHandler('pinch', customEvent);

    this._previousCenter = center;
  };

  private setupPinch = (): void => {
    this._el.addEventListener('touchstart', this._pinchStart.bind(this));
    this._el.addEventListener('touchend', this._pinchEnd.bind(this));
    this._el.addEventListener('touchcancel', this._pinchCancel.bind(this));
    this._el.addEventListener('touchmove', this._pinchMove.bind(this));
  };

  private _distance = (touch1: Touch | TinyTouch, touch2: Touch | TinyTouch): number => {
    const first = {
      x: touch1.pageX,
      y: touch1.pageY
    };
    const second = {
      x: touch2.pageX,
      y: touch2.pageY
    };
    const a = Math.abs(first.x - second.x);
    const b = Math.abs(first.y - second.y);
    const d = Math.sqrt(a ** 2 + b ** 2);

    return d;
  };

  private addHandler = (eventName: string, fn: EventHandler): void => {
    this._handlers[eventName].push(fn);
  };

  private callHandler = (eventName: string, event: CustomEvent): void => {
    if (this._handlers[eventName].length) this._handlers[eventName].forEach((fn: EventHandler) => fn(event));
  };

  private _mousedown = ($ev: TouchEvent | MouseEvent): void => {
    // Ignore non SVG touches (i.e., the buttons)
    if (!($ev.target instanceof SVGElement)) return;
    // Ignore non left-click mouse button
    if ($ev instanceof MouseEvent && $ev.button != MouseButton.Left) return;

    $ev.preventDefault();
    $ev.stopPropagation();

    // Ignore multiple touches
    if (window.TouchEvent && $ev instanceof TouchEvent && $ev.touches.length > 1) return;
    // Add listeners
    if ('ontouchstart' in window) {
      window.addEventListener('touchmove', this._mousemove);
      window.addEventListener('touchend', this._mouseup);
    } else {
      window.addEventListener('mousemove', this._mousemove);
      window.addEventListener('mouseup', this._mouseup);
    }

    this._mouseDown = true;
    this._click = true;
    if (window.TouchEvent && $ev instanceof TouchEvent) this._touchIdentifier = $ev.changedTouches?.[0].identifier;
  };

  private _getEvent = ($ev: TouchEvent | MouseEvent): Touch | MouseEvent => {
    if (window.TouchEvent && $ev instanceof TouchEvent) {
      let event = Array.from($ev.touches).find((t: Touch) => t.identifier === this._touchIdentifier);
      if (!event) {
        event = Array.from($ev.changedTouches).find((t: Touch) => t.identifier === this._touchIdentifier);
      }
      if (!event) {
        throw new Error(`Event not found. Shouldn't have ended up here`);
      }

      return event;
    }
    return $ev as MouseEvent;
  };

  private _mousemove = ($ev: TouchEvent | MouseEvent): void => {
    $ev.preventDefault();
    $ev.stopPropagation();
    if (window.TouchEvent && $ev instanceof TouchEvent && $ev.touches.length > 1) return;

    if (!this._mouseDown) return;

    // get touch or click from either mouse or touch
    const touchOrClick = this._getEvent($ev);
    if (!touchOrClick) return;

    if (!this._previousLocation) this._previousLocation = { x: touchOrClick.pageX, y: touchOrClick.pageY };

    this._delta = {
      x: touchOrClick.pageX - this._previousLocation.x,
      y: touchOrClick.pageY - this._previousLocation.y
    };

    // If we're not already dragging and the delta is 0 or 1, treat as possible click
    // (isClick evaluates on mouseUp, so it could be reset if mouse moves a bit more
    //  and delta increases)
    if (!this._dragging && Math.abs(this._delta.x) <= 1 && Math.abs(this._delta.y) <= 1) {
      this._click = true;
      return;
    }

    // If we are here, we must be dragging...
    this._dragging = true;
    this._click = false;

    if (!this._previousDelta) this._previousDelta = this._delta;

    const customEvent = new CustomEvent('move', {
      detail: {
        x: touchOrClick.pageX,
        y: touchOrClick.pageY,
        delta: this._delta
      }
    });

    this._previousLocation = { x: touchOrClick.pageX, y: touchOrClick.pageY };
    this._previousDelta = this._delta;

    this.callHandler('move', customEvent);
  };

  private _mouseup = ($ev: TouchEvent | MouseEvent): void => {
    // Ignore non SVG touches (i.e., the buttons)
    if (!($ev.target instanceof SVGElement)) return;
    $ev.preventDefault();
    $ev.stopPropagation();

    if ('ontouchstart' in window) {
      window.removeEventListener('touchmove', this._mousemove);
      window.removeEventListener('touchend', this._mouseup);
    } else {
      window.removeEventListener('mousemove', this._mousemove);
      window.removeEventListener('mouseup', this._mouseup);
    }

    // Ignore 2nd, 3rd, etc.. fingers' 'touchend'
    if (window.TouchEvent && $ev instanceof TouchEvent && $ev.touches.length > 1) return;

    if (!this._mouseDown) return;

    this._mouseDown = false;
    this._previousLocation = null;

    const event = this._getEvent($ev);

    // This was a click, trigger 'tap'
    if (this._click) {
      const customEvent = new CustomEvent('tap', {
        detail: {
          x: event.pageX,
          y: event.pageY
        }
      });
      this.callHandler('tap', customEvent);
    }

    // This was a 'move', trigger 'moveend'
    if (this._dragging && !this._click) {
      const customEvent = new CustomEvent('moveEnd', {
        detail: {
          x: event.pageX,
          y: event.pageY,
          delta: this._delta
        }
      });
      this.callHandler('moveEnd', customEvent);
    }

    this._delta = this._previousDelta = { x: 0, y: 0 };
    this._click = false;
    this._dragging = false;
  };

  public $tap = (fn: EventHandler): void => {
    this.addHandler('tap', fn);
  };

  public $move = (fn: EventHandler): void => {
    this.addHandler('move', fn);
  };

  public $moveEnd = (fn: EventHandler): void => {
    this.addHandler('moveEnd', fn);
  };

  public $pinch = (fn: EventHandler): void => {
    this.setupPinch();
    this.addHandler('pinch', fn);
  };
}
