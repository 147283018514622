// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading {
  padding: var(--content-margin);
}

.container {
  height: 100%;
}

table {
  width: 100%;
}

tr {
  cursor: pointer;
}

.lightgray-icon {
  color: lightgray;
}

.progress {
  margin-bottom: 10px;
  font-size: 15px;
}

.relative {
  position: relative;
}

app-terminal {
  position: absolute;
  bottom: 0;
  left: 10px;
  right: 10px;
  top: 450px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/models/deploy/deploy.component.scss"],"names":[],"mappings":"AAAA;EACE,8BAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,mBAAA;EACA,eAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,SAAA;EACA,UAAA;EACA,WAAA;EACA,UAAA;AACF","sourcesContent":[".loading {\n  padding: var(--content-margin);\n}\n\n.container {\n  height: 100%;\n}\n\ntable {\n  width: 100%;\n}\n\ntr {\n  cursor: pointer;\n}\n\n.lightgray-icon {\n  color: lightgray;\n}\n\n.progress {\n  margin-bottom: 10px;\n  font-size: 15px;\n}\n\n.relative {\n  position: relative;\n}\n\napp-terminal {\n  position: absolute;\n  bottom: 0;\n  left: 10px;\n  right: 10px;\n  top: 450px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
