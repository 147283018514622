import { Component } from '@angular/core';

@Component({
  selector: '[wfc-grid]',
  template: `<svg:defs>
      <svg:pattern id="smallGrid" width="50" height="50" patternUnits="userSpaceOnUse">
        <svg:path d="M 50 0 L 0 0 0 50" fill="none" stroke-width="1" stroke="#0000000d" />
      </svg:pattern>
      <svg:pattern id="bigGrid" width="250" height="250" patternUnits="userSpaceOnUse">
        <svg:rect width="250" height="250" fill="url(#smallGrid)"></svg:rect>
        <svg:path d="M 250 0 L 0 0 0 250" fill="none" stroke-width="3" stroke="#0000000d" />
      </svg:pattern>
    </svg:defs>
    <svg:rect x="-100000" y="-100000" width="200000" height="200000" fill="url(#bigGrid)" />`
})
export class WorkflowCanvasGridComponent {}
