import { Component, Input, OnInit } from '@angular/core';
import { HostTimeline } from 'src/app/models/host-timeline';
import { HostStatsService } from 'src/app/services/host-stats.service';

@Component({
  selector: 'app-host-timeline',
  templateUrl: './host-timeline.component.html',
  styleUrls: ['./host-timeline.component.scss']
})
export class HostTimelineComponent implements OnInit {
  @Input() teamName: string = '';
  @Input() hostValue: number = null;

  public timeline: any[] = [];

  constructor(private hostStatsService: HostStatsService) {}

  ngOnInit(): void {
    this.load();
  }

  load() {
    if (!this.teamName) {
      return;
    }

    const sub = this.hostStatsService.getTimeline(this.teamName).subscribe((response: HostTimeline[]) => {
      sub.unsubscribe();
      this.timeline = response.sort((a, b) => b.lastModified - a.lastModified);
    });
  }

  calculateValue(elapsedTime: number) {
    return this.hostValue * (elapsedTime / 3600);
  }

  getTimestamp(str) {
    return new Date(str).toLocaleDateString('en-US');
  }
}
