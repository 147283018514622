import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Artifact } from 'src/app/models/asset';
import { AssetsService } from 'src/app/services/assets.service';
import { TeamsService } from 'src/app/services/teams.service';
import { MenuService } from 'src/app/services/menu.service';
import { SocketService } from 'src/app/services/socket.service';
import { SubscriptionContainer } from 'src/app/models/subscription-container';

@Component({
  selector: 'app-asset',
  templateUrl: './asset.component.html',
  styleUrls: ['./asset.component.scss']
})
export class AssetComponent implements OnInit {
  @Input() isWidget: boolean = false;
  @Input() teamName: string;
  @Input() assetName: string;
  @Output() onInspectClick = new EventEmitter<{ type: string; assetName: string; fileName: string }>();
  @Output() onModelClick = new EventEmitter<{ assetName: string }>();
  @Output() closeAsset = new EventEmitter();

  public isLoading: boolean = true;
  public sampleLoading: boolean = false;
  public isUpdating: boolean = false;
  public inUse: boolean = false;
  public warn: boolean = false;


  public queueLabel: string = 'Queue';
  public displayedColumns: string[] = ['name', 'owner', 'sample_count', 'bytes', 'training'];
  public prodDS = new MatTableDataSource<Artifact>([]);
  public queueDS = new MatTableDataSource<Artifact>([]);
  public assets = [];
  public inspect: any = null;
  public mostRecentBuild: any = null;
  public fileName: string;

  private _subscriptions = new SubscriptionContainer();

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _assetsService: AssetsService,
    private _teamsService: TeamsService,
    private _menuService: MenuService,
    private _socketService: SocketService
  ) {}

  ngOnInit(): void {
    if (this.isWidget) {
      this.load(this.teamName, this.assetName);
    } else {
      this.activatedRoute.params.subscribe((params) => {
        this.load(params.team, params.name);
      });
    }

    this._socketService.joinRoom('asset');
    this._subscriptions.add = this._socketService.subscribeToRoomEvents('asset', () => {
      this.load(this.teamName, this.assetName);
    });
    (<any>window).asset = this;
    this._menuService.delayedSetActive('assets');
  }

  public ngOnDestroy(): void {
    this._subscriptions.dispose();
    this._socketService.leaveRoom('asset');
  }

  async load(teamName: string, assetName: string) {
    this.teamName = teamName;
    this.assetName = assetName;

    const sub = this._assetsService.get(teamName, assetName).subscribe((response: any) => {
      sub.unsubscribe();
      this.isLoading = false;
      const assets = response;
      console.log(assets);

      this.mostRecentBuild = assets[0].latestBuild;
      this.prodDS.data = assets.filter((x) => !x.path.includes('queue'));
      this.queueDS.data = assets.filter((x) => x.path.includes('queue'));
      this.inUse = assets.filter((x) => x.isTraining === true || x.anyBuildsRunning === true).length > 0;

      this.warn = this.queueDS.data.filter((x) => x.isTraining === true).length > 0;
    });

  }

  async moveToProd() {
    if (!confirm('Are you sure you want to move these assets to production?')) return;

    this.isUpdating = true;
    const sub = this._assetsService.move(this.teamName, this.assetName).subscribe((response: any) => {
      sub.unsubscribe();
      this.isUpdating = false;
      this.prodDS.data = response.filter((x) => !x.path.includes('queue'));
      this.queueDS.data = response.filter((x) => x.path.includes('queue'));
    });
  }

  buildClick() {
    this.router.navigate([
      this.teamName,
      'data',
      'build',
      this.mostRecentBuild.rdsName,
      this.mostRecentBuild.runid,
      this.mostRecentBuild.user,
      this.mostRecentBuild.isArchived
    ]);
  }

  $clickDesignModel(): void {
    if (this.isWidget) {
      this.onModelClick.emit({ assetName: this.assetName });
    } else {
      this.router.navigate([this.teamName, 'model', 'definitions'], { queryParams: { filter: this.assetName } });
    }
  }

  rowClick(row, location) {
    this.fileName = location === 'queue' ? `${location}/${row.name}` : row.name;

    if (this.isWidget) {
      this.onInspectClick.emit({ type: 'asset', assetName: this.assetName, fileName: this.fileName });
    } else {
      this.router.navigate([this.teamName, 'data', 'inspect', 'asset', this.assetName, this.fileName]);
    }
  }

  close() {
    this.closeAsset.emit();
  }
}
