import {
  ChartData,
  ChartMetaInfo,
  IExternalSaveLoadAdapter,
  StudyTemplateData,
  StudyTemplateMetaInfo
} from 'src/assets/charting_library/charting_library';

const STORAGE_KEYS = ['charts', 'studyTemplates', 'drawingTemplates', 'chartTemplates'];
const CUSTOM_SYMBOL_RX = /LIT:/g;

export class ChartSaveLoadService implements IExternalSaveLoadAdapter {
  private charts: ChartData[];
  private studyTemplates: StudyTemplateData[];
  private drawingTemplates: StudyTemplateData[];

  private convertCustomSymbolToLowercase() {
    this.charts.forEach((chart) => {
      chart.content = chart.content.replace(/LIT:[\w\.]+/g, (match) => match.slice(4).toLowerCase());
    });
  }

  private syncTo(toAssign: ChartSaveLoadService | Storage) {
    // NOTE: This function requires member variables in this class to have the same name as their localStorage storage key.
    const getter =
      toAssign instanceof ChartSaveLoadService
        ? (key: string) => JSON.parse(localStorage.getItem(key)) ?? [] // read from localStorage, assign to this
        : (key: string) => JSON.stringify(this[key]); // read from this, assign to localStorage

    Object.assign(toAssign, Object.fromEntries(STORAGE_KEYS.map((key) => [key, getter(key)])));
  }

  private load = () => {
    this.syncTo(this);
    this.convertCustomSymbolToLowercase();
  };
  private save = () => {
    this.convertCustomSymbolToLowercase();
    this.syncTo(localStorage);
  };

  constructor() {
    window.$tvcsl = this;
    this.load();
  }

  async getAllCharts(): Promise<ChartMetaInfo[]> {
    return <any>this.charts;
  }

  async removeChart<T extends string | number>(id: T): Promise<void> {
    const filtered = this.charts.filter((chart) => chart.id !== id.toString());
    if (filtered.length === this.charts.length) return Promise.reject();
    this.charts = filtered;
    this.save();
  }

  async saveChart(chartData: ChartData): Promise<string> {
    if (!chartData.id) chartData.id = Math.random().toString();
    else this.removeChart(chartData.id);

    this.charts.push(chartData);
    this.convertCustomSymbolToLowercase();
    this.save();
    return chartData.id;
  }

  async getChartContent(chartId: number): Promise<string> {
    const found = this.charts.find((chart) => chart.id === chartId.toString());
    return found?.content ?? Promise.reject();
  }

  async getAllStudyTemplates(): Promise<StudyTemplateMetaInfo[]> {
    return this.studyTemplates;
  }

  async removeStudyTemplate(studyTemplateInfo: StudyTemplateMetaInfo): Promise<void> {
    const filtered = this.studyTemplates.filter((study) => study.name !== studyTemplateInfo.name);
    if (filtered.length === this.studyTemplates.length) return Promise.reject();
    this.studyTemplates = filtered;
    this.save();
  }

  async saveStudyTemplate(studyTemplateData: StudyTemplateData): Promise<void> {
    try {
      await this.removeStudyTemplate(studyTemplateData);
    } catch (e) {
      console.warn(e);
    } finally {
      this.studyTemplates.push(studyTemplateData);
      this.save();
    }
  }

  async getStudyTemplateContent(studyTemplateInfo: StudyTemplateMetaInfo): Promise<string> {
    const found = this.studyTemplates.find((study) => study.name === studyTemplateInfo.name);
    return found?.content ?? Promise.reject();
  }

  async getDrawingTemplates(toolName: string): Promise<string[]> {
    return this.drawingTemplates.map((drawing) => drawing.name);
  }

  async loadDrawingTemplate(toolName: string, templateName: string): Promise<string> {
    const found = this.drawingTemplates.find((drawing) => drawing.name === templateName);
    return found?.content ?? Promise.reject();
  }

  async removeDrawingTemplate(toolName: string, templateName: string): Promise<void> {
    const filtered = this.drawingTemplates.filter((drawing) => drawing.name !== templateName);
    if (filtered.length === this.drawingTemplates.length) return Promise.reject();
    this.drawingTemplates = filtered;
    this.save();
  }

  async saveDrawingTemplate(toolName: string, templateName: string, content: string): Promise<void> {
    try {
      await this.removeDrawingTemplate(toolName, templateName);
    } catch (e) {
      console.warn(e);
    } finally {
      this.drawingTemplates.push({ name: templateName, content });
      this.save();
    }
  }
}
