import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Host } from 'src/app/models/host';
import { ConfigService } from 'src/app/services/config.service';
import { HostsService } from 'src/app/services/hosts.service';
import { IdentityService } from 'src/app/services/identity.service';

@Component({
  selector: 'app-hosts',
  templateUrl: './hosts.component.html',
  styleUrls: ['./hosts.component.scss']
})
export class HostsComponent implements OnInit, AfterViewInit {
  @Input() isWidget: boolean = false;
  @Output() onHostSelect = new EventEmitter<{ hostName: string }>();
  @Output() onTerminalSelect = new EventEmitter();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  isLoading: boolean = true;
  private teamName: string;
  public dataSource = new MatTableDataSource<Host>([]);
  public displayedColumns = ['name', 'location', 'gpus', 'value', 'totalTime', 'totalROI'];

  constructor(
    private router: Router,
    private hostsService: HostsService,
    private identity: IdentityService
  ) {}

  ngOnInit(): void {
    this.load(this.identity.me.selectedTeamName);
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  async load(teamName: string) {
    if (!ConfigService.saasMode) {
      this.displayedColumns.push('ssh');
    }

    this.teamName = teamName;
    const response: Host[] = await this.hostsService.loadAsync(this.teamName);
    this.isLoading = false;
    this.dataSource.data = response;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) this.dataSource.paginator.firstPage();
  }

  rowClick(row) {
    if (this.isWidget) {
      this.onHostSelect.emit({ hostName: row.name });
    } else {
      this.router.navigate([this.teamName, 'hosts', row.name]);
    }
  }

  ssh(row, $event) {
    $event.stopPropagation();
    if (this.isWidget) {
      this.onTerminalSelect.emit();
    } else {
      this.router.navigate([this.teamName, 'terminal']);
    }
  }
}
