import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { Meta } from '../models/meta';

export type Feature<Params = {}> = {
  name: string;
  path: string;
  isScript?: boolean;
  deleted?: boolean;
  group?: string;
} & Params;

export type AvailableFeature<Params = {}> = {
  script: string;
  name: null;
  path: string;
} & Params;

@Injectable({
  providedIn: 'root'
})
export class FeatureService {
  constructor(private http: HttpClient) {}

  public getAll = <Params = {}>(teamName: string) => this.http.get<Feature<Params>[]>(`${ConfigService.apiUrl}/features/${teamName}`);

  public getAllAsync = async <Params = {}>(teamName: string) => lastValueFrom(this.getAll<Params>(teamName));

  public getAvailable = <Params = {}>(teamName: string) =>
    this.http.get<AvailableFeature<Params>[]>(`${ConfigService.apiUrl}/features/available/${teamName}`);

  public getAvailableAsync = async <Params = {}>(teamName: string) => lastValueFrom(this.getAvailable<Params>(teamName));

  public reflect = (teamName: string, path: string) =>
    this.http.get<Meta>(`${ConfigService.apiUrl}/feature/reflect/${teamName}/${encodeURIComponent(path)}`);

  public reflectAsync = async (teamName: string, path: string) => lastValueFrom(this.reflect(teamName, path));

  public add = (teamName: string, config: { [key: string]: any }) =>
    this.http.post(`${ConfigService.apiUrl}/feature/add/${teamName}`, config);

  public addAsync = async (teamName: string, config: { [key: string]: any }) => lastValueFrom(this.add(teamName, config));

  public delete = (teamName: string, name: string) => this.http.delete(`${ConfigService.apiUrl}/feature/delete/${teamName}/${name}`);

  public deleteAsync = async (teamName: string, name: string) => lastValueFrom(this.delete(teamName, name));

  public restore = (teamName: string, name: string) => this.http.get(`${ConfigService.apiUrl}/feature/restore/${teamName}/${name}`);

  public restoreAsync = async (teamName: string, name: string) => lastValueFrom(this.restore(teamName, name));

  public rename = (teamName: string, originalName: string, newName: string) =>
    this.http.get(`${ConfigService.apiUrl}/feature/rename/${teamName}/${originalName}/${newName}`);

  public renameAsync = async (teamName: string, originalName: string, newName: string) =>
    lastValueFrom(this.rename(teamName, originalName, newName));

  public regroup = (teamName: string, featureName: string, groupName: string) =>
    this.http.get(`${ConfigService.apiUrl}/feature/regroup/${teamName}/${featureName}/${groupName}`);

  public regroupAsync = (teamName: string, featureName: string, groupName: string) =>
    lastValueFrom(this.regroup(teamName, featureName, groupName));

  public demo = (teamName: string, featurePath: string, project: string, index: number, config: { [key: string]: any }) =>
    this.http.post<any[]>(`${ConfigService.apiUrl}/feature/demo/${teamName}`, {
      featurePath,
      project,
      index,
      config
    });

  public demoAsync = async (teamName: string, featurePath: string, project: string, index: number, config: { [key: string]: any }) =>
    lastValueFrom(this.demo(teamName, featurePath, project, index, config));
}
