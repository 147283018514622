// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iframe-container {
  padding-left: 0px;
  margin-top: -4px;
}

iframe {
  border: none;
  position: relative;
  top: 4px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/strategy-grid/stream-rest-widget/stream-rest-widget.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,gBAAA;AACF;;AAEA;EACE,YAAA;EACA,kBAAA;EACA,QAAA;AACF","sourcesContent":[".iframe-container {\n  padding-left: 0px;\n  margin-top: -4px;\n}\n\niframe {\n  border: none;\n  position: relative;\n  top: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
