import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { Subject, Observable, lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';

export type TeamConfig = {
  orchestrator: { [key: string]: any };
  path: { [key: string]: string };
  services: string[];
};

@Injectable({ providedIn: 'root' })
export class TeamsService {
  private _save$: Subject<any> = new Subject();
  private _delete$: Subject<any> = new Subject();

  public list: any[] = [];
  private _collection$: Subject<any> = new Subject();
  get collection$() {
    return this._collection$.asObservable();
  }

  constructor(private http: HttpClient) {
    window.$teams = this;
  }

  public load(): Observable<any> {
    this.http.get(`${ConfigService.apiUrl}/team`).subscribe((response: any) => {
      const teams = response;
      this._collection$.next(teams);
      this.list = teams;
    });
    return this._collection$.asObservable();
  }

  public save(team: any): Observable<any> {
    return this.http.post(`${ConfigService.apiUrl}/team`, team);
  }

  public delete(teamName: string): Observable<any> {
    this.http.delete(`${ConfigService.apiUrl}/team/${teamName}`).subscribe(
      () => {
        this._delete$.next(true);
        this.load();
      },
      (error: any) => console.error(error)
    );
    return this._delete$.asObservable();
  }

  public async getConfigAsync(teamName: string) {
    return lastValueFrom(this.http.get<TeamConfig>(`${ConfigService.apiUrl}/team/config/${teamName}`));
  }
}
