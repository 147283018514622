export const COL_BASE = -534;
export const ROW_BASE = -285;
export const ROW_SPACE = 221;
export const MARGIN_Y = 16;
export const MARGIN_X = 30;

export const NODE_WIDTH = 124;
export const NODE_HEIGHT = 91;
export const TERMINAL_HEIGHT = 56;

export const ARROW_LENGTH = 63;
export const ARROW_WIDTH = 12;
export const ARROW_HEIGHT = MARGIN_Y - 1;

export const PARALELLOGRAM_SLANT = 12;

export const FAIL_VERTICAL = 42;

export const DIAMOND_POINTS = [
  [0, NODE_HEIGHT / 2],
  [NODE_WIDTH / 2, 0],
  [NODE_WIDTH, NODE_HEIGHT / 2],
  [NODE_WIDTH / 2, NODE_HEIGHT]
]
  .map((pt) => pt.join(','))
  .join(' ');

export const PARA_POINTS = [
  [0, NODE_HEIGHT],
  [PARALELLOGRAM_SLANT, 0],
  [NODE_WIDTH, 0],
  [NODE_WIDTH - PARALELLOGRAM_SLANT, NODE_HEIGHT]
]
  .map((pt) => pt.join(','))
  .join(' ');

export const ARROW_POINTS = [
  [-ARROW_HEIGHT, 0],
  [0, ARROW_WIDTH / 2 - 0.5],
  [0, ARROW_WIDTH / 2 + 0.5],
  [-ARROW_HEIGHT, ARROW_WIDTH]
]
  .map((pt) => pt.join(','))
  .join(' ');

export const TRACK_POINTS = (() => {
  const getRowY = (row: number) => ({
    top: ROW_BASE - MARGIN_Y + row * ROW_SPACE,
    bot: ROW_BASE - MARGIN_Y + row * ROW_SPACE + NODE_HEIGHT + 2 * MARGIN_Y
  });
  const getColEnd = (nodes: number) => ARROW_LENGTH * (nodes - 1) + NODE_WIDTH * nodes + MARGIN_X + COL_BASE;
  const [row1, row2, row3] = [0, 1, 2].map(getRowY);
  const absoluteLeft = COL_BASE - MARGIN_X;
  const [colEnd1, colEnd4, colEnd5] = [1, 4, 5].map(getColEnd);

  return [
    [colEnd1, row1.top],
    [colEnd5, row1.top],
    [colEnd5, row2.bot],
    [colEnd1 - MARGIN_X / 2, row2.bot],
    [colEnd1 - MARGIN_X / 2, row3.top],
    [colEnd5, row3.top],
    [colEnd5, row3.bot],
    [absoluteLeft, row3.bot],
    [absoluteLeft, row2.top],
    [colEnd4 + MARGIN_X / 2, row2.top],
    [colEnd4 + MARGIN_X / 2, row1.bot],
    [colEnd1, row1.bot]
  ];
})()
  .map((pt) => pt.join(','))
  .join(' ');
