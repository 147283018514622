import { KeycloakService } from 'keycloak-angular';
import { environment } from '../../environments/environment';
import { ConfigService } from '../services/config.service';
import { InitService } from '../services/init.service';

export function initializeKeycloak(keycloak: KeycloakService, initService: InitService) {
  return () =>
    initService.getAsync().then((results: any) => {
      ConfigService.initialized = results.initialized;
      if (!ConfigService.initialized) {
        return Promise.resolve(true);
      } else {
        let realm = ConfigService.keycloakRealm;
        if (ConfigService.saasMode) {
          realm =
            window.location.hostname === 'localhost' || window.location.hostname.split('.')[0] === 'demo'
              ? ConfigService.keycloakRealm
              : window.location.hostname.split('.')[0].toUpperCase();
        }

        return keycloak.init({
          config: {
            url: environment.keycloakUrl,
            realm: realm,
            clientId: environment.keycloakClientId,
          },
          initOptions: {
            onLoad: 'check-sso',
            silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
            refreshToken: 'true'
          },
          loadUserProfileAtStartUp: true,
          enableBearerInterceptor: true,
          bearerPrefix: 'Bearer'

        });
      }
    });
}
