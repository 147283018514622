import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InitService {
  constructor(private http: HttpClient) {}

  public async getAsync(): Promise<any> {
    return lastValueFrom(this.http.get(`${ConfigService.apiUrl}/init`));
  }

  public async getNetworkInterfacesAsync() {
    return lastValueFrom(this.http.get(`${ConfigService.apiUrl}/init/nwi`));
  }

  public async getLicenseAsync() {
    return lastValueFrom(this.http.get(`${ConfigService.apiUrl}/init/license`));
  }

  public async getMachineIdAsync() {
    return lastValueFrom(this.http.get(`${ConfigService.apiUrl}/init/machineid`));
  }

  public async post(url: string) {
    return lastValueFrom(this.http.post(`${ConfigService.apiUrl}/init`,{url}));
  }

  public ping() {
    return (this.http.get(`${ConfigService.apiUrl}/version`));
  }
}
