import { AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { HighlightCallback } from 'prismjs';
import { HighlightService, SupportedPrismLanguage } from 'src/app/services/highlight.service';

const AutoTabs: [number, string[]][] = [
  [2, ['javascript', 'js', 'typescript', 'ts']],
  [4, ['python', 'py']]
];

@Component({
  selector: 'app-code-view',
  templateUrl: './code-view.component.html',
  styleUrls: ['./code-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CodeViewComponent implements AfterViewInit, OnChanges {
  @ViewChild('el') el: ElementRef<HTMLElement>;

  @Input() async: boolean = false;
  @Input() callback?: HighlightCallback;

  @Input() code: string;
  @Input() language: SupportedPrismLanguage;

  @Input() title?: string;
  @Input() lineNumbers?: boolean | '' | undefined;
  @Input() tabSize?: number;
  @Input() fontSize?: number;
  public overrideStyles: { [key: string]: string | number };

  private getTabSize(): number | null {
    if (this.tabSize !== undefined) return this.tabSize;
    for (const [tabSize, languages] of AutoTabs) if (languages.includes(this.language)) return tabSize;
    return null;
  }

  private getFontSize(): string | null {
    return this.fontSize ? `${this.fontSize}px` : null;
  }

  constructor(private highlightService: HighlightService) {}

  ngAfterViewInit(): void {
    if (this.code) this.render();
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.render();
  }

  render() {
    this.overrideStyles = {
      tabSize: this.getTabSize(),
      fontSize: this.getFontSize()
    };
    this.highlightService.highlight(this.el, { code: this.code, async: this.async, callback: this.callback });
  }
}
