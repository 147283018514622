// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  height: 20px;
  z-index: 1000;
  display: flex;
  gap: 20px;
  padding: 2px;
  flex-shrink: 0;
  font-size: 13.33333px;
  margin-bottom: 10px;
}
:host.code {
  margin-bottom: unset;
}

button.close,
button.maximize,
button.restore {
  position: absolute;
  top: 2px;
  width: 18px;
  height: 18px;
  padding: 0;
  font-size: 9px;
  opacity: 0.7;
  z-index: 2000;
  margin: unset !important;
  border: none;
  background: none;
}

button.close {
  right: 2px;
}

button.restore {
  right: 22px;
}

button.maximize {
  right: 22px;
}

button mat-icon {
  font-size: 14px;
  line-height: 14px;
  height: 14px;
  width: 14px;
}

.title {
  font-weight: bold;
  margin-left: 5px;
  margin-top: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/strategy-grid/widget-titlebar/widget-titlebar.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,aAAA;EACA,SAAA;EACA,YAAA;EACA,cAAA;EAEA,qBAAA;EACA,mBAAA;AAAF;AACE;EACE,oBAAA;AACJ;;AAKA;;;EAGE,kBAAA;EACA,QAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;EACA,cAAA;EACA,YAAA;EACA,aAAA;EACA,wBAAA;EACA,YAAA;EACA,gBAAA;AAFF;;AAKA;EACE,UAAA;AAFF;;AAKA;EACE,WAAA;AAFF;;AAKA;EACE,WAAA;AAFF;;AAKA;EACE,eAAA;EACA,iBAAA;EACA,YAAA;EACA,WAAA;AAFF;;AAKA;EACE,iBAAA;EACA,gBAAA;EACA,eAAA;AAFF","sourcesContent":[":host {\n  height: 20px;\n  z-index: 1000;\n  display: flex;\n  gap: 20px;\n  padding: 2px;\n  flex-shrink: 0;\n\n  font-size: 13.33333px;\n  margin-bottom:10px;\n  &.code {\n    margin-bottom: unset;\n  }\n\n\n}\n\nbutton.close,\nbutton.maximize,\nbutton.restore {\n  position: absolute;\n  top: 2px;\n  width: 18px;\n  height: 18px;\n  padding: 0;\n  font-size: 9px;\n  opacity: 0.7;\n  z-index: 2000;\n  margin: unset !important;\n  border: none;\n  background: none;\n}\n\nbutton.close {\n  right: 2px;\n}\n\nbutton.restore {\n  right: 22px;\n}\n\nbutton.maximize {\n  right: 22px;\n}\n\nbutton mat-icon {\n  font-size: 14px;\n  line-height: 14px;\n  height: 14px;\n  width: 14px;\n}\n\n.title {\n  font-weight: bold;\n  margin-left:5px;\n  margin-top: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
