import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import {
  SciChartSurface,
  NumericAxis,
  FastCandlestickRenderableSeries,
  OhlcDataSeries,
  NumberRange,
  ZoomExtentsModifier,
  ZoomPanModifier,
  MouseWheelZoomModifier,
  CursorModifier,
  SeriesInfo,
  CursorTooltipSvgAnnotation,
  EDataSeriesType,
  OhlcSeriesInfo,
  FastColumnRenderableSeries,
  XyDataSeries,
  EAutoRange
} from 'scichart';
import { SettingsService } from 'src/app/services/settings.service';
import { SubscriptionContainer } from 'src/app/models/subscription-container';
import { VolumePaletteProvider } from '../explore-candlestick-container/explore-candlestick-container.component';

@Component({
  selector: 'app-insights-cs-chart',
  templateUrl: './insights-cs-chart.component.html',
  styleUrls: ['./insights-cs-chart.component.scss']
})
export class InsightsCsChartComponent implements OnInit {
  @Input() public id: string;
  @Input() public title?: string | string[];
  @Input() public xValues: number[] = [];
  @Input() public openValues: number[] = [];
  @Input() public highValues: number[] = [];
  @Input() public lowValues: number[] = [];
  @Input() public closeValues: number[] = [];
  @Input() public volumeValues: number[] = [];

  public chart: SciChartSurface | undefined = undefined;
  private _subs = new SubscriptionContainer();

  constructor(private _settingsService: SettingsService) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (!this.chart) return;

    const xValues = 'xValues' in changes ? changes.xValues.currentValue : undefined;
    const openValues = 'openValues' in changes ? changes.openValues.currentValue : undefined;
    const highValues = 'highValues' in changes ? changes.highValues.currentValue : undefined;
    const lowValues = 'lowValues' in changes ? changes.lowValues.currentValue : undefined;
    const closeValues = 'closeValues' in changes ? changes.closeValues.currentValue : undefined;
    const volumeValues = 'volumeValues' in changes ? changes.volumeValues.currentValue : undefined;

    if (xValues || openValues || highValues || lowValues || closeValues || volumeValues) {
      if (xValues) this.xValues = xValues;
      if (openValues) this.openValues = openValues;
      if (highValues) this.highValues = highValues;
      if (lowValues) this.lowValues = lowValues;
      if (closeValues) this.closeValues = closeValues;
      if (volumeValues) this.volumeValues = volumeValues;

      this.init();
    }
  }

  ngOnInit(): void {
    this.init();

    this._subs.add = this._settingsService.theme$.subscribe(this.$changeTheme.bind(this));
    this._subs.add = this._settingsService.chartTheme$.subscribe(this.$changeTheme.bind(this));
  }

  init() {
    setTimeout(async () => {
      // LICENSING
      // Commercial licenses set your license code here
      // Purchased license keys can be viewed at https://www.scichart.com/profile
      // How-to steps at https://www.scichart.com/licensing-scichart-js/
      // SciChartSurface.setRuntimeLicenseKey("YOUR_RUNTIME_KEY");
      //SciChartSurface.UseCommunityLicense();

      // Set this code in application startup, before creating a SciChartSurface
      //SciChart is initialized at app start: src/app/init/scichart-init.factory.ts

      const theme = this._settingsService.getChartThemeProvider();
      const { sciChartSurface: chart, wasmContext: wasm } = await SciChartSurface.create(this.id, {
        theme,
        title: this.title,
        disableAspect: true
      });
      Object.assign(this, { chart });

      chart.xAxes.add(new NumericAxis(wasm, { growBy: new NumberRange(0.1, 0.1), labelPrecision: 0 }));
      chart.yAxes.add(new NumericAxis(wasm, { labelPrefix: '$', labelPrecision: 2, growBy: new NumberRange(0.1, 0.1) }));

      chart.yAxes.add(
        new NumericAxis(wasm, {
          id: 'volume',
          growBy: new NumberRange(0, 4),
          isVisible: false,
          autoRange: EAutoRange.Always,
          labelPrecision: 0
        })
      );
      // Data format is { dateValues[], openValues[], highValues[], lowValues[], closeValues[] }

      if (!Array.isArray(this.xValues) || !Array.isArray(this.openValues) || this.openValues[0] === undefined) {
        return;
      }

      // Create a OhlcDataSeries with open, high, low, close values
      const dataSeries = new OhlcDataSeries(wasm, {
        xValues: this.xValues,
        openValues: this.openValues,
        highValues: this.highValues,
        lowValues: this.lowValues,
        closeValues: this.closeValues
      });

      // Create and add the Candlestick series
      const candlestickSeries = new FastCandlestickRenderableSeries(wasm, {
        strokeThickness: 1,
        dataSeries,
        dataPointWidth: 0.7,
        brushUp: '#33ff3377',
        brushDown: '#ff333377',
        strokeUp: '#77ff77',
        strokeDown: '#ff7777'
      });
      chart.renderableSeries.add(candlestickSeries);

      // Add volume data onto the chart
      chart.renderableSeries.add(
        new FastColumnRenderableSeries(wasm, {
          dataSeries: new XyDataSeries(wasm, { xValues: this.xValues, yValues: this.volumeValues, dataSeriesName: 'Volume' }),
          strokeThickness: 0,
          // This is how we get volume to scale - on a hidden YAxis
          yAxisId: 'volume',
          // This is how we colour volume bars red or green
          paletteProvider: new VolumePaletteProvider(dataSeries, '#77ff77', '#ff7777')
        })
      );

      chart.chartModifiers.add(
        new ZoomExtentsModifier(),
        new ZoomPanModifier(),
        new MouseWheelZoomModifier(),
        new CursorModifier({
          crosshairStroke: '#FF7518',
          axisLabelFill: '#FF7518',
          tooltipLegendTemplate: this.getTooltipLegendTemplate.bind(this)
        })
      );
    }, 1000);
  }
  getTooltipLegendTemplate = (seriesInfos: SeriesInfo[], svgAnnotation: CursorTooltipSvgAnnotation) => {
    let outputSvgString = '';

    // Foreach series there will be a seriesInfo supplied by SciChart. This contains info about the series under the house
    let i = 0;
    seriesInfos.forEach((seriesInfo, index) => {
      const y = 20 + index * 20;
      const textColor = '#7393B3';

      let legendText = `Volume=${seriesInfo.formattedYValue}`;
      if (seriesInfo.dataSeriesType === EDataSeriesType.Ohlc) {
        const o = seriesInfo as OhlcSeriesInfo;
        legendText = `Open=${o.formattedOpenValue} High=${o.formattedHighValue} Low=${o.formattedLowValue} Close=${o.formattedCloseValue}`;
      }
      outputSvgString += `<text x="8" y="${y}" font-size="14" font-weight="500" font-family="Roboto, 'Helvetica Neue', sans-serif" fill="${textColor}">
            ${legendText}
        </text>`;
    });

    return `<svg width="100%" height="100%">
                ${outputSvgString}
            </svg>`;
  };

  public ngOnDestroy(): void {
    if (this.chart) this.chart.delete();

    this._subs.dispose();
  }

  public $changeTheme(): void {
    if (this.chart) this.chart.applyTheme(this._settingsService.getChartThemeProvider());
  }
}
