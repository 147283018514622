// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}

:host,
.root,
.container {
  height: 100%;
  width: 100%;
}

.container {
  display: flex;
  flex-direction: row;
}

.overview-container {
  flex-grow: 1;
  display: grid;
  grid-template-rows: 80% 20%;
}

#perf-chart-legend {
  font-size: 13.3333px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/models/perf-chart/perf-chart.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAEA;;;EAGE,YAAA;EACA,WAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;EACA,2BAAA;AACF;;AAEA;EACE,oBAAA;AACF","sourcesContent":[":host {\n  display: block;\n}\n\n:host,\n.root,\n.container {\n  height: 100%;\n  width: 100%;\n}\n\n.container {\n  display: flex;\n  flex-direction: row;\n}\n\n.overview-container {\n  flex-grow: 1;\n  display: grid;\n  grid-template-rows: 80% 20%;\n}\n\n#perf-chart-legend {\n  font-size: 13.3333px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
