import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Host } from 'src/app/models/host';
import { HostsService } from 'src/app/services/hosts.service';
import { TrustService } from 'src/app/services/trust.service';
import { IdentityService } from 'src/app/services/identity.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { DataService } from 'src/app/services/data.service';
import { DirectoryService } from 'src/app/services/directory.service';
import { ConfigService } from 'src/app/services/config.service';
import { SocketService } from 'src/app/services/socket.service';
import { SubscriptionContainer } from 'src/app/models/subscription-container';

@Component({
  selector: 'app-host',
  templateUrl: './host.component.html',
  styleUrls: ['./host.component.scss']
})
export class HostComponent implements OnInit, OnDestroy {
  @Input() isWidget: boolean = false;
  @Input() hostName: string;
  @Input() team: string;

  @Output() onTerminalSelect = new EventEmitter();
  @Output() onCloseHost = new EventEmitter();

  public host: Host;
  public isLoading = true;
  public initializing = false;
  public teamName: string;
  public isTrusted = false;
  public availableAdapters: any = [];
  public paths: string[] = [];
  public output: string = '';

  private _subs = new SubscriptionContainer();

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private hostsService: HostsService,
    private trustService: TrustService,
    private identity: IdentityService,
    private _socketService: SocketService,
    public snackbar: MatSnackBar
  ) {}

  public hostForm = new FormGroup({
    name: new FormControl<any>(null),
    ipAddress: new FormControl<any>(null),
    port: new FormControl<any>(null),
    value: new FormControl<any>(null, Validators.required),
    python: new FormControl<any>(null)
  });

  ngOnInit(): void {
    (<any>window).host = this;

    if (this.isWidget) {
      this.load(this.team, this.hostName);
    } else {
      this.activatedRoute.params.subscribe((params) => {
        const teamName = params.team;
        const hostName = params.hostname;
        this.load(teamName, hostName);
      });
    }
  }

  subscribeToFormChanges() {
    this.hostForm.setValue({
      name: this.host.name,
      ipAddress: this.host.ipAddress,
      port: this.host.port,
      value: this.host.value,
      python: this.host.python
    });

    this.hostForm.valueChanges
      .pipe(distinctUntilChanged())
      .pipe(debounceTime(2000))
      .subscribe((value: any) => {
        if (this.hostForm.valid) {
          const raw = this.hostForm.getRawValue();

          this.host.name = raw.name;
          this.host.value = +raw.value;
          this.host.python = raw.python;

          this.update();
        }
      });
  }

  async load(teamName: string, hostName: string) {
    this.subscribeToSocketEvents();
    const response: Host = await this.hostsService.getAsync(teamName, hostName);
    if (response.zone === undefined) {
      response.zone = null;
    }
    if (response.raw === undefined) {
      response.raw = null;
    }
    if (response.python === undefined) {
      response.python = null;
    }
    this.teamName = teamName;
    this.host = response;
    this.isTrusted = this.host.isTrusted;

    this.isLoading = false;
    setTimeout(() => {
      this.subscribeToFormChanges();
    }, 500);
  }

  get locations() {
    return this.hostsService.locations;
  }

  save() {
    if (!this.host.id) {
      this.add();
      return;
    }

    this.update();
  }

  back() {
    this.onCloseHost.emit();
  }

  async add() {
    this.isLoading = true;
    await this.hostsService.addAsync(this.teamName, this.host);

    if (this.isWidget) {
      this.back();
    } else {
      this.router.navigate([this.teamName, 'hosts', this.host.name]);
    }
  }

  async update() {
    try {
      await this.hostsService.updateAsync(this.teamName, this.host);
      this.openSnackBar('Host saved successfully.', 'OK');
    } catch {}
  }

  openSnackBar(message: string, action: string) {
    const durationInSeconds = 1;
    const horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    const verticalPosition: MatSnackBarVerticalPosition = 'top';

    this.snackbar.open(message, action, {
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition,
      duration: durationInSeconds * 1000
    });
  }

  keyPressNumbers(event) {
    const hostValue = this.host.value === undefined ? '' : this.host.value;
    const test = `${hostValue}${event.key}`;
    if (isNaN(test as any)) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  public async toggleTrust(event: MatSlideToggleChange) {
    this.isTrusted = event.checked;

    if (!this.isTrusted) {
      const sub = this.trustService.delete(this.teamName, this.host.name).subscribe({
        error: (error) => {
          this.isTrusted = true;
          sub.unsubscribe();
        },
        complete: () => {
          sub.unsubscribe();
          this.isTrusted = false;
        }
      });

      return;
    }

    try {
      this.initializing = true;
      const results = await this.trustService.promptUserForCreds(this.teamName, this.host.name, true);
      if (results.canceled) {
        this.initializing = false;
        this.isTrusted = false;
        return;
      }
      this.isTrusted = results.isTrusted;
    } catch (error) {
      this.isTrusted = false;
      console.error(error);
      this.openSnackBar('Error creating trust.', 'OK');
    }
  }

  subscribeToSocketEvents() {
    this._subs.add = this._socketService.initPipEvents.subscribe((msg: any) => {
      this.output = msg.msg;
      if (msg.status === 'COMPLETE') {
        this.initializing = false;
        this.output = '';
      }
    });
  }

  ssh() {
    if (this.isWidget) {
      this.onTerminalSelect.emit();
    } else {
      this.router.navigate([this.teamName, 'terminal']);
    }
  }

  get saasMode() {
    return ConfigService.saasMode;
  }

  ngOnDestroy(): void {
    this._subs.dispose();
  }
}
