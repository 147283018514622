import { Component, Input, ViewChild } from '@angular/core';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { ExperimentsService } from 'src/app/services/experiments.service';
import { isNil } from 'lodash';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

export type Shape = (number | nil)[];

@Component({
  selector: 'app-compiler-widget',
  templateUrl: './compiler-widget.component.html',
  styleUrls: ['../base-widget/base-widget.component.scss', './compiler-widget.component.scss']
})
export class CompilerWidgetComponent extends BaseWidgetComponent {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input() rdsName: string;

  editorOptions = {
    theme: 'vs-dark',
    language: 'json',
    automaticLayout: true,
    scrollBeyondLastLine: false,
    horizontal: 'visible',
    readOnly: true,
    minimap: {
      enabled: false
    }
  };
  displayedColumns: string[] = ['index', 'name', 'class', 'shape', 'params'];
  dataSource = new MatTableDataSource();
  modelSummary: any = {};
  compileOutput: string = '{}';
  compiling: boolean = true;
  isTrusted = false;

  constructor(private _experimentsService: ExperimentsService) {
    super();
    (<any>window).compilerWidget = this;
  }

  private _shapeToString = (shape?: Shape) =>
    isNil(shape) ? '' : '(' + shape.map((v, i) => (v === null && i == 0 ? 'None' : (v ?? NaN).toString())).join(', ') + ')';

  private _combineShapes = (shapes: Shape[]) =>
    shapes.reduce((agg, shape) => (agg.length === 0 ? [...shape] : [...agg.slice(0, -1), agg[agg.length - 1] + shape[agg.length - 1]]), []);

  private _shapesToString = (shape?: Shape | Shape[]) =>
    isNil(shape)
      ? ''
      : shape[0] instanceof Array
        ? this._shapeToString(this._combineShapes(shape as Shape[]))
        : this._shapeToString(shape as Shape);

  private _coerceRawLayer = (layer: any, index: number) => ({
    index,
    class: layer.class_name,
    name: layer.name,
    shape: this._shapesToString(layer.config?.batch_shape ?? layer.build_config?.input_shape),
    params: layer.params
  });

  private _coerceRawSummary = (summary: any) => summary.config.layers.map(this._coerceRawLayer.bind(this));

  async ngOnInit(): Promise<void> {
    const sub = this._experimentsService.compile(this.teamName, this.rdsName, 'json').subscribe((results: any) => {
      sub.unsubscribe();
      results = results.replace(/[^\S\r\n]+$/gm, '');
      results = results.replace(/^\s*[\r\n]/gm, '');

      this.modelSummary = JSON.parse(results);
      this.compileOutput = JSON.stringify(this.modelSummary, undefined, 2);
      this.dataSource.data = this._coerceRawSummary(this.modelSummary);

      setTimeout(() => {
        this.dataSource.paginator = this.paginator;
      }, 0);

      this.compiling = false;
    });
  }
}
