import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';

@Component({
  selector: 'app-packet-widget',
  templateUrl: './packet-widget.component.html',
  styleUrls: ['../base-widget/base-widget.component.scss', './packet-widget.component.scss']
})
export class PacketWidgetComponent extends BaseWidgetComponent implements OnInit, OnDestroy {
  @Input() packetContent: any = null;
  @Input() title: string = null;

  public editorOptions = {
    theme: 'vs-dark',
    language: 'json',
    automaticLayout: true,
    scrollBeyondLastLine: false,
    minimap: {
      enabled: false
    }
  };

  constructor() {
    super();
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
