import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

export namespace ChatService {
  export type ChatResponse = {
    output?: string;
    steps?: string[];
    error?: string;
    model?: string;
  };

  export type HistoryResponse = ChatMessage[];

  export type ClearResponse =
    | {
        success: true;
        error: undefined;
      }
    | {
        success: false;
        error: string;
      };

  export type ModelsResponse = {
    revisors: AvailableModel[];
    hasAllModels: boolean;
  };

  export type AvailableModel = {
    model: string;
    name: string;
    desc: string;
  };

  export type ChatMessage = {
    isSelf: boolean;
    content: string;
    isError?: boolean;
  };
}

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  constructor(private http: HttpClient) {}

  public chat = (team: string, username: string, msg: string, project: string, model?: string) =>
    this.http.post<ChatService.ChatResponse>(`${ConfigService.chatUrl}/${team}/${username}`, { msg, project, model });

  public chatAsync = async (team: string, username: string, msg: string, project: string, model?: string) =>
    lastValueFrom(this.chat(team, username, msg, project, model));

  public history = (team: string, username: string) =>
    this.http.get<ChatService.HistoryResponse>(`${ConfigService.chatUrl}/${team}/${username}`);

  public historyAsync = async (team: string, username: string) => lastValueFrom(this.history(team, username));

  public clear = (team: string, username: string) =>
    this.http.delete<ChatService.ClearResponse>(`${ConfigService.chatUrl}/${team}/${username}`);

  public clearAsync = async (team: string, username: string) => lastValueFrom(this.clear(team, username));

  public models = () => this.http.get<ChatService.ModelsResponse>(`${ConfigService.chatUrl}/models`);

  public modelsAsync = async () => lastValueFrom(this.models());
}
