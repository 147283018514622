import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import {
  NumberRange,
  SciChartSurface,
  TSciChart,
  MouseWheelZoomModifier,
  ZoomPanModifier,
  ZoomExtentsModifier,
  XAxisDragModifier,
  YAxisDragModifier,
  RubberBandXyZoomModifier,
  CursorModifier,
  DataPointSelectionModifier,
  NumericAxis,
  FastLineRenderableSeries,
  IRenderableSeries,
  SweepAnimation,
  XyDataSeries,
  DateTimeNumericAxis,
  DataPointSelectionPaletteProvider,
  EllipsePointMarker,
  VerticalLineAnnotation,
  ELabelPlacement,
  SmartDateLabelProvider,
  TAxisTitleStyle
} from 'scichart';
import { SubscriptionContainer } from 'src/app/models/subscription-container';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-insights-line-chart',
  templateUrl: './insights-line-chart.component.html',
  styleUrls: ['./insights-line-chart.component.scss']
})
export class InsightsLineChartComponent implements OnInit, OnDestroy, OnChanges {
  @Output() load: EventEmitter<void> = new EventEmitter();
  @Output() dataPointClick: EventEmitter<any> = new EventEmitter();
  @Input() public id: string;
  @Input() public title?: string | string[];
  @Input() public xValues: number[] = [];
  @Input() public yValues: number[] = [];
  @Input() public home: [NumberRange, NumberRange] | undefined;
  @Input() public animationDuration: number = 300;

  private _subs = new SubscriptionContainer();
  public chart: SciChartSurface | undefined = undefined;
  public wasm: TSciChart | undefined = undefined;
  public modifiers = {
    mouseWheelZoom: new MouseWheelZoomModifier(),
    zoomPan: new ZoomPanModifier(),
    zoomExtents: new ZoomExtentsModifier(),
    xAxisDrag: new XAxisDragModifier(),
    yAxisDrag: new YAxisDragModifier(),
    rubberBandXyZoom: new RubberBandXyZoomModifier(),
    cursor: new CursorModifier(),
    dataPoint: new DataPointSelectionModifier()
  };
  public xAxis: NumericAxis;
  public yAxis: NumericAxis;

  public get annotations() {
    return this.chart.annotations;
  }

  public get mouseMode() {
    if (this.modifiers.rubberBandXyZoom.isEnabled) return 'rubberband';
    return 'pan';
  }

  public get hasCursor() {
    return this.modifiers.cursor.isEnabled;
  }

  constructor(private _settingsService: SettingsService) {}

  async ngOnInit(): Promise<void> {
    setTimeout(async () => {
      // LICENSING
      // Commercial licenses set your license code here
      // Purchased license keys can be viewed at https://www.scichart.com/profile
      // How-to steps at https://www.scichart.com/licensing-scichart-js/
      // SciChartSurface.setRuntimeLicenseKey("YOUR_RUNTIME_KEY");
      //SciChartSurface.UseCommunityLicense();

      // Set this code in application startup, before creating a SciChartSurface
      //SciChart is initialized at app start: src/app/init/scichart-init.factory.ts

      // Initialize SciChartSurface. Don't forget to await!
      const theme = this._settingsService.getChartThemeProvider();
      const { sciChartSurface: chart, wasmContext: wasm } = await SciChartSurface.create(this.id, {
        theme,
        title: this.title,
        titleStyle: { fontSize: 22 },
        disableAspect: true
      });
      Object.assign(this, { chart, wasm }); // hacky?

      const dataPointSelectionModifier = this.modifiers.dataPoint;
      dataPointSelectionModifier.selectionChanged.subscribe((args) => {
        if (args.selectedDataPoints.length !== 1) return;

        const index = this.xValues.findIndex((value) => value === args.selectedDataPoints[0].xValue);
        this.setTimeStampAnnotation(args.selectedDataPoints[0].xValue, new Date(args.selectedDataPoints[0].xValue * 1000).toUTCString());
        this.dataPointClick.emit(index);
      });

      // Create an XAxis and YAxis with growBy padding
      const axisTitleStyle: TAxisTitleStyle = { fontSize: 20, fontFamily: 'Roboto' };
      const growBy = new NumberRange(0.1, 0.1);
      this.xAxis = new DateTimeNumericAxis(this.wasm, {
        axisTitle: '',
        axisTitleStyle,
        growBy,
        labelProvider: new SmartDateLabelProvider()
      });
      this.yAxis = new NumericAxis(this.wasm, { axisTitle: '', axisTitleStyle, growBy, labelPrecision: 2 });
      this.chart.xAxes.add(this.xAxis);
      this.chart.yAxes.add(this.yAxis);

      // Create a line series with some initial data
      this.chart.renderableSeries.add(this.getMainLine());

      // Add some interaction modifiers to show zooming and panning
      this.chart.chartModifiers.add(...Object.values(this.modifiers));

      this.modifiers.rubberBandXyZoom.isEnabled = false;
      this.modifiers.cursor.isEnabled = true;
      this.modifiers.dataPoint.isEnabled = true;
      this.modifiers.dataPoint.allowDragSelect = false;

      this._subs.add = this._settingsService.theme$.subscribe(this.$changeTheme.bind(this));
      this._subs.add = this._settingsService.chartTheme$.subscribe(this.$changeTheme.bind(this));

      this.load.emit();
    }, 500);
  }

  public ngOnDestroy(): void {
    if (this.chart) this.chart.delete();
    this._subs.dispose();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!this.chart) return;

    const xValues = 'xValues' in changes ? changes.xValues.currentValue : undefined;
    const yValues = 'yValues' in changes ? changes.yValues.currentValue : undefined;
    const home = 'home' in changes ? changes.home.currentValue : undefined;

    if (xValues || yValues) {
      if (xValues) this.xValues = xValues;
      if (yValues) this.yValues = yValues;
      this.chart.renderableSeries.clear();
      this.chart.renderableSeries.add(this.getMainLine());
      this.chart.zoomExtents();
    }

    if (home) this.homeView(false);
  }

  public getMainLine(): IRenderableSeries {
    return new FastLineRenderableSeries(this.wasm, {
      stroke: '#2196F3',
      strokeThickness: 3,
      pointMarker: new EllipsePointMarker(this.wasm, {
        width: 8,
        height: 8,
        strokeThickness: 2,
        stroke: 'SteelBlue',
        fill: 'LightSteelBlue'
      }),
      dataSeries: new XyDataSeries(this.wasm, {
        xValues: this.xValues,
        yValues: this.yValues,
        containsNaN: false,
        isSorted: true
      }),
      animation: new SweepAnimation({ duration: this.animationDuration, fadeEffect: true }),
      paletteProvider: new DataPointSelectionPaletteProvider({ fill: 'white', stroke: 'white' })
    });
  }

  public removeAnnotation(id: string) {
    const oldIndex = this.chart.annotations.getById(id);
    this.chart.annotations.remove(oldIndex);
  }

  public setTimeStampAnnotation(index: number, timestamp: string) {
    this.removeAnnotation('timestamp');
    this.chart.annotations.add(
      new VerticalLineAnnotation({
        id: 'timestamp',
        stroke: 'SteelBlue',
        axisLabelFill: 'SteelBlue',
        strokeThickness: 3,
        axisFontSize: 20,
        x1: index,
        labelValue: timestamp,
        showLabel: true,
        labelPlacement: ELabelPlacement.Axis
      })
    );
  }

  public setIndexAnnotation(index?: number) {
    this.removeAnnotation('index');
    this.chart.annotations.add(
      new VerticalLineAnnotation({
        id: 'index',
        stroke: '#775DD0',
        strokeThickness: 3,
        x1: index,
        labelValue: 'Index',
        showLabel: true,
        labelPlacement: ELabelPlacement.TopRight
      })
    );
  }

  public maximizeView(): void {
    this.chart.zoomExtents(this.animationDuration);
  }

  public homeView(animate: boolean = true): void {
    console.log('homeView', this.home);
    if (!this.home) return;
    const [xHome, yHome] = this.home;
    const duration = animate ? this.animationDuration : 0;

    this.xAxis.animateVisibleRange(xHome, duration);
    this.yAxis.animateVisibleRange(yHome, duration);
  }

  public $changeTheme(): void {
    if (this.chart) this.chart.applyTheme(this._settingsService.getChartThemeProvider());
  }

  public $clickMaximize(): void {
    this.maximizeView();
  }

  public $clickHome(): void {
    this.homeView();
  }

  public $clickToggleRubberband(): void {
    this.modifiers.rubberBandXyZoom.isEnabled = !this.modifiers.rubberBandXyZoom.isEnabled;
    this.modifiers.zoomPan.isEnabled = !this.modifiers.rubberBandXyZoom.isEnabled;
  }

  public $clickToggleCursor(): void {
    this.modifiers.cursor.isEnabled = !this.modifiers.cursor.isEnabled;
  }
}
