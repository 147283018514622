// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ng-terminal {
  overflow: hidden;
  background: black;
}

.xterm-viewport {
  overflow: auto !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/terminal/terminal.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,iBAAA;AACF;;AAEA;EACE,yBAAA;AACF","sourcesContent":["ng-terminal {\n  overflow: hidden;\n  background: black;\n}\n\n.xterm-viewport {\n  overflow: auto !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
