import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { ARROW_WIDTH, ARROW_POINTS, FAIL_VERTICAL, NODE_HEIGHT, NODE_WIDTH, PARALELLOGRAM_SLANT } from './utils';
import { SerializedPoint } from 'src/app/models/point';
import { ProjectBoardNode, ProjectBoardNodeShape } from './project-board-node.component';

export type Edge = 'left' | 'top' | 'right' | 'bottom';

export type ProjectBoardArrowNode = {
  node: ProjectBoardNode;
  edge: Edge;
};

export type ProjectBoardArrowConfig = Partial<{
  dashed: boolean;
  text: string;
  /** The port offset, used for Terminal and IO nodes. */
  portOffset: SerializedPoint;
  /** The line alignment offset, used for multiple connections */
  lineOffset: SerializedPoint;
}>;

export class ProjectBoardArrow {
  src: ProjectBoardArrowNode;
  dst: ProjectBoardArrowNode;
  fail: boolean;
  text?: string;
  portOffset: SerializedPoint;
  arrowOffset: SerializedPoint;
  lineOffset: SerializedPoint;

  srcPort: SerializedPoint;
  dstPort: SerializedPoint;
  lineVertical: number;

  constructor(src: ProjectBoardArrowNode, dst: ProjectBoardArrowNode, config: ProjectBoardArrowConfig = undefined) {
    this.src = src;
    this.dst = dst;

    this.fail = config?.dashed ?? false;
    this.text = config?.text;
    this.portOffset = config?.portOffset ?? { x: 0, y: 0 };
    this.arrowOffset = { x: 0, y: 0 };
    this.lineOffset = config?.lineOffset ?? { x: 0, y: 0 };

    this.srcPort = this.src.node.getPort(this.src.edge);
    this.dstPort = this.dst.node.getPort(this.dst.edge);

    this._adjustArrowHead();
    this.lineVertical = this._getArrowLineVertical();
  }

  private _adjustArrowHead(): void {
    if (this.dst.node.shape === ProjectBoardNodeShape.Parallelogram) {
      if (this.dst.edge === 'left') this.arrowOffset.x += PARALELLOGRAM_SLANT / 2;
      if (this.dst.edge === 'right') this.arrowOffset.x -= PARALELLOGRAM_SLANT / 2;
    }
  }

  private _getArrowLineVertical(): number {
    const { y: sy } = this.srcPort;
    const { y: dy } = this.dstPort;

    if (this.src.edge === 'top' || this.src.edge === 'bottom') {
      if (this.src.edge === this.dst.edge) {
        return sy + (this.lineOffset.y + 1) * (this.src.edge === 'top' ? -FAIL_VERTICAL : +FAIL_VERTICAL);
      } else if (this.dst.edge === 'left' || this.dst.edge === 'right') {
        return dy;
      } else {
        return (sy + dy) / 2;
      }
    } else {
      return sy;
    }
  }

  public getCommands(): [string, number[]][] {
    const { x: sx, y: sy } = this.srcPort;
    const { x: dx, y: dy } = this.dstPort;

    const halfHeight = NODE_HEIGHT / 2;
    const halfWidth = NODE_WIDTH / 2;

    const getYToCenter = (edge: Edge) => (edge === 'top' || edge === 'bottom' ? (edge === 'top' ? halfHeight : -halfHeight) : 0);
    const getXToCenter = (edge: Edge) => (edge === 'left' || edge === 'right' ? (edge === 'left' ? halfWidth : -halfWidth) : 0);

    const [syToCenter, dyToCenter] = [getYToCenter(this.src.edge), getYToCenter(this.dst.edge)];
    const [sxToCenter, dxToCenter] = [getXToCenter(this.src.edge), getXToCenter(this.dst.edge)];

    if (this.src.edge === this.dst.edge && this.src.edge !== 'top' && this.src.edge !== 'bottom') {
      // An edge case where the ports are on the same horizontal edge
      return [
        ['M', [sx + sxToCenter, sy + syToCenter]],
        ['H', [sx + (this.src.edge === 'right' ? 42 : -42)]],
        ['V', [dy + dyToCenter]],
        ['H', [dx + dxToCenter]]
      ];
    }

    return [
      ['M', [sx + sxToCenter, sy + syToCenter]],
      ['V', [this.lineVertical]],
      ['H', [dx + dxToCenter]],
      ['V', [dy + dyToCenter]]
    ];
  }

  public getTextConfig(): SerializedPoint & { anchor: 'start' | 'middle' | 'end' } {
    const { x: sx } = this.srcPort;
    const { x: dx } = this.dstPort;
    let anchor: 'start' | 'middle' | 'end' = 'middle';
    let [xOffset, yOffset] = [0, 0];

    if (this.src.edge === this.dst.edge && this.src.edge !== 'top' && this.src.edge !== 'bottom') {
      // An edge case where the ports are on the same horizontal edge
      anchor = this.src.edge === 'right' ? 'start' : 'end';
      xOffset = this.src.edge === 'right' ? +4 : -4;
      yOffset = 12 + Math.abs(xOffset) / 2;
    } else {
      anchor = sx < dx ? 'start' : 'end';
      xOffset = sx < dx ? +4 : -4;
      yOffset = this.src.edge === 'top' ? 12 + Math.abs(xOffset) / 2 : -Math.abs(xOffset);
    }

    return { anchor, x: sx + xOffset, y: this.lineVertical + yOffset };
  }

  public getTransform(): string {
    let rotation = 0;
    let { x, y } = this.dstPort;

    if (this.dst.edge === 'top') {
      x += ARROW_WIDTH / 2;
      y += 1;
      rotation = 90;
    }
    if (this.dst.edge === 'bottom') {
      x -= ARROW_WIDTH / 2;
      y -= 1;
      rotation = -90;
    }
    if (this.dst.edge === 'right') {
      x -= 1;
      y += ARROW_WIDTH / 2;
      rotation = 180;
    }
    if (this.dst.edge === 'left') {
      x += 1;
      y -= ARROW_WIDTH / 2;
    }

    if (this.arrowOffset?.x) x += this.arrowOffset.x;
    if (this.arrowOffset?.y) y += this.arrowOffset.y;

    return `translate(${x}, ${y}) rotate(${rotation})`;
  }
}

@Component({
  selector: '[project-board-arrow]',
  template: `
    <svg:path [attr.d]="path" />
    <svg:text *ngIf="arrow.text" [attr.text-anchor]="textConfig.anchor" [attr.x]="textConfig.x" [attr.y]="textConfig.y">
      {{ arrow.text }}
    </svg:text>
    <svg:polygon [attr.points]="ARROW_POINTS" [attr.transform]="arrowTransform" />
  `,
  styleUrls: ['./project-board-internals.scss']
})
export class ProjectBoardArrowComponent implements OnInit {
  public readonly ARROW_POINTS = ARROW_POINTS;

  @Input() arrow: ProjectBoardArrow;

  public path: string;
  public textConfig: SerializedPoint & { anchor: 'start' | 'middle' | 'end' };
  public arrowTransform: string;

  constructor(private host: ElementRef<SVGGElement>) {}

  ngOnInit(): void {
    this.path = this.arrow
      .getCommands()
      .map(([cmd, args]) => `${cmd}${args.join(',')}`)
      .join(' ');
    this.textConfig = this.arrow.getTextConfig();
    this.arrowTransform = this.arrow.getTransform();

    this.host.nativeElement.classList.add('arrow');
    if (this.arrow.fail) this.host.nativeElement.classList.add('fail');
  }
}
