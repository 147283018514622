import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-explore-data-dialog',
  templateUrl: './explore-data-dialog.component.html',
  styleUrls: ['./explore-data-dialog.component.scss']
})
export class ExploreDataDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ExploreDataDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  public codeEditorOptions = {
    theme: 'vs-dark',
    language: 'json',
    automaticLayout: true,
    scrollBeyondLastLine: false,
    minimap: {
      enabled: false
    },
    scrollbar: {
      vertical: 'hidden'
    },
    overviewRulerBorder: false,
    readonly: true
  };

  ngOnInit(): void {}
}
