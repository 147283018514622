import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  _activeMenuItem$: Subject<string> = <Subject<string>>new Subject();
  get activeMenuItem$() {
    return this._activeMenuItem$.asObservable();
  }

  public get activeMenuItem(): string {
    return localStorage.getItem('activeMenuItem') || 'home';
  }

  public set activeMenuItem(v: string) {
    localStorage.setItem('activeMenuItem', v);
  }

  public get activeMenuGroup(): string {
    if (['projects', 'people'].includes(this.activeMenuItem)) return 'team';
    else if (['hosts', 'gpus'].includes(this.activeMenuItem)) return 'devices';
    else if (['raw', 'features', 'raw', 'features', 'schemas', 'builds', 'assets'].includes(this.activeMenuItem)) return 'data';
    else if (['definitions', 'experiments', 'vault', 'streams', 'deploys'].includes(this.activeMenuItem)) return 'models';
    return 'unknown';
  }

  constructor(private _title: Title) {}

  delayedSetActive(menuItem: string) {
    setTimeout(() => {
      this.setActive(menuItem);
    }, 0);
  }

  setActive(menuItem: string) {
    this._title.setTitle(`lit>${menuItem}`);
    this._activeMenuItem$.next(menuItem);
    return this._activeMenuItem$.asObservable();
  }

  setExpanded(name: string, v: boolean): void {
    localStorage.setItem(`expanded_${name}`, JSON.stringify(v));
  }

  isExpanded(name: string): boolean {
    const val = localStorage.getItem(`expanded_${name}`);
    if (!val) return false;
    return JSON.parse(val);
  }
}
