import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';

@Component({
  selector: 'app-feature-selector-widget',
  templateUrl: './feature-selector-widget.component.html',
  styleUrls: ['../base-widget/base-widget.component.scss', './feature-selector-widget.component.scss']
})
export class FeatureSelectorWidgetComponent extends BaseWidgetComponent implements OnInit {
  @Output() onEditFile = new EventEmitter<{ parentId: string; path: any }>();
  @Output() onPacketSelect = new EventEmitter<{ parentId: string; packetContent: any }>();

  constructor() {
    super();
  }

  ngOnInit(): void {}

  editFile(input) {
    this.onEditFile.emit({ parentId: this.parentId, path: input.path });
  }

  onPacketSelectInner(packetContent:any) {
    this.onPacketSelect.emit({ parentId: this.parentId, packetContent: packetContent.packetContent });
  }
}
