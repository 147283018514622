import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Router } from '@angular/router';
import { humanizeDuration } from 'src/app/directives/duration.util';
import { PredictEngineService } from 'src/app/services/predict-engine.service';

export type SystemServiceState =
  | 'plugged'
  | 'mounted'
  | 'running'
  | 'waiting'
  | 'abandoned'
  | 'exited'
  | 'dead'
  | 'active'
  | 'listening'
  | 'updating';
export const SERVICE_STATES = [
  'plugged',
  'mounted',
  'running',
  'waiting',
  'abandoned',
  'exited',
  'dead',
  'active',
  'listening',
  'updating'
] as const;

@Component({
  selector: 'app-deploy-card',
  templateUrl: './deploy-card.component.html',
  styleUrls: ['./deploy-card.component.scss']
})
export class DeployCardComponent implements OnInit {
  @Input() teamName: string;
  @Input() deploy: {
    active: string;
    host: string;
    memory: number;
    name: string;
    started: string;
    pid: number;
    static: boolean;
    status: SystemServiceState;
    tasks: number;
  };
  @Input() selected: boolean;
  @Input() isWidget: boolean = false;
  @Input() parentId: string;

  @Output() changeStatus = new EventEmitter<'start' | 'stop' | 'restart'>();
  @Output() select = new EventEmitter<void>();

  public models = [];
  public loading = true;

  ngOnInit() {
    if (this.deploy.static) {
      this.loading = false;
      return;
    }

    const sub = this.predictEngineService.loadOne(this.teamName, this.deploy.name).subscribe((response) => {
      sub.unsubscribe();
      if (response.output && response.output.models) {
        this.models = response.output.models;
      }
      this.loading = false;
    });
  }

  public get humanizedRuntime() {
    return this.deploy.started ? humanizeDuration(new Date().getTime() - new Date(this.deploy.started).getTime()) : 'not running';
  }

  public get statusClass() {
    return {
      'status-avatar': true,
      active: ['running', 'active'].includes(this.deploy.status),
      warn: ['plugged', 'mounted', 'waiting', 'listening'].includes(this.deploy.status) || !SERVICE_STATES.includes(this.deploy.status),
      error:
        ['abandoned', 'exited', 'dead'].includes(this.deploy.status) || (this.deploy.status === null && this.deploy.active === 'failed')
    };
  }

  public get statusIcon() {
    const status = this.deploy.status === null && this.deploy.active === 'failed' ? 'dead' : this.deploy.status;
    switch (status) {
      case 'running':
      case 'active':
        return 'directions_running';
      case 'plugged':
        return 'power';
      case 'mounted':
        return 'link';
      case 'waiting':
      case 'updating':
        return 'hourglass_bottom';
      case 'listening':
        return 'hearing';
      case 'abandoned':
      case 'exited':
      case 'dead':
        return 'close';
      default:
        return 'question_mark';
    }
  }

  constructor(
    private router: Router,
    private predictEngineService: PredictEngineService
  ) {}

  $clickLaunch() {
    if (this.isWidget) {
      this.predictEngineService.serviceClick$.next({ parentId: this.parentId, serviceName: this.deploy.name });
    } else {
      this.router.navigate([this.teamName, 'deploy', this.deploy.name]);
    }
  }

  $updateStatus(status: 'start' | 'stop' | 'restart') {
    this.deploy.status = 'updating';
    this.changeStatus.emit(status);
  }
}
