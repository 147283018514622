import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataBuild } from 'src/app/models/data-build';
import { Pid } from 'src/app/models/pid';
import { DataBuildsService } from 'src/app/services/data-builds.service';

@Component({
  selector: 'app-pids',
  templateUrl: './pids.component.html',
  styleUrls: ['./pids.component.scss']
})
export class PidsComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input() teamName: string;
  @Input() runid: number;

  public dataSource = new MatTableDataSource<Pid>([]);
  public isLoading: boolean = true;
  public displayedColumns: string[] = ['isRunning', 'scriptName', 'pid', 'startTime', 'endTime', 'duration'];
  public username: string;

  constructor(private dataBuildsService: DataBuildsService) {}

  ngOnInit(): void {
    (<any>window).grid = this;
    this.load();
  }

  load() {
    this.isLoading = true;
    const sub = this.dataBuildsService.loadPids(this.teamName, null, this.runid, false).subscribe((results: Pid[]) => {
      sub.unsubscribe();
      this.isLoading = false;

      this.dataSource.data = results;
      setTimeout(() => {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }, 0);
    });
  }
}
