import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  public static cache: any = {};

  public static delete(code: string) {
    delete this.cache[code];
  }

  constructor() {}
}
