import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { isNil } from 'lodash';

@Component({
  selector: 'app-pretty-description',
  templateUrl: './pretty-description.component.html',
  styleUrls: ['./pretty-description.component.scss']
})
export class PrettyDescriptionComponent implements OnInit {
  private get _isHidden(): boolean {
    return isNil(this.description) ?? this.description === '';
  }

  @HostBinding('style.display')
  public get display(): 'none' | 'initial' {
    return this._isHidden ? 'none' : 'initial';
  }

  @Input() description: string;
  @Input() chunkDescription: boolean = false;

  public chunks: string[] = [''];
  public currentChunk = 0;

  private _maxWords = 50;
  constructor() {}

  ngOnInit(): void {
    if (!this.description) return;

    const words = this.description.replace('\\n', ' ').split(/\s+/);
    if (words.length < this._maxWords || !this.chunkDescription) {
      this.chunks[0] = this.description;
      return;
    }

    this.chunks = [];
    for (let i = 0; i < words.length; i += this._maxWords) {
      let chunk = words.slice(i, i + this._maxWords).join(' ');
      this.chunks.push(chunk);
    }
  }

  more = () => (this.currentChunk += 1);
  reset = () => (this.currentChunk = 0);
  pretty = (str?: string) => (isNil(str) ? str : str.replaceAll('\\n', '\n').slice(1, -1));
}
