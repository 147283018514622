// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  margin-left: 20px;
}

table {
  width: 100%;
}

.compact {
  height: 38px !important;
}

.compact mat-cell,
.compact mat-cell-header {
  font-size: 10pt;
}

th {
  font-size: 9pt;
  font-weight: bold;
}

tr {
  cursor: pointer;
}

mat-tab-group {
  height: 100%;
  overflow: hidden;
}

.settings {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: calc(100% - 20px);
  overflow: hidden;
}

.config-tab {
  margin: var(--content-margin, 1rem) calc(var(--content-margin, 1rem) / 2) 0px;
}
.config-tab .config-group {
  display: flex;
  -moz-column-gap: var(--content-gutter);
       column-gap: var(--content-gutter);
  flex-wrap: wrap;
}
.config-tab .config-group .form-field {
  flex: 1 0 180px;
}

.terminal-tab {
  height: 100%;
  margin-top: -5px;
}

.no-data {
  font-size: 1.125rem;
  font-weight: 600;
  text-align: center;
  padding: 1rem 0;
}

.keys-table-container {
  height: calc(100% - 76px);
  overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/components/strategy-grid/streams-widget/streams-widget.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,uBAAA;AACF;;AAEA;;EAEE,eAAA;AACF;;AAEA;EACE,cAAA;EACA,iBAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,YAAA;EACA,gBAAA;AACF;;AAEA;EACE,kBAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,yBAAA;EACA,gBAAA;AACF;;AAEA;EACE,6EAAA;AACF;AACE;EACE,aAAA;EACA,sCAAA;OAAA,iCAAA;EACA,eAAA;AACJ;AACI;EACE,eAAA;AACN;;AAIA;EACE,YAAA;EACA,gBAAA;AADF;;AAIA;EACE,mBAAA;EACA,gBAAA;EACA,kBAAA;EACA,eAAA;AADF;;AAIA;EACE,yBAAA;EACA,gBAAA;AADF","sourcesContent":[".container {\n  margin-left: 20px;\n}\n\ntable {\n  width: 100%;\n}\n\n.compact {\n  height: 38px !important;\n}\n\n.compact mat-cell,\n.compact mat-cell-header {\n  font-size: 10pt;\n}\n\nth {\n  font-size: 9pt;\n  font-weight: bold;\n}\n\ntr {\n  cursor: pointer;\n}\n\nmat-tab-group {\n  height: 100%;\n  overflow: hidden;\n}\n\n.settings {\n  position: relative;\n  flex-grow: 1;\n  display: flex;\n  flex-direction: column;\n  height: calc(100% - 20px);\n  overflow: hidden;\n}\n\n.config-tab {\n  margin: var(--content-margin, 1rem) calc(var(--content-margin, 1rem) / 2) 0px;\n\n  .config-group {\n    display: flex;\n    column-gap: var(--content-gutter);\n    flex-wrap: wrap;\n\n    .form-field {\n      flex: 1 0 180px;\n    }\n  }\n}\n\n.terminal-tab {\n  height: 100%;\n  margin-top: -5px;\n}\n\n.no-data {\n  font-size: 1.125rem;\n  font-weight: 600;\n  text-align: center;\n  padding: 1rem 0;\n}\n\n.keys-table-container {\n  height: calc(100% - 76px);\n  overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
