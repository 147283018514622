import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DataBuild } from 'src/app/models/data-build';
import { Project } from 'src/app/models/project';
import { AssetsService } from 'src/app/services/assets.service';
import { DataBuildsService } from 'src/app/services/data-builds.service';
import { ProjectsService } from 'src/app/services/projects.service';

@Component({
  selector: 'app-last-build-selector',
  templateUrl: './last-build-selector.component.html',
  styleUrls: ['./last-build-selector.component.scss']
})
export class LastBuildSelectorComponent implements OnInit, OnChanges {
  @Input() teamName: string;
  @Input() project: Project;
  @Output() onBuildDetails = new EventEmitter<{ projectName: string; runid: number }>();
  @Output() onOpenAsset = new EventEmitter<{ projectName: string; assetName: string }>();

  public lastProjectBuild: DataBuild = null;
  public sampleCount: number = null;
  public testTrainCount: number = null;

  constructor(
    private _dataBuildsService: DataBuildsService,
    private _projectsService: ProjectsService,
    private _assetsService: AssetsService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  ngOnChanges(_changes: SimpleChanges): Promise<void> {
    if (_changes.project.firstChange || _changes.project.currentValue?.name === _changes.project.previousValue?.name) return;

    this.init();
  }

  init() {
    const buildSub = this._dataBuildsService.load(this.teamName).subscribe((results: DataBuild[]) => {
      buildSub.unsubscribe();
      if (!results || results.length === 0) return;

      const projectBuilds = results.filter((x) => x.project === this.project?.name || x.rdsName === this.project?.name);
      if (projectBuilds.length === 0) return;

      this.lastProjectBuild = projectBuilds[projectBuilds.length - 1];

      this.lastProjectBuild.status = this.lastProjectBuild.cancelled === true ? 'canceled' : this.lastProjectBuild.status;
      this._projectsService.count(this.teamName, this.project?.name).then((results: number) => {
        this.sampleCount = results;
      });

      const assetsSub = this._assetsService.getSampleCount(this.teamName, this.project?.name).subscribe((results: any) => {
        assetsSub.unsubscribe();
        this.testTrainCount = results.sampleCount;
      });
    });
  }

  asDate(timestamp) {
    return new Date(timestamp);
  }

  $clickBuildDetails() {
    this.onBuildDetails.emit({ projectName: this.project.name, runid: this.lastProjectBuild?.runid });
  }

  $clickStartBuild() {
    if (this.lastProjectBuild) {
      this.rebuild();
      return;
    }

    this.startBuild();
  }

  $clickStopBuild() {
    if (!confirm('Are you sure you want to canel this build?')) return;

    const sub = this._dataBuildsService
      .stop(this.teamName, this.lastProjectBuild.rdsName, this.lastProjectBuild.runid.toString())
      .subscribe((results: any) => {
        sub.unsubscribe();
        this.init();
      });
  }

  $clickOpenAsset() {
    this.onOpenAsset.emit({ projectName: this.project.name, assetName: this.lastProjectBuild?.rdsName });
  }

  startBuild() {
    const sub = this._dataBuildsService.start(this.teamName, this.project.name, false, false).subscribe((results: any) => {
      sub.unsubscribe();
      this.init();
    });
  }

  rebuild() {
    const sub = this._dataBuildsService.clean(this.teamName, this.lastProjectBuild.rdsName).subscribe((results: any) => {
      sub.unsubscribe();
      this.startBuild();
    });
  }
}
