// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  position: relative;
  flex-direction: column;
}

:host:last-child {
  min-height: 0;
}

.wrapper {
  display: flex;
}

.point-track-container {
  flex: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.point {
  display: flex;
  align-self: baseline;
  border-width: 2px;
  border-style: solid;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
}

.track {
  flex-grow: 1;
  width: 2px;
}

.content {
  flex: 1;
  padding: 0 1rem;
}

:host:not(:last-child) .content {
  margin-bottom: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/app/components/timeline/timeline-event.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;EACA,sBAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,OAAA;EACA,aAAA;EACA,mBAAA;EACA,sBAAA;AACF;;AAEA;EACE,aAAA;EACA,oBAAA;EACA,iBAAA;EACA,mBAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;AACF;;AAEA;EACE,YAAA;EACA,UAAA;AACF;;AAEA;EACE,OAAA;EACA,eAAA;AACF;;AAGE;EACE,mBAAA;AAAJ","sourcesContent":[":host {\n  display: flex;\n  position: relative;\n  flex-direction: column;\n}\n\n:host:last-child {\n  min-height: 0;\n}\n\n.wrapper {\n  display: flex;\n}\n\n.point-track-container {\n  flex: 0;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n}\n\n.point {\n  display: flex;\n  align-self: baseline;\n  border-width: 2px;\n  border-style: solid;\n  border-radius: 50%;\n  width: 1rem;\n  height: 1rem;\n}\n\n.track {\n  flex-grow: 1;\n  width: 2px;\n}\n\n.content {\n  flex: 1;\n  padding: 0 1rem;\n}\n\n:host:not(:last-child) {\n  .content {\n    margin-bottom: 1rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
