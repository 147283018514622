// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  overflow: hidden;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

ngx-monaco-editor {
  flex: 1 1 100dvh;
}`, "",{"version":3,"sources":["webpack://./src/app/components/data/log/log.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,iBAAA;EACA,2BAAA;EACA,oBAAA;EACA,gBAAA;EACA,WAAA;EACA,YAAA;EACA,sBAAA;AACF;;AAEA;EACE,gBAAA;AACF","sourcesContent":[":host {\n  display: flex;\n  flex-wrap: nowrap;\n  justify-content: flex-start;\n  align-items: stretch;\n  overflow: hidden;\n  width: 100%;\n  height: 100%;\n  flex-direction: column;\n}\n\nngx-monaco-editor {\n  flex: 1 1 100dvh;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
