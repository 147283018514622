export function minMax(...values: number[]): [number, number] {
  let [min, max] = [Infinity, -Infinity];

  for (const val of values) {
    if (val < min) min = val;
    if (val > max) max = val;
  }

  return [min, max];
}

export function chunkedMinMax(values: number[], chunkSize: number = 25_000): [number, number] {
  let [min, max] = [Infinity, -Infinity];

  for (let index = 0; index < values.length; index += chunkSize) {
    const chunk = values.slice(index, index + chunkSize);
    const [chunkMin, chunkMax] = minMax(...chunk);

    if (chunkMin < min) min = chunkMin;
    if (chunkMax > max) max = chunkMax;
  }

  return [min, max];
}
