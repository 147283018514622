import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SchemasService {
  constructor(private http: HttpClient) {}

  public load = (teamName: string) => this.http.get(`${ConfigService.apiUrl}/data/schemas/${teamName}`);

  public loadAsync = async (teamName: string) => lastValueFrom(this.load(teamName));

  public getByName = (teamName: string, rdsName: string) => this.http.get(`${ConfigService.apiUrl}/data/schema/${teamName}/${rdsName}`);

  public getByNameAsync = async (teamName: string, rdsName: string) => lastValueFrom(this.getByName(teamName, rdsName));

  public post = (teamName: string, rdsName: string, data: any) =>
    this.http.post(`${ConfigService.apiUrl}/data/schema/${teamName}/${rdsName}`, data);

  public postAsync = async (teamName: string, rdsName: string, data: any) => lastValueFrom(this.post(teamName, rdsName, data));

  public delete = (teamName: string, rdsName: string) =>
    this.http.post(`${ConfigService.apiUrl}/data/delete-schema/${teamName}/${rdsName}`, {});

  public deleteAsync = async (teamName: string, rdsName: string) =>
    lastValueFrom(this.http.post(`${ConfigService.apiUrl}/data/delete-schema/${teamName}/${rdsName}`, {}));
}
