import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PredictAnalysisService } from 'src/app/services/predict-analysis.service';

@Component({
  selector: 'app-analysis-container',
  templateUrl: './analysis-container.component.html',
  styleUrls: ['./analysis-container.component.scss']
})
export class AnalysisContainerComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private predictAnalysis: PredictAnalysisService,
    private router: Router
  ) {}

  ngOnInit(): void {
    (<any>window).analysisContainer = this;
    this.activatedRoute.params.subscribe((params) => {});
  }
}
