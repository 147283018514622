import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HomeService } from 'src/app/services/home.service';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  public teamName: string;

  public projectCount: number = null;
  public peopleCount: number = null;
  public hostCount: number = null;
  public gpuCount: number = null;
  public assetCount: number = null;
  public activeBuildCount = null;
  public runningExperimentCount: number = null;
  public vaultItemCount: number = null;
  public sub: any = null;

  public isLoading: boolean = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private menuService: MenuService,
    private homeService: HomeService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.teamName = params.team;
      this.load();
    });
  }

  ngOnDestroy(): void {
    if (this.sub) this.sub.unsubscribe();
  }

  async load() {
    this.sub = this.homeService.load(this.teamName).subscribe((metrics: any) => {
      this.projectCount = metrics.projectCount;
      this.peopleCount = metrics.peopleCount;
      this.hostCount = metrics.hostCount;
      this.gpuCount = metrics.gpuCount;
      this.assetCount = metrics.assetCount;
      this.activeBuildCount = metrics.activeBuildCount;
      this.runningExperimentCount = metrics.runningExperimentCount;
      this.vaultItemCount = metrics.vaultItemCount;

      this.isLoading = false;
    });
  }

  navigate(route: string[]) {
    this.menuService.setActive(route[route.length - 1]);
    this.router.navigate(route);
  }
}
