// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.setting-container {
  margin: var(--content-gutter) 0;
}
.setting-container:first-of-type {
  margin-top: 0;
}
.setting-container:last-of-type {
  margin-bottom: 0;
}

.button-group-label {
  font-weight: bold;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 4px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/settings-dialog/settings-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,+BAAA;AACF;AACE;EACE,aAAA;AACJ;AAEE;EACE,gBAAA;AAAJ;;AAIA;EACE,iBAAA;EACA,SAAA;EACA,aAAA;EACA,mBAAA;EACA,QAAA;AADF","sourcesContent":[".setting-container {\n  margin: var(--content-gutter) 0;\n\n  &:first-of-type {\n    margin-top: 0;\n  }\n\n  &:last-of-type {\n    margin-bottom: 0;\n  }\n}\n\n.button-group-label {\n  font-weight: bold;\n  margin: 0;\n  display: flex;\n  align-items: center;\n  gap: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
