// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
  padding: 20px;
  height: calc(100% - 40px);
}

.toolbar button {
  height: 36px;
  margin-bottom: 10px;
}

table {
  width: 100%;
}

.tab-group {
  height: calc(100% - 81px);
  overflow: hidden;
}

.vertical-spacer {
  height: 10px;
}

.compact {
  height: 38px !important;
}

.compact mat-cell,
.compact mat-cell-header {
  font-size: 10pt;
}

.nodata {
  padding: 20px;
  opacity: 0.3;
}

.inline-input {
  width: 200px;
  font-size: 15px;
}

.input-header {
  padding-left: 10px;
  padding-top: 10px;
  height: 70px;
}

.hidden {
  display: none;
}

.chart-flex-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.chart-flex-wrapper > .scichart {
  flex-grow: 1;
}

.wider {
  min-width: 250px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/models/analysis-container/analysis/analysis.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,yBAAA;AACF;;AAEA;EACE,YAAA;EACA,mBAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,yBAAA;EACA,gBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,uBAAA;AACF;;AAEA;;EAEE,eAAA;AACF;;AAEA;EACE,aAAA;EACA,YAAA;AACF;;AAEA;EACE,YAAA;EACA,eAAA;AACF;;AAEA;EACE,kBAAA;EACA,iBAAA;EACA,YAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,gBAAA;AACF","sourcesContent":[".content {\n  padding: 20px;\n  height: calc(100% - 40px);\n}\n\n.toolbar button {\n  height: 36px;\n  margin-bottom: 10px;\n}\n\ntable {\n  width: 100%;\n}\n\n.tab-group {\n  height: calc(100% - 81px);\n  overflow: hidden;\n}\n\n.vertical-spacer {\n  height: 10px;\n}\n\n.compact {\n  height: 38px !important;\n}\n\n.compact mat-cell,\n.compact mat-cell-header {\n  font-size: 10pt;\n}\n\n.nodata {\n  padding: 20px;\n  opacity: 0.3;\n}\n\n.inline-input {\n  width: 200px;\n  font-size: 15px;\n}\n\n.input-header {\n  padding-left: 10px;\n  padding-top: 10px;\n  height: 70px;\n}\n\n.hidden {\n  display: none;\n}\n\n.chart-flex-wrapper {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n}\n\n.chart-flex-wrapper > .scichart {\n  flex-grow: 1;\n}\n\n.wider {\n  min-width: 250px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
