import { Component, HostBinding, HostListener, Input } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { humanizeDuration } from 'src/app/directives/duration.util';

@Component({
  selector: 'app-live-chip',
  template: 'Live',
  providers: [MatTooltip],
  styles: [
    `
      :host {
        display: inline-block;
        margin: 0px;

        font-weight: bold;
        text-transform: uppercase;

        background-color: grey;
        color: #ffffff;
        transition: background-color 150ms linear;

        line-height: calc(1rem * var(--scale-factor, 1));
        height: calc(1rem * var(--scale-factor, 1));
        border-radius: calc(0.25rem * var(--scale-factor, 1));
        padding: 0px calc(0.25rem * var(--scale-factor, 1));
        font-size: calc(10px * var(--scale-factor, 1));
        letter-spacing: calc(0.5px * var(--scale-factor, 1));
        user-select: none;

        &.pulse {
          animation: pulse 2s infinite;
        }

        &.connected {
          background-color: #d93025;
        }
      }

      @keyframes pulse {
        0% {
          box-shadow: 0 0 0 0 #d9302566;
        }
        70% {
          box-shadow: 0 0 0 10px #d9302500;
        }
        100% {
          box-shadow: 0 0 0 0 #d9302500;
        }
      }
    `
  ]
})
export class LiveChipComponent {
  @HostBinding('class.connected')
  @Input()
  connected: boolean;

  @HostBinding('class.pulse')
  @Input()
  pulse: boolean;

  @Input()
  size: 'large' | 'medium' | 'small' | number = 'small';

  @Input()
  updated?: Date;

  private get _height(): number {
    if (typeof this.size === 'number') return this.size;
    if (this.size === 'large') return 28;
    if (this.size === 'medium') return 24;
    return 16;
  }

  private get _scaleFactor() {
    return this._height / 16;
  }

  @HostBinding('style')
  public get scaleFactorVariable() {
    return `--scale-factor: ${this._scaleFactor};`;
  }

  constructor(private readonly _tooltip: MatTooltip) {}

  @HostListener('mouseenter') onMouseEnter(): void {
    if (this.updated === undefined) return;
    this._tooltip.message = humanizeDuration(new Date().getTime() - this.updated.getTime());
    this._tooltip.show();
  }

  @HostListener('mouseleave') onMouseLeave(): void {
    this._tooltip.hide();
  }
}
