import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Project } from 'src/app/models/project';
import { ProjectsService } from 'src/app/services/projects.service';
import { ExperimentsService } from 'src/app/services/experiments.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { IdentityService } from 'src/app/services/identity.service';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public isLoading: boolean = true;
  public dataSource = new MatTableDataSource<any>([]);
  public displayedColumns = ['host', 'user', 'runid', 'epoch', 'time', 'cost'];
  public totalHosts: number = 0;

  public get hasRows(): boolean {
    return this.dataSource?.data?.length > 0;
  }

  public get project(): Project {
    return this._identity.me.selectedProject;
  }

  private get _teamName(): string {
    return this._identity.me.selectedTeamName;
  }

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _projectsService: ProjectsService,
    private _router: Router,
    private _identity: IdentityService
  ) {
    (<any>window).project = this;
  }

  public ngOnInit(): void {
    console.log(this._identity.me);
    this._activatedRoute.params.subscribe(({ team, project }) => {
      this.loadProject(team, project);
    });
  }

  public loadProject(teamName: string, projectName: string): void {
    const sub = this._projectsService.get(teamName, projectName).subscribe(({ project, hostStats }) => {
      sub.unsubscribe();
      this.isLoading = false;

      this._identity.setProject(project);
      this.dataSource.data = hostStats;
      this.totalHosts = new Set(this.dataSource.data.map((x) => x.host)).size;

      setTimeout(() => {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }, 0);
    });
  }

  public getColumnType(columnName: string) {
    if (columnName == 'elapsed') return 'elapsed';
    if (columnName == 'time') return 'time';
    if (ExperimentsService.known_numbers.indexOf(columnName) > -1) return 'number';
    return 'text';
  }

  public $clickProjectBoard(): void {
    this._router.navigate([this._teamName, 'project-board']);
  }
}
