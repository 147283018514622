import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import {
  NODE_WIDTH,
  NODE_HEIGHT,
  TERMINAL_HEIGHT,
  DIAMOND_POINTS,
  PARA_POINTS,
  ARROW_LENGTH,
  COL_BASE,
  ROW_BASE,
  ROW_SPACE
} from './utils';
import { SerializedPoint } from 'src/app/models/point';
import { Edge, ProjectBoardArrow, ProjectBoardArrowConfig } from './project-board-arrow.component';

export enum ProjectBoardNodeShape {
  Rectangle = 'rectangle',
  Diamond = 'diamond',
  Parallelogram = 'parallelogram'
}

export enum ProjectBoardNodeType {
  Terminal = 'terminal',
  Task = 'task',
  IO = 'io',
  Decision = 'decision'
}

export class ProjectBoardNode {
  public position: SerializedPoint;
  public text: [string] | [string, string] | [string, string, string];
  public route: string[];
  public type: ProjectBoardNodeType;
  public status: 'done' | 'here' | undefined;

  public get shape() {
    switch (this.type) {
      case ProjectBoardNodeType.IO:
        return ProjectBoardNodeShape.Parallelogram;
      case ProjectBoardNodeType.Decision:
        return ProjectBoardNodeShape.Diamond;
      default:
        return ProjectBoardNodeShape.Rectangle;
    }
  }

  public get terminal() {
    return this.type === ProjectBoardNodeType.Terminal;
  }

  public get bb() {
    const left = COL_BASE + this.position.x * (NODE_WIDTH + ARROW_LENGTH);
    const top = ROW_BASE + this.position.y * ROW_SPACE + (this.terminal ? (NODE_HEIGHT - TERMINAL_HEIGHT) / 2 : 0);
    return {
      left,
      top,
      right: left + NODE_WIDTH,
      bottom: top + (this.terminal ? TERMINAL_HEIGHT : NODE_HEIGHT)
    };
  }

  public get center() {
    return {
      x: (this.bb.left + this.bb.right) / 2,
      y: (this.bb.top + this.bb.bottom) / 2
    };
  }

  public get transform() {
    return `translate(${this.bb.left}, ${this.bb.top})`;
  }

  public get classList(): ['node', string] {
    return ['node', this.type];
  }

  constructor(
    x: number,
    y: number,
    text: [string] | [string, string] | [string, string, string],
    route: string[],
    type: ProjectBoardNodeType = ProjectBoardNodeType.Task
  ) {
    this.position = { x, y };
    this.text = text;
    this.route = route;
    this.type = type;
    this.status = undefined;
  }

  public getPort = (edge: Edge): SerializedPoint =>
    edge === 'top' || edge === 'bottom'
      ? {
          x: this.center.x,
          y: this.bb[edge]
        }
      : {
          x: this.bb[edge],
          y: this.center.y
        };

  public addConnection = (node: ProjectBoardNode, [srcEdge, dstEdge]: [Edge, Edge], config: ProjectBoardArrowConfig = undefined) =>
    new ProjectBoardArrow({ node: this, edge: srcEdge }, { node: node, edge: dstEdge }, config);
}

@Component({
  selector: '[project-board-node]',
  template: `
    <ng-container *ngIf="node.terminal; else regularNode">
      <svg:rect x="0" y="0" [attr.width]="NODE_WIDTH" [attr.height]="TERMINAL_HEIGHT" rx="20" (click)="$click($event)" />
      <svg:text [attr.x]="NODE_WIDTH / 2" y="36">{{ node.text[0] }}</svg:text>
    </ng-container>

    <svg:g class="state-icon">
      <svg:path d="M0 0h24v24H0V0z" fill="none" />
      <svg:path
        *ngIf="node.status === 'done'"
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z"
      />
      <svg:path
        *ngIf="node.status === 'here'"
        d="M12 11c1.33 0 4 .67 4 2v.16c-.97 1.12-2.4 1.84-4 1.84s-3.03-.72-4-1.84V13c0-1.33 2.67-2 4-2zm0-1c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm6 .2C18 6.57 15.35 4 12 4s-6 2.57-6 6.2c0 2.34 1.95 5.44 6 9.14 4.05-3.7 6-6.8 6-9.14zM12 2c4.2 0 8 3.22 8 8.2 0 3.32-2.67 7.25-8 11.8-5.33-4.55-8-8.48-8-11.8C4 5.22 7.8 2 12 2z"
      />
    </svg:g>

    <ng-template #regularNode>
      <ng-container [ngSwitch]="node.shape">
        <ng-container *ngSwitchCase="'rectangle'">
          <svg:rect x="0" y="0" [attr.width]="NODE_WIDTH" [attr.height]="NODE_HEIGHT" (click)="$click($event)" />
        </ng-container>
        <ng-container *ngSwitchCase="'diamond'">
          <svg:polygon [attr.points]="DIAMOND_POINTS" (click)="$click($event)" />
        </ng-container>
        <ng-container *ngSwitchCase="'parallelogram'">
          <svg:polygon [attr.points]="PARA_POINTS" (click)="$click($event)" />
        </ng-container>
        <ng-container *ngSwitchDefault>
          <svg:rect x="0" y="0" [attr.width]="NODE_WIDTH" [attr.height]="NODE_HEIGHT" (click)="$click($event)" />
        </ng-container>
      </ng-container>

      <ng-container [ngSwitch]="node.text.length">
        <ng-container *ngSwitchCase="1">
          <svg:text [attr.x]="NODE_WIDTH / 2" y="50">{{ node.text[0] }}</svg:text>
        </ng-container>
        <ng-container *ngSwitchCase="2">
          <svg:text [attr.x]="NODE_WIDTH / 2" y="43">{{ node.text[0] }}</svg:text>
          <svg:text [attr.x]="NODE_WIDTH / 2" y="57">{{ node.text[1] }}</svg:text>
        </ng-container>
        <ng-container *ngSwitchCase="3">
          <svg:text [attr.x]="NODE_WIDTH / 2" y="37">{{ node.text[0] }}</svg:text>
          <svg:text [attr.x]="NODE_WIDTH / 2" y="50">{{ node.text[1] }}</svg:text>
          <svg:text [attr.x]="NODE_WIDTH / 2" y="63">{{ node.text[2] }}</svg:text>
        </ng-container>
      </ng-container>
    </ng-template>
  `,
  styleUrls: ['./project-board-internals.scss']
})
export class ProjectBoardNodeComponent {
  public readonly NODE_WIDTH = NODE_WIDTH;
  public readonly NODE_HEIGHT = NODE_HEIGHT;
  public readonly TERMINAL_HEIGHT = TERMINAL_HEIGHT;
  public readonly DIAMOND_POINTS = DIAMOND_POINTS;
  public readonly PARA_POINTS = PARA_POINTS;

  @Input() node: ProjectBoardNode;
  @Output() click = new EventEmitter<ProjectBoardNode>();

  @HostBinding('attr.transform')
  public get transform() {
    return this.node.transform;
  }

  @HostBinding('class')
  public get klass() {
    return this.node.classList.join(' ');
  }

  $click($ev: PointerEvent): void {
    $ev.stopPropagation();
    $ev.preventDefault();
    this.click.emit(this.node);
  }
}
