import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  AfterViewInit,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  EventEmitter,
  Output,
  ElementRef
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Run } from 'src/app/models/run';
import { ExperimentsService } from 'src/app/services/experiments.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SocketService } from 'src/app/services/socket.service';
import { SelectionModel } from '@angular/cdk/collections';
import { SubscriptionContainer } from 'src/app/models/subscription-container';
import { IdentityService } from 'src/app/services/identity.service';
import { PerfChartComponent } from '../perf-chart/perf-chart.component';
import { SplitComponent } from 'angular-split';
import { PerfTableComponent } from '../perf-table/perf-table.component';
import { ModelViewComponent } from '../model-view/model-view.component';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { ModelDiffComponent } from '../model-diff/model-diff.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { MenuService } from 'src/app/services/menu.service';
import { GpusService } from 'src/app/services/gpus.service';
import { OmnibarComponent } from '../../omnibar/omnibar.component';
import { isNil } from 'lodash';
import { DefinitionsService } from 'src/app/services/definitions.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ContentObserver } from '@angular/cdk/observers';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-experiments',
  templateUrl: './experiments.component.html',
  styleUrls: ['./experiments.component.scss']
})
export class ExperimentsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(PerfChartComponent) chart: PerfChartComponent;
  @ViewChild(PerfTableComponent) grid: PerfChartComponent;
  @ViewChild(ModelViewComponent) modelView: ModelViewComponent;
  @ViewChild(ModelDiffComponent) modelDiff: ModelDiffComponent;
  @ViewChild(SplitComponent) split: SplitComponent;
  @ViewChild(OmnibarComponent) omnibar: OmnibarComponent;
  @ViewChild('selectTrigger') matMenuTrigger: MatMenuTrigger;
  @ViewChild('stateTrigger') stateTrigger: MatMenuTrigger;

  @Input() isWidget: boolean = false;
  @Input() teamName: string;
  @Input() sharedLinkId: string;
  @Input() definitionName: string = null;
  @Output() onProcessView = new EventEmitter<{ command: string; title: string }>();
  @Output() onVaultItem = new EventEmitter<{ vaultName: string }>();
  @Output() onPopoutPerfTable = new EventEmitter<{run: any, displayedColumns: string[], dynamicColumns: any[], live: boolean}>();

  public showLegend = false;
  public view = 'TABLE';
  public isLoading: boolean = true;
  public initSort = null;
  public defaultSort = { id: 'created', start: 'desc' };
  public dataSource = new MatTableDataSource<Run>([]);
  public selection = new SelectionModel<Run>(true, []);
  private _subscriptions = new SubscriptionContainer();
  public save: number[];
  public dynamicColumns: any[] = [];
  public originalData: Run[] = [];
  public terminalId = new Date().getTime().toString();

  public devices: string[] = ['cpu'];
  public allGPUS = [];

  public columns: string[] = [];
  public displayedColumns: string[] = [];

  public defaultColumns = ['select', 'is_running', 'runid', 'description', 'loss', 'epochs', 'last_modified', 'elapsed'];
  public perfColumns: string[] = [];
  public chartColumns: string[] = [];
  public allPerfColumns: string[] = [];
  public allChartColumns: string[] = [];
  public displayedPerfColumns: string[] = [];
  public displayedChartColumns: string[] = [];
  public pageSize = 10;

  public contextMenuPosition = { x: '0px', y: '0px' };
  public sortSub: any = null;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _experimentsService: ExperimentsService,
    private gpuService: GpusService,
    private _socketService: SocketService,
    private _identity: IdentityService,
    private _snackbar: MatSnackBar,
    private _changeDetectorRefs: ChangeDetectorRef,
    private _menuService: MenuService,
    private _definitionsService: DefinitionsService,
    private _notificationService: NotificationService,
    private _elementRef: ElementRef
  ) {}

  public ngOnInit(): void {
    this._menuService.delayedSetActive('experiments');

    if (!this.isWidget) this.definitionName = this._activatedRoute.snapshot.queryParamMap.get('search');

    this._socketService.joinRoom('experiments');
    this._subscriptions.add = this._socketService.subscribeToRoomEvents('experiments', (data: any) => {
      if (data.msg === 'update experiment index') {
        this.refresh();
      }
    });

    this._subscriptions.add = this._experimentsService.popin$.subscribe((results) => {
      const selected = this.selection.selected.find(x => x.runid === results.runid);
      if (!selected) {
        this.toggle(results);
        if (this.view === 'TABLE') this.changeView('GRID');
      }
    });


    this._activatedRoute.params.subscribe((params) => {
      this.initTeam(params.team);

      const sub = this._experimentsService.meta$.subscribe((meta) => {
        sub.unsubscribe();
        this.initColumns(meta);
      });
    });

    this.dataSource.filterPredicate = (data: any, filter: string) => {
      const filters = filter
        .split(',')
        .filter((x) => x)
        .map((x) => x.trim().toLowerCase());
      function match_filter(data, filter) {
        if (filter.indexOf('=') != -1) {
          const parts = filter.split('=');
          const namedProp = parts[0];
          filter = parts[1];
          const compareTo = data[namedProp]?.toString()?.trim().toLowerCase();
          if (compareTo && compareTo.indexOf(filter) != -1) {
            return true;
          }
        } else if (filter.indexOf('<') != -1) {
          const parts = filter.split('<');
          const namedProp = parts[0];
          filter = parts[1];
          if (Number(data[namedProp] < Number(filter))) {
            return true;
          }
        } else if (filter.indexOf('>') != -1) {
          const parts = filter.split('>');
          const namedProp = parts[0];
          filter = parts[1];
          if (Number(data[namedProp] > Number(filter))) {
            return true;
          }
        } else {
          for (const prop in data) {
            const compareTo = data[prop]?.toString()?.trim().toLowerCase();
            if (compareTo && compareTo.indexOf(filter) != -1) {
              return true;
            }
          }
        }
        return false;
      }
      return filters.every((filter) => match_filter(data, filter));
    };

    const gpusub = this.gpuService.countGpus(this.teamName).subscribe((response) => {
      gpusub.unsubscribe();
      this.allGPUS = response;
      this.resetDevices();
    });
  }

  resetDevices() {
    this.devices = ['cpu'];
    if (this.allGPUS.length > 0) {
      const count = this.allGPUS[0]['count'];
      for (let i = 0; i < count; i++) {
        this.devices.push(`gpu${i}`);
      }
    }
  }

  public initTeam(teamName: string): void {
    this.teamName = teamName;
    this._identity.setTeam(this.teamName);
    localStorage.setItem('palette', 'colorblind-32'); // Set pallete for graphs
    this.loadRuns();
    (<any>window).experiments = this;
  }

  public ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    if (this.definitionName) this.applyFilter(this.definitionName);
  }

  public onResize(): void {
    const event = new Event('resize-experiments');
    window.dispatchEvent(event);
    setTimeout(() => {
      this.optimizePageSize();
    }, 500);
  }

  public changeViewSelection(event): void {
    this.changeView(event.value);
  }

  public changeView(view: string): void {
    if (view === 'ATTACH') return;

    if (view == 'CODE') {
      if (this.selection.selected.length < 2) {
        view = 'MODEL';
      } else if (this.selection.selected.length == 2) {
        view = 'DIFF';
      } else {
        this.noMoreThanTwoWarn();
      }
    }

    this.view = view;
    setTimeout(() => {
      this.optimizePageSize();
    }, 100);
    this.updateRoute();
  }

  public refresh(): void {
    switch (this.view) {
      case 'CHART':
        this.loadRuns();
        this.chart.loadRuns();
        break;
      case 'GRID':
        this.loadRuns();
        break;
      case 'MODEL':
        this.loadRuns();
        this.modelView.deleteCacheReload();
        break;
      case 'DIFF':
        this.loadRuns();
        this.modelDiff.deleteCacheReload();
        break;
      default:
        this.loadRuns();
        break;
    }
  }

  public async stopSelected(): Promise<void> {
    if (!confirm('Are you sure you want to stop ALL of the selected runs?')) return;

    this.selection.selected.forEach(async (row) => {
      const index = this.dataSource.data.findIndex((x) => x.runid === row.runid);
      const sub = this._experimentsService.stop(this.teamName, row.runid).subscribe((results: any) => {
        sub.unsubscribe();
        this.dataSource.data[index].is_running = false;
        this.warn(`Stopped ${this.teamName}/${row.runid}.`);
      });
    });
  }

  public async continueSelected(device: string, smartfit?: boolean): Promise<void> {
    const run: Run = this.selection.selected[0];
    const index = this.dataSource.data.findIndex((x) => x.runid === run.runid);
    const sub = this._experimentsService.continue(this.teamName, run.runid, device, smartfit).subscribe((results: any) => {
      sub.unsubscribe();
      this.dataSource.data[index].is_running = false;
      this.warn(`Resumed ${this.teamName}/${run.runid}.`);
    });
  }

  public onTableResize(): void {
    this.optimizePageSize();
  }

  public optimizePageSize(): void {
    if (this.split.displayedAreas.length == 0) return;

    const parentHeight: number = (<any>document.querySelector('app-experiments')).offsetHeight;

    if (this.split.displayedAreas.length == 1) {
      const x = parentHeight;
      const blocks = Math.round(0.00493601 * x - 1.6);
      this.pageSize = blocks * 5;
      this.paginator._changePageSize(this.pageSize);
    } else {
      const displaySize = this.split.displayedAreas[1].size;
      const x = ((displaySize === '*' ? 100 : displaySize) / 100) * parentHeight;
      this.pageSize = Math.max(1, Math.round(0.026 * x - 5));
      this.paginator._changePageSize(this.pageSize);
    }
  }

  public updateRoute(): void {
    var shadowRuns = this.selection.selected.map((x) => {
      var shadow = this.selection.selected.find((y) => x.runid == y.runid );
      return shadow;
    });
    const selectedRuns = [...new Set(shadowRuns.map((x) => `${x.runid}`))].join(',');
    const queryParams: Params = {};
    queryParams.view = this.view;

    if (selectedRuns.length > 0) {
      queryParams.selectedRuns = selectedRuns;
      queryParams.perfColumns = this.displayedPerfColumns.join(',');
      queryParams.chartColumns = this.displayedChartColumns.join(',');
    }
    queryParams.columns = this.displayedColumns.join(',');
    if (
      this.sort &&
      this.sort.active &&
      this.sort.direction &&
      (this.sort.active !== this.defaultSort.id || this.sort.direction !== this.defaultSort.start)
    ) {
      queryParams.sort = `${this.sort.active},${this.sort.direction}`;
    }
    if (this.omnibar.value) {
      queryParams.search = this.omnibar.value;
    }
    this._router.navigate([], { relativeTo: this._activatedRoute, queryParams: queryParams });
  }

  public rowIsSelected(row) {
    return this.selection.selected.filter((x) => x.runid == row.runid).length > 0;
  }

  viewInitialized = false;
  public loadView(params): void {
    if (this.viewInitialized) return; // only do this once

    this.viewInitialized = true;
    if (params.view) {
      this.view = params.view;
    }

    if (params.search) {
      this.omnibar.value = params.search;
      this.applyFilter(this.omnibar.value);
    }

    if (params.selectedRuns) {
      const selectedRuns = params.selectedRuns?.split(',');
      selectedRuns.forEach((selectedRun) => {
        const runid = selectedRun;

        var shadow = this.selection.selected.find((x) => x.runid == runid);
        if (shadow && !this.rowIsSelected(shadow)) {
          this.selection.select(shadow);
        }
      });
      setTimeout(() => {
        this.updateDetailViewColumns();
      }, 100);
    }

    if (params.perfColumns) {
      this.displayedPerfColumns = params.perfColumns?.split(',');
    }

    if (params.chartColumns) {
      this.displayedChartColumns = params.chartColumns?.split(',');
    }

    if (params.sort) {
      const [column, direction] = params.sort.split(',').map((x) => x.trim());
      if (column && direction) this.initSort = { id: column, start: direction };
    }

    setTimeout(() => {
      this.optimizePageSize();
    }, 100);
  }

  public findRow(runid: number): Run | undefined {
    return this.dataSource.data.find((x) => x.runid === runid);
  }

  public ngOnDestroy(): void {
    this._subscriptions.dispose();
    this._socketService.leaveRoom('experiments');
    if (this.sortSub) {
      this.sortSub.unsubscribe();
    }
  }

  public initColumns(meta: any[]): void {
    // Columns which create errors for detail views
    const errorDetailColumns = new Set(['description', 'elapsed', 'is_running', 'runid']);
    // Columns which are empty for detail views
    const emptyDetailColumns = new Set([
      'created',
      'device',
      'epochs',
      'is_archived',
      'path',
      'modified',
      'pid',
      'tags',
      'total_elapsed',
      'user',
      'max_accuracy',
      'max_val_accuracy',
      'max_val_auc',
      'max_val_loss',
      'max_val_precision',
      'max_val_recall',
      'min_loss',
      'min_val_accuracy',
      'min_val_auc',
      'min_val_loss',
      'min_val_precision',
      'min_val_recall'
    ]);
    // All excluded columns for detail views
    const excludedDetailColumns = new Set([...errorDetailColumns, ...emptyDetailColumns]);
    // Columns which are always empty for the grid view
    const excludedGridColumns = new Set([]);
    // Columns which are always empty for the chart view
    const excludedChartColumns = new Set(['time', 'vault']);
    this.displayedColumns = ['select', 'is_running', 'runid', 'description', 'created', 'last_modified', 'elapsed'];

    if (this.dynamicColumns.length === 0) {
      for (const item of meta) {
        // if meta item not in displayedColumns, add it to the end
        const columns = this.displayedColumns.filter((x) => x === item.columnDef);
        if (columns.length === 0) {
          this.dynamicColumns.push(item);
          this.displayedColumns.splice(this.displayedColumns.length - 2, 0, item.columnDef);
        }
      }

      this.columns = [...this.displayedColumns];

      // set gridColumns and chartColumns
      const detailColumns = this.columns.filter((col) => !excludedDetailColumns.has(col));
      this.allPerfColumns = detailColumns.filter((col) => !excludedGridColumns.has(col));
      this.allChartColumns = detailColumns.filter((col) => !excludedChartColumns.has(col));
      this.allPerfColumns.sort();
      this.allChartColumns.sort();

      this.perfColumns = this.allPerfColumns;
      this.chartColumns = this.allChartColumns;

      // load gridColumns from local-storage
      let defaultPerfColumns = ['epoch'];
      const preferredGridColumnToken = localStorage.getItem('displayedPerfColumns');
      if (preferredGridColumnToken) {
        defaultPerfColumns = preferredGridColumnToken.split(',');
      }
      this.displayedPerfColumns = defaultPerfColumns.filter((p) => this.columns.includes(p));
      // load chartColumns from local-storage
      let defaultChartColumns = ['epoch'];
      const preferredChartColumnToken = localStorage.getItem('displayedChartColumns');
      if (preferredChartColumnToken) {
        defaultChartColumns = preferredChartColumnToken.split(',');
      }
      this.displayedChartColumns = defaultChartColumns.filter((p) => this.columns.includes(p));
    }

    // load table columns from local-storage
    const preferredColumnToken = localStorage.getItem('displayedColumns');
    const myDefaultColumns = preferredColumnToken ? preferredColumnToken.split(',') : this.defaultColumns;

    // saved column must exist in this.columns
    this.displayedColumns = myDefaultColumns.filter((p) => this.columns.includes(p));
  }

  public onSelectContextMenu(event: MouseEvent, item: any): boolean {
    return this.onContextMenu(this.matMenuTrigger, event, item);
  }

  public onStateContextMenu(event: MouseEvent, item: any): boolean {
    return this.onContextMenu(this.stateTrigger, event, item);
  }

  public onContextMenu(trigger: MatMenuTrigger, event: MouseEvent, item: any): boolean {
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    trigger.menuData = { item: item };
    trigger.menu.focusFirstItem('mouse');
    trigger.openMenu();
    return false;
  }

  public getDynamicColumns(type: string): any[] {
    return this.dynamicColumns.filter((x) => x.type == type);
  }

  public updateSelected(runs: Run[]) {
    this.selection.selected.forEach((x) => {
      let found = runs.find((y) => y.runid == x.runid );
      if (found) {
        Object.keys(found).forEach((key) => {
          x[key] = found[key];
        });
      }
    });
  }

  public loadRuns(): void {
    // this.selection = new SelectionModel<Run>(true, []); // this is the source of flashing table
    const sub = this._experimentsService.load(this.teamName).subscribe((data: any) => {
      sub.unsubscribe();

      this.isLoading = false;
      const runs: Run[] = data.runs;
      runs.forEach((x) => {
        const d = new Date(0, 0, 0, 0, 0, 0, 0);
        d.setSeconds(x.elapsed);
        x['formattedElapsed'] = d;
      });

      this.originalData = runs;
      this.dataSource.data = this.originalData.filter((x) => !x.is_archived);
      this.setSort();

      this.updateSelected(runs);

      setTimeout(() => {
        this.loadView(this._activatedRoute.snapshot.queryParams);
        if (!this.dataSource.sort) {
          this.setSort();
        }
        if (this.definitionName) {
          this.omnibar.value = this.definitionName;
        }
        // this.updateRoute();
      }, 100);
    });
  }

  sortInitialized = false;
  public setSort(): void {
    if (this.sortInitialized) return;

    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'last_modified':
          return item.last ? item.last : item.modified;
        case 'select':
          return this.selection.selected.includes(item);
        default:
          return item[property];
      }
    };

    this.sortInitialized = true;
    if (this.initSort) {
      this.sort.sort(this.initSort as MatSortable);
    } else {
      this.sort.sort(this.defaultSort as MatSortable); // if you crash here, you got here before the table existed. fix the table not existing
    }

    this.dataSource.sort = this.sort;
    if (!this.sortSub) {
      this.sortSub = this.sort.sortChange.subscribe((value) => {
        this.updateRoute();
      });
    }
  }

  public subscribeToSocketEvents(): void {
    this._socketService.joinRoom();
    this._subscriptions.add = this._socketService.events.subscribe((msg: any) => {
      const action = msg.action;
      if (action === 'update') {
        this.loadRuns();
      }
    });
  }

  public isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  private _toggleRow(row) {
    const shadow = this.selection.selected.find((x) => x.runid == row.runid );
    if (shadow) {
      this.selection.deselect(shadow);
    } else {
      this.selection.select(row);
    }
  }

  public toggle(row: Run): void {
    this._toggleRow(row);

    if (this.view === 'MODEL') {
      if (this.selection.selected.length == 2) {
        this.changeView('DIFF');
      } else if (this.selection.selected.length < 2) {
        // do nothing
      } else {
        // > 2
        this._toggleRow(row);
        this.noMoreThanTwoWarn();
        return;
      }
    }
    if (this.view == 'CODE') {
      // user selected more than 2, then selected code
      if (this.selection.selected.length == 2) {
        this.changeView('DIFF');
      } else if (this.selection.selected.length < 2) {
        this.changeView('MODEL');
      }
    }

    if (this.view === 'DIFF') {
      if (this.selection.selected.length == 2) {
        // do nothing
      } else if (this.selection.selected.length < 2) {
        this.changeView('MODEL');
      } else {
        // > 2
        this._toggleRow(row);
        // this.selection.toggle(row);
        this.noMoreThanTwoWarn();
        return;
      }
    }

    this.rankSelections();
    this.updateRoute();
    this.updateDetailViewColumns();
  }

  public noMoreThanTwoWarn(): void {
    return this.warn('No more than 2 experiments may be selected in CODE view.');
  }

  public warn(message: string): void {
    const action = 'OK';
    const durationInSeconds = 3;
    const horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    const verticalPosition: MatSnackBarVerticalPosition = 'top';

    this._snackbar.open(message, action, {
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition,
      duration: durationInSeconds * 1000
    });
  }

  public disableStop(): boolean {
    if (this.selection.selected.length === 0 || this.selection.selected.length > 1) return true;

    if (!this.selection.selected[0].is_running) return true;

    return false;
  }

  public updateDetailViewColumns(): void {
    // function findColumn(runs: Run[], col: string): boolean {
    //   for (const i in runs) {
    //     if (Object.keys(runs[i]).find((prop) => prop == col)) return true;
    //   }
    //   return false;
    // }

    // const runs = this.selection.selected;

    // let filteredPerfColumns = this.allPerfColumns.filter((col) => findColumn(runs, col));
    // if(filteredPerfColumns.length == 0) { filteredPerfColumns = this.allPerfColumns};
    // this.perfColumns = filteredPerfColumns;

    // let filteredChartColumns = this.allChartColumns.filter((col) => findColumn(runs, col));
    // if(filteredChartColumns.length == 0) { filteredChartColumns = this.allChartColumns};
    // this.chartColumns = filteredChartColumns;

    this.perfColumns = this.allPerfColumns;
    this.chartColumns = this.allChartColumns;
  }

  public rankSelections(): void {
    if (!this.selection || !this.selection.selected) {
      return;
    }

    const orderedDates: number[] = this.selection.selected
      .filter((x) => x.last)
      .map((x) => x.last)
      .sort((a, b) => b - a);
    this.dataSource.data.forEach((x) => (x.modifiedRank = 9999));
    this.selection.selected.forEach((run) => (run.modifiedRank = orderedDates.indexOf(run.last)));
  }

  public selectAll(): void {
    this.dataSource.filteredData.forEach((run: Run) => this.selection.select(run));
  }

  public async setarchive(value: boolean): Promise<void> {
    const uniqueProjects = [...new Set(this.selection.selected.map((x) => x.project))];
    await uniqueProjects.forEach(async (projectName: string) => {
      const filtered = this.selection.selected.filter((x) => x.project == projectName);
      if (value) {
        const sub = this._experimentsService.archive(this.teamName, filtered).subscribe(() => {
          filtered.forEach((run: Run) => {
            run.is_archived = true;
            this._toggleRow(run);
            this.applyFilter(this.omnibar.value);
          });

          sub.unsubscribe();
        });
      } else {
        const sub = this._experimentsService.unarchive(this.teamName, filtered).subscribe(() => {
          filtered.forEach((run: Run) => {
            run.is_archived = false;
            this._toggleRow(run);
            this.applyFilter(this.omnibar.value);
          });

          sub.unsubscribe();
        });
      }
    });
  }

  public async archive(): Promise<void> {
    return this.setarchive(true);
  }

  public async unarchive(): Promise<void> {
    return this.setarchive(false);
  }

  public allSelectedCanBeArchived(): boolean {
    return this.selection.selected.every((x) => !x.is_archived && !x.is_running);
  }

  public allSelectedCanBeUnarchived(): boolean {
    return this.selection.selected.every((x) => x.is_archived);
  }

  public applyFilter(filterValue: string): void {
    if (filterValue === 'ATTACH') return; // weird omnibar behavior, hitting escape for onProcessViewClick alert gets us here
    if (filterValue.length == 0) this.dataSource.data = this.originalData.filter((x) => !x.is_archived);
    else {
      this.dataSource.data = this.originalData;
      this.setSort();
    }

    this.dataSource.filter = filterValue;
    const meta = this._experimentsService.getMeta(this.dataSource.filteredData);
    this.initColumns(meta);
    this.updateRoute();
  }

  public checkboxLabel(row?: Run): string {
    return row
      ? `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.runid + 1}`
      : `${this.isAllSelected() ? 'select' : 'deselect'} all`;
  }

  public selectColumn(selected): void {
    this.displayedColumns = [...selected.value];
    localStorage.setItem('displayedColumns', this.displayedColumns.join(','));
  }

  public selectPerfColumn(selected): void {
    this.displayedPerfColumns = [...selected.value];
    localStorage.setItem('displayedPerfColumns', this.displayedPerfColumns.join(','));
  }

  public selectChartColumn(selected): void {
    this.displayedChartColumns = [...selected.value];
    localStorage.setItem('displayedChartColumns', this.displayedChartColumns.join(','));
  }

  public encodeDiff(x: any): string {
    if(!x) {
      return null;
    }
    return encodeURI(`${x.path}/${x.runid}/definition.json`);
  }

  public tagsUpdated(input: any): void {
    const index = this.dataSource.data.findIndex((x) => x.runid === input.runid);
    if (!index || index < 0) return;

    this.dataSource.data[index]['tags'] = input.tags;
    this._changeDetectorRefs.detectChanges();
  }

  onProcessViewClick() {
    const running = this.selection.selected.filter(
      (x) => x.is_running === true && x.user === this._identity.me.username && x.session_name !== null
    );
    if (running.length === 0) {
      alert(`Please select at least 1 running experiment that was launched by ${this._identity.me.username}`);
      return;
    }

    for (let item of running) {
      this.onProcessView.emit({ command: `screen -d -r ${item.session_name}`, title: `run id: ${item.runid}` });
    }
  }

  public copyDefinition(): void {
    if (this.selection.selected.length !== 1) return;

    const { session_name: sessionName } = this.selection.selected[0];
    const canvas = /^(?<canvas>.+)\_(?<device>.+)\_(?<uid>[\da-f]{8})$/.exec(sessionName)?.groups?.canvas;
    if (isNil(canvas)) throw `unable to derive canvas name from sessionName="${sessionName}"`;

    this._definitionsService.duplicateAsync(this.teamName, canvas).then(() => {
      this._notificationService.showSuccess(`Created a copy of the ${canvas} definition`);
    });
  }

  public onVaultItemClick($event) {
    this.onVaultItem.emit($event);
  }

  public onPopoutPerfTableClick($event) {
    this._toggleRow($event.run);
    if (this.selection.selected.length === 0) {
      this.changeView('TABLE');
    }

    this.onPopoutPerfTable.emit($event);
  }
}
