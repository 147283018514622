import { Component, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit, OnDestroy {
  @Input() isLoading: boolean;

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
