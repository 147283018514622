import { Injectable } from '@angular/core';
import { Subject, Observable, lastValueFrom } from 'rxjs';
import { Project } from '../models/project';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {
  private _collection$: Subject<Project[]> = new Subject();
  private _item$: Subject<{ project: Project; hostStats: any }> = new Subject();

  constructor(private http: HttpClient) {}

  public load(teamName: string): Observable<Project[]> {
    this.http.get(`${ConfigService.apiUrl}/projects/${teamName}`).subscribe((response: Project[]) => {
      this._collection$.next(response);
    });

    return this._collection$.asObservable();
  }

  public async loadAsync(teamName: string): Promise<Project[]> {
    return lastValueFrom(this.http.get<Project[]>(`${ConfigService.apiUrl}/projects/${teamName}`));
  }

  public get(teamName: string, projectName: string) {
    this.http
      .get<{ project: Project; hostStats: any }>(`${ConfigService.apiUrl}/projects/${teamName}/${projectName}`)
      .subscribe((response) => {
        this._item$.next(response);
      });

    return this._item$.asObservable();
  }

  public count(teamName: string, projectName: string, schemaName: string = null): Promise<number | undefined> {
    return lastValueFrom(this.http.get<number | undefined>(`${ConfigService.apiUrl}/data/project/count/${teamName}/${projectName}/${schemaName}`));
  }

  public async demo(teamName: string, featurePath: string, project: string, schema: string, index: number, config: { [key: string]: any }) {
    return lastValueFrom(
      this.http.post<any>(`${ConfigService.apiUrl}/project/demo/${teamName}`, {
        featurePath,
        project,
        index,
        config,
        schema
      })
    );
  }

  public async data(teamName: string, project: string, start: number, stop: number, schema: string = null) {
    return lastValueFrom(
      this.http.post<any>(`${ConfigService.apiUrl}/project/data/${teamName}`, {
        project,
        start,
        stop,
        schema
      })
    );
  }

  public async dataByDate(teamName: string, project: string, timestamp: number, aperture: number, schema: string = null) {
    return lastValueFrom(
      this.http.post<any>(`${ConfigService.apiUrl}/project/data/bydate/${teamName}`, {
        project,
        timestamp,
        aperture,
        schema
      })
    );
  }

  public async estimate(teamName: string, featurePath: string, project: string, schema: string, count: number, config: { [key: string]: any }) {
    return lastValueFrom(
      this.http.post<any>(`${ConfigService.apiUrl}/project/estimate/${teamName}`, {
        featurePath,
        project,
        schema,
        count,
        config
      })
    );
  }

  public add(teamName: string, projectName: string) {
    return lastValueFrom(this.http.get(`${ConfigService.apiUrl}/project/add/${teamName}/${projectName}`));
  }

  public updateRaw(teamName: string, projectName: string, item: string, add: boolean) {
    const data = { add, item };
    return lastValueFrom(this.http.post<any[]>(`${ConfigService.apiUrl}/project/raw/update/${teamName}/${projectName}`, data));
  }

  public updateFeatures(teamName: string, projectName: string, item: string, add: boolean) {
    return lastValueFrom(
      this.http.post<any[]>(`${ConfigService.apiUrl}/project/features/update/${teamName}/${projectName}`, {
        add,
        item
      })
    );
  }

  public archive(teamName: string, projectName: string): Observable<any> {
    return this.http.post(`${ConfigService.apiUrl}/project/archive/${teamName}/${projectName}`, {});
  }

  public async archiveAsync(teamName: string, projectName: string): Promise<any> {
    return lastValueFrom(this.archive(teamName, projectName));
  }

  public restore(teamName: string, projectName: string): Observable<any> {
    return this.http.post(`${ConfigService.apiUrl}/project/restore/${teamName}/${projectName}`, {});
  }

  public async restoreAsync(teamName: string, projectName: string): Promise<any> {
    return lastValueFrom(this.restore(teamName, projectName));
  }
}
