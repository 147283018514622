import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-data-viewer',
  templateUrl: './data-viewer.component.html',
  styleUrls: ['./data-viewer.component.scss']
})
export class DataViewerComponent implements OnInit {
  @Input() path: string;
  @Input() teamName: string;
  @Input() isWidget: boolean;
  @Input() fileType: string;

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      if (!this.teamName) {
        this.load(params.team, params.path);
      }
    });
  }

  public load(teamName: string, path: string) {
    this.path = this.decode(path);
    this.teamName = teamName;
    this.fileType = this.path.split('/').pop().split('.')[1];
  }

  decode(content: string) {
    const decoded = decodeURIComponent(content).replace(/\//g, '//');
    return decoded.replace(/\\/g, '/');
  }
}
