import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-palette-input',
  templateUrl: './palette-input.component.html',
  styleUrls: ['./palette-input.component.scss']
})
export class PaletteInputComponent {
  @ViewChild('colorInput') colorInput: ElementRef<HTMLInputElement>;

  public readonly PRIMARY_PALETTE = [
    ...['#ffffff', '#d1d4dc', '#b2b5be', '#9598a1', '#787b86', '#5d606b', '#434651', '#2a2e39', '#131722', '#000000'],
    ...['#f23645', '#ff9800', '#ffeb3b', '#4caf50', '#089981', '#00bcd4', '#2962ff', '#673ab7', '#9c27b0', '#e91e63']
  ];
  public readonly FULL_PALETTE = [
    ...['#fccbcd', '#ffe0b2', '#fff9c4', '#c8e6c9', '#ace5dc', '#b2ebf2', '#bbd9fb', '#d1c4e9', '#e1bee7', '#f8bbd0'],
    ...['#faa1a4', '#ffcc80', '#fff59d', '#a5d6a7', '#70ccbd', '#80deea', '#90bff9', '#b39ddb', '#ce93d8', '#f48fb1'],
    ...['#f77c80', '#ffb74d', '#fff176', '#81c784', '#42bda8', '#4dd0e1', '#5b9cf6', '#9575cd', '#ba68c8', '#f06292'],
    ...['#f7525f', '#ffa726', '#ffee58', '#66bb6a', '#22ab94', '#26c6da', '#3179f5', '#7e57c2', '#ab47bc', '#ec407a'],
    ...['#b22833', '#f57c00', '#fbc02d', '#388e3c', '#056656', '#0097a7', '#1848cc', '#512da8', '#7b1fa2', '#c2185b'],
    ...['#801922', '#e65100', '#f57f17', '#1b5e20', '#00332a', '#006064', '#0c3299', '#311b92', '#4a148c', '#880e4f']
  ];

  @Input() value: string = '#4caf50';
  @Output() valueChange = new EventEmitter<string>(false);

  public update(value: string): void {
    this.value = value;
    this.valueChange.emit(this.value);
  }

  public $clickSwatch(color: string): void {
    this.update(color);
  }

  public $inputColor(): void {
    this.update(this.colorInput.nativeElement.value);
  }
}
