import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { Feature, FeatureService } from 'src/app/services/feature.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { IdentityService } from 'src/app/services/identity.service';
import { HostsService } from 'src/app/services/hosts.service';
import { HttpClient } from '@angular/common/http';
import { Host } from 'src/app/models/host';
import { TrustService } from 'src/app/services/trust.service';
import { ConfigService } from 'src/app/services/config.service';
import { TeamConfig, TeamsService } from 'src/app/services/teams.service';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss']
})
export class FeaturesComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  @Input() teamName: string;
  public code: string = null;
  displayedColumns: string[] = ['name', 'path'];
  dataSource = new MatTableDataSource<any>([]);
  all: Feature[];
  public featureView: 'ALL' | 'AVAILABLE' | 'DELETED' = 'AVAILABLE';
  public isLoading = true;
  public pageSize = 5;
  public pageSizes = [5, 10, 15, 20, 25];
  private teamConfig: TeamConfig;

  constructor(
    private activatedRoute: ActivatedRoute,
    public router: Router,
    private featureService: FeatureService,
    private el: ElementRef,
    public dialog: MatDialog,
    private http: HttpClient,
    private hostsService: HostsService,
    private teamsService: TeamsService,
    private identity: IdentityService,
    private trustService: TrustService
  ) {}

  ngOnInit(): void {
    if (!this.teamName) {
      this.activatedRoute.params.subscribe((params) => {
        this.teamName = params.team;
        this.load().then(this.optimizePageSize.bind(this));
      });
    } else {
      this.load().then(this.optimizePageSize.bind(this));
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) this.dataSource.paginator.firstPage();
  }

  $changeView(view?: 'ALL' | 'AVAILABLE' | 'DELETED') {
    if (view !== undefined) this.featureView = view;
    switch (this.featureView) {
      case 'ALL':
        this.dataSource.data = this.all;
        break;
      case 'AVAILABLE':
        this.dataSource.data = this.all.filter((x) => x.deleted !== true);
        break;
      case 'DELETED':
        this.dataSource.data = this.all.filter((x) => x.deleted === true);
        break;
    }
  }

  public async load() {
    this.teamConfig = await this.teamsService.getConfigAsync(this.teamName);
    const response = await this.featureService.getAllAsync(this.teamName);
    this.all = response;
    this.$changeView();
    setTimeout(() => {
      this.isLoading = false;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }, 0);
  }

  public $clickAdd() {
    this.router.navigate([this.teamName, 'data', 'new-feature']);
  }

  public $clickRow(feature: Feature) {
    this.router.navigate([this.teamName, 'data', 'feature', feature.name]);
  }

  async save(feature: Feature) {
    const index = this.all.findIndex((x) => x.name === feature.name);
    if (index === -1) this.all.push(feature);
    else this.all[index] = feature;

    const sub = this.update().subscribe({
      error: (error) => {
        sub.unsubscribe();
        console.error(error);
      },
      complete: () => {
        sub.unsubscribe();
      }
    });
  }

  update() {
    return this.postFile({
      path: `${this.teamConfig.path.features}/_index.json`,
      type: 'json',
      data: this.all
    });
  }

  postFile(data: any) {
    return this.http.post(`${ConfigService.apiUrl}/file/${this.teamName}`, data);
  }

  optimizePageSize(): void {
    const table = this.el.nativeElement;
    const thead = table.querySelector('thead');

    if (table && thead) {
      const tableHeight = table ? table.offsetHeight : 0;
      const theadHeight = thead ? thead.offsetHeight : 0;
      const rowHeight = 40;
      const headerHeight = 48;
      const paginatorHeight = 56;
      const footerHeight = 48;

      this.pageSize = Math.floor((tableHeight - headerHeight - footerHeight - theadHeight - paginatorHeight) / rowHeight);
      this.paginator._changePageSize(this.pageSize);
      this.pageSizes = [
        this.pageSize,
        ...Array(20)
          .fill(0)
          .map((v, i) => (i + 1) * 5)
          .filter((v) => v <= this.pageSize)
      ];
    } else {
      setTimeout(this.optimizePageSize.bind(this), 500);
    }
  }
}
