export class IntlService {
  private static readonly _dateFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  } as const;

  private static _timeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
  } as const;

  private static readonly _dateTimeFormat = new Intl.DateTimeFormat(undefined, {
    calendar: 'gregory',
    ...this._dateFormatOptions,
    ...this._timeFormatOptions
  });

  static formatDateTime(date: Date | number): string;
  static formatDateTime(date: number, unixTimestamp: true): string;

  static formatDateTime(date: Date | number, unixTimestamp?: true): string {
    return this._dateTimeFormat.format(date instanceof Date || !unixTimestamp ? date : date * 1000);
  }
}
