// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-builds {
  height: 100%;
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/app/components/strategy-grid/builds-widget/builds-widget.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,cAAA;AACF","sourcesContent":["app-builds {\n  height: 100%;\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
