// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `mat-drawer-container {
  height: 100%;
}

mat-drawer {
  padding: 1rem;
}

mat-grid-tile {
  background: lightblue;
}

.hidden {
  display: none;
}

.first-row {
  margin-top: 10px;
}

app-feature-selector {
  overflow-x: auto;
  width: 100%;
  padding: 10px;
}

::ng-deep .mat-step-text-label {
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
             supported by Chrome, Edge, Opera and Firefox */
  -webkit-touch-callout: none; /* iOS Safari */
}

.warning {
  padding: 20px;
  background: rgba(255, 0, 0, 0.1);
  margin: 20px;
}

#manage-learning {
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/team/project-board/project-board.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,qBAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,gBAAA;EACA,WAAA;EACA,aAAA;AACF;;AAEA;EACE,yBAAA,EAAA,WAAA,EACA,mBAAA,EACA,4BAAA,EACA,2BAAA;EACA,iBAAA,EAAA;2DAAA;EAEA,2BAAA,EAAA,eAAA;AACF;;AAEA;EACE,aAAA;EACA,gCAAA;EACA,YAAA;AACF;;AAEA;EACE,gBAAA;AACF","sourcesContent":["mat-drawer-container {\n  height: 100%;\n}\n\nmat-drawer {\n  padding: 1rem;\n}\n\nmat-grid-tile {\n  background: lightblue;\n}\n\n.hidden {\n  display: none;\n}\n\n.first-row {\n  margin-top: 10px;\n}\n\napp-feature-selector {\n  overflow-x: auto;\n  width: 100%;\n  padding: 10px;\n}\n\n::ng-deep .mat-step-text-label {\n  -webkit-user-select: none; /* Safari */\n  -khtml-user-select: none; /* Konqueror HTML */\n  -moz-user-select: none; /* Old versions of Firefox */\n  -ms-user-select: none; /* Internet Explorer/Edge */\n  user-select: none; /* Non-prefixed version, currently\n                                  supported by Chrome, Edge, Opera and Firefox */\n  -webkit-touch-callout: none; /* iOS Safari */\n}\n\n.warning {\n  padding: 20px;\n  background: rgba(255, 0, 0, 0.1);\n  margin: 20px;\n}\n\n#manage-learning {\n  margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
