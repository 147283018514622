// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

mat-tab-group {
  flex-grow: 1;
  max-height: calc(100% - 24px);
}

::ng-deep .mat-mdc-tab-body-content {
  display: flex;
  flex-direction: column;
}

.base-summary {
  display: flex;
  margin: calc(var(--content-margin) / 2);
  -moz-column-gap: calc(var(--content-gutter) / 2);
       column-gap: calc(var(--content-gutter) / 2);
  justify-content: space-between;
}
.base-summary .no-shrink {
  flex-shrink: 0;
}
.base-summary .more-shrink {
  flex-shrink: 2;
}
.base-summary .most-shrink {
  flex-shrink: 3;
}

.table-container {
  flex-grow: 1;
  overflow: hidden auto;
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.table-container table {
  --mdc-typography-body2-font-family: var(--font-mono);
}
.table-container tr.smol {
  height: 36px;
}

mat-paginator {
  width: 100%;
}

ngx-monaco-editor {
  flex-grow: 1;
}`, "",{"version":3,"sources":["webpack://./src/app/components/strategy-grid/compiler-widget/compiler-widget.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;;AAEA;EACE,YAAA;EACA,6BAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;AACF;;AAEA;EACE,aAAA;EACA,uCAAA;EACA,gDAAA;OAAA,2CAAA;EACA,8BAAA;AACF;AACE;EACE,cAAA;AACJ;AAEE;EACE,cAAA;AAAJ;AAGE;EACE,cAAA;AADJ;;AAKA;EACE,YAAA;EACA,qBAAA;EAEA,+CAAA;AAHF;AAKE;EACE,oDAAA;AAHJ;AAME;EACE,YAAA;AAJJ;;AAQA;EACE,WAAA;AALF;;AAQA;EACE,YAAA;AALF","sourcesContent":[".spinner-container {\n  height: 100%;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\nmat-tab-group {\n  flex-grow: 1;\n  max-height: calc(100% - 24px); // full height w/o widget titlebar\n}\n\n::ng-deep .mat-mdc-tab-body-content {\n  display: flex;\n  flex-direction: column;\n}\n\n.base-summary {\n  display: flex;\n  margin: calc(var(--content-margin) / 2);\n  column-gap: calc(var(--content-gutter) / 2);\n  justify-content: space-between;\n\n  .no-shrink {\n    flex-shrink: 0;\n  }\n\n  .more-shrink {\n    flex-shrink: 2;\n  }\n\n  .most-shrink {\n    flex-shrink: 3;\n  }\n}\n\n.table-container {\n  flex-grow: 1;\n  overflow: hidden auto;\n\n  border-top: 1px solid rgba(255, 255, 255, 0.12);\n\n  table {\n    --mdc-typography-body2-font-family: var(--font-mono);\n  }\n\n  tr.smol {\n    height: 36px;\n  }\n}\n\nmat-paginator {\n  width: 100%;\n}\n\nngx-monaco-editor {\n  flex-grow: 1;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
