import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IdentityService } from '../../../services/identity.service';
import { Title } from '@angular/platform-browser';
import { Team } from 'src/app/models/team';
import { TeamsService } from 'src/app/services/teams.service';
import { MenuService } from 'src/app/services/menu.service';
import { TrustService } from 'src/app/services/trust.service';
import { hostname } from 'os';
import { HostTrustService } from 'src/app/services/host-trust.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SocketService } from 'src/app/services/socket.service';

@Component({
  selector: 'app-projects',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class TeamsMainComponent implements OnInit, OnDestroy {
  newTeamName: string;
  loading = false;
  subs = [];
  teams: Team[] = [];

  public isTrusted: boolean = false;
  public initializing: boolean = false;
  public output: string = '';
  public error: string = null;

  private _hostname: string;
  private _socketSub;

  public hostTrustForm = new FormGroup({
    password: new FormControl<any>(null, Validators.required)
  });

  constructor(
    private _teamService: TeamsService,
    private _identity: IdentityService,
    private _menuService: MenuService,
    private _hostTrustService: HostTrustService,
    private _socketService: SocketService,
    public router: Router,
    public dialog: MatDialog
  ) {}

  async ngOnInit(): Promise<void> {
    await this.verifyTrust();
    if (!this.isTrusted) return;

    this.load();
    this._menuService.delayedSetActive('projects');
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());

  }

  async verifyTrust() {
    const response: any = await this._hostTrustService.isTrustedAsync();
    if (!response) return;

    this.isTrusted = response.isTrusted;
    this._hostname = response.hostName;

    if (!this.isTrusted) {
      this.subscribeToSocketEvents();
    }
  }

  load() {
    this.loading = true;

    this.subs.push(
      this._teamService.load().subscribe((results) => {
        this.teams = results;
        this.loading = false;
      })
    );
  }

  selectTeam(teamName: string) {
    let user = this._identity.me;
    if (!user.teams.find((ns) => ns.name === teamName)) {
      user.teams.push(this.teams.find((ns) => ns.name === teamName));
    }

    localStorage.setItem('lastTeamSelected', teamName);
    this._identity.setTeam(teamName);
    this.router.navigate(['/', teamName, 'grid']);
  }

  saveNewTeam() {
    const sub = this._teamService.save({ name: this.newTeamName }).subscribe({
      error: (error) => {
        sub.unsubscribe();
        console.error(error);
        alert(error.error);
      },
      complete: () => {
        sub.unsubscribe();
        window.location.reload();
      }
    });
  }

  createTrust() {
    this.initializing = true;
    this.error = null;
    this.output = 'Initializing trust';
    const raw = this.hostTrustForm.getRawValue();
    const sub = this._hostTrustService.add(raw.password).subscribe((response: any) => {
      sub.unsubscribe();
    });
  }

  subscribeToSocketEvents() {
    this.subs.push(this._socketService.initPipEvents.subscribe(async (response: any) => {
      switch (response.status) {
        case 'INIT':
          this.output = response.msg;
          break;
        case 'COMPLETE':
          this.output = response.msg;
          this.initializing = false;
          this.output = '';
          location.reload();
          break;
        case 'ERROR':
          this.initializing = false;
          this.error = response.msg;
          this.output = '';
          break;
        default:
          break;
      }
    }));
  }
}
