import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { Observable, Observer, Subscription } from 'rxjs';

type ObserverLike<T> = Partial<Observer<T>> | ((value: T) => void);

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  events = this.socket.fromEvent<any>('event');
  sshEvents = this.socket.fromEvent<any>('ssh');
  scpEvents = this.socket.fromEvent<any>('scp');
  initEvents = this.socket.fromEvent<any>('init');
  initPipEvents = this.socket.fromEvent<any>('pip-init');

  constructor(private socket: Socket) {}

  joinRoom(room: string = 'all') {
    this.socket.emit('subscribe', room);
  }

  leaveRoom(room: string = 'all') {
    this.socket.emit('unsubscribe', room);
  }

  subscribeToRoomEvents<T>(roomName: string, observerOrNext?: ObserverLike<T>): Subscription {
    return new Observable<T>((observer) => {
      this.events.subscribe((event) => {
        if (event && event?.$room === roomName) observer.next(event);
      });
    }).subscribe(observerOrNext);
  }

  subscribeToRoomMessages(roomName: string): Observable<any> {
    return new Observable((observer) => {
      this.socket.on(roomName, (data: any) => {
        observer.next(data);
      });
    });
  }

  sshConnect(user: any) {
    this.socket.emit('ssh-connect', user);
  }

  sshDisconnect() {
    this.socket.emit('ssh-disconnect');
  }

  emit(eventName: string, cmd: any) {
    this.socket.emit(eventName, cmd);
  }

  scp(data: any) {
    this.socket.emit('scp', data);
  }

  init(url: string, sshPort: number, licensee: string, signature: string) {
    this.socket.emit('init', url, sshPort, licensee, signature);
  }
}
