// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
  margin: 2.5rem 5rem;
}

.subtext {
  font-size: 14px;
}

mat-card-title {
  cursor: pointer;
}

.trust-card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.trust-card {
  max-width: 600px;
  min-width: 600px;
}

.trust-text {
  margin-top: 5px;
  margin-bottom: 15px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 11pt;
}

.trust-error {
  margin-top: 5px;
  margin-bottom: 15px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 11pt;
  color: red;
}

.init {
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 10pt;
  font-family: "Roboto";
}`, "",{"version":3,"sources":["webpack://./src/app/components/teams/main/main.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;AACF;;AAEA;EACE,gBAAA;EACA,gBAAA;AACF;;AAEA;EACE,eAAA;EACA,mBAAA;EACA,iDAAA;EACA,eAAA;AACF;;AAEA;EACE,eAAA;EACA,mBAAA;EACA,iDAAA;EACA,eAAA;EACA,UAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,eAAA;EACA,qBAAA;AACF","sourcesContent":[".content {\n  margin: 2.5rem 5rem;\n}\n\n.subtext {\n  font-size: 14px;\n}\n\nmat-card-title {\n  cursor: pointer;\n}\n\n.trust-card-container {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-top: 20px;\n}\n\n.trust-card {\n  max-width: 600px;\n  min-width: 600px;\n}\n\n.trust-text {\n  margin-top: 5px;\n  margin-bottom: 15px;\n  font-family: Roboto, 'Helvetica Neue', sans-serif;\n  font-size: 11pt;\n}\n\n.trust-error {\n  margin-top: 5px;\n  margin-bottom: 15px;\n  font-family: Roboto, 'Helvetica Neue', sans-serif;\n  font-size: 11pt;\n  color: red;\n}\n\n.init {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n  font-size: 10pt;\n  font-family: 'Roboto';\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
