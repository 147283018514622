import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class StreamEngineService {
  public serviceClick$: Subject<{ parentId: string; serviceName: string }> = new Subject();

  constructor(private http: HttpClient) {}

  public load(teamName: string): Observable<any> {
    return this.http.get(`${ConfigService.apiUrl}/streams/${teamName}`);
  }

  public loadOne(teamName: string, serviceName: string): Observable<any> {
    return this.http.get(`${ConfigService.apiUrl}/streams/${teamName}/${serviceName}`);
  }

  public start(teamName: string, serviceName: string): Observable<any> {
    const body = { name: serviceName, action: 'start' };
    return this.http.post(`${ConfigService.apiUrl}/streams/action/${teamName}`, body);
  }

  public restart(teamName: string, serviceName: string): Observable<any> {
    const body = { name: serviceName, action: 'restart' };
    return this.http.post(`${ConfigService.apiUrl}/streams/action/${teamName}`, body);
  }

  public stop(teamName: string, serviceName: string): Observable<any> {
    const body = { name: serviceName, action: 'stop' };
    return this.http.post(`${ConfigService.apiUrl}/streams/action/${teamName}`, body);
  }
}
