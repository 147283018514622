// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  display: flex;
  flex-direction: column;
}

.error-row {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 11pt;
  color: red;
}

.text-row {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 11pt;
}

.button-row {
  display: flex;
  justify-content: flex-end; /* Align items to the right */
  padding: 10px; /* Optional: Add padding for spacing */
}

.mat-mdc-button {
  color: black !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/info-snackbar/info-snackbar.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;;AAIA;EACE,iDAAA;EACA,eAAA;EACA,UAAA;AADF;;AAIA;EACE,iDAAA;EACA,eAAA;AADF;;AAIA;EACE,aAAA;EACA,yBAAA,EAAA,6BAAA;EACA,aAAA,EAAA,sCAAA;AADF;;AAIA;EACE,uBAAA;AADF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n\n\n}\n\n.error-row {\n  font-family: Roboto, \"Helvetica Neue\", sans-serif;\n  font-size: 11pt;\n  color: red;\n}\n\n.text-row {\n  font-family: Roboto, \"Helvetica Neue\", sans-serif;\n  font-size: 11pt;\n}\n\n.button-row {\n  display: flex;\n  justify-content: flex-end; /* Align items to the right */\n  padding: 10px; /* Optional: Add padding for spacing */\n}\n\n.mat-mdc-button {\n  color: black !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
