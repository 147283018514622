import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class DirectoryService {
  constructor(private http: HttpClient) {}

  public loadDirs(teamName: string) {
    return this.http.get(`${ConfigService.apiUrl}/dirs/${teamName}`);
  }

  public createDirAsync(teamName: string, path: string) {
    return lastValueFrom(this.http.post(`${ConfigService.apiUrl}/dirs/${teamName}`, { path }));
  }

  public renameDirAsync(teamName: string, currentPath: string, newPath: string) {
    return lastValueFrom(this.http.put(`${ConfigService.apiUrl}/dirs/${teamName}`, { currentPath, newPath }));
  }
}
