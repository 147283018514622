import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Project } from 'src/app/models/project';
import { IdentityService } from 'src/app/services/identity.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { RawService } from 'src/app/services/raw.service';

@Component({
  selector: 'app-file-selector',
  templateUrl: './file-selector.component.html',
  styleUrls: ['./file-selector.component.scss']
})
export class FileSelectorComponent implements OnInit, OnChanges {
  @Input() teamName: string;
  @Input() project: Project;
  @Output() onProjectUpdate = new EventEmitter<{ project: Project }>();
  @Output() onCachingFiles = new EventEmitter<{ cachingCount: number }>();

  public rawPathsAll: any = [];
  public rawPathsFiltered: any = [];
  private _selected: string[] = [];

  fileSelectorForm = new FormGroup({
    paths: new FormControl<any>('', Validators.required)
  });

  constructor(
    private rawService: RawService,
    private projectService: ProjectsService,
    private identity: IdentityService
  ) {
    (<any>window).fileSelector = this;
  }

  async ngOnInit(): Promise<void> {
    await this.init();
  }

  async ngOnChanges(_changes: SimpleChanges): Promise<void> {
    if (_changes.project.firstChange || _changes.project.currentValue?.name === _changes.project.previousValue?.name) return;

    await this.init();
  }

  async init() {
    const rawData: any = await this.rawService.getAsync(this.teamName);
    this.rawPathsAll = rawData.map((x) => x.fullPath);
    this.rawPathsFiltered = this.rawPathsAll;

    this._selected = this.project?.raw?.filter((x) => this.rawPathsAll.includes(x));

    this.fileSelectorForm.get('paths').setValue(this._selected);
    const cachingCount = rawData.filter((x) => x.cacheFileScreen !== null).length;
    this.onCachingFiles.emit({ cachingCount });
  }

  applyFilter(filterValue: string): void {
    if (!filterValue || filterValue.length === 0) {
      this.rawPathsFiltered = this.rawPathsAll;
      return;
    }

    this.rawPathsFiltered = this.rawPathsAll.filter((s) => s.includes(filterValue));
  }

  async onPathsChange($event) {
    let item: string = null;
    let added: boolean = false;

    if (this._selected.length > $event.value.length) {
      item = this._selected.find((x) => !$event.value.includes(x));
    }

    if (this._selected.length < $event.value.length) {
      item = $event.value.find((x) => !this._selected.includes(x));
      added = true;
    }

    if (!item) return;

    await this.projectService.updateRaw(this.teamName, this.project.name, item, added);
    this._selected = $event.value;
    this.project.raw = this._selected;
    this.identity.setProject(this.project);
    this.onProjectUpdate.emit({ project: this.project });
  }
}
