// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  width: 100%;
}

.compact {
  height: 38px !important;
}

tr.element-row:not(.expanded-row):hover {
  background: whitesmoke;
}

tr.element-row:not(.expanded-row):active {
  background: #efefef;
}

.element-row td {
  border-bottom-width: 0;
  font-size: 10pt;
}

.element-detail {
  display: flex;
  overflow: hidden;
}

tr.detail-row {
  height: 0;
}

tr {
  cursor: pointer;
}

app-feature {
  margin: 10px 50px;
}

.dim {
  opacity: 0.5;
}

#scrollable {
  overflow: auto;
  height: calc(100% - 100px);
}

.table-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.table-controls .status-filter {
  border: none !important;
  border-radius: 0;
}
.table-controls .status-filter span {
  line-height: 3.5rem;
}`, "",{"version":3,"sources":["webpack://./src/app/components/data/features/features.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAEA;EACE,uBAAA;AACF;;AAEA;EACE,sBAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,sBAAA;EACA,eAAA;AACF;;AAEA;EACE,aAAA;EACA,gBAAA;AACF;;AAEA;EACE,SAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,cAAA;EACA,0BAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,WAAA;AACF;AACE;EACE,uBAAA;EACA,gBAAA;AACJ;AACI;EACE,mBAAA;AACN","sourcesContent":["table {\n  width: 100%;\n}\n\n.compact {\n  height: 38px !important;\n}\n\ntr.element-row:not(.expanded-row):hover {\n  background: whitesmoke;\n}\n\ntr.element-row:not(.expanded-row):active {\n  background: #efefef;\n}\n\n.element-row td {\n  border-bottom-width: 0;\n  font-size: 10pt;\n}\n\n.element-detail {\n  display: flex;\n  overflow: hidden;\n}\n\ntr.detail-row {\n  height: 0;\n}\n\ntr {\n  cursor: pointer;\n}\n\napp-feature {\n  margin: 10px 50px;\n}\n\n.dim {\n  opacity: 0.5;\n}\n\n#scrollable {\n  overflow: auto;\n  height: calc(100% - 100px);\n}\n\n.table-controls {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  width: 100%;\n\n  .status-filter {\n    border: none !important;\n    border-radius: 0;\n\n    span {\n      line-height: 3.5rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
