import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class VersionService {
  constructor(private _http: HttpClient) {
    (<any>window).$version = this;
  }

  public async get() {
    return await lastValueFrom(this._http.get<{ version: string }>(`${ConfigService.apiUrl}/version`));
  }
}
