import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  constructor(private http: HttpClient) {}

  public loadFeaturesAsync(teamName: string) {
    return lastValueFrom(this.http.get<any[]>(`${ConfigService.apiUrl}/data-features/${teamName}`));
  }

  public loadModelsAsync(teamName: string) {
    return lastValueFrom(this.http.get<any[]>(`${ConfigService.apiUrl}/data-models/${teamName}`));
  }

  public loadAdaptersAsync(teamName: string) {
    return lastValueFrom(this.http.get<any[]>(`${ConfigService.apiUrl}/data-adapters/${teamName}`));
  }

  public capture(teamName: string, models: string[]) {
    return this.http.post(`${ConfigService.apiUrl}/data-capture/${teamName}`, { models });
  }

  public async pollAsync(teamName: string) {
    return lastValueFrom(this.http.get(`${ConfigService.apiUrl}/data-capture/${teamName}`));
  }

  public static humanReadable(bytes, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }

    const units = si ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return bytes.toFixed(dp) + ' ' + units[u];
  }
}
