import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { WidgetService } from 'src/app/services/widget.service';
import { StreamsService } from 'src/app/services/streams.service';
import { ConfigService } from 'src/app/services/config.service';
@Component({
  selector: 'app-stream-rest-widget',
  templateUrl: './stream-rest-widget.component.html',
  styleUrls: ['../base-widget/base-widget.component.scss', './stream-rest-widget.component.scss']
})
export class StreamRestWidgetComponent extends BaseWidgetComponent implements OnInit {

  @Input() name: string;
  @Output() onIFrameFocus = new EventEmitter<{id: string}>();

  public port: number;
  public url: string = null;
  @ViewChild('swaggerFrame') swaggerFrame!: ElementRef;

  public i = 0;
  constructor(private _widgetService: WidgetService, private _streamsService: StreamsService) {
    super();
  }

  ngOnInit(): void {
    const sub = this._streamsService.get(this.teamName, this.name).subscribe((response: any) => {

      try {
        this.port = response.parameters.port;
        const parsedURL = new URL(ConfigService.hostUrl)
        this.url = `${parsedURL.protocol}//${parsedURL.hostname}:${this.port}/api/docs`

        this._widgetService.resize$.subscribe((response) => {
          if (response.item.id === this.id) this.optimizePageSize();
        });

        setTimeout(() => {
          this.optimizePageSize();
        }, 0)
      } catch(ex) {
        console.error(ex);
      }
    });
  }

  optimizePageSize() {
    try {

      const parent = (<any>document.querySelector('app-stream-rest-widget'));

      const parentHeight: number = Math.max(parent.offsetHeight, parent.scrollHeight);
      const parentWidth: number = parent.offsetWidth;

      const iframe: any = document.getElementById('swagger-frame');
      this.swaggerFrame.nativeElement.style.width = parentWidth + 'px';
      this.swaggerFrame.nativeElement.style.height = parentHeight + 'px';
    }  catch(ex) {}
  }

  onIFrameClick() {
    this.onIFrameFocus.emit({id: this.id});
  }
}
