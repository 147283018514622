import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Feature, FeatureService } from 'src/app/services/feature.service';
import { TeamConfig, TeamsService } from 'src/app/services/teams.service';
import { FileService } from 'src/app/services/file.service';

@Component({
  selector: 'app-feature',
  templateUrl: './feature.component.html',
  styleUrls: ['./feature.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FeatureComponent implements OnInit {
  public teamName: string;
  public teamConfig: TeamConfig;
  public hostName: string;
  public featureName: string;

  public feature: Feature;
  public features: Feature[];
  public dataLoaded: boolean = false;
  public formLoaded: boolean = false;
  public code: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    public router: Router,
    private teamsService: TeamsService,
    private featureService: FeatureService,
    private fileService: FileService
  ) {
    (<any>window).feature = this;
  }

  async ngOnInit() {
    const { team: teamName, host: hostName, name: featureName } = this.activatedRoute.snapshot.params;
    Object.assign(this, { teamName, hostName, featureName });
    this.load();
  }

  async load() {
    await Promise.all([
      (async () => (this.teamConfig = await this.teamsService.getConfigAsync(this.teamName)))(),
      (async () => (this.features = await this.featureService.getAllAsync(this.teamName)))()
    ]);
    this.feature = this.features.find(({ name }) => name === this.featureName);
    this.dataLoaded = true;

    this.fileService.get(this.teamName, this.feature.path).then((code) => {
      this.code = code;
    });
  }

  $loaded() {
    this.formLoaded = true;
  }

  $clickClone() {
    this.router.navigate([this.teamName, 'data', 'new-feature'], {
      queryParams: { hostName: this.hostName, featureName: this.featureName }
    });
  }

  $toggleDelete() {
    this.dataLoaded = false;
    this.formLoaded = false;
    const handler = (() => this.router.navigate([this.teamName, 'data', 'features'])).bind(this);
    if (this.feature.deleted === true) this.featureService.restoreAsync(this.teamName, this.featureName).then(handler);
    else this.featureService.deleteAsync(this.teamName, this.featureName).then(handler);
  }
}
