import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class StratsService {
  constructor(private http: HttpClient) {}

  public load = (teamName: string) => this.http.get(`${ConfigService.apiUrl}/strats/${teamName}`);

  public loadReports = (teamName: string) => this.http.get(`${ConfigService.apiUrl}/strats/reports/${teamName}`);

  public loadBreakout(teamName: string, ticker: string, model: string, strategy: string, days: number) {
    let url = `${ConfigService.apiUrl}/strats/breakout/${teamName}/${ticker}/${model}/${strategy}/${days}`;
    return this.http.get(url);
  }
}
