// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
  margin: 10px 10px 10px 10px;
  height: 100%;
}

.save-error {
  margin-bottom: 5px;
  font-size: 15px;
  color: red;
}

.progress {
  margin-bottom: 5px;
  font-size: 15px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/models/stream/stream.component.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,YAAA;AACF;;AAEA;EACE,kBAAA;EACA,eAAA;EACA,UAAA;AACF;;AAEA;EACE,kBAAA;EACA,eAAA;AACF","sourcesContent":[".content {\n  margin: 10px 10px 10px 10px;\n  height: 100%;\n}\n\n.save-error {\n  margin-bottom: 5px;\n  font-size: 15px;\n  color: red;\n}\n\n.progress {\n  margin-bottom: 5px;\n  font-size: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
