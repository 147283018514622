import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HostTrustService {
  constructor(private http: HttpClient) {}
  public add(password: string) {
    return this.http.post(`${ConfigService.apiUrl}/host-trust`, { password });
  }

  async addTrust(password: string) {
    await lastValueFrom(this.add(password));
  }

  public isTrusted() {
    return this.http.get(`${ConfigService.apiUrl}/host-trust`);
  }

  public async isTrustedAsync() {
    return await lastValueFrom(this.isTrusted());
  }
}
