import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-csv-viewer',
  templateUrl: './csv-viewer.component.html',
  styleUrls: ['./csv-viewer.component.scss']
})
export class CsvViewerComponent implements OnInit, AfterViewInit {
  private paginator: MatPaginator;
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
  }

  @Input() teamName: string;
  @Input() filePath: string;

  public pageSize = 10;
  public pageNumber = 1;
  public isLoading = true;
  public fetching = false;
  public displayedColumns = [];
  public dataSource = new MatTableDataSource<any>([]);
  public numPages = 0;

  constructor(private http: HttpClient) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.optimizePageSize();
    }, 0);
  }

  ngOnInit(): void {
    this.load(this.pageNumber);
  }

  load(pageNumber: number) {
    this.pageNumber = pageNumber;
    const sub = this.http
      .get(
        `${ConfigService.apiUrl}/csv/${this.teamName}/${encodeURI(this.filePath.replace(/\//g, '\\'))}/${this.pageSize}/${this.pageNumber}`
      )
      .subscribe((response: any) => {
        sub.unsubscribe();

        if (!response || response.data.length === 0) {
          this.displayedColumns = ['empty-row'];
          this.dataSource.data = [{ 'empty-row': 'No Data' }];
          this.isLoading = false;
          if (response.error) {
            alert(response.error);
          }
          return;
        }

        if (this.pageNumber === 1) {
          this.numPages = response.totalPages;
          this.displayedColumns = Object.keys(response.data[0]).filter((x) => !x.startsWith('field'));
        }

        this.dataSource.data = response.data;
        this.isLoading = false;
        this.fetching = false;
      });
  }

  stringify(data) {
    if (typeof data == 'object') return JSON.stringify(data);
    return data;
  }

  public page(event: PageEvent) {
    this.fetching = true;
    let pageIndex = event.pageIndex + 1;

    if (this.pageSize !== event.pageSize) {
      this.paginator.pageIndex = 0;
      pageIndex = 1;
    }

    this.pageSize = event.pageSize;
    this.load(pageIndex);
  }

  optimizePageSize() {
    const parentHeight: number = (<any>document.querySelector('app-csv-viewer')).offsetHeight;
    var x = parentHeight;

    this.pageSize = Math.round(0.026 * x - 8);
    if (this.paginator) {
      this.paginator._changePageSize(this.pageSize);
    }
  }
}
