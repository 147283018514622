import { Pipe, PipeTransform } from '@angular/core';
import { IntlService } from '../services/intl.service';
import { isNil } from 'lodash';
import { toTitleCase } from '../directives/string.util';
import { DecimalPipe } from '@angular/common';

@Pipe({ name: 'safeNumber' })
export class SafeNumberPipe implements PipeTransform {
  transform(value: any, args: string) {
    let pipe = new DecimalPipe('en-US');
    value = isNaN(value) ? 0 : +value;
    return pipe.transform(value, args);
  }
}

@Pipe({ name: 'unixTimetoLocalDate' })
export class FormatUnixTimePipe implements PipeTransform {
  transform = (value: number) => IntlService.formatDateTime(value, true);
}

@Pipe({ name: 'secondsToDHMS' })
export class FormatSecondsPipe implements PipeTransform {
  transform(value: number): string {
    let seconds = Math.ceil(value);

    const days = Math.floor(seconds / (3600 * 24));
    seconds -= days * 3600 * 24;
    const hours = Math.floor(seconds / 3600);
    seconds -= hours * 3600;
    const minutes = Math.floor(seconds / 60);
    seconds -= minutes * 60;

    const leadingZero = (input: number) => (input < 10 ? `0${input}` : input.toString());
    return `${leadingZero(days)}:${leadingZero(hours)}:${leadingZero(minutes)}:${leadingZero(seconds)}`;
  }
}

@Pipe({ name: 'transformBytes' })
export class FormatBytes implements PipeTransform {
  transform(value: number, decimals: number = 2): string {
    if (isNil(value) || value === 0 || isNaN(value)) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(value) / Math.log(k));

    return parseFloat((value / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}

@Pipe({ name: 'toTitleCase' })
export class FormatToTitleCase implements PipeTransform {
  transform(value: string) {
    return toTitleCase(value);
  }
}

@Pipe({ name: 'ellipsize' })
export class FormatEllipsize implements PipeTransform {
  transform(value: string, maxLength: number) {
    if (value.length <= maxLength) return value;
    const maxStringLength = maxLength - 1;
    let end = maxStringLength;

    for (let i = 0; i < maxStringLength; ++i) if (' _-'.indexOf(value.charAt(i)) !== -1) end = i;

    return value.slice(0, end) + '...';
  }
}
