import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ProjectStats } from 'src/app/models/stats';

@Component({
  selector: 'app-user-project-stats',
  templateUrl: './user-project-stats.component.html',
  styleUrls: ['./user-project-stats.component.scss']
})
export class UserProjectStatsComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input() projectStats: ProjectStats[] = [];

  public displayedColumns = ['project', 'totalTime', 'totalCost'];
  public dataSource = new MatTableDataSource<ProjectStats>([]);

  constructor() {}

  ngOnInit(): void {
    this.dataSource.data = this.projectStats;
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
}
