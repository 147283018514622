// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --wfc-bg: #e0e0e0;
  --wfc-primary: #616a74;
  --wfc-green: #9ccc65;
  --wfc-red: #f44336;
  --wfc-port-fill: color-mix(in srgb, var(--wfc-primary) 50%, var(--wfc-bg));
  --wfc-lines: #8a939a;
  --wfc-icon: #d1d7e4;
  --wfc-text-color: #464a53;
  --wfc-label-color: color-mix(in srgb, var(--wfc-primary) 15%, var(--wfc-bg));
  --wfc-unselected-blend: 25%;
  --wsl-selected-light-mod: 10%;
}

.dragging {
  cursor: grabbing;
}

.no-cursor {
  cursor: none;
}`, "",{"version":3,"sources":["webpack://./src/app/components/models/definition/workflow-canvas/workflow-canvas.vars.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,sBAAA;EACA,oBAAA;EACA,kBAAA;EACA,0EAAA;EACA,oBAAA;EACA,mBAAA;EACA,yBAAA;EACA,4EAAA;EACA,2BAAA;EACA,6BAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,YAAA;AACF","sourcesContent":[":host {\n  --wfc-bg: #e0e0e0;\n  --wfc-primary: #616a74;\n  --wfc-green: #9ccc65;\n  --wfc-red: #f44336;\n  --wfc-port-fill: color-mix(in srgb, var(--wfc-primary) 50%, var(--wfc-bg));\n  --wfc-lines: #8a939a;\n  --wfc-icon: #d1d7e4;\n  --wfc-text-color: #464a53;\n  --wfc-label-color: color-mix(in srgb, var(--wfc-primary) 15%, var(--wfc-bg));\n  --wfc-unselected-blend: 25%;\n  --wsl-selected-light-mod: 10%;\n}\n\n.dragging {\n  cursor: grabbing;\n}\n\n.no-cursor {\n  cursor: none;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
