import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Gpu } from 'src/app/models/gpu';
import { HostStatsService } from 'src/app/services/host-stats.service';
@Component({
  selector: 'app-host-gpu-stats',
  templateUrl: './host-gpu-stats.component.html',
  styleUrls: ['./host-gpu-stats.component.scss']
})
export class HostGpuStatsComponent implements OnInit, OnChanges {
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  @Input() teamName: string = '';
  @Input() hostValue: number = null;

  public totalROI: number = null;
  public dataSource = new MatTableDataSource<Gpu>([]);
  public displayedColumns = ['name', 'pciBusId', 'vbiosVersion', 'memoryTotal', 'timeUsed', 'value', 'roi'];

  constructor(private hostStatsService: HostStatsService) {}

  ngOnInit(): void {
    this.load();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.calculateROI(changes.hostValue?.currentValue);
  }

  load() {
    if (!this.teamName) {
      return;
    }

    const sub = this.hostStatsService.getGPUStats(this.teamName).subscribe((response: Gpu[]) => {
      sub.unsubscribe();
      this.dataSource.data = response;
      this.dataSource.sort = this.sort;
      this.calculateROI(this.hostValue);
    });
  }

  calculateROI(hostValue: number) {
    this.totalROI = this.dataSource.data.reduce((a, b) => a + (hostValue * (b['timeUsed'] / 3600) || 0), 0);
  }
}
