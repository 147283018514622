import { coerceStringArray } from '@angular/cdk/coercion';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { removeAffixes, toTitleCase } from 'src/app/directives/string.util';
import { Host } from 'src/app/models/host';
import { HostsService } from 'src/app/services/hosts.service';
import { RelocateRawParameters } from 'src/app/services/raw.service';
import { TeamsService } from 'src/app/services/teams.service';

export type MoveRawDialogData = {
  raws: any[];
  mode: 'copy' | 'move';
  teamName: string;
};

@Component({
  selector: 'app-move-raw-dialog',
  templateUrl: './move-raw-dialog.component.html',
  styleUrls: ['./move-raw-dialog.component.scss']
})
export class MoveRawDialogComponent {

  loading: boolean = true;
  titleVerb: string;
  buttonVerb: string;
  selectedDir: string;


  constructor(
    private hostService: HostsService,
    private teamsService: TeamsService,
    public dialogRef: MatDialogRef<MoveRawDialogComponent, RelocateRawParameters[]>,
    @Inject(MAT_DIALOG_DATA) public data: MoveRawDialogData
  ) {
    (<any>window).moveRaw = this;

    const mode = this.data.mode;
    this.loading = false;
    this.titleVerb = mode === 'copy' ? 'Copying' : 'Moving';
    this.buttonVerb = toTitleCase(mode);
  }

  $clickAction(): void {
    if (!this.selectedDir) {
      this.dialogRef.close(null);
      return;
    }

    const results: RelocateRawParameters[] = [];

    for (let raw of this.data.raws) {
      const src = raw.fullPath;
      const dst = `${this.selectedDir.split(":")[1]}/${src.split("/").pop()}`;
      if (dst !== src) results.push({src, dst});
    }

    this.dialogRef.close(results);
  }

  $clickClose(): void {
    this.dialogRef.close();
  }

  onSelectDirectory($event) {
    this.selectedDir = $event;
  }
}
