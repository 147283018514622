import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { Subject } from 'rxjs';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { Project } from '../models/project';

@Injectable()
export class IdentityService {
  public session:any = {};

  private _me$: Subject<User> = <Subject<User>>new Subject();
  get me$() {
    return this._me$.asObservable();
  }

  private _remove$: Subject<User> = <Subject<User>>new Subject();
  get remove$() {
    return this._remove$.asObservable();
  }

  private _projectChange$: Subject<string> = <Subject<string>>new Subject();
  get projectChange$() {
    return this._projectChange$.asObservable();
  }

  private _teamChange$: Subject<string> = <Subject<string>>new Subject();
  get teamChange$() {
    return this._teamChange$.asObservable();
  }

  constructor(private http: HttpClient) {
    window.$identity = this;
  }

  public get me(): User {
    const cached = localStorage.getItem('me');
    return cached !== null ? JSON.parse(cached) : {};
  }

  public reload(): void {
    this.http.get(`${ConfigService.apiUrl}/me`).subscribe((response: any) => {
      this.saveMe({
        ...this.me,
        ...response
      });
    });
  }

  public saveMe(data: User = null, emit: boolean = true): void {
    data ??= this.me;
    localStorage.setItem('me', JSON.stringify(data));
    if (emit) this._me$.next(data);
  }

  public unloadMe(): void {
    localStorage.removeItem('me');
    this._remove$.next(null);
  }

  private setProperty(field: string, value: any, emit: boolean = true): User {
    var me: User = this.me;
    if (value === me[field]) return;

    me[field] = value;
    this.saveMe(me, emit);
    return me;
  }

  public setTeam(value: string): void {
    this.setProperty('selectedTeamName', value);
    this._teamChange$.next(value);
  }

  public updateProject(value: Project): void {
    if (value.name !== this.me.selectedProjectName) {
      console.warn('updateProject should only be used to update the project, never to change the selected project (name mismatch)');
      this.setProject(value);
    } else {
      this.setProperty('selectedProject', value, false);
    }
  }

  public setProject(value: Project): void {
    this.setProperty('selectedProject', value, false);
    this.setProperty('selectedProjectName', value?.name);
    this._projectChange$.next(value?.name);
  }
}
