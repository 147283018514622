import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard extends KeycloakAuthGuard {
  constructor(
    protected readonly router: Router,
    protected readonly keycloak: KeycloakService
  ) {
    super(router, keycloak);
  }

  async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.authenticated) {
      await this.keycloak.login({
        redirectUri: window.location.origin + state.url
      });
    }

    const token = await this.keycloak.getToken();
    localStorage.setItem('id_token', token);
    const decodedJWT = JSON.parse(window.atob(token.split('.')[1]));
    return this.authenticated;
  }

  logout() {
    console.log('logout');
    // remove user from local storage to log user out
    localStorage.removeItem('id_token');
    localStorage.removeItem('me');
    this.keycloak.logout(window.location.href);
  }
}
