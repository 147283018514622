import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { StreamsService } from 'src/app/services/streams.service';

@Component({
  selector: 'app-stream-api-logs',
  templateUrl: './stream-api-logs.component.html',
  styleUrls: ['./stream-api-logs.component.scss']
})
export class StreamApiLogsComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input() teamName: string = null;
  @Input() streamName: string = null;

  public dataSource = new MatTableDataSource<any>([]);
  public displayedColumns = [
    'timestamp',
    'host',
    'endpoint',
    'method',
    'api_key',
    'remote_addr',
    'response_message',
    'response_status',
    'response_time'
  ];
  public isLoading = true;

  public pageInfo = {
    totalPages: 0,
    currentPage: 0,
    hasNextPage: false,
    hasPrevPage: false,
    total: 0,
    sortColumn: 'timestamp',
    sortOrder: 'desc',
    pageSize: 10
  };

  constructor(private _streamsService: StreamsService) {}

  public async ngAfterViewInit() {
    this.sort.sortChange.subscribe(async (value: Sort) => {
      console.log(value);
      this.isLoading = true;
      this.pageInfo.sortColumn = value.active;
      if (value.direction && value.direction.length > 0) {
        this.pageInfo.sortOrder = value.direction;
      } else {
        this.pageInfo.sortOrder = this.pageInfo.sortOrder === 'asc' ? 'desc' : 'asc';
      }

      this.paginator.pageIndex = 0;
      this.pageInfo.currentPage = 0;

      await this.getLogs();
      this.isLoading = false;
    });

    await this.getLogs();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.isLoading = false;
  }

  ngOnInit(): void {}

  public async getLogs() {
    const results = await this._streamsService.getApiLogsAsync(
      this.teamName,
      this.streamName,
      this.pageInfo.pageSize,
      this.pageInfo.currentPage + 1,
      this.pageInfo.sortColumn,
      this.pageInfo.sortOrder
    );

    if (!results) {
      this.dataSource.data = [];
      this.pageInfo = {
        totalPages: 0,
        currentPage: 0,
        hasNextPage: false,
        hasPrevPage: false,
        total: 0,
        sortColumn: 'timestamp',
        sortOrder: 'desc',
        pageSize: 10
      };
      return;
    }

    this.dataSource.data = results.perf;
    this.pageInfo.total = results.total;
    this.pageInfo.totalPages = results.totalPages;
    this.pageInfo.currentPage = this.paginator.pageIndex;
    this.pageInfo.hasNextPage = results.hasNextPage;
    this.pageInfo.hasPrevPage = results.hasPrevPage;

    setTimeout(() => this.updatePaginator(), 10);
  }

  public async onChangePage({ pageSize, pageIndex }: PageEvent) {
    this.isLoading = true;
    this.pageInfo.pageSize = pageSize;
    this.pageInfo.currentPage = pageIndex;
    await this.getLogs();
    this.isLoading = false;
  }

  public updatePaginator() {
    this.paginator.length = this.pageInfo.total;
    this.paginator.pageSize = this.pageInfo.pageSize;
    this.paginator.pageIndex = this.pageInfo.currentPage;
  }
}
