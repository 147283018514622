import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { of as observableOf } from 'rxjs';
import { DirectoryService } from 'src/app/services/directory.service';
import { IdentityService } from 'src/app/services/identity.service';
import { HostsService } from 'src/app/services/hosts.service';
import { TrustService } from 'src/app/services/trust.service';
import { Host } from 'src/app/models/host';
import { TeamsService } from 'src/app/services/teams.service';

export interface TreeNode {
  name: string;
  type: string;
  host: string;
  path: string;
  isLocal: boolean;
  level: number;
  expandable: boolean;
}

@Component({
  selector: 'app-directory',
  templateUrl: './directory.component.html',
  styleUrls: ['./directory.component.scss']
})
export class DirectoryComponent implements OnInit {
  @Input() teamName: string;
  @Output() onSelect = new EventEmitter<any>();

  public treeControl: FlatTreeControl<TreeNode>;
  public treeFlattener: MatTreeFlattener<any, TreeNode>;
  public dataSource: MatTreeFlatDataSource<any, TreeNode>;
  public editNode: any;
  public originalName: string;
  public destination = null;

  private nodes: any[] = [];
  private teamConfig: any = null;

  constructor(
    private identity: IdentityService,
    private hostsService: HostsService,
    private trustService: TrustService,
    private teamsService: TeamsService,
    private directoryService: DirectoryService
  ) {}

  async ngOnInit() {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);

    this.treeControl = new FlatTreeControl<TreeNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    this.teamConfig = await this.teamsService.getConfigAsync(this.teamName);

    const sub = this.directoryService.loadDirs(this.teamName).subscribe((response: any) => {
      sub.unsubscribe();
      this.nodes = response;
      this.dataSource.data = this.nodes;
    });
  }

  /** Transform the data to something the tree can read. */
  transformer(node: any, level: number) {
    return {
      name: node.name,
      type: node.type,
      host: node.host,
      path: node.path,
      isLocal: node.type === 'host' ? node.isLocal : null,
      level: level,
      expandable: !!node.children
    };
  }

  /** Get the level of the node */
  getLevel(node: TreeNode) {
    return node.level;
  }

  /** Return whether the node is expanded or not. */
  isExpandable(node: TreeNode) {
    return node.expandable;
  }

  /** Get the children for the node. */
  getChildren(node: any) {
    return observableOf(node.children);
  }

  /** Get whether the node has children or not. */
  hasChild(index: number, node: TreeNode) {
    return node.expandable;
  }

  selected(node: any) {
    const combineHostPath = (node) => `${node.host}:${node.path}`;
    return node.type === 'directory' && this.destination === combineHostPath(node);
  }

  select(node: any) {
    this.editNode = null;
    this.originalName = null;

    if (node.type === 'directory') {
      this.destination = node.type === 'directory' ? `${node.host}:${node.path}` : null;
    } else {
      this.destination = null;
    }

    this.onSelect.emit(this.destination);
  }

  showInput(node) {
    if (node.type === 'host' || node.name === this.teamConfig.path.raw) {
      this.editNode = null;
      this.originalName = null;
      return;
    }

    this.editNode = node;
    this.originalName = node.name;
    setTimeout(() => {
      const element = <HTMLInputElement>document.getElementById(`${node.host}:${node.path}`);
      if (element) {
        element.focus();
        element.select();
      }
    }, 0);
  }

  getSelectedHost() {
    const hostDest = this.destination.split(':');
    const host = this.dataSource.data.find((x) => x.type === 'host' && x.name === hostDest[0]);
    return host;
  }

  async add(parent: any) {
    const root = this.nodes.find((x) => x.type === 'host' && x.name === parent.host);
    const expansionModel = this.treeControl.expansionModel.selected;
    let selected = this.search(root.children, parent.path);

    if (selected) {
      const next = this.getNext(selected.children);
      selected.children.push({ name: next, type: 'directory', host: root.name, path: `${parent.path}/${next}`, children: [] });

      await this.directoryService.createDirAsync(this.teamName, `${parent.path}/${next}`);

      this.dataSource.data = this.nodes;
      this.maintainExpand(expansionModel);
    }
  }

  getNext(nodes) {
    const prefix = 'new_folder_';
    const newFolders = nodes.filter((x) => x.name.includes(prefix));

    if (newFolders.length === 0) {
      return `${prefix}1`;
    }

    const suffixes = newFolders.map((x) => +x.name.split('_')[2]);
    const next = Math.max(...suffixes) + 1;
    return `${prefix}${next}`;
  }

  search(nodes, path) {
    for (let node of nodes) {
      if (node.path === path) {
        return node;
      }

      if (node.children.length > 0) {
        let selected = this.search(node.children, path);
        if (selected) {
          return selected;
        }
      }
    }
  }

  async rename() {
    if (!this.isValid(this.editNode.name)) {
      alert('ERROR: Input can only contain letters, numbers and underscores');
      return;
    }

    const expansionModel = this.treeControl.expansionModel.selected;

    const currentPath = this.editNode.path;
    const newPath = this.editNode.path.replace(new RegExp(this.originalName + '$'), this.editNode.name);

    if (newPath === currentPath) {
      this.editNode = null;
      this.originalName = null;
      return;
    }

    const root = this.nodes.find((x) => x.type === 'host' && x.name === this.editNode.host);
    let exists = this.search(root.children, newPath);

    if (exists) {
      alert(`A directory with that name already exists.`);
      return;
    }

    const current = this.search(root.children, currentPath);
    current.path = newPath;
    current.name = this.editNode.name;

    this.dataSource.data = this.nodes;
    this.maintainExpand(expansionModel);
    this.editNode = null;
    this.originalName = null;

    await this.directoryService.renameDirAsync(this.teamName, currentPath, newPath);
  }

  isValid(name: string) {
    // Allow numbers, alpahbets, underscore
    if (/^[a-zA-Z0-9-_]+$/.test(name)) return true;

    return false;
  }

  maintainExpand(expansionModel) {
    const dataNodes = this.treeControl.dataNodes;

    expansionModel.forEach((em) => {
      let node = null;
      if (em.type === 'host') {
        node = dataNodes.find((x) => x.name === em.name && x.type === 'host');
      } else {
        node = dataNodes.find((x) => x.host === em.host && x.path === em.path && x.type === 'directory');
      }

      if (node) {
        this.treeControl.expand(node);
      }
    });
  }

  reset(node) {
    node.name = this.originalName;
    this.editNode = null;
  }
}
