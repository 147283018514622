import { Component, Input, OnInit } from '@angular/core';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';

@Component({
  selector: 'app-indicator-widget',
  templateUrl: './indicator-widget.component.html',
  styleUrls: ['../base-widget/base-widget.component.scss', './indicator-widget.component.scss']
})
export class IndicatorWidgetComponent extends BaseWidgetComponent implements OnInit {
  @Input() indicator: any;
  @Input() ticker: string;
  @Input() parentChartData: any[] = [];

  constructor() {
    super();
  }

  ngOnInit(): void {}
}
