import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DiffEditorModel } from 'ngx-monaco-editor-v2';
import { ConfigService } from 'src/app/services/config.service';
import { CacheService } from 'src/app/services/cache.service';
import { shareReplay, catchError, EMPTY, Observable } from 'rxjs';
@Component({
  selector: 'app-model-diff',
  templateUrl: './model-diff.component.html',
  styleUrls: ['./model-diff.component.scss']
})
export class ModelDiffComponent implements OnInit {
  public options = {
    theme: 'vs-dark',
    automaticLayout: true,
    scrollBeyondLastLine: false
  };

  public originalModel: DiffEditorModel = { code: 'Loading ...', language: 'text' };
  public modifiedModel: DiffEditorModel = { code: 'Loading ...', language: 'text' };

  @Input() teamName: string;
  @Input() left: string;
  @Input() right: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      if (this.teamName && this.left && this.right) this.load(this.teamName, this.left, this.right);
      else this.load(params.team, params.left, params.right);
    });
  }

  load(teamName: string, leftPath: string, rightPath: string) {
    const decodedLeft = this.decodePath(leftPath);
    const decodedRight = this.decodePath(rightPath);

    this.getModel(`${ConfigService.apiUrl}/file/${teamName}/${decodedLeft.path}`, leftPath).subscribe((data: any) => {
      this.originalModel = {
        code: data.toString().trim(),
        language: 'python'
      };
      this.changeDetector.detectChanges();
    });

    this.getModel(`${ConfigService.apiUrl}/file/${teamName}/${decodedRight.path}`, rightPath).subscribe((data: any) => {
      this.modifiedModel = {
        code: data.toString().trim(),
        language: 'python'
      };
      this.changeDetector.detectChanges();
    });
  }

  getModel(url: string, cacheCode: string): Observable<any> {
    if (!CacheService.cache[cacheCode]) {
      CacheService.cache[cacheCode] = this.http.get(url, { responseType: 'text' }).pipe(
        shareReplay(1),
        catchError((err) => {
          console.error(err);
          CacheService.delete(cacheCode);
          return EMPTY;
        })
      );
    }

    return CacheService.cache[cacheCode];
  }

  decodePath(encoded: string) {
    const decoded = decodeURI(encoded);
    return { path: encodeURI(decoded.replace(/\//g, '\\')) };
  }

  deleteCacheReload() {
    CacheService.delete(this.left);
    CacheService.delete(this.right);
    this.load(this.teamName, this.left, this.right);
  }
}
