import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MetaParam } from 'src/app/models/meta';

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss']
})
export class TextComponent implements OnInit {
  @Input() param: MetaParam;
  @Input() form: FormGroup;
  @Input() chunkDescription: boolean = false;
  @Output() change = new EventEmitter<void>();

  public controlId: string;
  public description = null;

  ngOnInit(): void {
    this.controlId = `${this.param.name}_${new Date().getTime().toString()}`;
  }
}
