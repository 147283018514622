import { Directive, ElementRef, EventEmitter, HostListener, Output, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appIFrameTracker]'
})
export class IFrameTrackerDirective {

  private _iFrameMouseOver: boolean;
  @Output() iFrameClick = new EventEmitter<ElementRef>();

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.renderer.listen(window, 'blur', ($event) => this.onWindowBlur($event));
  }

  @HostListener('mouseover', ['$event'])
  private onIframeMouseOver(e: any) {
    this._iFrameMouseOver = true;
    this.resetFocusOnWindow();
  }

  @HostListener('mouseout', ['$event'])
  private onIframeMouseOut(e: any) {
    this._iFrameMouseOver = false;
    this.resetFocusOnWindow();
  }

  private onWindowBlur(e: any) {
    if (this._iFrameMouseOver) {
      this.resetFocusOnWindow();
      this.iFrameClick.emit(this.el);
    }
  }

  private resetFocusOnWindow() {
    window.focus();
  }

}
