import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { debounce } from 'src/app/directives/debounce.decorator';
import { ChatService } from 'src/app/services/chat.service';

@Component({
  selector: 'app-chat-screen',
  templateUrl: './chat-screen.component.html',
  styleUrls: ['./chat-screen.component.scss']
})
export class ChatScreenComponent {
  @ViewChild('content', { static: true }) private _content: ElementRef<HTMLDivElement>;

  @Input() messages: ChatService.ChatMessage[] = [];
  @Input() loading = false;
  @Input() embed = false;

  @debounce(100)
  public scrollToTop(): void {
    this._content.nativeElement.scrollTop = 0;
  }

  @debounce(100)
  public scrollToBottom(): void {
    this._content.nativeElement.scrollTop = this._content.nativeElement.scrollHeight;
  }
}
