import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from './config.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PredictAnalysisService {
  constructor(private http: HttpClient) {}

  public load(teamName: string): Observable<any> {
    return this.http.get(`${ConfigService.apiUrl}/predict-analysis/${teamName}`);
  }

  public getPredictions(teamName: string, model: string, date: string): Observable<any> {
    return this.http.get(`${ConfigService.apiUrl}/predict-analysis/${teamName}/${model}/${date}`);
  }

  public getPredictionInputs(teamName: string, engine: string, timestamp: string): Observable<any> {
    return this.http.get(`${ConfigService.apiUrl}/predict-inputs/${teamName}/${engine}/${timestamp}`);
  }

  public getAdhocDays(teamName: string, path: string): Observable<any> {
    return this.http.get(`${ConfigService.apiUrl}/adhoc-days/${teamName}/${encodeURIComponent(path)}`);
  }

  public getAdhocPredictions(teamName: string, model: string, date: string, path: string): Observable<any> {
    return this.http.get(`${ConfigService.apiUrl}/adhoc-predict/${teamName}/${model}/${date}/${encodeURIComponent(path)}`);
  }

  public getAdhocPredictionInputs(teamName: string, model: string, timestamp: string, path: string): Observable<any> {
    return this.http.get(`${ConfigService.apiUrl}/adhoc-predict-inputs/${teamName}/${model}/${timestamp}/${encodeURIComponent(path)}`);
  }

  public makePrediction(teamName: string, engine: string, model: string, timestamp: string, changes: object): Observable<any> {
    return this.http.post<any[]>(
      `${ConfigService.apiUrl}/predict-analysis/make-prediction/${teamName}/${engine}/${model}/${timestamp}`,
      changes
    );
  }

  public getImpactAnalysis(teamName: string, engine: string, model: string, timestamp: string): Observable<any> {
    return this.http.get<any[]>(
      `${ConfigService.apiUrl}/predict-analysis/impact/${teamName}/${engine}/${model}/${timestamp}`
    );
  }

}
