import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(
    public snackBar: MatSnackBar,
    private zone: NgZone
  ) {
    window.$notification = this;
  }

  private _open(message: string, isError: boolean) {
    // Had an issue with the snackbar being ran outside of angular's zone.
    this.zone.run(() => {
      this.snackBar.open(message, isError ? 'X' : 'OK', {
        duration: 5000,
        horizontalPosition: 'right',
        panelClass: isError ? ['notification-bottom-margin', 'error'] : ['notification-bottom-margin']
      });
    });
  }

  showSuccess(message: string): void {
    this._open(message, false);
  }

  showError(message: string): void {
    this._open(message, true);
  }
}
