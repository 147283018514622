import { Component, OnDestroy, OnInit } from '@angular/core';
import { CompactType, DisplayGrid, GridType, GridsterConfig, GridsterItemComponent } from 'angular-gridster2';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuService } from 'src/app/services/menu.service';
import { IdentityService } from 'src/app/services/identity.service';
import { WidgetService } from 'src/app/services/widget.service';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { ConfigService } from 'src/app/services/config.service';
import { SocketService } from 'src/app/services/socket.service';
import { ChatService } from 'src/app/services/chat.service';

export namespace GridComponent {
  export type DashboardContainment = 'overlap' | 'contained' | 'outside';
}

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class GridComponent implements OnInit, OnDestroy {
  private maxRows = 1000;
  private maxCols = 1000;

  options: GridsterConfig = {
    gridType: GridType.Fixed,
    displayGrid: DisplayGrid.None,
    compactType: CompactType.None,
    pushItems: true,
    draggable: {
      enabled: true,
      stop: this.onDragStop.bind(this),
      start: this.onDragStart.bind(this)
    },
    resizable: {
      enabled: true,
      stop: this.onResizeStop.bind(this),
      start: this.onResizeStart.bind(this)
    },
    itemResizeCallback: this.onResize.bind(this),
    margin: 1,
    fixedColWidth: 20,
    fixedRowHeight: 20,
    minCols: 50,
    maxCols: this.maxCols,
    minRows: 50,
    maxRows: this.maxRows,
    maxItemCols: this.maxCols,
    minItemCols: 1,
    maxItemRows: this.maxRows,
    minItemRows: 1,
    maxItemArea: this.maxRows * this.maxCols,
    minItemArea: 1,
    defaultItemCols: 1,
    defaultItemRows: 1,
    allowMultiLayer: true,
    addEmptyRowsCount: 2
  };
  teamName: string;
  isOpen = { board: false, devices: false, data: false, models: false, streams: false };

  private _nextLayerIndex = 0;
  get nextLayerIndex(): number {
    this._nextLayerIndex = Math.max(this._nextLayerIndex, ...this.dashboard.map((x) => x.layerIndex)) + 1;
    return this._nextLayerIndex;
  }

  public get dashboard() {
    return this._widgetService.widgets;
  }

  /**
   * Creates a Widget, adds the widget to the grid, and then saves the grid.
   * @param name The name of the widget to add
   * @param values The raw metadata associated with the widget
   */
  private _addWidget(
    name: WidgetService.WidgetName,
    values?: Partial<WidgetService.Widget>,
    config?: Partial<{
      defaultSpace: WidgetService.GridSpace;
      baseInputs: Record<string, any>;
      extraOutputs: Record<string, any>;
    }>
  ) {
    const onClose = this.closeWidget.bind(this);
    const onRestore = this.restoreWidget.bind(this);
    const onMaximize = this.maximizeWidget.bind(this);
    const onToggle = this.toggleWidget.bind(this);

    const widget = this._widgetService.createWidget(name, this.teamName, values, {
      defaultSpace: config?.defaultSpace,
      baseInputs: config?.baseInputs,
      outputs: { ...(config?.extraOutputs ?? {}), onClose, onRestore, onMaximize, onToggle }
    });
    widget.isMaximized = values?.isMaximized;

    this._widgetService.addWidget(this.teamName, widget);
  }

  private _removeWidget(...ids: string[]) {
    // use setTimeout, otherwise gridster leaves gridster-preview behind
    setTimeout(() => this._widgetService.removeWidget(this.teamName, ...ids));
  }
  chatEnabled = true;
  private _socketSub: any;

  constructor(
    private readonly _router: Router,
    private readonly _menuService: MenuService,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _widgetService: WidgetService,
    public readonly identity: IdentityService,
    private _socketService: SocketService
  ) {
    (<any>window).grid = this;
    this.chatEnabled = ConfigService.features?.chat != false;
  }

  lastShowMeTime: any = new Date();
  lastShowMe = null;

  ngOnInit(): void {
    this._widgetService.registerGrid(this);

    this._activatedRoute.params.subscribe((params) => {
      this.teamName = params.team;
      this.loadDashboard();
    });
    this._widgetService.restore$.subscribe(() => {
      this.options.api.optionsChanged();
    });
    this._widgetService.maximize$.subscribe(() => {
      this.options.api.optionsChanged();
    });
    this._socketSub = this._socketService.subscribeToRoomMessages('showme').subscribe((response) => {
      const now: any = new Date();
      if (this.identity.me.username !== response.username) {
        return;
      }
      this.lastShowMe = response.component.toLowerCase();
      this.lastShowMeTime = now;

      const component = response.component.toLowerCase();
      if (['experiments', 'training'].includes(component)) {
        this.addExperimentsWidget();
      } else if (['raw', 'upload', 'uploads', 'data'].includes(component)) {
        this.addRawDataWidget();
      } else if (['explore', 'features'].includes(component)) {
        this.addFeatureSelectorWidget();
      } else if (component == 'schemas') {
        this.addSchemasWidget();
      } else if (component == 'builds') {
        this.addBuildsWidget();
      } else if (['jupyterlab', 'jupyter', 'lab'].includes(component)) {
        this.addLabWidget();
      } else if (['audit', 'assets'].includes(component)) {
        this.addAssetsWidget();
      } else if (['design', 'canvas'].includes(component)) {
        const filter = 'filter' in response ? response.filter : null;
        this.addDefinitionsWidget(filter, null);
      } else if (component == 'vault') {
        const vaultName = 'vaultName' in response ? response.vaultName : null;
        this.addVaultWidget(vaultName);
      } else if (['deploys', 'services'].includes(component)) {
        this.addDeploysWidget();
      } else if (['hosts', 'machines'].includes(component)) {
        this.addHostsWidget();
      } else if (['compute', 'devices', 'gpus'].includes(component)) {
        this.addGPUsWidget();
      } else if (component == 'settings') {
        this.addSettingsWidget();
      } else if (component === 'stream') {
        const streamName = 'streamName' in response ? response.streamName : null;
        const modelName = 'modelName' in response ? response.modelName : null;
        if (!streamName) return;

        this.addStreamWidget(streamName, null, null, modelName);
      }
    });
  }

  ngOnDestroy(): void {
    if (this._socketSub) this._socketSub.unsubscribe();
  }

  onClick(_$event, item: WidgetService.Widget): void {
    this.dashboard.forEach((dashboardItem) => {
      if (dashboardItem.id == item.id) {
        return;
      }
      const containment = this.findContainment(item, dashboardItem);
      if (containment != 'outside' && dashboardItem.layerIndex > item.layerIndex) {
        const now: any = new Date();
        if (!dashboardItem.birthday || now - dashboardItem.birthday.getTime() > 1000) {
          item.layerIndex = this.nextLayerIndex;
        }
      }
    });
  }

  findNestedIframe(collection) {
    for (let i = 0; i < collection.length; i++) {
      const element = collection[i];

      if (element.tagName === 'IFRAME') {
        return element;
      }

      if (element.children.length > 0) {
        const nestedIframe = this.findNestedIframe(element.children);
        if (nestedIframe) {
          return nestedIframe;
        }
      }
    }
    return null;
  }

  preventIFrameCollision(itemComponent: GridsterItemComponent, pointerEvents: boolean = false): void {
    const children = itemComponent.el.children;
    const widget = Array.from(children).find((e) => e.nodeName.endsWith('-WIDGET')) as HTMLDivElement | undefined;
    if (!widget) return;

    const iframe = this.findNestedIframe(Array.from(widget.children));
    if (!iframe) return;

    iframe.style.pointerEvents = pointerEvents ? 'initial' : 'none';
  }

  onDragStop(item: WidgetService.Widget, itemComponent: GridsterItemComponent, $event): void {
    this.preventIFrameCollision(itemComponent, true);
    setTimeout(() => this._widgetService.updateWidget(this.teamName, item.id, item), 100);
  }

  onDragStart(item: WidgetService.Widget, itemComponent: GridsterItemComponent, _$event): void {
    this.preventIFrameCollision(itemComponent);
  }

  onResizeStop(item: WidgetService.Widget, itemComponent: GridsterItemComponent, _$event): void {
    this.preventIFrameCollision(itemComponent, true);
  }

  onResizeStart(item: WidgetService.Widget, itemComponent: GridsterItemComponent, _$event): void {
    this.preventIFrameCollision(itemComponent);
  }

  onResize(item: WidgetService.Widget, itemComponent: GridsterItemComponent): void {
    this._widgetService.resize$.next({ item, itemComponent });
    setTimeout(() => this._widgetService.updateWidget(this.teamName, item.id, item), 100);

    const event = new Event(`resize:${item.id}`);
    window.dispatchEvent(event);
  }

  findContainment(a: WidgetService.Widget, b: WidgetService.Widget): GridComponent.DashboardContainment {
    // adapted from: https://stackoverflow.com/a/59498518/61396
    function toRect(item) {
      return {
        left: item.x,
        right: item.x + item.cols,
        top: item.y,
        bottom: item.y + item.rows
      };
    }

    const aRect = toRect(a);
    const bRect = toRect(b);

    if (
      /* Does container left or right edge pass through element? */
      (aRect.left < bRect.left && aRect.right > bRect.left) ||
      (aRect.left < bRect.right && aRect.right > bRect.right) ||
      /* Does container top or bottom edge pass through element? */
      (aRect.top < bRect.top && aRect.bottom > bRect.top) ||
      (aRect.top < bRect.bottom && aRect.bottom > bRect.bottom)
    ) {
      return 'overlap';
    }

    /*
    If boundaries of element fully contained inside bounday of
    container, classify this as containment of element in container
    */
    if (aRect.left >= bRect.left && aRect.top >= bRect.top && aRect.bottom <= bRect.bottom && aRect.right <= bRect.right) {
      return 'contained';
    }

    /*
    Otherwise, the element is fully outside the container
    */
    return 'outside';
  }

  navigate(route: string[]): void {
    this._menuService.setActive(route[route.length - 1]);
    this._router.navigate(route);
  }

  loadDashboard(): void {
    try {
      const storageItem = localStorage.getItem(`${this.teamName}-widgets`);
      if (!storageItem || storageItem.length === 0) return;

      const dashboard = JSON.parse(storageItem) as WidgetService.Widget[];
      if (!dashboard) return;

      for (let item of dashboard) {
        switch (item.name) {
          case 'RestStreamWidget':
            this.addStreamRestWidget(item.inputs.streamName, item.inputs.activeIndicators, item);
            break;
          case 'StreamWidget':
            this.addStreamWidget(item.inputs.streamName, item.inputs.activeIndicators, item);
            break;
          case 'StreamsWidget':
            this.addStreamsWidget(item);
            break;
          case 'ModelChartWidget':
            this.addModelWidget(
              item.inputs.streamName,
              item.inputs.modelName,
              item.inputs.parentId,
              item.inputs.ticker,
              item.inputs.parentChartData,
              item
            );
            break;
          case 'TickWidget':
            this.addTickWidget(item.inputs.streamName, item.inputs.symbol, item.inputs.parentId, item.inputs.date);
            break;
          case 'PacketWidget':
            this.addPacketWidget(item.inputs.parentId, item.inputs.packetContent, item);
            break;
          case 'MonacoWidget':
            this.addMonacoWidget(item.inputs.parentId, item.inputs.path, item);
            break;
          case 'ModelInputsWidget':
            this.addModelInputWidget(item.inputs.parentId, item.inputs.timestamp, item.inputs.modelName, item.inputs.modelValues, item);
            break;
          case 'StrategyWidget':
            this.addStrategyWidget(item.inputs.parentId, item.inputs.modelName, item.inputs.ticker, item.inputs.strats, item);
            break;
          case 'StrategyEditorWidget':
            this.addStrategyEditorWidget(item.inputs.parentId, item.inputs.modelName, item.inputs.name, item);
            break;
          case 'BreakoutWidget':
            this.addBreakoutWidget(
              item.inputs.parentId,
              item.inputs.modelName,
              item.inputs.ticker,
              item.inputs.strategyName,
              item.input.days,
              item
            );
            break;
          case 'ExperimentsWidget':
            this.addExperimentsWidget(item.inputs.definitionName, item);
            break;
          case 'PerfTableWidget':
            this.addPerfTableWidget(item.run, item.displayedColumns, item.dynamicColumns, item.live, item);
            break;
          case 'RawDataWidget':
            this.addRawDataWidget(item);
            break;
          case 'FeatureSelectorWidget':
            this.addFeatureSelectorWidget(item);
            break;
          case 'SchemasWidget':
            this.addSchemasWidget(item);
            break;
          case 'BuildsWidget':
            this.addBuildsWidget(item);
            break;
          case 'LabWidget':
            this.addLabWidget(item);
            break;
          case 'AssetsWidget':
            this.addAssetsWidget(item);
            break;
          case 'InspectWidget':
            this.addInspectWidget(item.inputs.parentId, item.inputs.type, item.inputs.assetName, item.inputs.fileName, item);
            break;
          case 'DefinitionsWidget':
            this.addDefinitionsWidget(item.inputs.filter, item);
            break;
          case 'VaultWidget':
            this.addVaultWidget(item.inputs.name, item);
            break;
          case 'DeploysWidget':
            this.addDeploysWidget(item);
            break;
          case 'HostsWidget':
            this.addHostsWidget(item);
            break;
          case 'TerminalWidget':
            this.addTerminalWidget(item.command, item.title, item);
            break;
          case 'GPUsWidget':
            this.addGPUsWidget(item);
            break;
          case 'ChatWidget':
            this.addChatWidget(item.inputs.messages, item.inputs.disabled, item);
            break;
          case 'SettingsWidget':
            this.addSettingsWidget(item);
            break;
          case 'CompilerWidget':
            this.addCompilerWidget(item.inputs.parentId, item.inputs.rdsName, item);
            break;
        }
      }
    } catch {}
  }

  addStreamWidget(streamName: string, activeIndicators = [], values = null, modelName = null): void {
    this._addWidget('StreamWidget', values, {
      defaultSpace: { rows: 25, cols: 50 },
      baseInputs: { streamName, activeIndicators, modelName },
      extraOutputs: {
        onModelSelect: this.onModelSelect.bind(this),
        onIndicatorSelect: this.onIndicatorSelect.bind(this),
        onCloseChildren: this.onCloseChildren.bind(this),
        onTickSelect: this.onTickSelect.bind(this),
        onStrategy: this.onStrategy.bind(this)
      }
    });
  }

  addStreamRestWidget(streamName: string, activeIndicators = [], values = null, modelName = null): void {
    this._addWidget('RestStreamWidget', values, {
      defaultSpace: { rows: 25, cols: 50 },
      baseInputs: { name: streamName },
      extraOutputs: { onIFrameFocus: this.onIFrameFocus.bind(this) }
    });
  }

  addStreamsWidget(values = null): void {
    this._addWidget('StreamsWidget', values, {
      extraOutputs: {
        onStreamSelect: this.onStreamSelect.bind(this)
      }
    });
  }

  addModelWidget(streamName: string, modelName: string, parentId: string, ticker: string, parentChartData: any[], values = null): void {
    const parent = this.dashboard.find(({ id }) => id === parentId);

    this._addWidget('ModelChartWidget', values, {
      defaultSpace: { x: parent.x, y: parent.y + parent.rows, rows: 15, cols: parent.cols },
      baseInputs: { parentId, streamName, modelName, ticker, parentChartData },
      extraOutputs: { onPredictSelect: this.onPredictSelect.bind(this), onStrategyEditor: this.onStrategyEditor.bind(this) }
    });
  }

  addIndicatorWidget(streamName: string, parentId: string, ticker: string, indicator: any, parentChartData: any[], values = null): void {
    const parent = this.dashboard.find(({ id }) => id === parentId);

    this._addWidget('IndicatorWidget', values, {
      defaultSpace: { x: parent.x, y: parent.y + parent.rows, rows: 15, cols: parent.cols },
      baseInputs: { parentId, streamName, indicator, ticker, parentChartData }
    });
  }

  addTickWidget(streamName: string, symbol: string, parentId: string, date: Date, values = null): void {
    this._addWidget('TickWidget', values, {
      defaultSpace: { y: 3, rows: 20, cols: 50 },
      baseInputs: { parentId, streamName, symbol, date },
      extraOutputs: { onPacketSelect: this.onPacketSelect.bind(this) }
    });
  }

  addPacketWidget(parentId: string, packetContent: any, values = null): void {
    this._addWidget('PacketWidget', values, {
      defaultSpace: { y: 4, rows: 20, cols: 40 },
      baseInputs: { parentId, packetContent },
      extraOutputs: { onPacketSelect: this.onPacketSelect.bind(this) }
    });
  }

  addMonacoWidget(parentId: string, path: any, values = null): void {
    this._addWidget('MonacoWidget', values, {
      defaultSpace: { y: 1, rows: 40, cols: 40 },
      baseInputs: { parentId, path },
      extraOutputs: {}
    });
  }

  addModelInputWidget(parentId: string, timestamp: string, modelName: string, modelValues: any, values = null): void {
    this._addWidget('ModelInputsWidget', values, {
      baseInputs: { parentId, timestamp, modelName, modelValues },
      extraOutputs: { onPredictSelect: this.onPredictSelect.bind(this) }
    });
  }

  addStrategyWidget(parentId: string, modelName: string, ticker: string, strats: string[], values = null): void {
    this._addWidget('StrategyWidget', values, {
      defaultSpace: { rows: 23, cols: 50 },
      baseInputs: { parentId, modelName, ticker, strats },
      extraOutputs: { onLoadBreakout: this.onLoadBreakout.bind(this) }
    });
  }

  addStrategyEditorWidget(parentId: string, modelName: string, name: string, values = null): void {
    this._addWidget('StrategyEditorWidget', values, {
      defaultSpace: { rows: 23, cols: 50 },
      baseInputs: { parentId, modelName, name },
      extraOutputs: {}
    });
  }

  addBreakoutWidget(parentId: string, modelName: string, ticker: string, strategyName, days, values = null): void {
    this._addWidget('BreakoutWidget', values, {
      defaultSpace: { x: 4, y: 2, rows: 30, cols: 40 },
      baseInputs: { parentId, modelName, ticker, strategyName, days }
    });
  }

  addExperimentsWidget(definitionName: string = null, values = null): void {
    this._addWidget('ExperimentsWidget', values, {
      baseInputs: { definitionName },
      extraOutputs: {
        onProcessView: this.onTerminal.bind(this),
        onVaultItem: this.onViewVaultDetail.bind(this),
        onPerfTableView: this.onPerfTableView.bind(this)
      }
    });
  }

  addPerfTableWidget(run: any, displayedColumns: string[], dynamicColumns: any[], live: boolean, values = null) {
    this._addWidget('PerfTableWidget', values, {
      baseInputs: { run, displayedColumns, dynamicColumns, live }
    });
  }

  addRawDataWidget(values = null): void {
    this._addWidget('RawDataWidget', values);
  }

  addFeatureSelectorWidget(values = null): void {
    this._addWidget('FeatureSelectorWidget', values, {
      extraOutputs: {
        onEditFile: this.onEditFile.bind(this),
        onPacketSelect: this.onPacketSelect.bind(this)
      }
    });
  }

  addSchemasWidget(values = null): void {
    this._addWidget('SchemasWidget', values, {
      extraOutputs: { onBuild: this.onViewBuild.bind(this) }
    });
  }

  addBuildsWidget(values = null): void {
    const inputs = values && Object.keys(values).includes('build') ? { build: values['build'] } : null;
    this._addWidget('BuildsWidget', values, {
      baseInputs: inputs
    });
  }

  addAssetsWidget(values = null): void {
    const inputs = values && Object.keys(values).includes('assetName') ? { assetName: values['assetName'] } : null;
    this._addWidget('AssetsWidget', values, {
      baseInputs: inputs,
      extraOutputs: { onDefinition: this.onDefinition.bind(this) }
    });
  }

  addInspectWidget(parentId: string, type: string, assetName: string, fileName: string, values = null) {
    this._addWidget('InspectWidget', values, {
      baseInputs: { parentId, type, assetName, fileName }
    });
  }

  addDefinitionsWidget(filter: string = null, values = null): void {
    this._addWidget('DefinitionsWidget', values, {
      baseInputs: { filter },
      extraOutputs: {
        onViewAsset: this.onAsset.bind(this),
        onViewExperiments: this.onExperiments.bind(this),
        onEditFile: this.onEditFile.bind(this),
        onCompile: this.onCompile.bind(this)
      }
    });
  }

  addVaultWidget(vaultName?: string, values = null): void {
    this._addWidget('VaultWidget', values, {
      baseInputs: { vaultName }
    });
  }

  addDeploysWidget(values = null): void {
    this._addWidget('DeploysWidget', values);
  }

  addHostsWidget(values = null): void {
    this._addWidget('HostsWidget', values, {
      extraOutputs: { onTerminal: this.onTerminal.bind(this) }
    });
  }

  addTerminalWidget(command: string, title: string, values = null): void {
    this._addWidget('TerminalWidget', values, {
      baseInputs: { command, title }
    });
  }

  addGPUsWidget(values = null): void {
    this._addWidget('GPUsWidget', values);
  }

  addLabWidget(values = null): void {
    this._addWidget('LabWidget', values);
  }

  addChatWidget(messages: ChatService.ChatMessage[], disabled: boolean, values = null): void {
    this._addWidget('ChatWidget', values, {
      baseInputs: { messages: messages ?? [], disabled: disabled ?? false },
      defaultSpace: { minItemCols: 20, minItemRows: 20, cols: 25, rows: 35 },
      extraOutputs: {
        onViewExperiments: this.onExperiments.bind(this),
        onViewDefinition: this.onDefinition.bind(this),
        onViewBuild: this.onViewBuild.bind(this),
        onViewAsset: this.onViewAsset.bind(this),
        onViewFeatureExplorer: this.onFeatureExplorer.bind(this),
        onViewVault: this.onViewVault.bind(this),
        onViewUpload: this.onUpload.bind(this)
      }
    });
  }

  addSettingsWidget(values = null): void {
    this._addWidget('SettingsWidget', values, { defaultSpace: { minItemCols: 27, rows: 35, cols: 55 } });
  }

  addCompilerWidget(parentId: string, rdsName: string, values = null): void {
    this._addWidget('CompilerWidget', values, {
      baseInputs: { parentId, rdsName },
      defaultSpace: { minItemCols: 41, minItemRows: 25 }
    });
  }

  closeWidget($event: BaseWidgetComponent.Event): void {
    this._removeWidget($event.id);
  }

  restoreWidget($event: BaseWidgetComponent.Event): void {
    this._widgetService.restoreWidget(this.teamName, $event.id);
  }

  maximizeWidget($event: BaseWidgetComponent.Event): void {
    this._widgetService.maximizeWidget(this.teamName, $event.id);
  }

  toggleWidget($event: BaseWidgetComponent.Event): void {
    this._widgetService.toggleWidget(this.teamName, $event.id);
  }

  onCloseChildren(children: string[]): void {
    this._removeWidget(...children);
  }

  onModelSelect(input: any): void {
    this.addModelWidget(input.streamName, input.modelName, input.parentId, input.ticker, input.parentChartData);
  }

  onIndicatorSelect(input: any): void {
    const found = this._widgetService.widgets.find((widget) => widget.id === input.parentId);
    if (!found) return;

    found.inputs.activeIndicators = input.activeIndicators;
    this._widgetService.updateWidget(this.teamName, input.parentId, found);
  }

  onTickSelect(input: any): void {
    this.addTickWidget(input.streamName, input.ticker, input.parentId, input.date);
  }

  onEditFile(input: any): void {
    this.addMonacoWidget(input.parentId, input.path);
  }

  onPacketSelect(input: any): void {
    this.addPacketWidget(input.parentId, input.packetContent);
  }

  onPredictSelect(input: any): void {
    this.addModelInputWidget(input.parentId, input.timestamp, input.modelName, input.modelValues);
  }

  onStrategy(input: any): void {
    this.addStrategyWidget(input.parentId, input.modelName, input.ticker, input.strats);
  }

  onStrategyEditor(input: any): void {
    this.addStrategyEditorWidget(input.parentId, input.modelName, input.name);
  }

  onLoadBreakout(input: any): void {
    this.addBreakoutWidget(input.parentId, input.modelName, input.ticker, input.strategyName, input.days);
  }

  onTerminal(input: any): void {
    const command = input.command ? input.command : null;
    const title = input.title ? input.title : null;
    this.addTerminalWidget(command, title);
  }

  onLab(input: any): void {
    this.addLabWidget(input.parentId);
  }

  onInspect(input: any) {
    this.addInspectWidget(input.parentId, input.type, input.assetName, input.fileName);
  }

  onExperiments(input: any) {
    this.addExperimentsWidget(input.projectName);
  }

  onDefinition(input: any) {
    this.addDefinitionsWidget(input.definitionName);
  }

  onViewBuild(input: any) {
    this.addBuildsWidget(input);
  }

  onViewAsset(input: any) {
    this.addAssetsWidget(input.assetName);
  }

  onFeatureExplorer(input: any) {
    this.addFeatureSelectorWidget(input.projectName);
  }

  onViewVault(input: any) {
    this.addVaultWidget(input.projectName);
  }

  onViewVaultDetail(input: any) {
    this.addVaultWidget(input.vaultName);
  }

  onAsset(input: any): void {
    this.addAssetsWidget();
  }

  onUpload() {
    this.addRawDataWidget();
  }

  onStreamSelect(input: any) {
    if (input.ui == 'rest') {
      this.addStreamRestWidget(input.streamName);
    } else {
      this.addStreamWidget(input.streamName);
    }
  }

  onCompile(input: any) {
    this.addCompilerWidget(input.parentId, input.rdsName);
  }

  onPerfTableView(input: any) {
    this.addPerfTableWidget(input.run, input.displayedColumns, input.dynamicColumns, input.live);
  }

  onIFrameFocus(input: any) {
    const item = this.dashboard.find((x) => x.id === input.id);
    if (!item) return;

    if (item.layerIndex <= this._nextLayerIndex || this._nextLayerIndex === 0) {
      item.layerIndex = this.nextLayerIndex;
      this._widgetService.updateWidget(this.teamName, item.id, item);
    }
  }
}
