import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';

export type Worker = {
  name: string;
  static: boolean;
  status: string;
  active: string;
  started: string | null;
  pid: number | null;
  tasks: number | null;
  memory: number | null;
  host?: string;
};

@Injectable({
  providedIn: 'root'
})
export class PredictEngineService {
  public serviceClick$: Subject<{ parentId: string; serviceName: string }> = new Subject();

  constructor(private http: HttpClient) {}

  public load(teamName: string) {
    return this.http.get<any>(`${ConfigService.apiUrl}/predict-engines/${teamName}`);
  }

  public loadOne(teamName: string, serviceName: string) {
    return this.http.get<any>(`${ConfigService.apiUrl}/predict-engine/${teamName}/${serviceName}`);
  }

  public start(teamName: string, serviceName: string, isStatic: boolean) {
    const body = { name: serviceName, static: isStatic, action: 'start' };
    return this.http.post<Worker>(`${ConfigService.apiUrl}/predict-engines/${teamName}`, body);
  }

  public restart(teamName: string, serviceName: string, isStatic: boolean) {
    const body = { name: serviceName, static: isStatic, action: 'restart' };
    return this.http.post<Worker>(`${ConfigService.apiUrl}/predict-engines/${teamName}`, body);
  }

  public stop(teamName: string, serviceName: string, isStatic: boolean) {
    const body = { name: serviceName, static: isStatic, action: 'stop' };
    return this.http.post<Worker>(`${ConfigService.apiUrl}/predict-engines/${teamName}`, body);
  }

  public delete(teamName: string, serviceName: string) {
    return this.http.post<any>(`${ConfigService.apiUrl}/predict-engines/delete/${teamName}/${serviceName}`, {});
  }
}
