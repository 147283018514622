import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { map, Observable, startWith } from 'rxjs';
import { HostsService } from 'src/app/services/hosts.service';
import { Host } from 'src/app/models/host';
import { SchemasService } from 'src/app/services/schemas.service';
import { MatSelectionList } from '@angular/material/list';
import { MatChipInputEvent } from '@angular/material/chips';
import { Feature, FeatureService } from 'src/app/services/feature.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { DataBuildsService } from 'src/app/services/data-builds.service';
import { DataBuild } from 'src/app/models/data-build';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { MenuService } from 'src/app/services/menu.service';
import { DatasetsService } from 'src/app/services/datasets.service';
import { ConfigService } from 'src/app/services/config.service';

export namespace SchemaComponent {
  export type BuildEvent = { build: DataBuild };
  export type DeleteEvent = { teamName: string; rdsName: string };
  export type SaveEvent = {
    rds: {
      name: string;
      adapter: {
        name: string | null;
        paths: string[];
        resolution: number;
        'num-chunks': number;
        hours_filter: string | null;
      };
      testtrain: {
        split: string | null;
        split_percent: number;
        split_date: string;
      }
      output: {
        precache_count: number | null;
        seed: number;
      };
      tags: string[];
      features: string[];
      dataset: string;
    };
  };
}

@Component({
  selector: 'app-schema',
  templateUrl: './schema.component.html',
  styleUrls: ['./schema.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SchemaComponent implements OnInit {
  @ViewChild(MatSelectionList, { static: false }) featureSelection: MatSelectionList;
  @ViewChild('featureInput') featureInput: ElementRef<HTMLInputElement>;

  @Input() isWidget: boolean = false;
  @Input() teamName: string;
  @Input() rdsName: string;
  @Input() isNew = false;
  @Input() readonly = false;
  @Input() rds: any = null;

  @Output() onBuild = new EventEmitter<SchemaComponent.BuildEvent>();
  @Output() onDelete = new EventEmitter<SchemaComponent.DeleteEvent>();
  @Output() onSave = new EventEmitter<SchemaComponent.SaveEvent>();
  @Output() onLaunch = new EventEmitter();

  private _builds: DataBuild[] = [];
  private _newBuildStarted: boolean = false;

  adapterForm = new FormGroup({
    rdsName: new FormControl<any>('', [Validators.required, Validators.minLength(3)]),
    datasetName: new FormControl<any>(null, [Validators.required]),
    paths: new FormControl<any>('', Validators.required),
    resolution: new FormControl<any>('', Validators.required),
    hours_filter: new FormControl<any>(''),
    split: new FormControl<any>('', Validators.required),
    split_percent: new FormControl<any>(0),
    split_date: new FormControl<any>(''),
    seed: new FormControl<any>(''),
    precache_count: new FormControl<any>(''),
    tags: new FormControl<any>([]),
    features: new FormControl<any>([])
  });


  loadingDataset: boolean = false;
  schemas: any = [];
  availableFeatures: any = [];
  features = [];
  newFeature: string;
  selectedFeature: any = null;
  benSelectedFeatureKeys: any[] = [];
  isLoading: boolean = true;
  saveStatus: 'COMPLETE' | 'ERROR' | 'SAVING' | 'SAVED' = 'COMPLETE';
  lastSaveTime: string = null;
  tags: Set<string>;

  allFeatures: string[] = [];
  filteredFeatures: Observable<string[]>;
  featureCtrl = new FormControl('');

  allDatasets: string[] = [];
  filteredDatasets: Observable<string[]>;

  hosts: Host[] = [];
  nameChange = false;
  preCacheOnly = false;
  buildInProgress = false;
  strict = false;
  dirty = false;

  public get anyBuildsRunning(): boolean {
    return (
      this._newBuildStarted || this._builds.filter((x) => x.isRunning && !x.cancelled && x.errors == 0 && x.isArchived == false).length > 0
    );
  }

  public get mostRecentBuild(): DataBuild {
    return this._builds.filter((x) => x.rdsName == this.rdsName)[0];
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    public router: Router,
    private schemasService: SchemasService,
    private buildsService: DataBuildsService,
    private hostsService: HostsService,
    private featureService: FeatureService,
    public snackbar: MatSnackBar,
    public featureDialog: MatDialog,
    public allFeaturesDialog: MatDialog,
    public newFeatureDialog: MatDialog,
    private datasetsService: DatasetsService,
    private menuService: MenuService
  ) {}

  drop(event: CdkDragDrop<Feature[]>) {
    moveItemInArray(this.features, event.previousIndex, event.currentIndex);
    this.update();
  }

  ngOnInit(): void {
    (<any>window).schema = this;
    if (!this.isWidget) {
      this.menuService.delayedSetActive('schemas');
      this.activatedRoute.params.subscribe((params) => {
        const urlParts = this.router.url.split('/');
        this.teamName = params.team;

        if (urlParts.includes('add')) {
          this.initNew();
        } else {
          this.rdsName = params.rds;
          this.load();
        }
      });
    } else {
      if (this.isNew) this.initNew();
      else this.load();
    }
  }

  public async initNew() {
    this.isNew = true;
    this.isLoading = false;
    this.hosts = await this.hostsService.loadAsync(this.teamName);
    this.loadFeatures();

    const schemaName = `build_${Date.now().toString()}`;

    this.adapterForm.setValue({
      rdsName: schemaName,
      datasetName: null,
      paths: null,
      resolution: 5,
      hours_filter: "all",
      split: "random",
      split_percent: 20,
      split_date: "",
      seed: 0,
      precache_count: 1,
      tags: [],
      features: []
    });

    this.rds = {
      name: schemaName,
      adapter: {
        name: null,
        paths: null,
        resolution: 5,
        'num-chunks': 80,
        hours_filter: "all",
      },
      testtrain: {
        split: "random",
        split_percent: 20,
        split_date: ""
      },
      output: {
        precache_count: null,
        seed: 0,
      },
      tags: [],
      features: []
    };

    this.tags = new Set();
    await this.loadDatasets();
  }

  buildClick() {
    if (!this.mostRecentBuild) return;
    const build: DataBuild = this.mostRecentBuild;
    if (this.isWidget) this.onBuild.emit({ build });
    else this.router.navigate([this.teamName, 'data', 'build', build.rdsName, build.runid, build.user, build.isArchived]);
  }

  public async load() {
    await this.loadFeatures();
    await this.loadDatasets();
    this.isLoading = false;

    this.features = this.rds?.features || [];
    this.tags = this.rds?.tags ? new Set(this.rds?.tags) : new Set();

    this.adapterForm.setValue({
      rdsName: this.rds?.name,
      datasetName: this.rds?.dataset || null,
      paths: this.rds?.adapter?.paths,
      resolution: this.rds?.adapter?.resolution,
      hours_filter: this.rds?.adapter?.hours_filter ? this.rds?.adapter?.hours_filter : "all",
      split: this.rds?.testtrain?.split ? this.rds?.testtrain?.split : "random",
      split_percent: this.rds?.testtrain?.split_percent || 20,
      split_date: this.rds?.testtrain?.split_date || "",
      seed: this.rds?.output?.seed || 0,
      precache_count: this.rds?.output?.precache_count || 1,
      tags: this.rds?.tags || [],
      features: this.features
    });

    if (this.readonly)
      this.adapterForm.disable();
  }

  private _filterDatasets(value: string): string[] {
    if (!value) return null;

    const filterValue = value.toLowerCase();
    return this.allDatasets.filter((dataset) => dataset.toLowerCase().includes(filterValue));
  }

  async loadDatasets(): Promise<void> {
    this.allDatasets  = await this.datasetsService.loadAsync(this.teamName);
    this.filteredDatasets = this.adapterForm.get('datasetName').valueChanges.pipe(
      startWith(null),
      map((dataset: string | null) => {
        const retval = dataset ? this._filterDatasets(dataset) : this.allDatasets.slice();
        return retval;
      })
    );
  }

  private _filterFeatures(value: string): string[] {
    if (!value) return null;

    const filterValue = value.toLowerCase();
    return this.allFeatures.filter((feature) => feature.toLowerCase().includes(filterValue));
  }

  async loadFeatures(): Promise<void> {
    const features = await this.featureService.getAllAsync(this.teamName);
    this.allFeatures = features.map(x => x.name);
    this.filteredFeatures = this.featureCtrl.valueChanges.pipe(
      startWith(null),
      map((feature: string | null) => {
        const retval = feature ? this._filterFeatures(feature) : this.allFeatures.slice();
        return retval.filter((x) => !this.features.includes(x));
      })
    );
  }

  async loadSchema(): Promise<void> {
    this.rds = await this.schemasService.getByNameAsync(this.teamName, this.rdsName);
  }

  onDatasetSelect($event: MatAutocompleteSelectedEvent) {
    this.loadingDataset = true;
    const datasetName = $event.option.viewValue;
    this.adapterForm.controls['datasetName'].disable();

    const sub = this.datasetsService.get(this.teamName, datasetName).subscribe((results) => {
      sub.unsubscribe();
      this.adapterForm.get('datasetName').setValue(datasetName);
      this.adapterForm.get('paths').setValue(results.raw);
      this.adapterForm.controls['datasetName'].enable();
      this.loadingDataset = false;
    });
  }

  async addFeature(event: MatAutocompleteSelectedEvent): Promise<void> {
    this.dirty = true;
    let featureName: string = null;

    featureName = event.option.viewValue;

    if (featureName) {
      this.features.push(featureName);
    }

    this.featureInput.nativeElement.value = '';
    this.featureCtrl.setValue(null);
  }

  deleteFeature(feature): void {
    this.features = this.features.filter((x) => x !== feature);
    this.featureCtrl.setValue(null);
  }

  async update(): Promise<void> {
    this.saveStatus = 'SAVING';

    this.rds.dataset = this.adapterForm.getRawValue().datasetName;
    this.rds.features = this.features;
    this.rds.adapter.paths = this.adapterForm.getRawValue().paths;

    try {
      await this.schemasService.postAsync(this.teamName, this.rds.name, this.rds).then(console.log);
      this.saveStatus = 'SAVED';
      this.lastSaveTime = new Date().toLocaleString();

      setTimeout(() => {
        if (this.saveStatus === 'SAVED') this.saveStatus = 'COMPLETE';
      }, 2000);

      if (this.isNew || this.nameChange) {
        if (!this.isWidget) window.location.href = `${ConfigService.hostUrl}/${this.teamName}/data/schema/${this.rds.name}`;
        else this.widgetUpdate();
      }
    } catch (error) {
      this.saveStatus = 'ERROR';
    }
  }

  widgetUpdate(): void {
    this.onSave.emit({ rds: this.rds });
    this.saveStatus = 'COMPLETE';
    this.isNew = false;
  }

  retry(): void {
    this.update();
  }

  addTag(event: MatChipInputEvent): void {
    if (event.value) {
      this.tags.add(event.value);
      this.rds.tags = [...this.tags];
      event.chipInput!.clear();
      this.adapterForm.updateValueAndValidity({ onlySelf: false, emitEvent: true });
    }
  }

  removeTag(keyword: string): void {
    this.tags.delete(keyword);
    this.rds.tags = [...this.tags];
    this.adapterForm.updateValueAndValidity({ onlySelf: false, emitEvent: true });
  }

  save(): void {
    this.update();
  }

  async delete(): Promise<void> {
    if (!confirm('Are you sure you want to delete this schema?')) {
      return;
    }

    try {
      await this.schemasService.deleteAsync(this.teamName, this.rdsName);
      if (this.isWidget) this.onDelete.emit({ teamName: this.teamName, rdsName: this.rdsName });
      else this.router.navigate(['/', this.teamName, 'data', 'schemas']);
    } catch (e) {
      console.error(e);
      this.openSnackBar('An error occured. See logs for details.', 'OK');
    }
  }

  openSnackBar(message: string, action: string) {
    const durationInSeconds = 3;
    const horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    const verticalPosition: MatSnackBarVerticalPosition = 'top';

    this.snackbar.open(message, action, {
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition,
      duration: durationInSeconds * 1000
    });
  }

  async clean() {
    this._newBuildStarted = true;
    const sub = this.buildsService.clean(this.teamName, this.rdsName).subscribe((results: any) => {
      sub.unsubscribe();
      this._newBuildStarted = false;
    });
  }

  async launch() {
    this.adapterForm.disable();
    await this.update();
    const sub = this.buildsService.start(this.teamName, this.rds.name, this.preCacheOnly, this.strict).subscribe((results: any) => {
      sub.unsubscribe();
      this._newBuildStarted = true;
      this.onLaunch.emit();;
    });
  }
}
