import { Component, HostBinding, Input } from '@angular/core';
import { isNil } from 'lodash';

export namespace ServiceStatusIconComponent {
  export type ServiceStatusInfo = {
    active: string;
    status: string;
  };

  export type ServiceStatus = 'active' | 'warn' | 'error' | 'unknown';
}

export function simplifyStatus(service?: ServiceStatusIconComponent.ServiceStatusInfo) {
  switch (service?.active === 'failed' ? 'dead' : service?.status ?? '') {
    case 'running':
    case 'active':
      return 'active';
    case 'plugged':
    case 'mounted':
    case 'waiting':
    case 'listening':
      return 'warn';
    case 'abandoned':
    case 'exited':
    case 'dead':
      return 'error';
    default:
      return 'unknown';
  }
}

export function consolidateStatus(services?: ServiceStatusIconComponent.ServiceStatusInfo[]) {
  if (isNil(services) || services.length === 0) return 'unknown';
  for (const status of services.map(simplifyStatus)) if (status !== 'active') return status;
  return 'active';
}

export function getStatusIcon(statusClass?: string) {
  switch (statusClass) {
    case 'active':
      return 'directions_running';
    case 'warn':
      return 'priority_high';
    case 'error':
      return 'close';
    case 'unknown':
    default:
      return 'question_mark';
  }
}

export function getStatusTooltip(statusClass?: string) {
  switch (statusClass) {
    case 'active':
      return 'All services running';
    case 'warn':
      return 'One or more services pending';
    case 'error':
      return 'One or more services failed';
    case 'unknown':
    default:
      return 'No services available';
  }
}

@Component({
  selector: 'app-service-status-icon',
  templateUrl: './service-status-icon.component.html',
  styleUrls: ['./service-status-icon.component.scss']
})
export default class ServiceStatusIconComponent {
  @HostBinding('class')
  @Input()
  status: ServiceStatusIconComponent.ServiceStatus;

  @Input()
  icon: string | undefined | null = undefined;

  @Input()
  tooltip: boolean | undefined = undefined;

  public get renderedIcon() {
    return !isNil(this.icon) ? this.icon : getStatusIcon(this.status);
  }

  public get renderedTooltip() {
    return getStatusTooltip(this.status);
  }

  public simplifyStatus(service?: ServiceStatusIconComponent.ServiceStatusInfo) {
    return simplifyStatus(service);
  }
}
