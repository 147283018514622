// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --icon-size: 16px;
  --icon-padding: 4px;
  height: calc(var(--icon-size, 24px) + var(--icon-padding, 1rem));
  width: calc(var(--icon-size, 24px) + var(--icon-padding, 1rem));
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
:host mat-icon {
  width: var(--icon-size, 24px);
  height: var(--icon-size, 24px);
  line-height: var(--icon-size, 24px);
  font-size: var(--icon-size, 24px);
}`, "",{"version":3,"sources":["webpack://./src/app/components/service-status-icon/service-status-icon.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,mBAAA;EAEA,gEAAA;EACA,+DAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAAF;AAEE;EACE,6BAAA;EACA,8BAAA;EACA,mCAAA;EACA,iCAAA;AAAJ","sourcesContent":[":host {\n  --icon-size: 16px;\n  --icon-padding: 4px;\n\n  height: calc(var(--icon-size, 24px) + var(--icon-padding, 1rem));\n  width: calc(var(--icon-size, 24px) + var(--icon-padding, 1rem));\n  border-radius: 50%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  mat-icon {\n    width: var(--icon-size, 24px);\n    height: var(--icon-size, 24px);\n    line-height: var(--icon-size, 24px);\n    font-size: var(--icon-size, 24px);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
