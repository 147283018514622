import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Gpu } from '../models/gpu';
import { HostTimeline } from '../models/host-timeline';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HostStatsService {
  private _gpustats$: Subject<Gpu[]> = new Subject();
  private _userstats$: Subject<any[]> = new Subject();
  private _timeline$: Subject<HostTimeline[]> = new Subject();

  constructor(private http: HttpClient) {}

  public getGPUStats(teamName: string): Observable<Gpu[]> {
    this.http.get(`${ConfigService.apiUrl}/host-stats/gpus/${teamName}`).subscribe((response: Gpu[]) => {
      this._gpustats$.next(response);
    });

    return this._gpustats$.asObservable();
  }

  public getUserStats(teamName: string): Observable<any[]> {
    this.http.get(`${ConfigService.apiUrl}/host-stats/users/${teamName}`).subscribe((response: any) => {
      this._userstats$.next(response);
    });

    return this._userstats$.asObservable();
  }

  public getTimeline(teamName: string): Observable<HostTimeline[]> {
    this.http.get(`${ConfigService.apiUrl}/host-stats/timeline/${teamName}`).subscribe((response: HostTimeline[]) => {
      this._timeline$.next(response);
    });

    return this._timeline$.asObservable();
  }
}
