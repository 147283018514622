import { Component, HostBinding, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export type MarkdownDialogData = {
  maxWidth?: string;
} & ({ src: string } | { contents: string });

@Component({
  selector: 'app-markdown-dialog',
  templateUrl: './markdown-dialog.component.html',
  styleUrls: ['./markdown-dialog.component.scss']
})
export class MarkdownDialogComponent {
  @HostBinding('style.max-width') get maxWidth() {
    return this.data.maxWidth ?? 'unset';
  }

  public get isRemoteMarkdown(): boolean {
    return Object.keys(this.data).includes('src');
  }

  constructor(
    public dialogRef: MatDialogRef<MarkdownDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MarkdownDialogData
  ) {
    (<any>window).markdownDialog = this;
  }

  close() {
    this.dialogRef.close();
  }
}
