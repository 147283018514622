import { Component, ElementRef, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IdentityService } from 'src/app/services/identity.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { Subscription } from 'rxjs';
import { Project } from 'src/app/models/project';
import { Router } from '@angular/router';
import { MatSelectChange } from '@angular/material/select';
import { timeout } from 'src/app/directives/timeout.util';
import { MenuService } from 'src/app/services/menu.service';
import { ConfigService } from 'src/app/services/config.service';
import { ProjectBoardNode } from '../team/project-board/project-board-node.component';

@Component({
  selector: 'app-hud',
  templateUrl: './hud.component.html',
  styleUrls: ['./hud.component.scss']
})
export class HUDComponent implements OnInit, OnDestroy {
  @ViewChild('collapseWrapper', { static: true }) public collapseWrapper!: ElementRef<HTMLDivElement>;

  @HostBinding('style.visibility')
  private get _visibility() {
    return this.opened ? 'visible' : 'hidden';
  }

  private _subs: Subscription[] = [];

  public view: 'hud' | 'menu' = 'hud';
  public opened: boolean = false;
  public availableProjects: { [key: string]: Project } = {};
  public wrapperMargin: number = -10000;
  public selectedTeamName: string | undefined = undefined;
  public selectedProjectName: string | undefined = undefined;

  public get features(): any {
    return ConfigService.features || {};
  }

  private _applyCloseMargin(): void {
    const { height } = this.collapseWrapper.nativeElement.getBoundingClientRect();
    this.wrapperMargin = -height;
  }

  private async _fetchAvailableProjects(): Promise<{ [key: string]: Project }> {
    const projects = await this._projectsService.loadAsync(this.selectedTeamName);
    this.availableProjects = Object.fromEntries(projects.map((project) => [project.name, project]));
    return this.availableProjects;
  }

  constructor(
    private _identity: IdentityService,
    private _projectsService: ProjectsService,
    private _router: Router,
    private _menuService: MenuService
  ) {
    (<any>window).hud = this;
    this.$newTeam(this._identity.me.selectedTeamName);
    this.$newProject(this._identity.me.selectedProjectName);
  }

  public ngOnInit(): void {
    this._subs.push(this._identity.teamChange$.subscribe(this.$newTeam.bind(this)));
    this._subs.push(this._identity.projectChange$.subscribe(this.$newProject.bind(this)));
  }

  public ngOnDestroy(): void {
    this._subs.forEach((sub) => sub.unsubscribe());
  }

  public async $newProject(projectName: string | undefined): Promise<void> {
    if (this.selectedProjectName === projectName) return;

    if (this.selectedProjectName && projectName) {
      // If we have are changing from one project to another, we don't need to mess with animations and offsets
      this.selectedProjectName = projectName;
    } else {
      // Otherwise, we'll close and reopen once the project board is ready
      const wasOpened = this.opened;
      if (wasOpened) await this.close();
      this.selectedProjectName = projectName;
      await timeout(125);
      this._applyCloseMargin();
      if (wasOpened) await this.open();
    }
  }

  public async $newTeam(teamName: string | undefined): Promise<void> {
    if (this.selectedTeamName === teamName) return;

    this.selectedTeamName = teamName;
    if (this.selectedTeamName) this._fetchAvailableProjects();
    else this.availableProjects = {};
  }

  public $selectProject($ev: MatSelectChange): void {
    this._identity.setProject(this.availableProjects[$ev.value]);
  }

  public $clickFullscreen(): void {
    this.close().then(() => {
      this._router.navigate([this.selectedTeamName, 'project-board']);
    });
  }

  public $clickNode(node: ProjectBoardNode): void {
    if (node.route.length === 0) return;
    this.navigate([this.selectedTeamName, ...node.route]);
  }

  public async toggle(): Promise<void> {
    await (this.opened ? this.close() : this.open());
  }

  public async open(): Promise<void> {
    this.wrapperMargin = 0;
    this.opened = true;
    await timeout(250);
  }

  public async close(): Promise<void> {
    this._applyCloseMargin();
    await timeout(250);
    this.opened = false;
  }

  public navigate(route: string[]): void {
    this.close().then(() => {
      this._menuService.setActive(route[route.length - 1]);
      this._router.navigate(route);
    });
  }
}
