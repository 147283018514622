import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-samples',
  templateUrl: './samples.component.html',
  styleUrls: ['./samples.component.scss']
})
export class SamplesComponent implements OnInit, OnChanges {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input() dataset: any[];
  @Input() cols: any[];
  public displayedColumns = [];
  public dataSource = new MatTableDataSource<any>([]);
  public pageSize = 10;

  constructor() {}

  ngOnInit(): void {
    (<any>window).samples = this;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadColumns();
    this.loadData();
  }

  onResize() {
    this.optimizePageSize();
  }

  optimizePageSize() {
    const parentHeight: number = (<any>document.querySelector('app-samples'))?.offsetHeight;
    var x = parentHeight;

    this.pageSize = Math.round(0.026 * x - 4);
    if (this.paginator) {
      this.paginator._changePageSize(this.pageSize);
    }
  }

  loadData() {
    const array = [];
    if (!Array.isArray(this.dataset[0])) {
      const parsed = JSON.parse(JSON.stringify(Object.assign({}, this.dataset)));
      array.push(parsed);
    } else {
      for (let item of this.dataset) {
        const parsed = JSON.parse(JSON.stringify(Object.assign({}, item)));
        array.push(parsed);
      }
    }

    this.dataSource.data = array;
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
      this.optimizePageSize();
    }, 0);
  }

  loadColumns() {
    this.displayedColumns = [];
    const numColumns = Array.isArray(this.dataset[0]) ? this.dataset[0].length : this.dataset.length;

    for (let i = 0; i < numColumns; i++) {
      this.displayedColumns.push(`${i}`);
    }

    if (!this.cols || this.cols.length === 0) {
      this.cols = this.displayedColumns;
    }
  }

  getColInfo(index) {
    const meta = this.cols[index];
    return `${meta.min}, ${meta.mean}, ${meta.max}`;
  }
}
