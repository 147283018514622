import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Asset } from 'src/app/models/asset';
import { AssetsService } from 'src/app/services/assets.service';
import { IdentityService } from 'src/app/services/identity.service';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.scss']
})
export class AssetsComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  @Input() isWidget: boolean = false;
  @Input() teamName: string;
  @Output() onAssetClick = new EventEmitter<{assetName: string}>();

  public filter: string = null;
  public isLoading = true;
  public dataSource = new MatTableDataSource<Asset>([]);
  public displayedColumns = ['name', 'host', 'outputPath', 'numFiles', 'numProdFiles', 'sample_count', 'totalBytes', 'lastModified'];
  public pageSize = 10;

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private assetsService: AssetsService,
              private menuService: MenuService,
              private _identity: IdentityService
  ) { }

  ngOnInit(): void {
    (<any>window).assets = this;

    this.menuService.delayedSetActive('assets');

    if(window.innerHeight > 890) {
      this.pageSize = 20;
    }

    if (this.isWidget) {
        this.load(this.teamName);
    } else {
      this.activatedRoute.params.subscribe(params => {
        this.load(params.team);
      });
    }
  }

  onResize() {
    this.optimizePageSize();
  }

  optimizePageSize() {
    if (this.isWidget) {
      const assetsElement = (<any>document.querySelector('app-assets'));
      if(assetsElement) {
        const parentHeight: number = assetsElement.offsetHeight;
        var x = parentHeight;

        this.pageSize = Math.round(0.026 * x - 5);
        if (this.paginator) {
          this.paginator._changePageSize(this.pageSize);
        }
      }

    } else {
      var x = window.innerHeight;
      var blocks = Math.round(0.00493601 * x - 1.3);
      this.pageSize = blocks * 5;
      this.paginator._changePageSize(this.pageSize);
    }
  }

  load(teamName: string) {

    this.teamName = teamName;
    const sub = this.assetsService.load(this.teamName).subscribe((response: Asset[]) => {
      sub.unsubscribe();

      const dateFields = [ 'lastModified'];
      response.forEach((row) => {
        dateFields.forEach((field) => {
          row[field] = new Date(row[field] * 1000);
        });
      });

      this.dataSource.data = response;

      setTimeout(() => {

        if(this._identity.session.assets?._lastFilter) {
          this.filter = this._identity.session.assets?._lastFilter;
          this.applyFilter(this._identity.session.assets?._lastFilter);
        }

        if(this._identity.session.assets?._lastSortDirection) {
          this.sort.direction = this._identity.session.assets?._lastSortDirection;
        }

        if(this._identity.session.assets?._lastSortActive) {
          this.sort.active = this._identity.session.assets?._lastSortActive;
        }
        this.sort.sortChange.emit(); // https://github.com/angular/components/issues/10242#issuecomment-462018312

        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;

        this.optimizePageSize();
      }, 0);

      this.isLoading = false;
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.filter = filterValue;

    if (this.dataSource.paginator)
      this.dataSource.paginator.firstPage();
  }

  rowClick(row: Asset) {
    if (this.isWidget) {

      if(!this._identity.session.assets)
        this._identity.session.assets = {};

      this._identity.session.assets["_lastFilter"] = this.filter;
      this._identity.session.assets["_lastSortDirection"] = this.sort.direction;
      this._identity.session.assets["_lastSortActive"] = this.sort.active;

      this.onAssetClick.emit({assetName: row.name});
    } else {
      this.router.navigate([this.teamName, 'data', 'asset', row.name]);
    }
  }
}
