import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { TerminalComponent } from '../../terminal/terminal.component';

@Component({
  selector: 'app-terminal-widget',
  templateUrl: './terminal-widget.component.html',
  styleUrls: ['../base-widget/base-widget.component.scss', './terminal-widget.component.scss']
})
export class TerminalWidgetComponent extends BaseWidgetComponent implements OnInit {
  @ViewChild('terminal') terminal!: TerminalComponent;

  @Input() command: string = null;
  @Input() title: string = null;
  public readOnly: boolean = false;

  public terminalId = new Date().getTime().toString();
  constructor() {
    super();
  }

  ngOnInit(): void {
    if (this.command) this.readOnly = true;
  }

  onDisconnect() {
    super.close();
  }

  close() {
    this.terminal.disconnect();
    super.close();
  }
}
