import { Injectable } from '@angular/core';
import { Subject, Observable, lastValueFrom } from 'rxjs';
import { Asset } from '../models/asset';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AssetsService {
  private _collection$: Subject<any> = new Subject();
  private _item$: Subject<any> = new Subject();

  constructor(private http: HttpClient) {}

  public load(teamName: string) {
    return this.http.get(`${ConfigService.apiUrl}/data/assets/${teamName}`);
  }

  public async loadAsync(teamName: string) {
    return lastValueFrom(this.load(teamName));
  }

  public get(teamName: string, assetName: string) {
    return this.http.get(`${ConfigService.apiUrl}/data/assets/${teamName}/${assetName}`);
  }

  public move(teamName: string, assetName: string) {
    return this.http.post(`${ConfigService.apiUrl}/data/assets/${teamName}`, { assetName });
  }

  public getSample(teamName: string, assetName: string, sample: number) {
    return this.http.get(`${ConfigService.apiUrl}/data/assets/sample/${teamName}/${assetName}/${sample}`);
  }

  public getSampleCount(teamName: string, assetName: string) {
    return this.http.get(`${ConfigService.apiUrl}/data/assets/sampleCount/${teamName}/${assetName}`);
  }
}
