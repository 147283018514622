// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
  height: 100%;
}

ngx-monaco-editor {
  object-fit: fill;
  height: calc(100vh - 100px) !important;
}

.progress {
  margin-bottom: 5px;
  font-size: 15px;
}

.save-error {
  margin-bottom: 5px;
  color: red;
  font-size: 15px;
}

a.save-error {
  text-decoration: none;
}

a.save-error:hover {
  -webkit-text-decoration: under;
          text-decoration: under;
}

.isDisabled {
  text-decoration: none;
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

.new {
  color: rgba(0, 0, 0, 0.5);
  font-style: italic;
}

.fix-padding {
  padding: 0.6em 0 0 0 !important;
}

.tags-list {
  width: 100%;
}

#searchInput {
  margin-left: 10px;
}

.clickable {
  cursor: pointer;
}

.toolbar {
  padding: 0 var(--content-margin);
}

.toolbar mat-checkbox {
  margin-top: 8px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/data/schema/schema.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE,gBAAA;EACA,sCAAA;AACF;;AAEA;EACE,kBAAA;EACA,eAAA;AACF;;AAEA;EACE,kBAAA;EACA,UAAA;EACA,eAAA;AACF;;AAEA;EACE,qBAAA;AACF;;AAEA;EACE,8BAAA;UAAA,sBAAA;AACF;;AAEA;EACE,qBAAA;EACA,YAAA;EACA,mBAAA;EACA,oBAAA;AACF;;AAEA;EACE,yBAAA;EACA,kBAAA;AACF;;AAEA;EACE,+BAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAMA;EACE,eAAA;AAHF;;AAMA;EACE,gCAAA;AAHF;;AAMA;EACE,eAAA;AAHF","sourcesContent":[".content {\n  height: 100%;\n}\n\nngx-monaco-editor {\n  object-fit: fill;\n  height: calc(100vh - 100px) !important;\n}\n\n.progress {\n  margin-bottom: 5px;\n  font-size: 15px;\n}\n\n.save-error {\n  margin-bottom: 5px;\n  color: red;\n  font-size: 15px;\n}\n\na.save-error {\n  text-decoration: none;\n}\n\na.save-error:hover {\n  text-decoration: under;\n}\n\n.isDisabled {\n  text-decoration: none;\n  opacity: 0.5;\n  cursor: not-allowed;\n  pointer-events: none;\n}\n\n.new {\n  color: rgba(0, 0, 0, 0.5);\n  font-style: italic;\n}\n\n.fix-padding {\n  padding: 0.6em 0 0 0 !important;\n}\n\n.tags-list {\n  width: 100%;\n}\n\n#searchInput {\n  margin-left: 10px;\n}\n\n// .adapter-form {\n//   margin-top: var(--content-margin);\n// }\n\n.clickable {\n  cursor: pointer;\n}\n\n.toolbar {\n  padding: 0 var(--content-margin);\n}\n\n.toolbar mat-checkbox {\n  margin-top: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
