import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Host } from 'src/app/models/host';
import { HostsService } from 'src/app/services/hosts.service';
import { ConfigService } from 'src/app/services/config.service';
@Component({
  selector: 'app-discovery',
  templateUrl: './discovery.component.html',
  styleUrls: ['./discovery.component.scss']
})
export class DiscoveryComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private hostsService: HostsService
  ) {}

  public teamName: string;
  public hosts: Host[] = [];
  public selectedNotebook: string;

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.teamName = params.team;
      this.load();
    });
  }

  public async load() {
    const response: Host[] = await this.hostsService.loadAsync(this.teamName);
    this.hosts = response;
  }

  onHostSelected(event) {
    const host = this.hosts.find((x) => x.name === event.value);
    this.selectedNotebook = `${ConfigService.hostUrl}/${host.name}/notebook/`; // host.notebook;
  }
}
