import { Injectable } from '@angular/core';
import { Subject, Observable, lastValueFrom } from 'rxjs';
import { Project } from '../models/project';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DatasetsService {
  private _collection$: Subject<any[]> = new Subject();
  private _item$: Subject<any> = new Subject();
  public pathAdded$: Subject<{dataset: string, path: string}> = new Subject();

  constructor(private http: HttpClient) {}

  public load(teamName: string): Observable<string[]> {
    this.http.get(`${ConfigService.apiUrl}/datasets/${teamName}`).subscribe((response: string[]) => {
      this._collection$.next(response);
    });

    return this._collection$.asObservable();
  }

  public async loadAsync(teamName: string): Promise<string[]> {
    return lastValueFrom(this.http.get<string[]>(`${ConfigService.apiUrl}/datasets/${teamName}`));
  }

  public get(teamName: string, name: string) {
    this.http.get(`${ConfigService.apiUrl}/dataset/${teamName}/${name}`).subscribe((response: any) => {
      this._item$.next(response);
    });

    return this._item$.asObservable();
  }

  public async add(teamName: string, name: string) {
    return lastValueFrom(this.http.get(`${ConfigService.apiUrl}/dataset/add/${teamName}/${name}`));
  }

  public async update(teamName: string, name: string, item: string, add: boolean) {
    const data = { add, item };
    return lastValueFrom(this.http.post<any[]>(`${ConfigService.apiUrl}/dataset/update/${teamName}/${name}`, data));
  }

  public count(teamName: string, name: string): Promise<number | undefined> {
    return lastValueFrom(this.http.get<number | undefined>(`${ConfigService.apiUrl}/dataset/count/${teamName}/${name}`));
  }

  public async data(teamName: string, name: string, start: number, stop: number) {
    return lastValueFrom(
      this.http.post<any>(`${ConfigService.apiUrl}/dataset/data/${teamName}`, {
        name,
        start,
        stop
      })
    );
  }

  public async dataByDate(teamName: string, name: string, timestamp: number, aperture: number) {
    return lastValueFrom(
      this.http.post<any>(`${ConfigService.apiUrl}/dataset/data/bydate/${teamName}`, {
        name,
        timestamp,
        aperture
      })
    );
  }

  public async demo(teamName: string, featurePath: string, name: string, index: number, config: { [key: string]: any }) {
    return lastValueFrom(
      this.http.post<any>(`${ConfigService.apiUrl}/dataset/demo/${teamName}`, {
        featurePath,
        name,
        index,
        config
      })
    );
  }

  public async estimate(teamName: string, featurePath: string, name: string, count: number, config: { [key: string]: any }) {
    return lastValueFrom(
      this.http.post<any>(`${ConfigService.apiUrl}/project/estimate/${teamName}`, {
        featurePath,
        name,
        count,
        config
      })
    );
  }

  public async remove(teamName: string, name: string) {
    return lastValueFrom(this.http.get(`${ConfigService.apiUrl}/dataset/remove/${teamName}/${name}`));
  }

}
