import { Component, ViewChild, OnDestroy, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { PredictEngineService } from 'src/app/services/predict-engine.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GpusService } from 'src/app/services/gpus.service';
import { DataService } from 'src/app/services/data.service';
import { VaultService } from 'src/app/services/vault.service';
import { ChipListComponent } from '../../chip-list/chip-list.component';
import { HostsService } from 'src/app/services/hosts.service';
import { IdentityService } from 'src/app/services/identity.service';
import { TrustService } from 'src/app/services/trust.service';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from 'src/app/services/config.service';
import { StreamsService } from 'src/app/services/streams.service';
import { DynamicFormComponent } from '../../dynamic-form/dynamic-form.component';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { WidgetService } from 'src/app/services/widget.service';

@Component({
  selector: 'app-deploy',
  templateUrl: './deploy.component.html',
  styleUrls: ['./deploy.component.scss']
})
export class DeployComponent implements OnInit, OnDestroy {
  @ViewChild('modelChips') modelChips: ChipListComponent;
  @ViewChild(DynamicFormComponent) parameterForm: DynamicFormComponent;

  @Input() isWidget: boolean = false;
  @Input() teamName: string;
  @Input() serviceName: string;
  @Output() closeClick = new EventEmitter<void>();

  public loading: boolean = true;
  public device: string;
  public stream: string;
  public ticker: string;
  public cycleTickers: string;
  public interval: string;
  public streams: any[] = [];
  public allGPUS = [];
  public devices: string[] = ['cpu'];
  public serviceData = null;
  public mode: 'add' | 'edit';
  private allDeploys = [];
  public modelLoaded: boolean = false;
  public models = [];
  public allModels = [];
  public saveStatus: 'COMPLETE' | 'ERROR' | 'SAVING' = 'COMPLETE';
  public command: string = null;
  public terminalId = new Date().getTime().toString();
  public tickers = [];

  private _modelsAdded: string[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private predictEngineService: PredictEngineService,
    private gpuService: GpusService,
    private vaultService: VaultService,
    public snackbar: MatSnackBar,
    private streamsService: StreamsService,
    private widgetService: WidgetService,
    private http: HttpClient
  ) {
    (<any>window).preview = this;
  }

  ngOnInit(): void {
    (<any>window).deploy = this;
    if (this.isWidget) {
      this.mode = this.serviceName.toLowerCase() === 'add' ? 'add' : 'edit';
      this.load();
    } else {
      this.activatedRoute.params.subscribe((params) => {
        this.teamName = params.team;
        this.serviceName = params.name;

        this.mode = this.serviceName.toLowerCase() === 'add' ? 'add' : 'edit';
        this.load();
      });
    }
  }

  ngAfterViewInit(): void {
    this.onResize();
  }

  onResize() {
    const terminal: HTMLElement = document.querySelector('ng-terminal');
    if (terminal) {
      terminal.style.width = terminal.parentElement.parentElement.clientWidth + 'px';
    }
  }

  chipClick(vaultName) {
    this.router.navigate([this.teamName, 'vault-detail', vaultName]);
  }

  chipAdded($event) {
    this._modelsAdded.push(`${this.serviceName}:${$event.name}`);
  }

  chipRemoved($event) {
    this._modelsAdded = this._modelsAdded.filter((x)=> x != `${this.serviceName}:${$event.name}`);
  }

  public async initModels() {
    const sub = this.vaultService.load(this.teamName).subscribe((response) => {
      sub.unsubscribe();
      if (!response || response.length == 0) return;
      this.modelLoaded = true;
      this.allModels = response.map((x) => x.name).filter((x) => x !== null);
    });
  }

  loadExisting() {
    const sub = this.predictEngineService.load(this.teamName).subscribe((response) => {
      sub.unsubscribe();
      this.allDeploys = response;
    });
  }

  resetDevices() {
    this.devices = ['cpu'];
    if (this.allGPUS.length > 0) {
      const count = this.allGPUS[0]['count'];
      for (let i = 0; i < count; i++) {
        this.devices.push(`gpu${i}`);
      }
    }
  }

  async load() {
    this.loading = true;

    const streams: any = await this.streamsService.loadAsync(this.teamName);
    this.streams = streams;

    this.loadExisting();

    const gpusub = this.gpuService.countGpus(this.teamName).subscribe((response) => {
      gpusub.unsubscribe();
      this.allGPUS = response;
      this.resetDevices();
    });

    if (this.mode === 'edit') {
      this.initModels();
      this.loadServiceData();
    } else {
      this.serviceName = null;
      this.serviceData = { name: null, host: null };
      this.models = [];
      this.loading = false;
    }
  }

  loadServiceData() {
    const sub = this.predictEngineService.load(this.teamName).subscribe((response) => {
      sub.unsubscribe();
      this.serviceData = response.filter((x) => x.name == this.serviceName)[0];
      const name = !this.serviceData.static ? `lit-predict@${this.teamName}:${this.serviceName}` : this.serviceName;
      this.command = `sudo journalctl -f -u ${name}`;

      if (!this.serviceData.static) {
        const subsub = this.predictEngineService.loadOne(this.teamName, this.serviceName).subscribe((response) => {
          subsub.unsubscribe();

          if (response.output) this.device = response.output.device;
          if (response.stream) {
            this.stream = response.stream;
            this.loadStream(this.stream);
          }

          if (response.parameters?.ticker) {
            this.ticker = response.parameters.ticker;
          } else if (response.ticker) this.ticker = response.ticker;

          if (response.parameters?.interval) {
            this.interval = response.parameters.interval;
          } else if (response.interval) this.interval = response.interval;

          if (response.parameters?.cycle_tickers) {
            this.cycleTickers = response.parameters.cycle_tickers.join(",");
          } else if (response.cycle_tickers) this.cycleTickers = response.cycle_tickers.join(",");

          if (response.output) {
            this.models = response.output.models;
          }

          this.loading = false;
        });
      } else {
        this.loading = false;
      }

      this.resetDevices();
    });
  }

  async onStart() {
    this.predictEngineService.start(this.teamName, this.serviceName, this.serviceData.static).subscribe((response) => {
      this.loadServiceData();
    });
  }

  async onStop() {
    this.predictEngineService.stop(this.teamName, this.serviceName, this.serviceData.static).subscribe((response) => {
      this.loadServiceData();
    });
  }

  async onRestart() {
    this.predictEngineService.restart(this.teamName, this.serviceName, this.serviceData.static).subscribe((response) => {
      this.loadServiceData();
    });
  }

  async delete() {
    if (!confirm('Are you sure you want to delete this deployment?')) {
      return;
    }

    if (this.serviceData.status == 'running') {
      await this.onStop();
    }
    this.predictEngineService.delete(this.teamName, this.serviceName).subscribe((response) => {
      if (this.isWidget) {
        this.closeClick.emit();
      } else {
        this.router.navigate([this.teamName, 'deploys']);
      }
    });
  }

  onClose() {
    this.closeClick.emit();
  }

  loadStream(streamName: string) {
    const sub = this.streamsService.get(this.teamName, streamName).subscribe((response: any) => {
      sub.unsubscribe();
      this.tickers = response.parameters?.tickers?.split(',');
    });
  }
  onStreamSelected(value: string) {
    const stream = this.streams.find((x) => x.name === value);
    if (!stream) {
      this.tickers = [];
      return;
    }

    this.loadStream(value);
  }

  onTickerSelected(value: string) {
    this.ticker = value;
  }

  onIntervalSelected(value: string) {
    this.interval = value;
  }

  isDeployValid() {
    if (!this.serviceName || !this.stream) return false;

    return true;
  }

  async save() {
    this.saveStatus = 'SAVING';
    if (this.mode === 'add') {
      const existing = this.allDeploys.map((x) => x.name);
      if (existing.includes(this.serviceName)) {
        alert(`A deployed named ${this.serviceName} already exists.`);
        this.saveStatus = 'COMPLETE';
        return;
      }
    }

    const newModels = [...new Set(this._modelsAdded)];

    this.serviceData = {
      name: this.serviceName,
      output: {
        device: this.device,
        models: this.modelChips?.value || []
      }
    };

    if (this.stream) {
      this.serviceData.stream = this.stream;
    }

    this.serviceData.parameters = {};
    if (this.ticker) {
      this.serviceData.parameters["ticker"] = this.ticker;
    }

    if (this.interval) {
      this.serviceData.parameters["interval"] = this.interval;
    }

    if (this.cycleTickers) {
      this.serviceData.parameters["cycle_tickers"] = this.cycleTickers?.split(",").map(x => x.trim())
    }

    const _this = this;
    function __innerSave() {
      const sub = _this.update(`/data/${_this.teamName}/preview/${_this.serviceName}.json`).subscribe({
        error: (error) => {
          sub.unsubscribe();
          _this.saveStatus = 'ERROR';
          console.error(error);
        },
        complete: () => {
          sub.unsubscribe();
          _this.saveStatus = 'COMPLETE';
          if (newModels.length > 0) {
            _this.widgetService.modelsAdded$.next({ models: newModels });
          }
          if (_this.mode === 'add') {
            if (_this.isWidget) {
              _this.closeClick.emit();
            } else {
              _this.router.navigate([_this.teamName, 'deploy', _this.serviceName]);
            }
          }
          _this.openSnackBar('Service must be restarted before changes will take affect.', 'OK');
          _this.loadServiceData();
        }
      });
    }

    if (newModels.length > 0) {
      // first copy the vault models to the target machine
      const vaultsub = this.vaultService.deploy(this.teamName, this.serviceData).subscribe({
        error: (error) => {
          vaultsub.unsubscribe();
          console.error(error);
        },
        complete: () => {
          vaultsub.unsubscribe();
          // then, if successful, update the json file that drives the prediction engine
          __innerSave();
        }
      });
    }
    else {
      // just update the json file
      __innerSave();
    }

  }

  openSnackBar(message: string, action: string) {
    const durationInSeconds = 3;
    const horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    const verticalPosition: MatSnackBarVerticalPosition = 'top';

    this.snackbar.open(message, action, {
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition,
      duration: durationInSeconds * 1000
    });
  }

  update(path: string) {
    return this.postFile({ path, type: 'json', data: this.serviceData });
  }

  postFile(data: any) {
    return this.http.post(`${ConfigService.apiUrl}/file/${this.teamName}`, data);
  }

  ngOnDestroy(): void {}
}
