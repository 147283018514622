import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { StreamsService } from 'src/app/services/streams.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MetaService } from 'src/app/services/meta.service';
import { DynamicFormComponent } from '../../dynamic-form/dynamic-form.component';
import { TeamsService } from 'src/app/services/teams.service';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClient } from '@angular/common/http';
import { DataService } from 'src/app/services/data.service';
import { StreamEngineService } from 'src/app/services/stream-engine.service';
import { isNil } from 'lodash';
import { simplifyStatus } from '../../service-status-icon/service-status-icon.component';
import { SocketService } from 'src/app/services/socket.service';
import { Worker } from 'src/app/services/predict-engine.service';
import { v4 as uuidv4 } from 'uuid';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

interface APIKey {
  key: string;
  issuedTo: string;
  issuedDate: string;
  status: string;
}

@Component({
  selector: 'app-streams-widget',
  templateUrl: './streams-widget.component.html',
  styleUrls: ['../base-widget/base-widget.component.scss', './streams-widget.component.scss']
})
export class StreamsWidgetComponent extends BaseWidgetComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator) keysPaginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) keysSort: MatSort;

  @ViewChild(DynamicFormComponent) settingsForm: DynamicFormComponent;

  @Output() onStreamSelect = new EventEmitter<{ parentId: string; streamName: string; ui: string }>();

  public displayedColumns: string[] = ['name', 'host', 'service', 'path', 'adapter', 'settings'];
  public pageSize = 10;
  public isLoading = true;
  public isLoadingSettings = true;
  public dataSource = new MatTableDataSource<any>([]);
  public selectedStreamName: string;
  public stream: any = null;
  public streamParameters;
  public isNew = false;
  public meta: any = null;
  public availableAdapters: string[] = [];
  public selectedAdapter: string;
  public serviceData = { name: null, host: null };
  public command: string = null;
  public terminalId = new Date().getTime().toString();
  public isRestAdapter: boolean;
  public apiKeys = new MatTableDataSource<APIKey>([]);
  public apiKeysColumns = ['key', 'issuedTo', 'issuedDate', 'status', 'action'];
  public apiKeyStatuses = ['enabled', 'revoked'];
  public isSaving = false;

  constructor(
    private _streamsService: StreamsService,
    private _metaService: MetaService,
    private _teamsService: TeamsService,
    private _dataService: DataService,
    private _http: HttpClient,
    private _streamEngineService: StreamEngineService,
    private _sockets: SocketService,
    public snackbar: MatSnackBar
  ) {
    super();
    if ((<any>window).streams) (<any>window).streams.push(this);
    else (<any>window).streams = [this];
  }

  async ngOnInit(): Promise<void> {
    await this.init();

    this._sockets.joinRoom('stream');
    this._subs.add = this._sockets.subscribeToRoomEvents('stream', (data: { service: Worker }) => {
      const { service } = data;
      const localName = service.name.replace(`lit-stream@${this.teamName}:`, '');
      const stream = this.dataSource.data.find((worker: Worker) => worker.name === localName);
      if (stream) Object.assign(stream, { ...service, name: localName, status: simplifyStatus(service) });
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    this._initKeysTable();
  }

  ngOnDestroy(): void {
    this._sockets.leaveRoom('stream');
    this._subs.dispose();
  }

  private _initKeysTable(data?: APIKey[]) {
    if (data !== undefined) this.apiKeys = new MatTableDataSource(data);

    this.apiKeys.sort = this.keysSort;
    this.apiKeys.paginator = this.keysPaginator;
  }

  async init(reload: boolean = false) {
    this.loadData().then(() => (this.isLoading = false));

    if (!reload) await this.loadAdapters();
  }

  async loadData() {
    const [streams, workers] = await Promise.all([
      this._streamsService.loadAsync(this.teamName),
      this._streamsService.getWorkersAsync(this.teamName)
    ]);
    if (!streams || !workers) return;

    const workerMap = Object.fromEntries(workers.map((worker) => [worker.name, worker]));
    this.dataSource.data = streams.map((stream) => {
      if (stream.name in workerMap) return { ...stream, status: simplifyStatus(workerMap[stream.name]) };
      return stream;
    });
  }

  async loadAdapters() {
    this.availableAdapters = (await this._dataService.loadAdaptersAsync(this.teamName)).map((x) => x.name);
  }

  loadServiceData() {
    const sub = this._streamEngineService.load(this.teamName).subscribe((response) => {
      sub.unsubscribe();
      this.serviceData = response.filter((x) => x.name == this.selectedStreamName)[0];
    });
  }

  isDeployValid() {
    return !isNil(this.selectedStreamName) && !isNil(this.stream);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) this.dataSource.paginator.firstPage();
  }

  rowClick(row) {
    if (row.ui === 'rest' && row.status !== 'active') {
      alert(`${row.name} needs to be started before viewing.`);
      return;
    }

    this.onStreamSelect.emit({ parentId: this.id, streamName: row.name, ui: row.ui });
  }

  async settings(row) {
    this.widgetView = 'DETAIL';
    this.isLoadingSettings = true;
    this.selectedAdapter = row.adapter;
    this.selectedStreamName = row.name;
    this.isRestAdapter = row.ui === 'rest';

    const meta: any = await this._metaService.getAsync('adapter', this.teamName, this.selectedAdapter);
    for (let param of meta.params) param.description = null;

    const name = `lit-stream@${this.teamName}:${this.selectedStreamName}`;
    this.command = `sudo journalctl -f -u ${name}`;

    this.loadServiceData();

    const subsub = this._streamEngineService.loadOne(this.teamName, this.selectedStreamName).subscribe((response) => {
      subsub.unsubscribe();

      if (response.stream) this.stream = response.stream;
    });

    const sub = this._streamsService.get(this.teamName, this.selectedStreamName).subscribe((response: any) => {
      sub.unsubscribe();
      this.stream = response;
      if ('api_keys' in this.stream.parameters) {
        this._initKeysTable(this.stream.parameters['api_keys'].map((key) => ({ issuedTo: null, issuedDate: null, ...key })));
      } else {
        this._initKeysTable([]);
      }

      this.streamParameters = this.stream.parameters;
      this.meta = meta;
      this.isLoadingSettings = false;
    });
  }

  async onStart() {
    this._streamEngineService.start(this.teamName, this.selectedStreamName).subscribe((response) => {
      this.loadServiceData();
    });
  }

  async onStop() {
    this._streamEngineService.stop(this.teamName, this.selectedStreamName).subscribe((response) => {
      this.loadServiceData();
    });
  }

  async onRestart() {
    this._streamEngineService.restart(this.teamName, this.selectedStreamName).subscribe((response) => {
      this.loadServiceData();
    });
  }

  add() {
    this.widgetView = 'DETAIL';
    this.isNew = true;

    this.stream = {
      adapter: {
        name: null
      },
      parameters: {}
    };
  }

  async delete() {
    if (!confirm(`Are you sure you want to delete ${this.selectedStreamName}?`)) return;
    await this._streamsService.deleteAsync(this.teamName, this.selectedStreamName);
    this.init(true);
    this.widgetView = 'LIST';
  }

  close() {
    if (this.widgetView === 'DETAIL') {
      this.widgetView = 'LIST';
      this.isNew = false;
      this.selectedStreamName = null;
      this.selectedAdapter = null;
      this.streamParameters = null;
      this.meta = null;
      this.isLoading = true;
      this.init(true);
    } else {
      super.close();
    }
  }

  private async _save() {
    this.isSaving = true;

    const teamConfig = await this._teamsService.getConfigAsync(this.teamName);
    const path = `${teamConfig.path.streams}/${this.selectedStreamName.replace(' ', '_')}.json`;
    await this.postFile({ path: path, type: 'json', data: this.stream }, this.isRestAdapter && this.isNew);

    this.isSaving = false;
  }

  async save() {
    const params = this.settingsForm.getFormData();

    this.stream.adapter.name = this.selectedAdapter;
    Object.keys(params).forEach((key) => {
      if (key !== 'id') this.stream.parameters[key] = params[key];
    });
    if (!this.isValid()) return;

    this._save();
  }

  async saveKeys() {
    if (!this.isRestAdapter) return;

    console.log(this.apiKeys.data);

    this.stream.adapter.name = this.selectedAdapter;
    this.stream.parameters['api_keys'] = this.apiKeys.data;
    if (!this.isValid()) return;

    await this._save();
  }

  async postFile(data: any, createSwagger: boolean = false): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const sub = this._http.post(`${ConfigService.apiUrl}/file/${this.teamName}`, data).subscribe({
        error: (error) => {
          sub.unsubscribe();
          this.openSnackBar('Error saving stream.', 'OK');
          console.error(error);
          reject(error);
        },
        complete: () => {
          sub.unsubscribe();
          if (createSwagger) {
            this.initSwagger(this.selectedStreamName.replace(' ', '_'));
          } else {
            this.isNew = false;
            this.openSnackBar('Save successful. Restart required.', 'OK');
          }
          resolve();
        }
      });
    });
  }

  initSwagger(streamName: string) {
    const sub = this._streamsService.createSwagger(this.teamName, streamName).subscribe((response) => {
      sub.unsubscribe();
      this.isNew = false;
      this.openSnackBar('Save successful.', 'OK');
    });
  }

  async onAdapterSelected($event) {
    const meta: any = await this._metaService.getAsync('adapter', this.teamName, this.selectedAdapter);
    for (let param of meta.params) {
      param.description = null;
    }
    this.meta = meta;
    const params = {};

    for (let item of this.meta.params) {
      params[item.name] = item.type === 'boolean' ? false : null;
      if (item.name === 'port') this.isRestAdapter = true;
    }

    this.streamParameters = params;
    this.isLoadingSettings = false;
  }

  isValid() {
    let valid = true;
    let message = '';

    if (!this.selectedStreamName || this.selectedStreamName.length < 4) {
      valid = false;
      message = 'Stream name must be at least 3 characters long.\n';
    }

    for (let item of this.meta.params) {
      if (item.type === 'boolean' || item.required === false) continue;

      if (!this.stream.parameters[item.name]) {
        valid = false;
        message = `${message} ${item.name} is required.\n`;
        continue;
      }

      if (item.type === 'number') {
        if (this.stream.parameters[item.name] === null && item.required === false) continue;

        if (isNaN(this.stream.parameters[item.name])) {
          valid = false;
          message = `${message} ${item.name} must be numeric.\n`;
        }
      }
    }

    if (!valid) {
      alert(message);
    }
    return valid;
  }

  getCurrentTimestamp() {
    const now = new Date();

    const options: any = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
      timeZoneName: 'short'
    };

    const timestamp = now.toLocaleString('en-US', options);
    return timestamp;
  }

  addKey(): void {
    const timestamp = this.getCurrentTimestamp();
    const keys = this.apiKeys.data;
    keys.unshift({ key: uuidv4(), status: 'enabled', issuedTo: null, issuedDate: timestamp });

    this._initKeysTable(keys);
  }

  deleteKey(row: APIKey): void {
    if (!confirm('Are you sure you want to delete this key?')) return;

    const keys = this.apiKeys.data.filter((x) => x.key !== row.key);
    this._initKeysTable(keys);
  }

  copyKey(row: APIKey): void {
    if (navigator.clipboard !== undefined) {
      navigator.clipboard.writeText(row.key);
      this.openSnackBar('Key copied to clipboard.', 'OK');
    } else console.info('Navigator does not have clipboard (requires HTTPS)');
  }

  $inputIssuedTo($ev: Event, apiKey: APIKey) {
    apiKey.issuedTo = (<HTMLInputElement>$ev.target).value
    console.log({ $ev, apiKey });
  }

  applyKeysFilter(filterValue: string) {
    this.apiKeys.filter = filterValue.trim().toLowerCase();

    if (this.apiKeys.paginator) this.apiKeys.paginator.firstPage();
  }

  openSnackBar(message: string, action: string) {
    const durationInSeconds = 3;
    const horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    const verticalPosition: MatSnackBarVerticalPosition = 'top';

    this.snackbar.open(message, action, {
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition,
      duration: durationInSeconds * 1000
    });
  }
}
