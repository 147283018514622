import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-timeline-event',
  template: `
    <div class="wrapper">
      <div class="point-track-container">
        <div [class.active]="active" class="point"></div>
        <div class="track"></div>
      </div>
      <div class="content">
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styleUrls: ['./timeline-event.component.scss']
})
export class TimelineEventComponent {
  @Input() active: boolean = false;
}

@Component({
  selector: 'app-timeline',
  template: `
    <div class="header" *ngIf="showTitle">Timeline</div>
    <ng-content></ng-content>
  `,
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent {
  @Input() showTitle: boolean = true;
}
