// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
      :host {
        display: inline-block;
        margin: 0px;

        font-weight: bold;
        text-transform: uppercase;

        background-color: grey;
        color: #ffffff;
        transition: background-color 150ms linear;

        line-height: calc(1rem * var(--scale-factor, 1));
        height: calc(1rem * var(--scale-factor, 1));
        border-radius: calc(0.25rem * var(--scale-factor, 1));
        padding: 0px calc(0.25rem * var(--scale-factor, 1));
        font-size: calc(10px * var(--scale-factor, 1));
        letter-spacing: calc(0.5px * var(--scale-factor, 1));
        -webkit-user-select: none;
                user-select: none;

        &.pulse {
          animation: pulse 2s infinite;
        }

        &.connected {
          background-color: #d93025;
        }
      }

      @keyframes pulse {
        0% {
          box-shadow: 0 0 0 0 #d9302566;
        }
        70% {
          box-shadow: 0 0 0 10px #d9302500;
        }
        100% {
          box-shadow: 0 0 0 0 #d9302500;
        }
      }
    `, "",{"version":3,"sources":["webpack://./src/app/components/live-chip/live-chip.component.ts"],"names":[],"mappings":";MACM;QACE,qBAAqB;QACrB,WAAW;;QAEX,iBAAiB;QACjB,yBAAyB;;QAEzB,sBAAsB;QACtB,cAAc;QACd,yCAAyC;;QAEzC,gDAAgD;QAChD,2CAA2C;QAC3C,qDAAqD;QACrD,mDAAmD;QACnD,8CAA8C;QAC9C,oDAAoD;QACpD,yBAAiB;gBAAjB,iBAAiB;;QAEjB;UACE,4BAA4B;QAC9B;;QAEA;UACE,yBAAyB;QAC3B;MACF;;MAEA;QACE;UACE,6BAA6B;QAC/B;QACA;UACE,gCAAgC;QAClC;QACA;UACE,6BAA6B;QAC/B;MACF","sourcesContent":["\n      :host {\n        display: inline-block;\n        margin: 0px;\n\n        font-weight: bold;\n        text-transform: uppercase;\n\n        background-color: grey;\n        color: #ffffff;\n        transition: background-color 150ms linear;\n\n        line-height: calc(1rem * var(--scale-factor, 1));\n        height: calc(1rem * var(--scale-factor, 1));\n        border-radius: calc(0.25rem * var(--scale-factor, 1));\n        padding: 0px calc(0.25rem * var(--scale-factor, 1));\n        font-size: calc(10px * var(--scale-factor, 1));\n        letter-spacing: calc(0.5px * var(--scale-factor, 1));\n        user-select: none;\n\n        &.pulse {\n          animation: pulse 2s infinite;\n        }\n\n        &.connected {\n          background-color: #d93025;\n        }\n      }\n\n      @keyframes pulse {\n        0% {\n          box-shadow: 0 0 0 0 #d9302566;\n        }\n        70% {\n          box-shadow: 0 0 0 10px #d9302500;\n        }\n        100% {\n          box-shadow: 0 0 0 0 #d9302500;\n        }\n      }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
