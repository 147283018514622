import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { WidgetService } from 'src/app/services/widget.service';

@Component({
  selector: 'app-assets-widget',
  templateUrl: './assets-widget.component.html',
  styleUrls: ['../base-widget/base-widget.component.scss', './assets-widget.component.scss']
})
export class AssetsWidgetComponent extends BaseWidgetComponent implements OnInit {
  public assetName: string;
  public assetType: string;
  public fileName: string;

  @Output() onAsset = new EventEmitter<{ parentId: string; assetName: string }>();
  @Output() onDefinition = new EventEmitter<{ assetName: string }>();

  constructor(private _widgetService: WidgetService) {
    super();
  }

  ngOnInit(): void {
    if (this.assetName) {
      this.widgetView = 'DETAIL';
    }

    this._subs.add = this._widgetService.resize$.subscribe((response) => {
      if ((<any>window).samples) {
        (<any>window).samples.onResize();
      }
      if ((<any>window).assets) {
        (<any>window).assets.onResize();
      }
    });
  }

  onAssetClick($event) {
    this.assetName = $event.assetName;
    this.widgetView = 'DETAIL';
    // const input = {parentId: this.id, ...$event};
    // this.onAsset.emit(input);
  }

  onInspectClick($event) {
    this.assetType = $event.type;
    this.fileName = $event.fileName;
    console.log('onInspect', $event);
    this.widgetView = 'INSPECT';
  }

  onModelClick($event) {
    console.log('onModelClick', $event);
    this.onDefinition.emit($event);
  }

  closeInspect() {
    this.assetType = null;
    this.fileName = null;
    this.widgetView = 'DETAIL';
  }

  closeAsset() {
    this.assetName = null;
    this.widgetView = 'LIST';
  }
}
