import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { WidgetService } from 'src/app/services/widget.service';
import {
  SciChartSurface,
  NumericAxis,
  NumberRange,
  ZoomPanModifier,
  MouseWheelZoomModifier,
  CursorModifier,
  XyDataSeries,
  EAutoRange,
  DataPointSelectionModifier,
  FastLineRenderableSeries,
  DateTimeNumericAxis,
  EllipsePointMarker,
  parseColorToUIntArgb,
  DefaultPaletteProvider,
  TPointMarkerArgb,
  EStrokePaletteMode,
  IPointMetadata
} from 'scichart';
import { SettingsService } from 'src/app/services/settings.service';
import { StreamsService } from 'src/app/services/streams.service';
import { appTheme } from '../../../modules/shared/material-theme';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';

enum PacketDisposition {
  Excluded = 0,
  Micropacket = 1,
  Processed = 2
}

export class PacketDispositionPaletteProvider extends DefaultPaletteProvider {
  private override: (data?: PacketDisposition) => TPointMarkerArgb | undefined;

  constructor(excluded: string, micropacket: string) {
    super();

    this.strokePaletteMode = EStrokePaletteMode.SOLID;
    this.override = (data?) =>
      [
        {
          stroke: parseColorToUIntArgb(excluded),
          fill: parseColorToUIntArgb(excluded)
        },
        {
          stroke: parseColorToUIntArgb(micropacket),
          fill: parseColorToUIntArgb(micropacket)
        },
        undefined
      ][data ?? PacketDisposition.Processed];
  }

  overridePointMarkerArgb = (
    _xValue: number,
    _yValue: number,
    _index: number,
    _opacity?: number,
    metadata?: IPointMetadata & { data: PacketDisposition }
  ) => this.override(metadata?.data);
}

@Component({
  selector: 'app-tick-widget',
  templateUrl: './tick-widget.component.html',
  styleUrls: ['../base-widget/base-widget.component.scss', './tick-widget.component.scss']
})
export class TickWidgetComponent extends BaseWidgetComponent implements OnInit, OnDestroy {
  @Input() streamName: string = null;
  @Input() symbol: string = null;
  @Input() date: Date;
  @Input() title: string = null;

  @Output() onPacketSelect = new EventEmitter<{ parentId: string; packetContent: any }>();

  private sciChartSurface: SciChartSurface | undefined;
  public packetContent: any = null;
  public showPacket: boolean = false;

  constructor(
    private _widgetService: WidgetService,
    private _settingsService: SettingsService,
    private streamsService: StreamsService
  ) {
    super();
  }

  ngOnInit(): void {
    this._subs.add = this._widgetService.streamUpdate$.subscribe((response: any) => {
      if (response.parentId !== this.parentId) return;
      this.init();
    });

    this.init();
  }

  init() {
    setTimeout(async () => {
      const date = this.date;

      this._widgetService.childAdd$.next({ id: this.id, parentId: this.parentId });
      const theme = this._settingsService.getChartThemeProvider();
      const { sciChartSurface, wasmContext } = await SciChartSurface.create(this.id, {
        theme,
        title: this.title,
        disableAspect: true
      });
      this.sciChartSurface = sciChartSurface;

      sciChartSurface.xAxes.add(new DateTimeNumericAxis(wasmContext));
      sciChartSurface.yAxes.add(new NumericAxis(wasmContext));

      sciChartSurface.yAxes.add(
        new NumericAxis(wasmContext, {
          id: 'volume',
          growBy: new NumberRange(0, 4),
          isVisible: false,
          autoRange: EAutoRange.Always,
          labelPrecision: 0
        })
      );

      const zeroPad = (padding, number) => String(number).padStart(padding, '0');
      const formattedDate = `${date.getFullYear()}-${zeroPad(2, date.getMonth() + 1)}-${zeroPad(2, date.getUTCDate())}_${zeroPad(
        2,
        date.getUTCHours()
      )}:${zeroPad(2, date.getUTCMinutes())}`;

      this.streamsService.getPackets(this.teamName, this.streamName, this.symbol, formattedDate).subscribe((response) => {
        const yValues = (<any>response).p;
        const xValues = (<any>response).t.map((x) => new Date(x).getTime() / 1000);
        const metadata = (<any>response).d.map((d) => ({
          data:
            d === 'Processed'
              ? PacketDisposition.Processed
              : d.includes('micropacket')
                ? PacketDisposition.Micropacket
                : PacketDisposition.Excluded
        }));

        const columnSeries = new FastLineRenderableSeries(wasmContext, {
          dataSeries: new XyDataSeries(wasmContext, { xValues, yValues, metadata, containsNaN: false, isSorted: true }),
          paletteProvider: new PacketDispositionPaletteProvider('#F23645', '#FFEB3B'),
          pointMarker: new EllipsePointMarker(wasmContext, {
            width: 6,
            height: 6,
            strokeThickness: 1
          })
        });

        const modifiers = {
          zoomPan: new ZoomPanModifier(),
          mouseWheelZoom: new MouseWheelZoomModifier(),
          cursor: new CursorModifier({
            crosshairStroke: '#eee',
            axisLabelFill: appTheme.DarkIndigo
          }),
          dataPoint: new DataPointSelectionModifier()
        };
        modifiers.dataPoint.selectionChanged.subscribe((args) => {
          if (args.selectedDataPoints.length > 1) {
            console.warn(`Multiple Data Points Selected: ${args.selectedDataPoints.length}`);
            return;
          }

          if (args.selectedDataPoints.length == 1) {
            const datapoint = args.selectedDataPoints[0];
            const soup_id = (<any>response).s[datapoint.index];
            this.streamsService.getPacket(this.teamName, this.streamName, this.symbol, formattedDate, soup_id).subscribe((response) => {
              const packetContent = JSON.stringify(response, null, 4);
              this.onPacketSelect.emit({ parentId: this.parentId, packetContent });
            });
          }
        });

        modifiers.dataPoint.allowDragSelect = false;
        sciChartSurface.chartModifiers.add(...Object.values(modifiers));

        sciChartSurface.renderableSeries.clear();
        sciChartSurface.renderableSeries.add(columnSeries);
      });

      sciChartSurface.domChartRoot.tabIndex = sciChartSurface.domChartRoot.tabIndex ?? 0;
      sciChartSurface.domChartRoot.focus();

      this._settingsService.chartTheme$.subscribe(this.$changeTheme.bind(this));
      this._settingsService.theme$.subscribe(this.$changeTheme.bind(this));
    }, 100);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public $changeTheme(): void {
    if (this.sciChartSurface) this.sciChartSurface.applyTheme(this._settingsService.getChartThemeProvider());
  }
}
