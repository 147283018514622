import { Injectable } from '@angular/core';
import { Observable, lastValueFrom } from 'rxjs';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class WorkersService {
  constructor(private http: HttpClient) {}

  public load(teamName: string): Observable<any> {
    return this.http.get(`${ConfigService.apiUrl}/workers/${teamName}`);
  }

  public addModel(teamName: string, workerName: string, modelName: string): Observable<any> {
    return this.http.post(`${ConfigService.apiUrl}/worker/model/add/${teamName}`, { workerName, modelName });
  }

  public removeModel(teamName: string, workerName: string, modelName: string): Observable<any> {
    return this.http.post(`${ConfigService.apiUrl}/worker/model/remove/${teamName}`, { workerName, modelName });
  }

  public addModelAsync(teamName: string, workerName: string, modelName: string) {
    return lastValueFrom(this.addModel(teamName, workerName, modelName));
  }

  public removeModelAsync(teamName: string, workerName: string, modelName: string) {
    return lastValueFrom(this.removeModel(teamName, workerName, modelName));
  }
}
