import { Component, Input, OnInit } from '@angular/core';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';

@Component({
  selector: 'app-inspect-widget',
  templateUrl: './inspect-widget.component.html',
  styleUrls: ['../base-widget/base-widget.component.scss', './inspect-widget.component.scss']
})
export class InspectWidgetComponent extends BaseWidgetComponent implements OnInit {
  @Input() type: string;
  @Input() assetName: string;
  @Input() fileName: string;

  constructor() {
    super();
  }

  ngOnInit(): void {
    console.log('widget', this.type, this.assetName, this.fileName);
  }
}
