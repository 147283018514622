import { Component, OnInit } from '@angular/core';
import { first, interval } from 'rxjs';
import { InitService } from 'src/app/services/init.service';
import { SocketService } from 'src/app/services/socket.service';

@Component({
  selector: 'app-init',
  templateUrl: './init.component.html',
  styleUrls: ['./init.component.scss']
})
export class InitComponent implements OnInit {
  public nwis: string[] = [];
  public selectedNWI: string = null;
  public selectedProtocol: string = null;
  public selectedPort: number = 22;
  public machineid: string = null;
  public licensee: string = null;
  public signature: string = null;
  public initializing: boolean = false;
  public status: string = null;
  public initProgress: any[] = [
    { msg: 'Keycloak waiting for initialization', status: 'WAITING' },
    { msg: 'Host waiting for initialization', status: 'WAITING' },
    { msg: 'SSH waiting for initialization', status: 'WAITING' },
    { msg: 'API waiting for initialization', status: 'WAITING' }
  ];

  constructor(
    private _initService: InitService,
    private _socketService: SocketService
  ) {}

  async ngOnInit(): Promise<void> {
    const results: any = await this._initService.getNetworkInterfacesAsync();
    this.nwis = results.networkInterfaces;

    const results2: any = await this._initService.getMachineIdAsync();
    this.machineid = results2.machineid;

    const results3: any = await this._initService.getLicenseAsync();
    if(results3.licensee)
      this.licensee = results3.licensee;
    if(results3.signature)
      this.signature = results3.signature;

  }

  async assign() {
    this.initializing = true;
    this._socketService.init(`${this.selectedProtocol}://${this.selectedNWI}`, this.selectedPort, this.licensee, this.signature);
    this._socketService.initEvents.subscribe((msg: any) => {
      switch (msg) {
        case 'KEYCLOAK':
          this.initProgress[0] = {
            msg: 'Keycloak Initializing',
            status: 'INIT'
          };
          break;
        case 'HOST':
          this.initProgress[0] = {
            msg: 'Keycloak Intialized ',
            status: 'COMPLETE'
          };
          this.initProgress[1] = {
            msg: 'Host Initializing',
            status: 'INIT'
          };
          break;
        case 'SSH':
          this.initProgress[1] = {
            msg: 'API Intialized ',
            status: 'COMPLETE'
          };
          this.initProgress[2] = {
            msg: 'SSH Initializing',
            status: 'INIT'
          };
          break;
        case 'API':
          this.initProgress[2] = {
            msg: 'SSH Initialized',
            status: 'COMPLETE'
          };
          this.initProgress[3] = {
            msg: 'API Initializing',
            status: 'INIT'
          };
          this.ping();
          break;
      }
    });
  }

  ping() {
    const intervalSeconds = 5;
    const source = interval(intervalSeconds * 1000);
    source.subscribe(() => {
      this._initService
        .ping()
        .pipe(first())
        .subscribe((resp: any) => {
          this.initProgress[3] = {
            msg: 'API Initialized.',
            status: 'COMPLETE'
          };

          let seconds = 3;
          const timer = setInterval(() => {
            this.status = `You will be redirected to the login page in ${seconds} seconds.`;
            seconds--;

            if (seconds == 0) {
              clearInterval(timer);
              window.location.reload();
            }
          }, 1000);
        });
    });
  }
}
