// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.delete-icon {
  color: rgba(0, 0, 0, 0.3);
  font-size: 20px;
  line-height: 15px;
}

app-text,
app-select,
app-checkbox {
  width: 400px;
}

:host.flexible app-text,
:host.flexible app-select,
:host.flexible app-checkbox {
  flex-grow: 1;
}`, "",{"version":3,"sources":["webpack://./src/app/components/dynamic-form/control/control.component.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,eAAA;EACA,iBAAA;AACF;;AAEA;;;EAGE,YAAA;AACF;;AAEA;;;EAGE,YAAA;AACF","sourcesContent":[".delete-icon {\n  color: rgba(0, 0, 0, 0.3);\n  font-size: 20px;\n  line-height: 15px;\n}\n\napp-text,\napp-select,\napp-checkbox {\n  width: 400px;\n}\n\n:host.flexible app-text,\n:host.flexible app-select,\n:host.flexible app-checkbox {\n  flex-grow: 1;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
