// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.directory-tree-invisible {
  display: none;
}

.directory-tree ul,
.directory-tree li {
  margin-top: 0;
  margin-bottom: 0;
  list-style-type: none;
}

/*
 * This padding sets alignment of the nested nodes.
 */
.directory-tree .mat-nested-tree-node div[role=group] {
  padding-left: 40px;
}

/*
 * Padding for leaf nodes.
 * Leaf nodes need to have padding so as to align with other non-leaf nodes
 * under the same parent.
 */
.directory-tree div[role=group] > .mat-tree-node {
  padding-left: 40px;
}

.selected {
  background-color: lightgray;
}`, "",{"version":3,"sources":["webpack://./src/app/components/data/directory/directory.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAEA;;EAEE,aAAA;EACA,gBAAA;EACA,qBAAA;AACF;;AAEA;;EAAA;AAGA;EACE,kBAAA;AACF;;AAEA;;;;EAAA;AAKA;EACE,kBAAA;AACF;;AAEA;EACE,2BAAA;AACF","sourcesContent":[".directory-tree-invisible {\n  display: none;\n}\n\n.directory-tree ul,\n.directory-tree li {\n  margin-top: 0;\n  margin-bottom: 0;\n  list-style-type: none;\n}\n\n/*\n * This padding sets alignment of the nested nodes.\n */\n.directory-tree .mat-nested-tree-node div[role='group'] {\n  padding-left: 40px;\n}\n\n/*\n * Padding for leaf nodes.\n * Leaf nodes need to have padding so as to align with other non-leaf nodes\n * under the same parent.\n */\n.directory-tree div[role='group'] > .mat-tree-node {\n  padding-left: 40px;\n}\n\n.selected {\n  background-color: lightgray;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
