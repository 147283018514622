import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgTerminal } from 'ng-terminal';
import { AuthGuard } from 'src/app/services/auth.guard';
import { IdentityService } from 'src/app/services/identity.service';
import { SocketService } from 'src/app/services/socket.service';

@Component({
  selector: 'app-terminal',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './terminal.component.html',
  styleUrls: ['./terminal.component.scss']
})
export class TerminalComponent implements OnInit, AfterViewInit {
  @ViewChild('child', { static: false }) child: NgTerminal;
  @Input() command: string = null;
  @Input() readOnly: boolean = false;
  @Input() teamName: string;
  @Input() disconnectEvent: boolean = false;
  @Input() isWidget: boolean = false;
  @Input() terminalId: string = null;

  @Output() disconnected = new EventEmitter();

  private subscription: any;
  private commandExecuted: boolean = false;

  public isLoading: boolean = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private socketService: SocketService,
    private identity: IdentityService,
    private authGuard: AuthGuard
  ) {}

  ngOnInit(): void {
    (<any>window).terminal = this;
  }

  onResize() {
    this.socketService.emit('resize', this.getScreen());
  }

  ngAfterViewInit(): void {
    this.child.setXtermOptions({
      fontFamily: '"Cascadia Code", Menlo, monospace',
      fontSize: 14,
      cursorBlink: true
    });

    if (this.command || this.isWidget) {
      this.load(this.teamName);
    } else {
      this.activatedRoute.params.subscribe((params) => {
        this.load(params.team);
      });
    }
  }

  public async load(teamName: string): Promise<void> {
    this.teamName = teamName;

    this.isLoading = false;
    if (!this.readOnly) this.subscribeToTerminal();

    this.connect();
  }

  public subscribeToTerminal(): void {
    this.child.onKey().subscribe(({ key }) => {
      this.socketService.emit('ssh', {terminalId: this.terminalId, msg:key});
    });
  }

  public subscribeToSocketEvents(config: any): void {
    this.socketService.sshConnect(config);
    this.subscription = this.socketService.sshEvents.subscribe((response: any) => {
      if (response.terminalId !== this.terminalId)
        return;

      let msg = response.msg;
      this.child.write(msg);
      if ((this.disconnectEvent || this.isWidget) && msg.trim() === '*** SSH CONNECTION CLOSED ***') this.disconnect();
      if (this.command && !this.commandExecuted && msg.endsWith('$ ')) {
        this.commandExecuted = true;
        this.socketService.emit('ssh', {terminalId: this.terminalId, msg: this.command + '\r'});
      }
    });
  }

  public disconnect(): void {
    if (!this.subscription) return;

    this.socketService.sshDisconnect();
    this.subscription.unsubscribe();
    this.subscription = null;
    if (this.disconnectEvent) this.child.underlying.dispose();
    else this.child.underlying.reset();
    this.disconnected.emit();
  }

  public async connect(): Promise<void> {
    const token = localStorage.getItem('id_token');
    if (!token) {
      this.authGuard.logout();
      return;
    }

    const socketConfig = { teamName: this.teamName, username: this.identity.me.username, token: token, terminalId: this.terminalId };
    socketConfig['screen'] = this.getScreen();
    this.subscribeToSocketEvents(socketConfig);
  }

  public getScreen(): { cols: number; rows: number } {
    const width = document.querySelector('ng-terminal').clientWidth;
    const height = document.querySelector('ng-terminal').clientHeight;

    const cols = width / 9 - 4;
    const rows = (height * 26) / 467 - 0.66;

    return {
      cols: Math.floor(cols),
      rows: Math.floor(rows)
    };
  }
}
