// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `gridster {
  background: transparent;
}

gridster {
  background-color: #111;
}

gridster-item {
  border: 1px solid silver;
}

gridster-item .close-button {
  display: none;
}

gridster-item:hover .close-button {
  display: block;
}

gridster-item .titlebar {
  display: none;
}

gridster-item:hover .titlebar {
  display: block;
}

gridster-item-content {
  overflow: auto;
  overflow-x: hidden;
}

#startmenu-wrapper {
  position: absolute;
  width: 100dvw;
  bottom: 20px;
  display: flex;
  justify-content: center;
  z-index: 100;
  pointer-events: none;
}

#startmenu {
  margin: 0px auto;
  display: flex;
  height: 60px;
  align-items: flex-end;
  gap: 6px;
  padding: 0px 10px;
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 10px;
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(227, 227, 227, 0.3);
  transition: all 200ms ease;
  pointer-events: all;
}

#startmenu button {
  transform: scale(1.25);
  margin-right: unset;
  margin: 10px;
  transition: all 225ms cubic-bezier(0.19, 1, 0.22, 1) normal;
}
#startmenu button:hover {
  transform: scale(2);
  margin: 20px;
}

app-chat-widget {
  display: block;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/components/strategy-grid/grid/grid.component.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;AACF;;AAEA;EACE,sBAAA;AACF;;AAEA;EACE,wBAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAEA;EACE,cAAA;EACA,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,aAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,YAAA;EACA,oBAAA;AACF;;AAEA;EACE,gBAAA;EACA,aAAA;EACA,YAAA;EACA,qBAAA;EACA,QAAA;EACA,iBAAA;EACA,mCAAA;UAAA,2BAAA;EACA,mBAAA;EACA,2CAAA;EACA,0CAAA;EACA,0CAAA;EACA,0BAAA;EACA,mBAAA;AACF;;AAEA;EACE,sBAAA;EACA,mBAAA;EACA,YAAA;EAEA,2DAAA;AAAF;AAEE;EACE,mBAAA;EACA,YAAA;AAAJ;;AAIA;EACE,cAAA;EACA,YAAA;AADF","sourcesContent":["gridster {\n  background: transparent;\n}\n\ngridster {\n  background-color: #111;\n}\n\ngridster-item {\n  border: 1px solid silver;\n}\n\ngridster-item .close-button {\n  display: none;\n}\n\ngridster-item:hover .close-button {\n  display: block;\n}\n\ngridster-item .titlebar {\n  display: none;\n}\n\ngridster-item:hover .titlebar {\n  display: block;\n}\n\ngridster-item-content {\n  overflow: auto;\n  overflow-x: hidden;\n}\n\n#startmenu-wrapper {\n  position: absolute;\n  width: 100dvw;\n  bottom: 20px;\n  display: flex;\n  justify-content: center;\n  z-index: 100;\n  pointer-events: none;\n}\n\n#startmenu {\n  margin: 0px auto;\n  display: flex;\n  height: 60px;\n  align-items: flex-end;\n  gap: 6px;\n  padding: 0px 10px;\n  backdrop-filter: blur(20px);\n  border-radius: 20px;\n  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 10px;\n  background-color: rgba(255, 255, 255, 0.2);\n  border: 1px solid rgba(227, 227, 227, 0.3);\n  transition: all 200ms ease;\n  pointer-events: all;\n}\n\n#startmenu button {\n  transform: scale(1.25);\n  margin-right: unset;\n  margin: 10px;\n\n  transition: all 225ms cubic-bezier(0.19, 1, 0.22, 1) normal;\n\n  &:hover {\n    transform: scale(2);\n    margin: 20px\n  }\n}\n\napp-chat-widget {\n  display: block;\n  height:100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
