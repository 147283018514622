// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.header {
  font-size: 18px;
  margin-bottom: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/app/components/timeline/timeline.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,YAAA;EACA,sBAAA;AACF;;AAEA;EACE,eAAA;EACA,mBAAA;AACF","sourcesContent":[":host {\n  display: flex;\n  flex-grow: 1;\n  flex-direction: column;\n}\n\n.header {\n  font-size: 18px;\n  margin-bottom: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
