// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  width: 100%;
}

td {
  overflow: hidden;
  width: 75px;
  font-size: 10pt;
  white-space: nowrap;
}

th {
  font-size: 9pt;
  font-weight: bold;
}

.compact {
  height: 38px !important;
}

.hidden-paginator {
  visibility: hidden;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-data {
  font-size: 1.125rem;
  font-weight: 600;
  text-align: center;
  padding: 1rem 0;
}`, "",{"version":3,"sources":["webpack://./src/app/components/stream-api-stats/stream-api-stats.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAEA;EACE,gBAAA;EACA,WAAA;EACA,eAAA;EACA,mBAAA;AACF;;AAEA;EACE,cAAA;EACA,iBAAA;AACF;;AAEA;EACE,uBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;;AAEA;EACE,mBAAA;EACA,gBAAA;EACA,kBAAA;EACA,eAAA;AACF","sourcesContent":["table {\n  width: 100%;\n}\n\ntd {\n  overflow: hidden;\n  width: 75px;\n  font-size: 10pt;\n  white-space: nowrap;\n}\n\nth {\n  font-size: 9pt;\n  font-weight: bold;\n}\n\n.compact {\n  height: 38px !important;\n}\n\n.hidden-paginator {\n  visibility: hidden;\n}\n\n.spinner-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.no-data {\n  font-size: 1.125rem;\n  font-weight: 600;\n  text-align: center;\n  padding: 1rem 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
