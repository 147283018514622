import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { log } from 'console';
import { SampleService } from 'src/app/services/sample.service';

@Component({
  selector: 'app-inspect',
  templateUrl: './inspect.component.html',
  styleUrls: ['./inspect.component.scss']
})
export class InspectComponent implements OnInit {
  @ViewChild('sampleInput') sampleInput: ElementRef<HTMLInputElement>;

  @Input('teamName') teamName?: string;
  @Input('assetType') type?: string;
  @Input('assetName') assetName?: string;
  @Input('fileName') fileName?: string;
  @Input() isWidget: boolean = false;

  @Output() closeInspect = new EventEmitter();

  public inspect: any;
  public sampleIndex: number = 0;
  public selectedDS: string;
  public selectedColumn: string;
  public dataset = [];
  public cols = [];
  public data: any;
  public noSamples: boolean = false;
  public isLoading: boolean = false;
  public sample_timestamp?: Date;
  public view: string = 'TABLE';
  public csChartView: boolean = true;

  constructor(
    private sampleService: SampleService,
    private activatedRoute: ActivatedRoute
  ) {}

  private async _setOptionsFromRoute() {
    new Promise<void>((resolve, _reject) => {
      this.activatedRoute.params.subscribe((params) => {
        this.teamName = params.team;
        this.type = params.type;
        this.assetName = params.name;
        this.fileName = params.file;
        resolve();
      });
    });
  }

  ngOnInit(): void {
    (<any>window).inspect = this;

    if (this.isWidget || (this.teamName && this.type && this.assetName && this.fileName)) {
      console.log('inspect', this.teamName, this.type, this.assetName, this.fileName);
      this.load();
    } else this._setOptionsFromRoute().then(this.load.bind(this));
  }

  public load(): void {
    this.isLoading = true;
    const sub = this.sampleService.get(this.teamName, this.type, this.assetName, this.fileName).subscribe((response) => {
      sub.unsubscribe();
      this.inspect = response;
      this.isLoading = false;
      if (this.inspect) {
        this.selectedDS = this.inspect.datasets[0];
        setTimeout(() => {
          this.getRandom();
        }, 100);
      } else {
        this.inspect = { total_samples: 0 };
        this.noSamples = true;
      }
    });
  }

  public decode(content: string): string {
    return decodeURIComponent(content);
  }

  public getSample(): void {
    this.noSamples = false;

    this.isLoading = true;
    const sub = this.sampleService
      .getSample(this.teamName, this.type, this.assetName, this.fileName, this.sampleIndex)
      .subscribe((response: any) => {
        sub.unsubscribe();

        if (!response) {
          this.noSamples = true;
          return;
        }

        const { timestamp, percentiles, data } = response.sample;
        this.inspect = response;
        this.selectedDS = this.inspect.datasets[0];
        if (timestamp) this.sample_timestamp = new Date(timestamp / 1000000);
        this.data = data;
        this.cols = this.getCols();
        this.selectedColumn = this.cols[0]?.name;
        this.dataset = this.getDataSet();
        this.getOHLC();
        this.isLoading = false;
      });
  }

  public getRandom(): void {
    this.sampleIndex = Math.floor(Math.random() * (this.inspect.total_samples - 1) + 1);
    this.sampleInput.nativeElement.value = this.sampleIndex.toString();
    this.getSample();
  }

  public onDatasetChange(): void {
    this.dataset = this.getDataSet();
    this.cols = this.getCols();
    this.getOHLC();
    this.selectedColumn = this.cols[0]?.name;
  }

  public getDataSet(): any[] {
    if (!this.data) return [];

    const selected = this.data.find((x) => x.key === this.selectedDS);
    if (selected) {
      if (selected.value === undefined) return [];
      else if (typeof selected.value == 'number') return [selected.value];
      else return selected.value;
    }
  }

  public xValues: number[];
  public openValues: number[];
  public highValues: number[];
  public lowValues: number[];
  public closeValues: number[];
  public volumeValues: number[];

  getOHLC() {
    const names = this.cols.map((x) => x.name).join(',');
    if (!['open', 'high', 'low', 'close'].every((x) => names.includes(x))) {
      this.view = 'TABLE';
      this.csChartView = false;
    } else {
      try {
      //deep copy
      const ohlcs = this.dataset.map((arr) => {
        return arr.slice();
      });

      //add x-values
      for (let i = 0; i < ohlcs.length; i++) {
        const item = ohlcs[i];
        item.unshift(i + 1);
      }

      const toPreso = (x) => x;

      this.xValues = ohlcs.map((item) => toPreso(item[0]));
      this.openValues = ohlcs.map((item) => toPreso(item[1]));
      this.highValues = ohlcs.map((item) => toPreso(item[2]));
      this.lowValues = ohlcs.map((item) => toPreso(item[3]));
      this.closeValues = ohlcs.map((item) => toPreso(item[4]));
      this.volumeValues = ohlcs.map((item) => toPreso(item[5]));
      this.csChartView = true;

      }
      catch(ex) {
        console.error(ex)
      }
    }
  }

  public getCols(): any[] {
    if (!this.data) return [];

    const selected = this.data.find((x) => x.key === this.selectedDS);
    return selected?.returns || [];
  }

  public getColMeta(col: string = null): any {
    if (!this.data) return {};

    if (!col) col = this.selectedColumn;
    const selected = this.data.find((x) => x.key === this.selectedDS);
    return selected?.returns?.find((x) => x.name === col);
  }

  public changeView(newView: string) {
    this.view = newView;
  }

  public close() {
    this.closeInspect.emit();
  }
}
