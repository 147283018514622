import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PeopleService {
  private _collection$: Subject<any[]> = new Subject();
  private _item$: Subject<any> = new Subject();

  constructor(private http: HttpClient) {}

  public load(teamName: string): Observable<any[]> {
    this.http.get(`${ConfigService.apiUrl}/people/${teamName}`).subscribe((response: any) => {
      this._collection$.next(response);
    });

    return this._collection$.asObservable();
  }

  public get(teamName: string, username: string): Observable<any> {
    this.http.get(`${ConfigService.apiUrl}/person/${teamName}/${username}`).subscribe((response: any) => {
      this._item$.next(response);
    });

    return this._item$.asObservable();
  }
}
