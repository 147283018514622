import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { take } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { VaultItem } from 'src/app/models/vault-item';
import { VaultService } from 'src/app/services/vault.service';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { WorkersService } from 'src/app/services/workers.service';
import { Color } from '@angular-material-components/color-picker';

@Component({
  selector: 'app-vault-detail',
  templateUrl: './vault-detail.component.html',
  styleUrls: ['./vault-detail.component.scss']
})
export class VaultDetailComponent implements OnInit {
  public editorOptions = {
    theme: 'vs-dark',
    language: 'python',
    automaticLayout: true,
    scrollBeyondLastLine: false,
    minimap: {
      enabled: false
    }
  };

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  public metrics: string = null;
  public displayedColumns = ['user', 'timestamp', 'escrowed'];
  public isLoading: boolean = true;

  @Input() isWidget: boolean = false;
  @Input() teamName: string;
  @Input() vaultName: string;
  @Input() parentId: string;
  @Output() onCloseDetail = new EventEmitter<void>();

  public data: VaultItem = new VaultItem();
  public isVaulted: boolean = false;
  public errorMessage: string;
  public updatingVault: boolean = false;
  public workers: string[] = [];
  public selectedWorker: string;
  public originalName: string;
  public saveStatus: 'COMPLETE' | 'ERROR' | 'SAVING' = 'COMPLETE';

  public vaultForm = new FormGroup({
    name: new FormControl<any>(null),
    value: new FormControl<any>(null),
    notes: new FormControl<any>(null),
    color: new FormControl<any>(null),
    deployment: new FormControl<any>(null)
  });

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private ngZone: NgZone,
    public snackbar: MatSnackBar,
    private vaultService: VaultService,
    private workersService: WorkersService
  ) {}

  ngOnInit(): void {
    (<any>window).vaultDetail = this;

    this.vaultForm.controls['name'].disable();
    this.vaultForm.controls['value'].disable();
    this.vaultForm.controls['notes'].disable();
    this.vaultForm.controls['color'].disable();
    this.vaultForm.controls['deployment'].disable();

    if (this.isWidget) {
      this.load(this.teamName, this.vaultName);
    } else {
      this.activatedRoute.params.subscribe((params) => {
        this.load(params.team, params.vaultName);
      });
    }
  }

  subscribeToFormChanges() {
    this.vaultForm.setValue({
      name: this.data.name,
      value: this.data.value,
      notes: this.data.notes,
      color: this.hexToRgb(this.data.color),
      deployment: this.data.deployment
    });

    this.vaultForm.valueChanges.pipe(distinctUntilChanged()).subscribe((value: any) => {
      const raw = this.vaultForm.getRawValue();
      this.data.name = raw.name;
      this.data.value = raw.value?.replace(/,/g, '');
      this.data.notes = raw.notes;
      this.data.color = raw.color?.hex || null;
      this.data.deployment = raw.deployment;
    });
  }

  hexToRgb(hex) {
    if (!hex) return null;

    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => {
      return r + r + g + g + b + b;
    });
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result) {
      return new Color(parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16));
    }

    null;
  }

  async load(teamName: string, vaultName: string) {
    this.teamName = teamName;

    const workersSub = this.workersService.load(this.teamName).subscribe((response: any) => {
      workersSub.unsubscribe();
      this.workers = response.workers;
    });

    const sub = this.vaultService.get(teamName, vaultName).subscribe(async (response: any) => {
      sub.unsubscribe();

      if (response.vaultItem && response.vaultItem.model) {
        this.isVaulted = true;
        this.data = response.vaultItem;
        this.originalName = this.data.name;

        this.vaultForm.controls['value'].enable();
        this.vaultForm.controls['notes'].enable();
        this.vaultForm.controls['color'].enable();
        this.vaultForm.controls['deployment'].enable();

        if (this.data.workers.length == 0) this.vaultForm.controls['name'].enable();
        this.data.deployment = this.data.workers;

        this.metrics = JSON.stringify(response.vaultItem.perf, null, 4);
      }

      this.subscribeToFormChanges();
      this.isLoading = false;
    });
  }

  async removeFromVault() {
    if (!confirm(`Are you sure you want to delete ${this.data.name}?`)) return;

    this.updatingVault = true;
    const sub = this.vaultService.remove(this.teamName, this.data.name).subscribe((response) => {
      sub.unsubscribe();
      this.isVaulted = false;
      this.data = new VaultItem();
      this.updatingVault = false;

      this.vaultForm.controls['name'].disable();
      this.vaultForm.controls['value'].disable();
      this.vaultForm.controls['notes'].disable();
      this.vaultForm.controls['color'].disable();
      this.vaultForm.controls['deployment'].disable();

      if (this.isWidget) {
        this.onCloseDetail.emit();
      } else {
        this.router.navigate([this.teamName, 'vault']);
      }
    });
  }

  async save() {
    this.saveStatus = 'SAVING';

    const from = this.data.workers;
    const to = this.data.deployment;

    this.updatingVault = true;
    this.errorMessage = null;
    let reloadAfterUpdate = false;

    let new_name = null;
    if (this.data.name != this.originalName) {
      new_name = this.data.name;
      this.data.new_name = new_name;
      this.data.name = this.originalName;
      reloadAfterUpdate = true;
    }

    const sub = this.vaultService.update(this.teamName, this.data).subscribe(async (response) => {
      sub.unsubscribe();
      if (response.error) {
        this.saveStatus = 'ERROR';
        this.errorMessage = response.error;
      }

      if (await this.changeDeployment(from, to)) {
        this.openSnackBar('Restart affected services to affect deployment change.', 'OK');
      }

      this.updatingVault = false;
      this.saveStatus = 'COMPLETE';

      if (reloadAfterUpdate) {
        this.isLoading = true;
        this.data = new VaultItem();

        if (this.isWidget) {
          this.vaultName = new_name;
          this.load(this.teamName, this.vaultName);
        } else {
          this.router.navigate([this.teamName, 'vault-detail', new_name]);
        }
      }
    });
  }

  async changeDeployment(from, to) {

    from.forEach(async x => {
      if(!to.includes(x)) {
        console.log(`Removing ${this.data.name} from ${x}`)
        await this.workersService.removeModelAsync(this.teamName, x, this.data.name);
      }
    });

    to.forEach(async x => {
      if(!from.includes(x)) {
        console.log(`Adding ${this.data.name} to ${x}`)
        await this.workersService.addModelAsync(this.teamName, x, this.data.name);
      }
    });

    this.data.workers = to;

    return true;
  }

  openSnackBar(message: string, action: string) {
    const durationInSeconds = 3;
    const horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    const verticalPosition: MatSnackBarVerticalPosition = 'top';

    this.snackbar.open(message, action, {
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition,
      duration: durationInSeconds * 1000
    });
  }

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this.ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
  }

  onWorkerSelected(event) {
    this.selectedWorker = event.value;
  }

  onResize() {}

  $clickDeploy(deploy: string) {
    if (this.isWidget) {
      this.onCloseDetail.emit();
    } else {
      this.router.navigate([this.teamName, 'deploy', deploy]);
    }
  }
}
