import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';

@Component({
  selector: 'app-definitions-widget',
  templateUrl: './definitions-widget.component.html',
  styleUrls: ['../base-widget/base-widget.component.scss', './definitions-widget.component.scss']
})
export class DefinitionsWidgetComponent extends BaseWidgetComponent implements OnInit {
  @Input() filter: string;

  @Output() onViewAsset = new EventEmitter<{ assetName: string }>();
  @Output() onViewExperiments = new EventEmitter<{ projectName: string }>();
  @Output() onEditFile = new EventEmitter<{ parentId: string; path: string }>();
  @Output() onCompile = new EventEmitter<{ parentId: string; rdsName: string }>();

  public rdsName: string;

  constructor() {
    super();
  }

  ngOnInit(): void {
    if (this.filter) {
      this.rdsName = this.filter;
      this.widgetView = 'DETAIL';
    }
  }

  defintionClick($event) {
    if ($event.parentId !== this.id) return;

    this.rdsName = $event.rdsName;
    this.widgetView = 'DETAIL';
  }

  assetClick($event) {
    if ($event.parentId !== this.id) return;
    this.onViewAsset.emit({ assetName: $event.assetName });
  }

  viewExpermentsClick($event) {
    if ($event.parentId !== this.id) return;

    this.onViewExperiments.emit({ projectName: $event.projectName });
  }

  onEditFileClick($event) {
    console.log($event);
    this.onEditFile.emit({ parentId: this.id, path: $event.path });
  }

  onCompileClick($event) {
    console.log($event);
    this.onCompile.emit({ parentId: this.id, rdsName: $event.rdsName });
  }

  closeClick($event) {
    if ($event.parentId !== this.id) return;

    this.filter = null;
    this.rdsName = null;
    this.widgetView = 'LIST';
  }
  close() {
    if (this.widgetView === 'DETAIL') {
      this.filter = null;
      this.rdsName = null;
      this.widgetView = 'LIST';
    } else {
      super.close();
    }
  }
}
