import { Component, OnInit } from '@angular/core';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { WidgetService } from 'src/app/services/widget.service';

@Component({
  selector: 'app-deploys-widget',
  templateUrl: './deploys-widget.component.html',
  styleUrls: ['../base-widget/base-widget.component.scss', './deploys-widget.component.scss']
})
export class DeploysWidgetComponent extends BaseWidgetComponent implements OnInit {
  public serviceName: string;

  constructor(private _widgetService: WidgetService) {
    super();
  }

  ngOnInit(): void {

    this._subs.add = this._widgetService.resize$.subscribe((response) => {
      if ((<any>window).deploys) {
        (<any>window).deploys.onResize();
      }
      if ((<any>window).deploy) {
        (<any>window).deploy.onResize();
      }
    });

  }

  serviceClick($event) {
    if (this.id !== $event.parentId) return;
    this.serviceName = $event.serviceName;
    this.widgetView = 'DETAIL';
  }

  closeClick() {
    this.widgetView = 'LIST';
  }

  close() {
    if (this.widgetView === 'DETAIL') {
      this.widgetView = 'LIST';
    } else {
      super.close();
    }
  }
}
