// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-direction: column;
  height: 100%;
}
:host .content {
  overflow: auto;
  overflow-x: hidden;
  flex-grow: 1;
}`, "",{"version":3,"sources":["webpack://./src/app/components/strategy-grid/base-widget/base-widget.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;AACF;AACE;EACE,cAAA;EACA,kBAAA;EACA,YAAA;AACJ","sourcesContent":[":host {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n\n  .content {\n    overflow: auto;\n    overflow-x: hidden;\n    flex-grow: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
