import { Component, Inject } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-settings-dialog',
  templateUrl: './settings-dialog.component.html',
  styleUrls: ['./settings-dialog.component.scss']
})
export class SettingsDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<SettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public settings: SettingsService
  ) {}

  public $changeTheme($ev: MatButtonToggleChange): void {
    this.settings.theme = $ev.value;
  }

  public $changeChartTheme($ev: MatButtonToggleChange): void {
    this.settings.chartTheme = $ev.value;
  }

  public $changePollingRate($ev: MatButtonToggleChange): void {
    this.settings.pollingRate = $ev.value;
  }
}
