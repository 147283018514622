// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[mat-dialog-title] {
  margin: -24px -24px 0px -24px !important;
  padding: 5px 10px;
  background: #1e1e1e;
  color: #fff;
  cursor: move;
}

ngx-monaco-editor {
  padding-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/explore-chart/explore-data-dialog/explore-data-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,wCAAA;EACA,iBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;AACF;;AAEA;EACE,iBAAA;AACF","sourcesContent":["[mat-dialog-title] {\n  margin: -24px -24px 0px -24px !important;\n  padding: 5px 10px;\n  background: #1e1e1e;\n  color: #fff;\n  cursor: move;\n}\n\nngx-monaco-editor {\n  padding-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
