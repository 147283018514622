import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { PeopleService } from 'src/app/services/people.service';

@Component({
  selector: 'app-person',
  templateUrl: './person.component.html',
  styleUrls: ['./person.component.scss']
})
export class PersonComponent implements OnInit {
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  private teamName: string;
  private username: string;

  public isLoading: boolean = true;
  public person: any;
  public dataSource = new MatTableDataSource<any>([]);
  public displayedColumns = [
    'host',
    'project',
    'created',
    'lastModified',
    'totalElapsed',
    'totalValue',
    'totalCost',
    'totalEpochs',
    'totalRuns'
  ];

  public totalValue: number = 0;
  public totalCost: number = 0;
  public totalEscrowed: number = 0;

  constructor(
    private activatedRoute: ActivatedRoute,
    private peopleService: PeopleService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.teamName = params.team;
      this.username = params.username;
      this.load();
    });
  }

  load() {
    const sub = this.peopleService.get(this.teamName, this.username).subscribe((response: any) => {
      sub.unsubscribe();
      this.isLoading = false;
      this.person = response.person;
      this.dataSource.data = response.projects;
      (this.totalValue = response.projects.reduce((a, b) => a + (b.totalValue || 0), 0)),
        (this.totalCost = response.projects.reduce((a, b) => a + (b.totalCost || 0), 0)),
        (this.totalEscrowed = response.projects.reduce((a, b) => a + (b.totalEscrowed || 0), 0)),
        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }, 0);
    });
  }
}
